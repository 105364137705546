import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { CAMERA_LIST } from '../action/CameraList'
import END_POINTS from '../../config/Endpoints'
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
export function* cameraList(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.CAMERA, params, {})
        yield put(Actions.cameraListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.cameraListFailure({ error }));
        callback && callback({ error })
    }
}

export default function* cameraListWatcher() {

    yield all([
        takeLatest(CAMERA_LIST, cameraList),
    ]);
}
