import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ProductList from "../Pages/vendors/ProductList"
import { CabinetList } from "./vendors/CabinetList"
import { Planogram } from "./Planogam"
import { Subscription } from "./Subscription"
import Branches from "./vendors/Branches"
import { CustomerJourney } from "./vendors/CustomerJourney"
import { InventoryManagement } from "./vendors/InventoryManagement"
import { useSelector } from "react-redux"
import { getVendorDetails } from "../../../redux/reducer/VendorReducer"
import { getLoginData } from "../../../redux/reducer/login"
import { useDispatch } from "react-redux"
import { Actions } from "../../../redux/action"
import { toast } from "react-toastify"
import { MESSAGE } from "../../../config/String"

const VendorDetails = () => {
    const navigate = useNavigate()
    const vendorDetails = useSelector(getVendorDetails)
    const loginDetails = useSelector(getLoginData)
    const dispatch = useDispatch();
    const location = useLocation()
    const [options, setOptions] = useState(null || []);


    const goBack = () => {
        navigate(-1)
    }
    const getCabinetList = () => {
        let url = location?.pathname
        let word = url.split('/')
        const callback = (response: any) => {
            if (response?.statuscode !== 0) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
        }
        let params = {
            branchId: '',
            limit: 6,
            pagenum: 1,
            vendorId: word[3],
        }
        dispatch(Actions.cabinet_search(params, callback));
    }
    const handleCabinets = () => {
        getCabinetList()
        let url = location?.pathname
        let word = url.split('/')
        if (role == 1) {
            const params = { vendor_id: word[3] }
            dispatch(Actions.branchesList(params, branchListCallback))
        } else {
            const { user_id } = loginDetails || {}
            const params = { vendor_id: user_id }
            dispatch(Actions.branchesList(params, branchListCallback))
        }
    }
    const handleBranch = () => {
        let url = location?.pathname
        let word = url.split('/')
        const { user_id } = loginDetails || {}
        const params = { vendor_id: user_id }
        const branchparams = { vendor_id: word[3] !== undefined ? word[3] : user_id }
        dispatch(Actions.branchesList(branchparams, branchListCallback))
    }

    const branchListCallback = (res: any) => {
        let data = [];
        if (res?.response) {
            data = res?.response?.result?.map((o: any) =>
            (
                {
                    label: `${o?.name}`, value: o?.id,
                }
            )
            );
        }
        setOptions(data)
    }
    const { role, user_id } = loginDetails

    return (
        <div className="container mt-4 ">

            <div className="row">
                <div className="col-sm-6 cursor-pointer">
                    <span onClick={() => goBack()}><i className="fa fa-arrow-left"></i> Go Back</span>
                </div>
                <div className="col-sm-6 text-end">
                    <h3 className="text-uppercase"><b>{vendorDetails?.first_name} {vendorDetails?.last_name}</b></h3>
                    <span>{vendorDetails?.email}</span>
                </div>
            </div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab"
                        data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                        type="button" role="tab" aria-controls="home-tab-pane"
                        aria-selected="true">Branches</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab"
                        data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                        type="button" role="tab" aria-controls="profile-tab-pane"
                        aria-selected="false">Products</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab"
                        data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                        type="button" role="tab" aria-controls="contact-tab-pane"
                        aria-selected="false" onClick={() => handleCabinets()}>Cabinets</button>
                </li>


                <li className="nav-item" role="presentation">
                    <button className="nav-link"
                        id="customer-tab" data-bs-toggle="tab"
                        data-bs-target="#customer-tab-pane" type="button"
                        role="tab" aria-controls="customer-tab-pane" aria-selected="false"
                        onClick={() => handleBranch()}
                    >Customer Journey</button>
                </li>

                <li className="nav-item" role="presentation">
                    <button className="nav-link"
                        id="inventory-tab" data-bs-toggle="tab"
                        data-bs-target="#inventory-tab-pane" type="button"
                        role="tab" aria-controls="inventory-tab-pane" aria-selected="false"
                    >Inventory Management</button>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <button className="nav-link"
                        id="disabled-tab" data-bs-toggle="tab"
                        data-bs-target="#disabled-tab-pane" type="button"
                        role="tab" aria-controls="disabled-tab-pane" aria-selected="false"
                    >Planogram</button>
                </li> */}
                {/* <li className="nav-item" role="presentation">
                    <button className="nav-link"
                        id="subscription" data-bs-toggle="tab"
                        data-bs-target="#subscription-pane" type="button"
                        role="tab" aria-controls="subscription-pane" aria-selected="false"
                    >Subscription</button>
                </li> */}

                {/* <li className="nav-item" role="presentation">
                    <button className="nav-link"
                        id="inventoryhistory" data-bs-toggle="tab"
                        data-bs-target="#inventoryhistory-pane" type="button"
                        role="tab" aria-controls="inventoryhistory-pane" aria-selected="false"
                    >Inventory History</button>
                </li> */}
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"><Branches /></div>
                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"><ProductList /></div>
                <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"><CabinetList options={options} /></div>
                <div className="tab-pane fade" id="customer-tab-pane" role="tabpanel" aria-labelledby="customer-tab"><CustomerJourney options={options} /></div>
                <div className="tab-pane fade" id="inventory-tab-pane" role="tabpanel" aria-labelledby="customer-tab"><InventoryManagement /></div>
                <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab"><Planogram /></div>
                <div className="tab-pane fade" id="subscription-pane" role="tabpanel" aria-labelledby="subscription"><Subscription /></div>
                {/* <div className="tab-pane fade" id="inventoryhistory-pane" role="tabpanel" aria-labelledby="inventoryhistory-tab">history</div> */}
            </div>
        </div>
    )
}

export default VendorDetails