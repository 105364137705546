import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux'
import { Actions } from '../../../redux/action';
import { COLORS } from '../../../config/String';
import { getLoginData } from "../../../redux/reducer/login"
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom"


export const customSelectStyles = {
    menu: (provided: any) => ({
        ...provided,
    }),
    control: (provided: any) => ({
        ...provided,
        borderColor: COLORS.appThemeHex,
        boxShadow: null,
        display: 'flex',
        flex: 1,
        minWidth: '200px'
    }),
    singleValue: (provided: any) => ({
        ...provided,
    }),
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? COLORS.appThemeHex : 'white',
        zIndex: 999
    }),
    valueContainer: (provided: any) => ({
        ...provided,
    }),
};

const BranchTypeAhead = ({
    defaultOptions = null,
    placeholder = '',
    onChange = (value: any) => value,
    isMulti = false,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const usersDetails = useSelector(getLoginData)
    const { role } = usersDetails || {}
    const vendorId = useParams();

    const optionsRef = useRef()
    const searchApi = useCallback((value?: any) => {
        const defaultFilter = {
            vendor_id: props?.vendorId,
            limit: 10,
            is_dropdown : true
        };
        if (role == 1 && props.vendorId || role !== 1) {
            setIsLoading(true);
            dispatch(Actions.branchesList(defaultFilter, (resp: any) => {
                let data = [];
                if (resp?.response) {
                    const fileredValue=resp?.response?.result?.filter((obj:any)=>obj?.is_active === true)
                    data = fileredValue?.map((o: any) =>
                    (
                        {
                            label: `${o?.name}`, value: o?.id,
                        }
                    )
                    );
                }
                setIsLoading(false)
                props.setOptions(data)
                optionsRef.current = data
            }))
        }
    }, [usersDetails, dispatch]);

    const debounceRef = useRef(debounce((value: any) => {
        searchApi(value ? value : '');
    }, 500));

    useEffect(() => {
        searchApi();
    }, [dispatch, searchApi])

    return <Select
        className="title-case"
        {...props}
        isDisabled={role == 1 && !props.vendorId || props.options?.length == 0 ? true : false}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        onInputChange={(value) => debounceRef.current(value)}
        options={props.options}
        isMulti={isMulti}
        onChange={(value) => onChange(value || {})}
    />
}



export default BranchTypeAhead;