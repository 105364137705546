import moment from "moment"

export const AccordionMolecules = ({ labelClassName, time, label }: { labelClassName: string, time: string, label: string }) => {
    return (
        <>
            <div className="col-sm">
                <div className="row">
                    <p className={`${labelClassName}Title`}>{label}</p>
                    {
                        time !== null &&
                        <p className={`${labelClassName}`}>
                            {
                                time && label.includes("Date") ? moment(time).format('DD-MM-YYYY ') : moment(time).format('hh:mm:ss a')
                            }
                        </p>
                    }
                </div>
            </div>
        </>
    )
}