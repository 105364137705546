import { useSelector } from "react-redux"
import END_POINTS from "../../../config/Endpoints"
import { getLoginData } from '../../../redux/reducer/login'
export const VideoClip = ({ close, eventData }: { close: (setpop: boolean) => void, eventData: string }) => {
    const token = useSelector(getLoginData)
    return (
        <>
            <div className='videoClips'>
                <video controls>
                    <source src={END_POINTS.API_BASE_URL + eventData} type="video/mp4" />
                </video>
                <i className="fa fa-times fa-3x CloseIcon" aria-hidden="true" onClick={() => close(false)}></i>
            </div>
        </>
    )
}