import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    registerProduct: undefined,
    error: false
}
export const registerReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.REGISTER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.REGISTER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                registerProduct: action.data,
                error: false
            };
        }
        case Actions.REGISTER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                registerProduct: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const registerData = (state: any) => state?.REGISTER_PRODUCT?.registerProduct;
export const registerIsLoading = (state: any) => state?.REGISTER_PRODUCT?.isLoading;
