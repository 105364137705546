import Pagination from "react-js-pagination";
export const PaginationSession: any = ({ onChangeHandle, currentPage, sessionCount }: { onChangeHandle: (page: number) => void, currentPage: number, sessionCount: number }) => {
    return (
        <>
            <div className="mt-3 d-flex pagination">
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={6}
                    totalItemsCount={sessionCount * 6}
                    pageRangeDisplayed={6}
                    onChange={onChangeHandle}
                    activeLinkClass='active'
                    itemClass='page-item'
                    linkClass="page-link"
                    prevPageText='Prev'
                    nextPageText='Next'
                />
            </div>
        </>
    )
}