
export const Button = (props: ButtonInterface) => {
    return (
        <button
            type={props.buttonType}
            className={props.buttonClassName}
            disabled={props.disable}
            id={props.id}
            onClick={props.handleClick}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            aria-expanded={props.expanded}
            aria-controls={props.controls}
        >
            {props.buttonName}
        </button>
    )
}
export type ButtonInterface = {
    buttonType?: 'button' | 'submit' | 'reset',
    buttonClassName: string,
    disable?: boolean,
    id?: string,
    buttonName: string | any,
    handleClick: (event: any) => void,
    toggle?: string,
    target?: string,
    expanded?: boolean,
    controls?: string
}