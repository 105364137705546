import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    dashboardList: [],
    error: false
}
export const DashboardReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        //DASHBOARD_LIST
        case Actions.DASHBOARD_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DASHBOARD_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                dashboardList: action.data,
                error: false
            };
        }
        case Actions.DASHBOARD_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                dashboardList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export const getDashboardData = (state: any) => state?.DASHBOARD?.dashboardList;
export const getDashboardLoader = (state: any) => state.DASHBOARD.isLoading

