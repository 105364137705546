import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    planogramStatus: {},
    error: false
}
export const planogramStatusReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.PLANOGRAM_STATUS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PLANOGRAM_STATUS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                planogramStatus: action?.data?.response,
                error: false
            };
        }
        case Actions.PLANOGRAM_STATUS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                planogramStatus: {},
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};
export const getCabinetData = (state: any) => state?.PLANOGRAM_STATUS_REDUCER?.planogramStatus;
