import { doAction, doResponseAction } from ".";

const SESSION_CABINET = 'SESSION_CABINET';
const SESSION_CABINET_SUCCESS = 'SESSION_CABINET_SUCCESS';
const SESSION_CABINET_FAILURE = 'SESSION_CABINET_FAILURE';
export {
    SESSION_CABINET,
    SESSION_CABINET_FAILURE,
    SESSION_CABINET_SUCCESS,
}

// SESSION_CABINET
export const session_cabinet = (params: {}, callback?: any) => doAction(SESSION_CABINET, params, callback);
export const session_cabinetSuccess = (data: {}) => doResponseAction(SESSION_CABINET_SUCCESS, data);
export const session_cabinetFailure = (data: {}) => doResponseAction(SESSION_CABINET_FAILURE, data);