import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    cabinet_list: [],
    count: '',
    error: false
}
export const cabinetListReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.CABINET_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CABINET_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cabinet_list: action?.data?.results,
                count: action?.data?.count,
                error: false
            };
        }
        case Actions.SEARCH_CABINET_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cabinet_list: action?.data,
                count: action?.data?.count,
                error: false
            };
        }
        case Actions.CABINET_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                cabinet_list: [],
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};
export const getCabinetData = (state: any) => state?.CABINET_LIST_REDUCER?.cabinet_list;
export const getcabinetListCount = (state: any) => state?.CABINET_LIST_REDUCER?.count
