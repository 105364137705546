import { doAction, doResponseAction } from ".";

const CUSTOMER_JOURNEY = 'CUSTOMER_JOURNEY';
const CUSTOMER_JOURNEY_SUCCESS = 'CUSTOMER_JOURNEY_SUCCESS';
const CUSTOMER_JOURNEY_FAILURE = 'CUSTOMER_JOURNEY_FAILURE';
export {
    CUSTOMER_JOURNEY,
    CUSTOMER_JOURNEY_FAILURE,
    CUSTOMER_JOURNEY_SUCCESS,
}

// customerJourney
export const customerJourney = (params: {}, callback?: any) => doAction(CUSTOMER_JOURNEY, params, callback);
export const customerJourneySuccess = (data: {}) => doResponseAction(CUSTOMER_JOURNEY_SUCCESS, data);
export const customerJourneyFailure = (data: {}) => doResponseAction(CUSTOMER_JOURNEY_FAILURE, data);