import { doAction } from ".";

const CAMERA_ID = 'CAMERA_ID';

export {
    CAMERA_ID,
}

// CameraId
export const cameraIDList = (data = {}) => doAction(CAMERA_ID, data);


