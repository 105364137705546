import { doAction, doResponseAction } from ".";

const PUBLISH_PRODUCT_LIST = 'PUBLISH_PRODUCT_LIST';
const PUBLISH_PRODUCT_LIST_SUCCESS = 'PUBLISH_PRODUCT_LIST_SUCCESS';
const PUBLISH_PRODUCT_LIST_FAILURE = 'PUBLISH_PRODUCT_LIST_FAILURE';

const ADMIN_PRODUCT_LIST = 'ADMIN_PRODUCT_LIST';
const ADMIN_PRODUCT_LIST_SUCCESS = 'ADMIN_PRODUCT_LIST_SUCCESS';
const ADMIN_PRODUCT_LIST_FAILURE = 'ADMIN_PRODUCT_LIST_FAILURE';

const PRODUCT_DETAILS = 'PRODUCT_DETAILS';
const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
const PRODUCT_DETAILS_FAILURE = 'PRODUCT_DETAILS_FAILURE';

const CREATE_PRODUCT = 'CREATE_PRODUCT';
const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';


const ADMIN_CREATE_PRODUCT = 'ADMIN_CREATE_PRODUCT';
const ADMIN_CREATE_PRODUCT_SUCCESS = 'ADMIN_CREATE_PRODUCT_SUCCESS';
const ADMIN_CREATE_PRODUCT_FAILURE = 'ADMIN_CREATE_PRODUCT_FAILURE';

const PRODUCT_DELETE = 'PRODUCT_DELETE';
const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
const PRODUCT_DELETE_FAILURE = 'PRODUCT_DELETE_FAILURE';

const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
const PRODUCT_UPDATE_FAILURE = 'PRODUCT_UPDATE_FAILURE';

const ADMIN_PRODUCT_UPDATE = 'ADMIN_PRODUCT_UPDATE';
const ADMIN_PRODUCT_UPDATE_SUCCESS = 'ADMIN_PRODUCT_UPDATE_SUCCESS';
const ADMIN_PRODUCT_UPDATE_FAILURE = 'ADMIN_PRODUCT_UPDATE_FAILURE';

const ADMIN_PRODUCT_PUBLISH = 'ADMIN_PRODUCT_PUBLISH';
const ADMIN_PRODUCT_PUBLISH_SUCCESS = 'ADMIN_PRODUCT_PUBLISH_SUCCESS';
const ADMIN_PRODUCT_PUBLISH_FAILURE = 'ADMIN_PRODUCT_PUBLISH_FAILURE';

const PRODUCT_ACTIVE = 'PRODUCT_ACTIVE';
const PRODUCT_ACTIVE_SUCCESS = 'PRODUCT_ACTIVE_SUCCESS';
const PRODUCT_ACTIVE_FAILURE = 'PRODUCT_ACTIVE_FAILURE';

const ADMIN_PRODUCT_ACTIVE = 'ADMIN_PRODUCT_ACTIVE';
const ADMIN_PRODUCT_ACTIVE_SUCCESS = 'ADMIN_PRODUCT_ACTIVE_SUCCESS';
const ADMIN_PRODUCT_ACTIVE_FAILURE = 'ADMIN_PRODUCT_ACTIVE_FAILURE';

const SEARCH_PRODUCT_LIST = 'SEARCH_PRODUCT_LIST'

export {
    PUBLISH_PRODUCT_LIST,
    PUBLISH_PRODUCT_LIST_FAILURE,
    PUBLISH_PRODUCT_LIST_SUCCESS,

    ADMIN_PRODUCT_LIST,
    ADMIN_PRODUCT_LIST_FAILURE,
    ADMIN_PRODUCT_LIST_SUCCESS,

    PRODUCT_DETAILS,
    PRODUCT_DETAILS_FAILURE,
    PRODUCT_DETAILS_SUCCESS,

    CREATE_PRODUCT,
    CREATE_PRODUCT_FAILURE,
    CREATE_PRODUCT_SUCCESS,

    ADMIN_CREATE_PRODUCT,
    ADMIN_CREATE_PRODUCT_FAILURE,
    ADMIN_CREATE_PRODUCT_SUCCESS,

    PRODUCT_DELETE,
    PRODUCT_DELETE_FAILURE,
    PRODUCT_DELETE_SUCCESS,

    PRODUCT_UPDATE,
    PRODUCT_UPDATE_FAILURE,
    PRODUCT_UPDATE_SUCCESS,

    ADMIN_PRODUCT_UPDATE,
    ADMIN_PRODUCT_UPDATE_FAILURE,
    ADMIN_PRODUCT_UPDATE_SUCCESS,

    ADMIN_PRODUCT_PUBLISH,
    ADMIN_PRODUCT_PUBLISH_FAILURE,
    ADMIN_PRODUCT_PUBLISH_SUCCESS,

    ADMIN_PRODUCT_ACTIVE,
    ADMIN_PRODUCT_ACTIVE_FAILURE,
    ADMIN_PRODUCT_ACTIVE_SUCCESS,

    PRODUCT_ACTIVE,
    PRODUCT_ACTIVE_FAILURE,
    PRODUCT_ACTIVE_SUCCESS,

    SEARCH_PRODUCT_LIST
}

// Publish Product List
export const publish_Product_List = (params?: {}, callback?: any) => doAction(PUBLISH_PRODUCT_LIST, params, callback);
export const publish_Product_List_Success = (data: {}) => doResponseAction(PUBLISH_PRODUCT_LIST_SUCCESS, data);
export const publish_Product_List_Failure = (data: {}) => doResponseAction(PUBLISH_PRODUCT_LIST_FAILURE, data);

//Request Product List
export const admin_Product_List = (params?: {}, callback?: any) => doAction(ADMIN_PRODUCT_LIST, params, callback);
export const admin_Product_List_Success = (data: {}) => doResponseAction(ADMIN_PRODUCT_LIST_SUCCESS, data);
export const admin_Product_List_Failure = (data: {}) => doResponseAction(ADMIN_PRODUCT_LIST_FAILURE, data);

// PRODUCT_DETAILS
export const productDetails = (params?: {}, callback?: any) => doAction(PRODUCT_DETAILS, params, callback);
export const productDetailsSuccess = (data = {}) => doResponseAction(PRODUCT_DETAILS_SUCCESS, data);
export const productDetailsFailure = (data = {}) => doResponseAction(PRODUCT_DETAILS_FAILURE, data);

// CREATE_PRODUCT
export const createProduct = (params?: {}, callback?: any) => doAction(CREATE_PRODUCT, params, callback);
export const createProductSuccess = (data = {}) => doResponseAction(CREATE_PRODUCT_SUCCESS, data);
export const createProductFailure = (data = {}) => doResponseAction(CREATE_PRODUCT_FAILURE, data);

// CREATE_PRODUCT
export const AdmincreateProduct = (params?: {}, callback?: any) => doAction(ADMIN_CREATE_PRODUCT, params, callback);
export const AdmincreateProductSuccess = (data = {}) => doResponseAction(ADMIN_CREATE_PRODUCT_SUCCESS, data);
export const AdmincreateProductFailure = (data = {}) => doResponseAction(ADMIN_CREATE_PRODUCT_FAILURE, data);
// PRODUCT_DELETE
export const productDelete = (params?: {}, callback?: any) => doAction(PRODUCT_DELETE, params, callback);
export const productDeleteSuccess = (data = {}) => doResponseAction(PRODUCT_DELETE_SUCCESS, data);
export const productDeleteFailure = (data = {}) => doResponseAction(PRODUCT_DELETE_FAILURE, data);

// PRODUCT_UPDATE
export const productUpdate = (params?: {}, callback?: any) => doAction(PRODUCT_UPDATE, params, callback);
export const productUpdateSuccess = (data = {}) => doResponseAction(PRODUCT_UPDATE_SUCCESS, data);
export const productUpdateFailure = (data = {}) => doResponseAction(PRODUCT_UPDATE_FAILURE, data);

export const adminproductUpdate = (params?: {}, callback?: any) => doAction(ADMIN_PRODUCT_UPDATE, params, callback);
export const adminproductUpdateSuccess = (data = {}) => doResponseAction(ADMIN_PRODUCT_UPDATE_SUCCESS, data);
export const adminproductUpdateFailure = (data = {}) => doResponseAction(ADMIN_PRODUCT_UPDATE_FAILURE, data);

export const adminproductPublish = (params?: {}, callback?: any) => doAction(ADMIN_PRODUCT_PUBLISH, params, callback);
export const adminproductPublishSuccess = (data = {}) => doResponseAction(ADMIN_PRODUCT_PUBLISH_SUCCESS, data);
export const adminproductPublishFailure = (data = {}) => doResponseAction(ADMIN_PRODUCT_PUBLISH_FAILURE, data);
// PRODUCT_ACTIVE
export const productActive = (params?: {}, callback?: any) => doAction(PRODUCT_ACTIVE, params, callback);
export const productActiveSuccess = (data = {}) => doResponseAction(PRODUCT_ACTIVE_SUCCESS, data);
export const productActiveFailure = (data = {}) => doResponseAction(PRODUCT_ACTIVE_FAILURE, data);

export const adminProductActive = (params?: {}, callback?: any) => doAction(ADMIN_PRODUCT_ACTIVE, params, callback);
export const adminProductActiveSuccess = (data = {}) => doResponseAction(ADMIN_PRODUCT_ACTIVE_SUCCESS, data);
export const adminProductActiveFailure = (data = {}) => doResponseAction(ADMIN_PRODUCT_ACTIVE_FAILURE, data);

export const search_Product_List = (data: {}) => doResponseAction(SEARCH_PRODUCT_LIST, data);