import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import '../../../assets/css/custom_css/CameraList.css'
import { cabinetList, cabinetSearchListEndPoint } from '../../../config/Endpoints'
import { MESSAGE } from '../../../config/String'
import { Actions } from '../../../redux/action'
import { getCabinetData, getcabinetListCount } from '../../../redux/reducer/CabinetListReducer'
import { Input } from '../../UI/Atoms/Input'
import { PaginationSession } from '../../UI/Molecules/Pagination'
import PlanogramLoader from '../../UI/Molecules/PlanogramLoader'
import { CabinetList } from "./vendors/CabinetList"
const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
export const PlanogramCameraList = () => {
    const cabinetListData = useSelector(getCabinetData)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isLoading, setLoading] = useState<boolean>(false)
    const count = useSelector(getcabinetListCount)
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState<string>('')
    //cabinet list api function
    const getCabinetList = () => {
        const callback = (response: any) => {
            setLoading(false)
            if (!response || response === 500 || response === 404) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
        }
        const sessionURL = cabinetList(currentPage)
        setLoading(true)
        dispatch(Actions.cabinetList(sessionURL, callback))
    }

    //search function input is event
    const handleSearch = () => {
        const callback = (response: any) => {
            setLoading(false)
            if (!response || response.error === 500 || response.error === 404) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
            else if (response?.statuscode === 1) {
                toast.error(response?.message)
            }
            else {
                dispatch(Actions.searchCabinetListSuccess([response?.contents]))
            }
        }
        // const cabinetSearchURL = cabinetSearchListEndPoint(searchData.trim())
        if (searchData === '') {
            getCabinetList()
        }
        else {
            setLoading(true)
            // dispatch(Actions.cabinet_search(cabinetSearchURL, callback));
        }
    }

    //For handle the pagination
    const onChangeHandle = (page: number) => {
        setCurrentPage(page)
    }

    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <section className='CameraList'>
                <div>
                    <p className='cabinetListHeader'>{MESSAGE.CABINET_LIST}</p>
                </div>
                {
                    (cabinetListData && cabinetListData?.length > 0) ?
                        <div className='cabinetSearchBar'>
                            <Input inputType="search" inputClassName="form-control" placeholder={MESSAGE.SEARCH_CABINET} handleChange={(event) => setSearchData(event.target.value)} handleKeydown={blockInvalidChar} />
                            <button className='btn btn-primary searchButton' onClick={handleSearch}><i className="fa fa-search searchIcon" aria-hidden="true"></i></button>
                        </div> : ""
                }
                <div className='cabinetListMain'>
                    {/* <CabinetList currentPage={currentPage} /> */}
                </div>
                {
                    cabinetListData?.length > 4 &&
                    <PaginationSession onChangeHandle={onChangeHandle} currentPage={currentPage} sessionCount={count} />
                }
            </section>
        </>
    )
}