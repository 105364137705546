import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    unregisterProduct: undefined,
    error: false
}
export const unregisterReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.UNREGISTER_PRODUCT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UNREGISTER_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                unregisterProduct: action.data,
                error: false
            };
        }
        case Actions.UNREGISTER_PRODUCT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                unregisterProduct: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const unregisterProductData = (state: any) => state?.UNREGISTER_PRODICT?.unregisterProduct?.results;
export const unregisterProductCount = (state: any) => state?.UNREGISTER_PRODICT?.unregisterProduct?.count;
export const unregisterIsLoading = (state: any) => state?.UNREGISTER_PRODICT?.isLoading;

