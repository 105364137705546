import { useEffect, useState } from "react"
import { Input } from "../../UI/Atoms/Input"
import BoldError from "../../UI/Atoms/BoldError";
import { Actions } from '../../../redux/action'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MESSAGE } from '../../../config/String'
import { toast } from "react-toastify";
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader";
import { useSelector } from "react-redux";
import { getToken } from "../../../redux/reducer/login";

const isCharacters = /[a-zA-Z -]+$/;
export const loginHeader = MESSAGE.LOGIN_HEADER
export const Login = () => {
    const [name, setName] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [error, setError] = useState<string | null>('')
    const [passwordType, setPasswordType] = useState<string>("password");
    const [isLoading, setLoading] = useState<boolean>(false)
    const Token = useSelector(getToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const togglePassword = (event: any) => {
        event.preventDefault();
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    useEffect(() => {
        if (Token) {
            navigate('/dashboard')
        }
    }, [])
    const OnSubmit = (event: any) => {
        event.preventDefault()
        const params = new FormData();
        params.append('username', name)
        params.append('password', password)
        const callback = (response: any) => {
            setLoading(false)
            if (response?.token) {
                navigate('/dashboard')
            }
            else if (response?.error === 400) {
                toast.error(MESSAGE.USERNAME_PASSWORD_DOES_NOT_MATCH)
            }
            else {
                toast.error(response?.message)
            }
        }
        if (!name && !password) {
            setError(MESSAGE.LOGIN.PLEASE_ENTER_THE_USERNAME_PASSOWRD);
        } else if (!name) {
            setError(MESSAGE.LOGIN.PLEASE_ENTER_THE_USER_NAME);
        }
        else if (name?.trim().length < 3) {
            setError('Name Minimum 3 Character');
        } else if (!password) {
            setError(MESSAGE.LOGIN.PLEASE_ENTER_THE_PASSWORD)

        } else {
            setError(null)
            setLoading(true)
            dispatch(Actions.loginUser(params, callback))
            event.preventDefault();
        }
    }
    const handleUsername = (event: any) => {
        let name = event.target.value
        setName(name.trim())
    }
    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <div className="Login_container">
                <div id="login-form-wrap">
                    <h2>{loginHeader}</h2>
                    {
                        error && <BoldError message={error} />
                    }
                    <form id="login-form">
                        <p>
                            <Input inputType={'text'} id={'username'} inputname={'username'} placeholder={'Username'} handleChange={handleUsername} value={name} />
                        </p>
                        <p>
                            <Input inputType={passwordType} id={'password'} inputname={'password'} placeholder={'Password'} value={password} handleChange={(e) => setPassword(e.target.value)} />
                            {passwordType === "password" ? <i className="fa fa-eye-slash fa-2x toggle" aria-hidden="true" onClick={(event) => togglePassword(event)}></i> : <i className="fa fa-eye fa-2x toggle" aria-hidden="true" onClick={(event) => togglePassword(event)}></i>}
                        </p>
                        <p>
                            <Input inputType={'submit'} id={'login'} value={'Login'} handleClick={(e) => OnSubmit(e)} />
                        </p>
                    </form>
                </div>
            </div>
        </>
    )
}