import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getisActive, getTrainigIdData } from "../../../redux/reducer/TrainingIdReducer";
import { toast } from "react-toastify"
import { Actions } from "../../../redux/action";
import { RegisterMolecules } from "../../UI/Molecules/RegisterMolecules";
import { PopupData } from "../../UI/Molecules/PopUpData";
import { MESSAGE } from '../../../config/String'
import { Input } from "../../UI/Atoms/Input";
export const RegisterWidgets = () => {
    const trainingref = useRef(null)
    const dispatch = useDispatch();
    const [popup, setPopup] = useState(false);
    const [popupImage, setPopUpImage] = useState<string>();
    const [imageFile, setImageFile] = useState<File>();
    const [registerData, setRegisterData] = useState<{
        Product_Name: string,
        SKU_ID: string,
        Training_ID: string,
        Height: string,
        Weight: string,
        Width: string,
        image_file: string | ArrayBuffer | null,
    }>(
        {
            Product_Name: '',
            SKU_ID: '',
            Training_ID: '',
            Height: '',
            Weight: '',
            Width: '',
            image_file: '',
        })
    const isactive = useSelector(getisActive);
    const Training_Id = useSelector(getTrainigIdData)
    const handlePopImage = (image: string) => {
        setPopup(true)
        setPopUpImage(image)
    }
    const Register = (event: React.FormEvent<HTMLFormElement>) => {
        if (isactive) {
            setRegisterData({ ...registerData, ...{ "Training_ID": Training_Id } })
        }
        event.preventDefault();
        let formData = new FormData();
        formData.append("image_file", imageFile as unknown as Blob)
        formData.append("product_name", registerData.Product_Name)
        formData.append("sku", registerData.SKU_ID)
        formData.append("training_id", Training_Id ? Training_Id : registerData.Training_ID)
        formData.append("height", registerData.Height)
        formData.append("weight", registerData.Weight)
        formData.append("width", registerData.Width)
        const registerCallback = (response: any) => {
            if (response.status === 0) {
                toast.success("Register Successfully")
            }
            else {
                toast.warning(response.message)
            }
        }
        dispatch(Actions.register(formData, registerCallback))
        dispatch(Actions.trainingIdList(''))
    }
    /*** Onchange Training Id ***/
    const getTrainingId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegisterData({ ...registerData, ...{ "Training_ID": event.target.value } })
    }
    /*** Upload image convert URL into Base-64 ***/
    const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImageFile(event.target.files![0])
        let reader = new FileReader();
        reader.onload = function () {
            setRegisterData({ ...registerData, ...{ "image_file": reader.result } })
        }
        reader.readAsDataURL(event.target.files![0]);
    }
    return (
        <>
            <div className="productList">
                <div className="Title">
                    {MESSAGE.REGISTER}
                </div>
                <div>
                    <div className="RegisterContainer">
                        <form id="form" onSubmit={Register} encType="multipart/form-data">
                            <div className="container">
                                <div className="row g-2">
                                    <div className="col-sm registerColumn">
                                        <RegisterMolecules label={MESSAGE.PRODUCT_NAME} id="ProductName" placeholder={MESSAGE.ENTER_PRODUCT_NAME} handlechange={(e) => setRegisterData({ ...registerData, ...{ "Product_Name": e.target.value } })} />
                                        <RegisterMolecules label={MESSAGE.SKU} id="SKUId" placeholder={MESSAGE.ENTER_SKU_ID} handlechange={(e) => setRegisterData({ ...registerData, ...{ "SKU_ID": e.target.value } })} />
                                        <RegisterMolecules label={MESSAGE.TRAINING_ID} id="TrainingId" placeholder={MESSAGE.ENTER_TRAINING_ID} handlechange={(e) => getTrainingId(e)} referValue={trainingref} inputValue={isactive ? Training_Id : undefined} />
                                    </div>
                                    <div className="col-sm registerColumn">
                                        <RegisterMolecules label={MESSAGE.HEIGHT} id="Height" placeholder={MESSAGE.ENTER_HEIGHT_IN_MM} handlechange={(e) => setRegisterData({ ...registerData, ...{ "Height": e.target.value } })} />
                                        <RegisterMolecules label={MESSAGE.WEIGHT} id="Weight" placeholder={MESSAGE.ENTER_WEIGHT_IN_KG} handlechange={(e) => setRegisterData({ ...registerData, ...{ "Weight": e.target.value } })} />
                                        <RegisterMolecules label={MESSAGE.WIDTH} id="Width" placeholder={MESSAGE.ENTER_WIDTH_IN_MM} handlechange={(e) => setRegisterData({ ...registerData, ...{ "Width": e.target.value } })} />
                                    </div>
                                    <div className="col-sm registerColumn">
                                        <RegisterMolecules label="Image" handlechange={handleImage} />
                                        <div className="row">
                                            {registerData?.image_file ?
                                                <span onClick={() => handlePopImage(registerData?.image_file as string)}>
                                                    <img src={registerData?.image_file as string} className="RegisterImage" alt="registerImage" />
                                                </span> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Register_Button_div">
                                <Input inputType="submit" inputClassName="btn btn-primary registerButton" value={MESSAGE.REGISTER} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                popup && <PopupData image={popupImage} close={() => setPopup(false)} />
            }
        </>
    )
}