import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import END_POINTS from '../../config/Endpoints';
export function* searchListSaga(param: any) {
    const { callback, params } = param
    try {
        const response: ResponseGenerator = yield doGet(params, '', '')
        callback && callback(response?.response)
        yield put(Actions.search_Success(response));

    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.search_Failure({ error }));
        }
    }
}
export function* cabinetSearchListSaga(param: any) {
    const { callback, params } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.SEARCH_CABINET,params, '')
        callback && callback(response)
        yield put(Actions.cabinet_search_Success(response));

    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.cabinet_search_Failure({ error }));
        }
    }
}
export default function* searchListWatcher() {
    yield all([
        takeLatest(Actions.SEARCH, searchListSaga),
        takeLatest(Actions.CABINET_SEARCH, cabinetSearchListSaga),
    ]);
}