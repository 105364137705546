import { useEffect, useState } from "react"
import { CheckBoxMolecules } from "../../UI/Molecules/CheckboxMolecules"
import { PaginationSession } from "../../UI/Molecules/Pagination"
import { AddMasterSubscription } from "./AddMasterSubscription"
import { useDispatch } from "react-redux"
import { Actions } from "../../../redux/action"
import { useSelector } from "react-redux"
import { getMasterSubscriptionList, getMasterSubscriptionListCount } from "../../../redux/reducer/MasterSubscriptionReducer"
import { DataNotFound } from "../../UI/Molecules/DataNotFound"
import { toast } from "react-toastify"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"

export const MasterSubscriptionWidget = () => {
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [showPopup, setShowPopup] = useState(false)
    const [editObject, setEditObject] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const masterSubscriptionList = useSelector(getMasterSubscriptionList)
    const listCount = useSelector(getMasterSubscriptionListCount)
    useEffect(() => {
        setIsLoading(true)
        dispatch(Actions.masterSubscriptionList('', mastersubscriptionCallback))
    }, [dispatch])

    const mastersubscriptionCallback = (response: any) => {
        setIsLoading(false)
        if (response?.status_code === 0) {
        } else {
            toast.error(response?.message)
        }

    }
    const addSubscription = () => {
        setShowPopup(true)
    }
    const onActive = (event: any, obj: any) => {
        const callback = (response: any) => {
            if (response?.status_code === 0) {
                dispatch(Actions.masterSubscriptionList({ pagenum: currentPageNumber }))
                if (event.target.checked) {
                    toast.success("Successfully deactivated")
                } else {
                    toast.success("Successfully activated")
                }
            } else {
                toast.error(response?.message)
            }
        }
        let params = {
            master_subscription_id: obj?.id,
            is_active: !obj?.is_active
        }
        dispatch(Actions.masterSubscriptionStatusUpdate(params, callback))
    }
    const handlePage = (page: number) => {
        setCurrentPageNumber(page)
        dispatch(Actions.masterSubscriptionList({ pagenum: page }))
    }

    const editMasterSubscription = (obj: any) => {
        setShowPopup(true)
        setEditObject(obj)
    }

    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <div className="container mt-4 ">
                <div className="row">
                    <div className="col-sm-6 offset-6 text-end">
                        <button className="btn btn-primary big-btn" onClick={() => addSubscription()}>Add new subscription</button>
                    </div>
                </div>
                <div className="row">
                    <div className="card card-body rounded-3 border-0 shadow p-2 mb-3 mb-xl-0 mt-2">
                        {
                            masterSubscriptionList?.length > 0 ?
                                <table className="table table-hover table-striped table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Subscription name</th>
                                            <th scope="col">Subscription type</th>
                                            <th scope="col">Subscription price</th>
                                            <th scope="col">Subscription status</th>
                                            {/* <th scope="col">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            masterSubscriptionList?.length > 0 && masterSubscriptionList?.map((item: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item?.subscription_name}</td>
                                                        <td>{item?.subscriptions_type}</td>
                                                        <td>{item?.subscriptions_price}</td>
                                                        <td>
                                                            <CheckBoxMolecules
                                                                checked={item?.is_active}
                                                                onChange={(event) => onActive(event, item)}
                                                            />
                                                        </td>
                                                        {/* <td>
                                                            <button className="btn btn-primary big-btn" onClick={() =>editMasterSubscription(item)}>Edit</button>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                                : <DataNotFound Error="Subscription not found" />
                        }

                    </div>
                </div>
                {
                    listCount > 1 &&
                    <PaginationSession
                        onChangeHandle={handlePage}
                        currentPage={currentPageNumber}
                        sessionCount={listCount} />
                }
            </div>
            {
                showPopup && <AddMasterSubscription close={() => setShowPopup(false)} editObject={editObject} setEditObject={setEditObject} />
            }
        </>
    )
}