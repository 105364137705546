import { doAction, doResponseAction } from ".";

const REPORT_LIST = 'REPORT_LIST';
const REPORT_LIST_SUCCESS = 'REPORT_LIST_SUCCESS';
const REPORT_LIST_FAILURE = 'REPORT_LIST_FAILURE';

const REPORT_LIST_API = 'REPORT_LIST_API'
const REPORT_LIST_API_SUCCESS = 'REPORT_LIST_API_SUCCESS';
const REPORT_LIST_API_FAILURE = 'REPORT_LIST_API_FAILURE';
export {
    REPORT_LIST_API,
    REPORT_LIST_API_FAILURE,
    REPORT_LIST_API_SUCCESS,

    REPORT_LIST,
    REPORT_LIST_FAILURE,
    REPORT_LIST_SUCCESS,
}

// reportList
export const reportList = (params?: {}, callback?: any) => doAction(REPORT_LIST, params, callback);
export const reportListSuccess = (data = {}) => doResponseAction(REPORT_LIST_SUCCESS, data);
export const reportListFailure = (data = {}) => doResponseAction(REPORT_LIST_FAILURE, data);


export const report_Api_List = (params?: {}, callback?: any) => doAction(REPORT_LIST_API, params, callback);
export const report_Api_List_Success = (data = {}) => doResponseAction(REPORT_LIST_API_SUCCESS, data);
export const report_Api_List_Failure = (data = {}) => doResponseAction(REPORT_LIST_API_FAILURE, data);