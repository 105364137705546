import { doAction, doResponseAction } from ".";

const SEARCH = 'SEARCH';
const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
const SEARCH_FAILURE = 'SEARCH_FAILURE';
const CABINET_SEARCH = 'CABINET_SEARCH';
const CABINET_SEARCH_SUCCESS = 'CABINET_SEARCH_SUCCESS';
const CABINET_SEARCH_FAILURE = 'CABINET_SEARCH_FAILURE';
export {
    SEARCH,
    SEARCH_FAILURE,
    SEARCH_SUCCESS,
    CABINET_SEARCH,
    CABINET_SEARCH_FAILURE,
    CABINET_SEARCH_SUCCESS,
}

// Search List
export const search = (params: {}, callback?: any) => doAction(SEARCH, params, callback);
export const search_Success = (data: {}) => doResponseAction(SEARCH_SUCCESS, data);
export const search_Failure = (data: {}) => doResponseAction(SEARCH_FAILURE, data);

// Cabinet Search List
export const cabinet_search = (params: {}, callback?: any) => doAction(CABINET_SEARCH, params, callback);
export const cabinet_search_Success = (data: {}) => doResponseAction(CABINET_SEARCH_SUCCESS, data);
export const cabinet_search_Failure = (data: {}) => doResponseAction(CABINET_SEARCH_FAILURE, data);