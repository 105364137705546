import * as login from './login'
import * as cameraList from './CameraList'
import * as cameraId from './GetCameraIdAction'
import * as customerJourney from './CustomerJourney'
import * as session_cabinet from './SessionCabinet'
import * as trainingIdList from './TrainingIdAction'
import * as searchList from './SearchAction'
import * as productList from './ProductListAction'
import * as productlistSearch from './ProductListSearch'
import * as unregisterProduct from './UnregisterProduct'
import * as getTrainingId from './GenerateTrainingId'
import * as register from './RegisterAction'
import * as getplanogram from './PlanogramAction'
import * as historyAction from './HistroyAction'
import * as update from './UpdatePlanogram'
import * as autofill from './AutoFillAction'
import * as sessionStart from './SessionStart'
import * as sessionEnd from './SessionEnd'
import * as cabinetList from './CabinetList'
import * as planogramStatus from './PlanogramStatus'
import * as cabinetOnboarding from './CabinetOnboarding'
import * as vendors from './VendorAction'
import * as inventoryManagementAction from './InventoryManagementAction'
import * as reportAction from './ReportAction'
import * as branches from './BranchesAction'
import * as dashboard from './DashboardAction'
import * as troubleShoot from './TroubleShootAction'
import * as healthCheck from './HealthCheckAction'
import * as resetPassword from './ResetPasswordAction'
import * as categoryAction from './CategoryAction'
import * as edgeServerAction from './EdgeServerActions'
import * as subscriptionActions from './SubscriptionActions'
import * as masterSubscriptionActions from './MasterSubscriptionAction'
export const Actions:any = {
    ...login,
    ...cameraList,
    ...cameraId,
    ...customerJourney,
    ...session_cabinet,
    ...trainingIdList,
    ...searchList,
    ...productList,
    ...productlistSearch,
    ...unregisterProduct,
    ...getTrainingId,
    ...register,
    ...getplanogram,
    ...historyAction,
    ...update,
    ...autofill,
    ...sessionStart,
    ...sessionEnd,
    ...cabinetList,
    ...planogramStatus,
    ...cabinetOnboarding,
    ...vendors,
    ...inventoryManagementAction,
    ...reportAction,
    ...branches,
    ...dashboard,
    ...troubleShoot,
    ...healthCheck,
    ...resetPassword,
    ...categoryAction,
    ...edgeServerAction,
    ...subscriptionActions,
    ...masterSubscriptionActions,
}
export const doAction = (type: any, params = {}, callback?: any) => ({ type, params, callback });
export const doResponseAction = (type: any, data = {}) => ({ type, data });
