import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet, doPost } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import END_POINTS from '../../config/Endpoints';
import { BRANCHES_LIST, BRANCHES_DETAILS, CREATE_BRANCHES, BRANCHES_DELETE, BRANCHES_UPDATE, BRANCHES_ACTIVE } from '../action/BranchesAction';
export function* BranchesListSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.BRANCHES_LIST, params, '')
        yield put(Actions.branchesListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.branchesListFailure({ error }));
        callback && callback({ error })
    }
}
export function* BranchesDetailsSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.BRANCHES_DETAILS, params, '')
        yield put(Actions.branchesDetailsSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.branchesDeleteFailure({ error }));
        callback && callback({ error })
    }
}

export function* CreateBranchesSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CREATE_BRANCHES, params, '')
        yield put(Actions.createBranchesSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.createBranchesFailure({ error }));
        callback && callback({ error })
    }
}

export function* BranchesDeleteSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.BRANCHES_DELETE, params, '')
        yield put(Actions.branchesDeleteSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.branchesDeleteFailure({ error }));
        callback && callback({ error })
    }
}

export function* BranchesUpdateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.BRANCHES_UPDATE, params, '')
        yield put(Actions.branchesUpdateSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.branchesUpdateFailure({ error }));
        callback && callback({ error })
    }
}

export function* BranchesActiveSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.BRANCHES_ACTIVE, params, '')
        callback && callback(response)
        yield put(Actions.branchesActiveSuccess(response));
    } catch (error) {
        yield put(Actions.branchesActiveFailure({ error }));
        callback && callback({ error })
    }
}
export default function* BranchesWatcher() {
    yield all([
        takeLatest(BRANCHES_LIST, BranchesListSaga),
        takeLatest(BRANCHES_DETAILS, BranchesDetailsSaga),
        takeLatest(CREATE_BRANCHES, CreateBranchesSaga),
        takeLatest(BRANCHES_DELETE, BranchesDeleteSaga),
        takeLatest(BRANCHES_UPDATE, BranchesUpdateSaga),
        takeLatest(BRANCHES_ACTIVE, BranchesActiveSaga),
    ]);
}
