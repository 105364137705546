import { MESSAGE } from '../../../config/String'
import { CartItemTable } from './CartItemTable'
const finalCartHeader = [
    MESSAGE.S_NO,
    MESSAGE.SKU_ID,
    MESSAGE.SHELF,
    MESSAGE.PRODUCT,
    MESSAGE.COUNT
]
const cvCartHeader = [
    MESSAGE.S_NO,
    MESSAGE.TRAINING_ID,
    MESSAGE.COUNT
]
export const CartItem = ({ cartItems, close }: { cartItems: any, close: (a: boolean) => void }) => {
    return (
        <>
            <div className="scroll">
                <div className="modal d-block" id="loader" tabIndex={-1} aria-labelledby="loader" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content cartScrollContainer p-4" style={{ width: "900px", height: "auto" }}>
                            <i className="fa fa-times fa-2x closeIcon" aria-hidden="true" onClick={() => close(false)} ></i>
                            <div className="modal-body text-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className='row'>
                                        <div className="card">
                                            <CartItemTable cardHeader={MESSAGE.CART} tableHeader={finalCartHeader} tableValue={cartItems?.finalCartData} notFound={MESSAGE.NO_DATA_FOUND} />
                                        </div>
                                        {/* <div className="card sbwCard">
                                            <CartItemTable cardHeader={MESSAGE.MISMATCHED_IN_SBW_CART} tableHeader={finalCartHeader} tableValue={cartItems.sbwCartData} notFound={MESSAGE.NO_DATA_FOUND} />
                                        </div>
                                        <div className="card cvCard">
                                            <CartItemTable cardHeader={MESSAGE.MISMATCHED_IN_CV_CART} tableHeader={cvCartHeader} tableValue={cartItems.cvCartData} notFound={MESSAGE.EVERY_PRODUCT_IS_MATCHED} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show loaderBackGround"></div>
            </div>

        </>
    )
}