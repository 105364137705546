import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AlertMolecules } from "../../UI/Molecules/AlertMolecules"
import { CheckBoxMolecules } from "../../UI/Molecules/CheckboxMolecules"
import { Actions } from "../../../redux/action"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { vendorListDetails } from "../../../redux/reducer/VendorReducer"
import { toast } from "react-toastify"
import { MESSAGE } from "../../../config/String"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { DataNotFound } from "../../UI/Molecules/DataNotFound"


const Vendors = () => {
    const dispatch = useDispatch();
    const vendorList = useSelector(vendorListDetails)
    const [isLoading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState<number>(1)


    const navigate = useNavigate()
    const [isOpen, setOpen] = useState<boolean>(false)

    useEffect(() => {
        const callback = (response: any) => {
            setLoading(false)
            if (response?.status_code !== 0) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
        }
        setLoading(true)
        dispatch(Actions.vendorOnboardingList("", callback))
    }, [dispatch])

    const viewVendorDetails = (obj: any) => {
        dispatch(Actions.updatedCurrentVendorDetails(obj))
        navigate(`/vendor/details/${obj?.vendor_id}`)
    }
    const AddVendors = () => {
        navigate('/vendor/manage')
    }
    const deleteVendors = (flag: boolean) => {
        setOpen(flag)
    }

    const confirm = () => {
        setOpen(false)
    }

    const Content = () => {
        return (
            <React.Fragment>
                <p>Do you want delete the vendor?</p>
                <div className="row mt-4">
                    <div className="col-sm-6">
                        <button className="btn big-btn btn-primary btn-lg w-100" onClick={() => confirm()}>Yes</button>
                    </div>
                    <div className="col-sm-6 text-end">
                        <button className="btn btn-outline-danger btn-lg ml-2 w-100" onClick={() => deleteVendors(false)}>Cancel</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const onActive = (e: any) => {
        const { checked } = e.target
    }
    //For handle the pagination
    const onChangeHandle = (page: number) => {
        setCurrentPage(page)
        // getCabinetList()
    }
    const disableORenableVendor = (event: any, vendorObj: any) => {
        const disableCallback = (response?: any) => {
            if (response?.status_code === 0) {
                dispatch(Actions.vendorOnboardingList(""))
                if (event.target.checked) {
                    toast.success("Vendor is disabled successfully")
                } else {
                    toast.success("Vendor is enabled successfully")
                }
            }
        }
        const params = {
            vendor_id: vendorObj?.vendor_id,
            active: event.target.checked
        }
        dispatch(Actions.vendorOnboardingActive(params, disableCallback))
    }


    return (
        <React.Fragment>
            {
                isLoading && <PlanogramLoader />
            }
            <div className="container mt-4 ">
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="font-bold">Vendors</h3>
                    </div>
                    <div className="col-sm-6 text-end">
                        <button className="btn btn-primary big-btn" onClick={() => AddVendors()}>Add New</button>
                    </div>
                </div>
                { vendorList?.vendor_details?.length>0 ?
                <div className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0 mt-4 ">
                    <table className="table table-hover table-striped table-borderless">
                        <thead>
                            <tr >
                                <th scope="col">S.No</th>
                                <th scope="col">Vendor name</th>
                                <th scope="col">Last name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile number</th>
                                <th scope="col">Active</th>
                                <th scope="col" className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vendorList?.vendor_details?.length>0 && vendorList?.vendor_details?.map((obj: any, index: number) => {
                                return (
                                    <tr >
                                        <th scope="row">{index + 1}</th>
                                        <td>{obj?.first_name}</td>
                                        <td>{obj?.last_name}</td>
                                        <td>{obj?.email}</td>
                                        <td>{obj?.mobile_number !== null ? obj?.mobile_number : "-"}</td>
                                        <td><CheckBoxMolecules
                                            onChange={(e) => disableORenableVendor(e, obj)}
                                            checked={obj?.is_active}
                                        /></td>

                                        <td className="text-center">
                                            <button type="button" id="editcombooffer"
                                                className="btn btn-sm btn-outline-dark mb-3 mb-md-0" onClick={() => viewVendorDetails(obj)}>View</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>


                </div>:<DataNotFound  Error={"Vendor not found"}/>}
                {/* <PaginationSession
                    onChangeHandle={onChangeHandle}
                    currentPage={currentPage}
                    sessionCount={5} /> */}

                {isOpen && <AlertMolecules
                    close={() => deleteVendors(false)}
                    content={Content()}
                />}
            </div>



        </React.Fragment>
    )
}

export default Vendors