import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    session_start: undefined,
    error: false
}
export const session_start_Reducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.SESSION_START: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SESSION_START_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                session_start: action.data,
                error: false
            };
        }
        case Actions.SESSION_START_FAILURE: {
            return {
                ...state,
                isLoading: false,
                session_start: undefined,
                error: action.error,
            };
        }

        default:
            return state;
    }
};
export const getSessionStartData = (state: any) => state?.SESSION_START?.session_start;
export const SessionStartLoader = (state: any) => state?.SESSION_START?.isLoading
