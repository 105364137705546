import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { CATEGORY_CREATE, CATEGORY_LIST,CATEGORY_UPDATE,CREATE_SUB_CATEGORY,SUB_CATEGORY_LIST, UPDATE_SUB_CATEGORY } from '../action/CategoryAction'
import END_POINTS from '../../config/Endpoints'
import { doGet, doPost } from '../../config';
import { ResponseGenerator } from './SagaInterface';
export function* categoryList(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.CATEGORY_LIST, params, {})
        yield put(Actions.categotyListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.categotyListFailure({ error }));
        callback && callback({ error })
    }
}
export function* categoryCreate(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CREATE_CATEGORY, params, {})
        yield put(Actions.createCategorySuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.createCategoryFailure({ error }));
        callback && callback({ error })
    }
}

export function* categoryUpdate(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.UPDATE_CATEGORY, params, {})
        yield put(Actions.updateCategorySuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.updateCategoryFailure({ error }));
        callback && callback({ error })
    }
}
export function* subCategoryList(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.SUB_CATEGORY_LIST, params, {})
        yield put(Actions.subCategotyListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.subCategotyListFailure({ error }));
        callback && callback({ error })
    }
}
export function* createSubCategory(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CREATE_SUB_CATEGORY, params, {})
        yield put(Actions.createSubCategorySuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.createSubCategoryFailure({ error }));
        callback && callback({ error })
    }
}

export function* updateSubCategory(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.UPDATE_SUB_CATEGORY, params, {})
        yield put(Actions.updateSubCategorySuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.updateSubCategoryFailure({ error }));
        callback && callback({ error })
    }
}
export default function* categoryListWatcher() {

    yield all([
        takeLatest(CATEGORY_LIST, categoryList),
        takeLatest(CATEGORY_CREATE, categoryCreate),
        takeLatest(CATEGORY_UPDATE, categoryUpdate),
        takeLatest(SUB_CATEGORY_LIST, subCategoryList),
        takeLatest(CREATE_SUB_CATEGORY, createSubCategory),
        takeLatest(UPDATE_SUB_CATEGORY, updateSubCategory),
    ]);
}
