import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import END_POINTS from '../../config/Endpoints'
import { doGet, doPost } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import { MASTER_SUBSCRIPTION_CREATE, MASTER_SUBSCRIPTION_LIST, MASTER_SUBSCRIPTION_STATUS_UPDATE, MASTER_SUBSCRIPTION_UPDATE } from '../action/MasterSubscriptionAction';

export function* masterSubscriptionListSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.MASTER_SUBSCRIPTION, params, {})
        yield put(Actions.masterSubscriptionListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.masterSubscriptionListFailure({ error }));
        callback && callback({ error })
    }
}

export function* masterSubscriptionCreateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.MASTER_SUBSCRIPTION_CREATE, params, {})
        yield put(Actions.masterSubscriptionCreateSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.masterSubscriptionCreateFailure({ error }));
        callback && callback({ error })
    }
}

export function* masterSubscriptionUpdateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.MASTER_SUBSCRIPTION_UPDATE, params, {})
        yield put(Actions.masterSubscriptionUpdateSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.masterSubscriptionUpdateFailure({ error }));
        callback && callback({ error })
    }
}

export function* masterSubscriptionStatusUpdateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.MASTER_SUBSCRIPTION_STATUS_UPDATE, params, {})
        yield put(Actions.masterSubscriptionStatusUpdateSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.masterSubscriptionStatusUpdateFailure({ error }));
        callback && callback({ error })
    }
}

export default function* masterSubscriptionWatcher() {
    yield all([
        takeLatest(MASTER_SUBSCRIPTION_LIST, masterSubscriptionListSaga),
        takeLatest(MASTER_SUBSCRIPTION_CREATE, masterSubscriptionCreateSaga),
        takeLatest(MASTER_SUBSCRIPTION_UPDATE, masterSubscriptionUpdateSaga),
        takeLatest(MASTER_SUBSCRIPTION_STATUS_UPDATE, masterSubscriptionStatusUpdateSaga),
    ]);
}
