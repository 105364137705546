import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import END_POINTS from '../../config/Endpoints';
export function* customerJourneyData(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS?.CUSTOMER_JOURNEY, params, "")
        yield put(Actions.customerJourneySuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.customerJourneyFailure({ error }));
        callback && callback(error)
    }
}
export default function* customerJourneyWatcher() {

    yield all([
        takeLatest(Actions.CUSTOMER_JOURNEY, customerJourneyData),
    ]);
}