import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { historyEndPoint } from "../../../config/Endpoints"
import { Actions } from "../../../redux/action"
import { historyCount, historyIsLoading, historyListData } from "../../../redux/reducer/HistoryReducer"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { PaginationSession } from "../../UI/Molecules/Pagination"
import { PopupData } from "../../UI/Molecules/PopUpData"
import { MESSAGE } from "../../../config/String"
const planogram_header = [
    MESSAGE.S_NO,
    MESSAGE.OBJECTS_IN_SHELF,
    MESSAGE.UPDATED_TIME,
    MESSAGE.IMAGE
]
export const PlanogramHistoryWidget = ({ cameraId }: { cameraId: string }) => {
    const dispatch = useDispatch()
    const [popup, setPopup] = useState<boolean>(false);
    const [popupImage, setPopUpImage] = useState<string>();
    const historyLoader = useSelector(historyIsLoading)
    const history = useSelector(historyListData)
    const count = useSelector(historyCount)
    const [pageNumber, setPageNumber] = useState(1)
    const onChangeHandle = (pageNumbers: number) => {
        setPageNumber(pageNumbers)
    }
    const handlePopImage = (image: string) => {
        setPopup(true)
        setPopUpImage(image)
    }
    useEffect(() => {
        const historyURL = historyEndPoint(cameraId, pageNumber)
        dispatch(Actions.history(historyURL))
    }, [pageNumber, cameraId, dispatch])
    return (
        <>
            {
                historyLoader && <PlanogramLoader />
            }
            <div className='row planogramContent'>
                <div className="card planogramHistory ">
                    <div className="card-header planogramHeader">
                        {MESSAGE.HISTORY}
                    </div>
                    <div className="card-body">
                        <div className='planogramHistorys'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        {planogram_header &&
                                            planogram_header?.map((elements, index) => (
                                                <th key={index} scope="col" >
                                                    {elements}
                                                </th>
                                            ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        history?.length > 0 ? history?.map((element: any, index: number) => {
                                            const popUp_Image = `${'data:image/png;base64,' + element.img}`
                                            let ObjectInshelf = element?.objects_inself.split(",")
                                            ObjectInshelf.forEach((item: any, id: number) => {
                                                ObjectInshelf[id] = {
                                                    value: item
                                                }
                                            })
                                            let b = ObjectInshelf.reduce((result: any, current: any) => {
                                                if (!result[current['value']]) {
                                                    result[current['value']] = {
                                                        ...current,
                                                        qty: 1
                                                    }
                                                } else {
                                                    result[current['value']] = {
                                                        ...current,
                                                        qty: result[current['value']].qty + 1
                                                    }
                                                }
                                                return result
                                            }, {})
                                            let finalObjectInself = Object.keys(b).map((o) => b[o])

                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{((3 * pageNumber) - (3 - (index + 1)))}</th>
                                                    <td>{finalObjectInself.map((finalObjectelement) =>
                                                        finalObjectelement.value + `(${finalObjectelement.qty + ' '})`
                                                    )}</td>
                                                    <td>{new Date(element.updated_time).toLocaleString()}</td>
                                                    <td>
                                                        <span onClick={() => handlePopImage(popUp_Image)}>
                                                            <img src={popUp_Image} alt="img" style={{ width: "50px" }} className='thumbnailImage' />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        }) : (<tr>
                                            <td className="text-center" colSpan={4}>
                                                {MESSAGE.NO_DATA_FOUND}
                                                <div>
                                                    {MESSAGE.SOMETHING_WENT_WORNG}
                                                </div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                            {
                                (count > 5) &&
                                <div className="pagination_Div">
                                    <PaginationSession onChangeHandle={onChangeHandle} currentPage={pageNumber} sessionCount={count} />
                                </div>

                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                popup && <PopupData image={popupImage} close={() => setPopup(false)} />
            }
        </>
    )
}