import React, { useEffect, useState } from "react"
import { PaginationSession } from "../../UI/Molecules/Pagination"
import { useNavigate } from "react-router-dom"
import { PopupData } from "../../UI/Molecules/PopUpData"
import { CheckBoxMolecules } from "../../UI/Molecules/CheckboxMolecules"
import InputField from '../../UI/Molecules/InputField'
import { AlertMolecules } from '../../UI/Molecules/AlertMolecules'
import Select from "../../UI/Molecules/Select"
import { Actions } from "../../../redux/action"
import { getCategoryListData, getSubCategoryListData, getCategoryLoader } from "../../../redux/reducer/CategoryReducer"
import { useDispatch, useSelector } from "react-redux"
import { getLoginData } from "../../../redux/reducer/login"
import { ProductValidation, validatemax } from "../../../util/Validations"
import { toast } from "react-toastify"
import { DataNotFound } from "../../UI/Molecules/DataNotFound"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"

const Category = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [popup, setPopup] = useState<boolean>(false);
    const [popupImage, setPopUpImage] = useState<string>();
    const [popupDetails, setPopUpDetails] = useState<string>();
    const [addPopup, setAddPopup] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const categoryList = useSelector(getCategoryListData)
    const usersDetails = useSelector(getLoginData)
    const isLoading = useSelector(getCategoryLoader)
    const [formDetails, setFormDetails] = useState<any>({});
    const [editCategory, setEditCategory] = useState<any>()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [errors, setErrors] = useState<any>();

    useEffect(() => {
        getCategoryList()

    }, [dispatch])

    useEffect(() => {
        dispatch(Actions.subCategotyList());
    }, [dispatch])

    const handleChange = (e: any) => {
        if (!e.target.value) {
            setErrors({ name: "Please enter the category name" })
        } else if (validatemax(e.target.value)) {
            setErrors({ name: "Please enter the valid category name" })
        } else {
            setErrors({ name: "" })
        }
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
    }
    const createCallBack = (response: any) => {
        if (response?.status_code === 0) {
            getCategoryList()
            toast.success(response?.message)
            ManageCategory(false)
        } else {
            toast.error(response?.message)
        }
    }

    const categoryCreate = (checkUpdate: any) => {
        if (!formDetails?.name) {
            setErrors({ name: "Please enter the valid category name" })
        } else {
            let params: any = {
                categoryname: formDetails?.name,
            }
            let updateparams: any = {
                category_id: formDetails?.id,
                is_active: formDetails?.is_active,
                name: formDetails?.name
            }
            if (checkUpdate) {
                dispatch(Actions.updateCategory(updateparams, createCallBack))

            } else {
                dispatch(Actions.createCategory(params, createCallBack))
            }
        }
    }

    const getCategoryList = () => {
        dispatch(Actions.categotyList());
    }
    const viewCategoryDetails = (obj: any) => {
        setPopup(true)
        setPopUpImage(obj.image)
        setPopUpDetails(obj)
    }
    const ManageCategory = (flag: any) => {
        setFormDetails({})
        setAddPopup(flag)
        setEditCategory(false)
    }
    const ManageEditCategory = (obj: any) => {
        setAddPopup(true)
        setEditCategory(true)
        setFormDetails(obj)
    }
    const onActive = (event: any, branObj: any) => {
        const disableCallback = (response: any) => {
            if (response?.status_code === 0) {
                getCategoryList()
                toast.success(response?.message)
            } else {
                toast.error(response?.message)
            }
        }
        let updateparams: any = {
            category_id: branObj?.id,
            is_active: !branObj?.is_active,
            name: branObj?.name
        }
        dispatch(Actions.updateCategory(updateparams, disableCallback))
    }
    const cancel = () => {
        setAddPopup(false)
        setFormDetails('')
        setErrors('')
    }
    const deleteVendors = (flag: boolean) => {
        setDeletePopup(flag)
    }

    const confirm = () => {
        setDeletePopup(false)
    }
    // For handle the pagination
    const onChangeHandle = (page: number) => {
        setCurrentPage(page)
        const { user_id } = usersDetails || {}
        const params = { vendor_id: user_id, limit: 6, page: page }
        // dispatch(Actions.categotyList(params))
    }

    const data = React.useMemo(() => categoryList, [categoryList]);

    // const { user_id, role } = usersDetails || {}
    const Content = (popupDetails: any) => {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="row">
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Category Name:</div>
                            <div className="col-8">{popupDetails?.name}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Category Id:</div>
                            <div className="col-8">{popupDetails?.id}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Status:</div>
                            <div className="col-8">{popupDetails?.is_active === true ? "Active" : 'InActive'}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const DeleteContent = () => {
        return (
            <React.Fragment>
                <p>Do you want delete the category?</p>
                <div className="row mt-4">
                    <div className="col-sm-6">
                        <button className="btn big-btn btn-primary btn-lg w-100" onClick={() => confirm()}>Yes</button>
                    </div>
                    <div className="col-sm-6 text-end">
                        <button className="btn btn-outline-danger btn-lg ml-2 w-100" onClick={() => deleteVendors(false)}>Cancel</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    const CreateCategory = () => {
        return (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="font-bold">{editCategory ? "Update Category" : "Create Category"}</h3>
                    </div>
                </div>
                <div className="row mt-3">
                    <InputField inputType={"text"} label="Category Name" placeholder="Category Name" name="name" value={formDetails?.name} columnSize={6} onChange={handleChange} error={errors?.name} />
                </div>
                <div className='row mb-2 mt-4'>
                    <div className='col-sm-6'></div>
                    <div className='col-sm-6 text-end'>
                        <button className="btn btn-secondary btn-outline" onClick={() => cancel()}>Cancel</button>
                        <button className="btn btn-primary big-btn ml-2" onClick={() => categoryCreate(editCategory)}>{editCategory ? "Update" : "Create"}</button>
                    </div>
                </div>
            </>
        )
    }
    return (
        <React.Fragment>
            <div className="container mt-4 ">
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="font-bold">Category</h3>
                    </div>
                    <div className="col-sm-6 text-end">
                        <button className="btn btn-primary big-btn" onClick={() => ManageCategory(true)}>Add New</button>
                    </div>

                </div>
                {data?.Category?.length > 0 ? <div className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0 mt-4 ">
                    <table className="table table-hover table-striped table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Category Name</th>
                                {/* <th scope="col">Category Id</th> */}
                                {/* <th scope="col">Status</th> */}
                                <th scope="col">Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data?.Category?.map((obj: any, index: number) => {
                                return (
                                    <tr >
                                        <th scope="row">{index + 1}</th>
                                        <td>{obj?.name}</td>
                                        {/* <td>{obj?.id}</td> */}
                                        {/* <td><CheckBoxMolecules
                                            checked={obj?.is_active}
                                            onChange={(event) => onActive(event, obj)}
                                        /></td> */}
                                        <td className="d-flex">
                                            <div className="ml-2">
                                                <button type="button" id="editcombooffer"
                                                    className="btn btn-sm btn-outline-dark mb-3 mb-md-0" onClick={() => viewCategoryDetails(obj)}>View</button>
                                            </div>
                                            {/* <div className="ml-2">
                                                <button type="button"
                                                    id="editcombo"
                                                    className="btn btn-sm btn-outline-dark" onClick={() => ManageEditCategory(obj)}>Edit</button>
                                            </div> */}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>


                </div>: <DataNotFound Error="No data found"/>}
                {(data?.Category?.length > 0 && data?.total_pages > 1) && <PaginationSession
                    onChangeHandle={onChangeHandle}
                    currentPage={currentPage}
                    sessionCount={data?.total_pages} />}
            </div>
            {!isLoading && data?.Categorys?.length === 0 && <DataNotFound
                Error={"No category found"}
            />}
            {popup && <PopupData image={popupImage} close={() => setPopup(false)} content={Content(popupDetails)} />}
            {
                addPopup &&
                <PopupData close={() => cancel()} content={CreateCategory()} />
            }
            {isLoading && <PlanogramLoader />}
        </React.Fragment>
    )
}

export default Category