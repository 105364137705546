import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import InputField from '../../UI/Molecules/InputField'
import { VendorValidation, validateName, validateminLengthRegExp, isValidEmail, validateMobileNumnber, validatemaxLength } from '../../../util/Validations'
import { useDispatch } from "react-redux"
import { Actions } from "../../../redux/action"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { toast } from "react-toastify"
import { MESSAGE } from "../../../config/String"
import { Input } from "../../UI/Atoms/Input"
export const ManageVendors = () => {
    const [vendorValues, setVendorValues] = useState<any>({ fields: {} })
    const [errors, setErrors] = useState<any>();
    const [passwordType, setPasswordType] = useState<string>("password");

    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {
        const { fields } = vendorValues
        if (Object.keys(fields)?.length !== 0) {
            let validatedError = VendorValidation(fields, ["firstName", "lastName", "email", "mobileNumber", "address", "password"])
            if (validatedError) {
                setErrors(validatedError)
            }
        }
    }, [vendorValues?.fields])

    const navigate = useNavigate()

    const createVendor = () => {
        const { fields } = vendorValues
        if (!fields?.firstName) {
            setErrors({ firstName: "Please enter the first name" })
        } else if (!validateName(fields?.firstName)) {
            setErrors({ firstName: "Please enter the valid first name" })
        } else if (!fields?.lastName) {
            setErrors({ lastName: "Please enter the last name" })
        } else if (!validateName(fields?.lastName)) {
            setErrors({ lastName: "Please enter the valid last name" })
        } else if (!fields?.email) {
            setErrors({ email: "Please enter the email id" })
        } else if (!isValidEmail(fields?.email)) {
            setErrors({ email: "Please enter the valid email id" })
        } else if (fields?.mobileNumber === undefined || !fields?.mobileNumber) {
            setErrors({ mobileNumber: "Please enter the mobile number" })
        } else if (!validateMobileNumnber(fields?.mobileNumber)) {
            setErrors({ mobileNumber: "Please enter the valid mobile number" })
        } else if (!fields?.address) {
            setErrors({ address: "Please enter the address" })
        } else if (validatemaxLength(fields?.address)) {
            setErrors({ address: "Please enter the valid address" })
        } else if (!fields?.password) {
            setErrors({ password: "Please enter the password" })
        } else if (!validateminLengthRegExp(fields?.password)) {
            setErrors({ password: "Please enter the valid password" })
        } else {
            const callback = (response: any) => {
                setLoading(false)
                if (response?.status_code === 0) {
                    toast.success("Vendor is onboarded succesfully")
                    navigate("/vendors")
                    setVendorValues({})
                    setErrors({})
                } else if (response?.status_code === 1) {
                    toast.error(response?.message)
                }
                else {
                    toast.error(MESSAGE.SOMETHING_WENT_WORNG)
                }
            }
            let params = {
                first_name: fields?.firstName,
                last_name: fields?.lastName,
                mobile_number: fields?.mobileNumber,
                address: fields?.address,
                email: fields?.email,
                password: fields?.password
            }
            setLoading(true)
            dispatch(Actions.createVendor(params, callback))
        }
    }

    const cancel = () => {
        navigate(-1)
    }
    const handleChange = ({ target }: any) => {
        const { name, value } = target || {}
        const { fields } = vendorValues;
        setVendorValues({
            ...vendorValues,
            fields: {
                ...fields,
                [name]: value
            },
        })
    }

    const togglePassword = (event: any) => {
        event.preventDefault();
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (<>
        {
            isLoading && <PlanogramLoader />
        }
        <div className='container mt-4 '>
            <div className="row">
                <div className="col-sm-6">
                    <h3 className="font-bold">Add vendor</h3>
                </div>
            </div>
            <div className='card card-body rounded-3 border-0 shadow p-4 mb-3  mt-4'>
                <div className="row mt-3">
                    <InputField error={errors?.firstName} inputType={"text"} name="firstName" label="First Name" placeholder="First Name" columnSize={6} onChange={(e) => handleChange(e)} />
                    <InputField error={errors?.lastName} inputType={"text"} name="lastName" label="Last Name" placeholder="Last Name" columnSize={6} onChange={(e) => handleChange(e)} />
                </div>

                <div className="row mt-3">
                    <InputField error={errors?.email} inputType={"text"} name="email" label="Email" placeholder="Email" columnSize={6} onChange={(e) => handleChange(e)} />
                    <InputField error={errors?.mobileNumber} inputType={"number"} name="mobileNumber" label="Mobile Number" placeholder="Mobile Number" columnSize={6} onChange={(e) => handleChange(e)} />
                </div>

                <div className="row mt-3">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <textarea name="address" placeholder="Address" className="form-control" id="exampleFormControlTextarea1" rows={3} onChange={(e) => handleChange(e)}></textarea>
                            <p className="errorMessage">
                                {
                                    errors?.address
                                }
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <Input inputType={passwordType} id={'password'} inputClassName="vendorPassword" inputname={'password'} placeholder={'Password'} handleChange={(e) => handleChange(e)} />
                        {passwordType === "password" ? <i className="fa fa-eye-slash fa-2x toggle vendorToggle" aria-hidden="true" onClick={(event) => togglePassword(event)}></i> : <i className="fa fa-eye fa-2x toggle vendorToggle" aria-hidden="true" onClick={(event) => togglePassword(event)}></i>}
                        {
                            errors?.password && <p className="errorMessage">{errors?.password}</p>
                        }
                    </div>
                    {/* <InputField error={errors?.password} inputType={"password"} name="password" label="Password" placeholder="Password" columnSize={6} onChange={(e) => handleChange(e)} /> */}

                </div>

                <div className='row mb-2 mt-2'>
                    <div className='col-sm-6'></div>
                    <div className='col-sm-6 text-end'>
                        <button className="btn btn-secondary btn-outline" onClick={() => cancel()}>Cancel</button>
                        <button className="btn btn-primary big-btn ml-2" onClick={() => createVendor()}>Create</button>
                    </div>
                </div>
            </div>

        </div>
    </>

    )
}