import { doAction, doResponseAction } from ".";

const PRODUCT_LIST_SEARCH = 'PRODUCT_LIST_SEARCH';
const PRODUCT_LIST_SEARCH_SUCCESS = 'PRODUCT_LIST_SEARCH_SUCCESS';
const PRODUCT_LIST_SEARCH_FAILURE = 'PRODUCT_LIST_SEARCH_FAILURE';
export {
    PRODUCT_LIST_SEARCH,
    PRODUCT_LIST_SEARCH_FAILURE,
    PRODUCT_LIST_SEARCH_SUCCESS,
}

// Product Search List
export const product_List_Search = (params: {}, callback?: any) => doAction(PRODUCT_LIST_SEARCH, params, callback);
export const product_List_Search_Success = (data: {}) => doResponseAction(PRODUCT_LIST_SEARCH_SUCCESS, data);
export const product_List_Search_Failure = (data: {}) => doResponseAction(PRODUCT_LIST_SEARCH_FAILURE, data);