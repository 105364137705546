import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    inventoryManagementRecentList: [],
    inventoryManagementHistroyList: [],
    inventoryManagementCount: 0,
    error: false
}
export const InventoryManagementReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.INVENTORY_MANAGEMENT_RECENT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.INVENTORY_MANAGEMENT_RECENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                inventoryManagementRecentList: action.data?.response,
                error: false
            };
        }
        case Actions.INVENTORY_MANAGEMENT_RECENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                inventoryManagementRecentList: [],
                error: action.data.error,
            };
        }
        case Actions.INVENTORY_MANAGEMENT_HISTORY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.INVENTORY_MANAGEMENT_HISTORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                inventoryManagementHistroyList: action.data?.response,
                error: false
            };
        }
        case Actions.INVENTORY_MANAGEMENT_HISTORY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                inventoryManagementHistroyList: [],
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};
export const getInventoryManagementRecentData = (state: any) => state?.INVENTORY_MANAGEMENT?.inventoryManagementRecentList;
export const getInventoryManagementHistoryData = (state: any) => state?.INVENTORY_MANAGEMENT?.inventoryManagementHistroyList;
