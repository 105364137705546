import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../redux/action";
import { getFrames, getPlanogramData } from "../../../redux/reducer/PlanogramReducer";
import { defaultShapeStyle, ReactPictureAnnotation } from "react-picture-annotation";
import { Dropdowns } from "../../UI/Molecules/AnnotaionDropDown";
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader";
import { MESSAGE } from '../../../config/String'
import { PlanogramDetailsWidget } from "./PlanogramDetailsWidget";
let timeout :any;
export const PlanogramImageWidget = ({ planogramShelfId, dropDownProduct, cameraId,
    onPlanogramChange, cabinetData, setImageSize, imageSize, next, previous, length, planogramList, shelfparams,
    handleFetchNewFrame, imageURL, setCheckStatus, checkStatus, PlanogramPopFields, setIsAccordionOpen, planogramObject, index, productListArray }: {
        cameraId: string, cabinetData: string, setImageSize: React.Dispatch<React.SetStateAction<{
            width: number;
            height: number;
        }>>, setCheckStatus: React.Dispatch<React.SetStateAction<{
            status: boolean;
            shelf: [];
        }>>, imageSize: {
            width: number;
            height: number;
        }, next?: number,
        previous?: number,
        length?: number,
        shelfparams: any,
        planogramList: any
        dropDownProduct: any
        handleFetchNewFrame: any
        imageURL: any
        index: number,
        checkStatus: any,
        closePlanogramPopup: any
        PlanogramPopFields: any
        setIsAccordionOpen: any
        planogramShelfId: any
        planogramId: any
        planogramObject: any,
        onPlanogramChange: any,
        productListArray: any
    }) => {
    const [isLoading, setLoading] = useState(false)
    const [annotatedId, setAnnotatedId] = useState('')
    const [checkAnnotation, setCheckAnnotation] = useState<boolean>(false)
    const [planogramData, setPlanogramData] = useState<any>()
    const dispatch = useDispatch()
    const [offsetData, setoffsetData] = useState({
        offsetDatawidth: 0,
        offsetDataheight: 0
    });
    useEffect(() => {
        if (planogramObject?.planogram_shelf?.hasOwnProperty('planogram_coordinates')) {
            setLoading(true)
            getAnnotationData(planogramObject?.planogram_shelf?.planogram_coordinates, { width: 1920, height: 1080 })
        }
    }, [imageSize])

    //Get the response and convert the annotation data in pixel
    const getAnnotationData = (response: any, imageData: any) => {
        let planogram: { comment: any; id: any; mark: { x: number; y: number; width: number; height: number; }; }[] = [];
        response?.map((o: any, index: number) => {
            return (
                planogram.push({
                    comment: o?.product,
                    id: o?.id,
                    mark: {
                        x: o?.xtl * imageData?.width,
                        y: o?.ytl * imageData?.height,
                        width: (o?.xbr * imageData?.width) - (o?.xtl * imageData?.width),
                        height: (o?.ybr * imageData?.height) - (o?.ytl * imageData?.height)
                    }
                })
            )
        })
        setLoading(false)
        setPlanogramData(planogram)
    }

    //To define the id for the specific annotation.
    const onSelect = (id: any) => { setAnnotatedId(id) };

    //To get the co-ordinates data and set to the reducer
    const onChanges = async (data: any) => {
        checkAnnotationData(data)
        setPlanogramData(data)
        if (checkproductData(data)) {
            const composed = data.map((obj1: any) => {
                return {
                    ...obj1,
                    product: productListArray.find((obj: any) => obj1?.comment == obj?.id)
                }
            })
            planogramList[index].annotation = composed
            if(timeout) {
                clearTimeout(timeout)
            }
            timeout = setTimeout(() => {
                onPlanogramChange(planogramList)
            }, 3000)
        }
    }

    //It is undo to clear the last annotated data.
    const handleClear = () => {
        if (planogramData?.length > 0) {
            planogramData.pop()
            const removedData = JSON.parse(JSON.stringify(planogramData))
            setPlanogramData(removedData)
        }
    }

    //It is clear all annotated data
    const handleClearAll = () => {
        dispatch(Actions.setAnnotaionPlanogram([]))
    }

    const previousPlanogram = (previous: any) => {
        const myGreeting = () => {
            let classData = document.querySelector(`#collapse${previous}`)?.classList
            setIsAccordionOpen({ status: classData?.contains("show"), index: previous })
        }
        setTimeout(myGreeting, 2500);
    }
    const nextPlanogram = (next: any) => {
        const myGreeting = () => {
            let classData = document.querySelector(`#collapse${next}`)?.classList
            setIsAccordionOpen({ status: classData?.contains("show"), index: next })
        }
        setTimeout(myGreeting, 2500);
    }
    // //To getimage size and offset size.
    const getImageSize = (data: any) => {
        setLoading(true)
        const Data: any = data.target
        setImageSize({
            width: (Data.width),
            height: (Data.height),
        })
        const offset: any = document.getElementsByClassName('planogramAccordian')[0];
        const offsetWidth = offset?.offsetWidth
        const percentageWidth = offsetWidth / 100
        const imageHeightPercent = (Data.height / Data.width) * 100
        const offsetHeight = percentageWidth * imageHeightPercent
        setoffsetData({
            offsetDatawidth: offsetWidth - 300,
            offsetDataheight: offsetHeight - 300
        })
        setLoading(false)
        return ({
            width: (Data.width),
            height: (Data.height),
        })
    }
    const getStep3Style = (height: any) => {
        return { height: `${height}px` }
    }
    const checkAnnotationData = (anoData: any) => {
        function isComment(box: any) {
            return ('comment' in box)
        }
        setCheckAnnotation(!anoData.every(isComment))
        return !anoData.every(isComment)
    }
    const checkproductData = (anodata: any) => {
        function isComment(box: any) {
            return box?.hasOwnProperty('comment')
        }
        return anodata.every(isComment)
    }
    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <img id="imageData" className="img-fluid" src={imageURL} style={{ display: "none" }} onLoad={getImageSize} alt="images" />
            <div className="row">
                <div className="col-8">
                    {offsetData?.offsetDataheight !== 0 && offsetData?.offsetDatawidth !== 0 ?
                        <div className='planogramImages' style={getStep3Style(offsetData?.offsetDataheight)} key={index}>
                            <ReactPictureAnnotation
                                inputElement={(value, onChange, onDelete) => {
                                    return (
                                        <Dropdowns
                                            setPlanogramData={setPlanogramData}
                                            planogramData={planogramData}
                                            annotatedId={annotatedId}
                                            inputLabel={value}
                                            onChange={onChange}
                                            onDelete={onDelete}
                                            optionsprops={dropDownProduct}
                                        />
                                    );
                                }}
                                image={imageURL}
                                onSelect={onSelect}
                                onChange={onChanges}
                                width={offsetData?.offsetDatawidth}
                                height={offsetData?.offsetDataheight}
                                scrollSpeed={0}
                                annotationStyle={{
                                    ...defaultShapeStyle,
                                    shapeStrokeStyle: "#2193ff",
                                    transformerBackground: "black"
                                }}
                                annotationData={planogramData}
                            />
                        </div> :
                        <div className="PlanogramNotFound">{MESSAGE.NO_DATA_FOUND}</div>
                    }
                </div>
                <div className="col-4">
                    <div className="fetchNewFrame">
                        <button className="btn btn-primary big-btn fetchFrame clearButton" onClick={() => handleFetchNewFrame(planogramObject)}>{MESSAGE.FETCH_NEW_FRAME}</button>
                    </div>
                    {
                        (imageURL.split('/')[4] !== undefined) &&
                        <PlanogramDetailsWidget planogramObject={planogramObject} planogramData={planogramData} planogramShelfId={planogramShelfId} PlanogramPopFields={PlanogramPopFields} checkStatus={checkStatus} setCheckStatus={setCheckStatus} setPlanogramData={setPlanogramData} index={index} shelfparams={shelfparams} handleClear={handleClear} checkAnnotation={checkAnnotation} cameraId={cameraId} cabinetId={cabinetData} imageSize={imageSize} />
                    }
                </div>
            </div>
            <div className="col-4 offset-9">
                {
                    next !== 1 &&
                    < button className={(length === next) ? "btn btn-outline btn-secondary clearButton planogramNext m-3" : "btn btn-outline btn-secondary clearButton m-3"} disabled={next !== 1 ? false : true} aria-controls={`collapse${previous}`} aria-expanded={true} data-bs-toggle={"collapse"} data-bs-target={`#collapse${previous}`} onClick={() => previousPlanogram(previous)}>{MESSAGE.PREVIOUS}</button>
                }
                {
                    (!(length === next) && imageURL !== '') && <button className={next !== 1 ? "btn btn-primary big-btn clearButton m-3" : "btn btn-primary big-btn clearButton planogramNext m-3"} aria-controls={`collapse${next}`} onClick={() => nextPlanogram(next)} aria-expanded={true} data-bs-toggle={"collapse"} data-bs-target={`#collapse${next}`}>{MESSAGE.NEXT}</button>
                }

            </div>
        </>
    )
} 