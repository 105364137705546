import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux'
import { Actions } from '../../../redux/action';
import { COLORS } from '../../../config/String';
import { getLoginData } from "../../../redux/reducer/login"
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom"


export const customSelectStyles = {
    menu: (provided: any) => ({
        ...provided,
    }),
    control: (provided: any) => ({
        ...provided,
        borderColor: COLORS.appThemeHex,
        boxShadow: null,
        display: 'flex',
        flex: 1,
        minWidth: '200px'
    }),
    singleValue: (provided: any) => ({
        ...provided,
    }),
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? COLORS.appThemeHex : 'white',
        zIndex: 999
    }),
    valueContainer: (provided: any) => ({
        ...provided,
    }),
};

const CategotyTypeAhead = ({
    defaultOptions = null,
    placeholder = '',
    onChange = (value: any) => value,
    isMulti = false,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(defaultOptions || []);
    const dispatch = useDispatch();
    const usersDetails = useSelector(getLoginData)
    const vendorId = useParams();

    const optionsRef = useRef()

    const searchApi = useCallback((value?: any) => {
        const { user_id } = usersDetails || {}
        const defaultFilter = {
            vendor_id: vendorId?.id ? vendorId?.id : user_id,
            keyword: value ? value : ''
        };
        setIsLoading(true);
        dispatch(Actions.categotyList(defaultFilter, (resp: any) => {
            let data = [];
            if (resp?.response) {
                data = resp?.response?.Category?.map((o: any) =>
                (
                    {
                        label: `${o?.name}`, value: o?.id,

                    }
                )
                );
            }
            setIsLoading(false)
            setOptions(data)
            optionsRef.current = data
        }))

    }, [usersDetails, dispatch]);

    const debounceRef = useRef(debounce((value) => {
        searchApi(value ? value : '');
    }, 500));

    useEffect(() => {
        searchApi();
    }, [dispatch])


    return <Select
        className="title-case"
        {...props}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        onInputChange={(value) => debounceRef.current(value)}
        options={options}
        isMulti={isMulti}
        onChange={(value) => onChange(value || {})}
    />
}



export default CategotyTypeAhead;