import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    sessionCabinet: [],
    error: false
}
export const sessionCabinetReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.SESSION_CABINET: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SESSION_CABINET_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                sessionCabinet: action.data.response.shelves,
                error: false
            };
        }
        case Actions.SESSION_CABINET_FAILURE: {
            return {
                ...state,
                isLoading: false,
                sessionCabinet: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getEventData = (state: any) => state?.SESSION_CABINET?.sessionCabinet;
export const geEventCount = (state: any) => state?.SESSION_CABINET?.sessionCabinet?.count
export const getEventLoader = (state: any) => state?.SESSION_CABINET?.isLoading