import { Input } from "../Atoms/Input"

export const CabinetInput = ({ LabelName, Placeholder, disable, handleInput, InputClassName, InputType, KeyDown, Value, name, index, cameraError, shelfError }: { LabelName: string, Placeholder: string, name?: string, handleInput: (event: any) => void, KeyDown?: (event: any) => void, InputClassName?: string, InputType: string, Value?: any, disable?: boolean, index?: number, cameraError?: any, shelfError?: string }) => {
    {
        return (
            <>
                {
                    index === 0 ?
                        <label className="caninetInput">{LabelName}</label> : ""
                }
                <div className={InputClassName}>
                    <Input inputType={InputType} value={Value} disable={disable} inputname={name} inputClassName="form-control" placeholder={Placeholder} handleChange={handleInput} handleKeydown={KeyDown} />
                </div>
                {cameraError && <p className="errorMessage">{cameraError}</p>}
                {shelfError && <p className="errorMessage">{shelfError}</p>}
            </>
        )
    }
}