import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    session_end: undefined,
    session_complete:{},
    error: false
}
export const session_end_Reducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.SESSION_END: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SESSION_END_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                session_end: action.data,
                error: false
            };
        }
        case Actions.SESSION_END_FAILURE: {
            return {
                ...state,
                isLoading: false,
                session_end: undefined,
                error: action.error,
            };
        }
        case Actions.SESSION_COMPLETE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SESSION_COMPLETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                session_complete: action.data,
                error: false
            };
        }
        case Actions.SESSION_COMPLETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                session_complete: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getSessionEndData = (state: any) => state?.SESSION_END?.session_end;
export const SessionEndLoader = (state: any) => state?.SESSION_END?.isLoading;
