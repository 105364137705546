import axios, { AxiosRequestConfig } from 'axios';
import { store } from '../createStore';
import END_POINTS from './Endpoints';

const Instance = axios.create({
    baseURL: END_POINTS.API_BASE_URL
});
const TestingInstance = axios.create({
    baseURL: END_POINTS.API_BASE_URL
});

export const httpAuthHeader = () => {
    const tokendata = store?.getState()?.USER;
    const { token } = tokendata['user'];
    return {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
    };
}
export const httpAuthHeader2 = () => {
    const tokendata = store?.getState()?.USER;
    const { token } = tokendata['user'];
    return {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Token ${token}`,
    };
}

const checkForRedirect = () => {
    localStorage.clear()
    window.location.href = '/'
}
export const doGet = async (url: any, params: {}, unAuthorize: any) => {
    const config: AxiosRequestConfig = {
        method: 'GET',
        url,
        params,
        headers: httpAuthHeader(),
    };
    try {
        const response = await Instance(config);
        return response.data;
    } catch (error: any) {
        if (!unAuthorize && error.response.status === 401) {
            return checkForRedirect();
        }
        throw error.response.status;
    }
}

export const doPost = async (url: any, data: any, _isOffSet: any) => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        data,
        headers: httpAuthHeader(),
    };
    try {
        const response = await Instance(config);
        return response.data;
    } catch (error: any) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        throw error.response.status;
    }
}

export const doTestingPost = async (url: any, data: any, _isOffSet: any) => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        data,
        headers: httpAuthHeader(),
    };
    try {
        const response = await TestingInstance(config);
        return response.data;
    } catch (error: any) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        throw error.response.status;
    }
}
export const login = async (url: string, data: any) => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };
    try {
        const response = await Instance(config);
        return response.data;
    } catch (error: any) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        throw error.response.status;
    }
}

export const doGetMultiform = async (url: string, data: any, _isOffSet: any) => {
    const config: AxiosRequestConfig = {
        method: 'GET',
        url,
        data,
        headers: httpAuthHeader2(),
    };
    try {
        const response = await Instance(config);
        return response.data;
    } catch (error: any) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        throw error.response.status;
    }
}
export const doPostMultiform = async (url: string, data: any, _isOffSet: any) => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        data,
        headers: httpAuthHeader2(),
    };
    try {
        const response = await Instance(config);
        return response.data;
    } catch (error: any) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        throw error.response.status;
    }
}

