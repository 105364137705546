import { doAction, doResponseAction } from ".";

const GENERATE_ID = 'GENERATE_ID';
const GENERATE_ID_SUCCESS = 'GENERATE_ID_SUCCESS';
const GENERATE_ID_FAILURE = 'GENERATE_ID_FAILURE';
export {
    GENERATE_ID,
    GENERATE_ID_FAILURE,
    GENERATE_ID_SUCCESS,
}
// Generate Training Id
export const generateId = <T>(params: {}, callback?: T) => doAction(GENERATE_ID, params, callback);
export const generateId_Success = (data: {}) => doResponseAction(GENERATE_ID_SUCCESS, data);
export const generateId_Failure = (data: {}) => doResponseAction(GENERATE_ID_FAILURE, data);