import { useRef, useState } from "react"
import { useDispatch } from "react-redux";
import { unregisterProductEndPoint } from "../../../config/Endpoints";
import { Actions } from "../../../redux/action";
import { unregisterProductInterface } from "./UnregisterProductInterface";
import { MESSAGE } from '../../../config/String'
import { Input } from "../../UI/Atoms/Input";
export const GenerateId = ({ pageNumber, setPopup, setPageNumber, setPopUpImage }: unregisterProductInterface) => {
    const [file, setFile] = useState<File>()
    const [disable, setdisable] = useState(true);
    const ref = useRef<HTMLInputElement | null>(null);
    const dispatch = useDispatch()
    const [TrainingId, setTrainingId] = useState();
    const [imageFile, setImageFile] = useState<string | ArrayBuffer | null>();
    const handleImage = (event: any) => {
        setFile(event.target.files![0])
        setdisable(false)
        let reader = new FileReader();
        reader.onload = function () {
            setImageFile(reader.result)
        }
        reader.readAsDataURL(event.target.files[0]);
    }
    const getTrainingId = (event: React.FormEvent<HTMLFormElement>) => {
        let formData = new FormData();
        formData.append("image_file", file as Blob)
        event.preventDefault();
        const generateIdCallback = (response: any) => {
            setTrainingId(response.training_id)
            setImageFile('')
            if (ref.current) {
                ref.current.value = ''
            }
            setPageNumber(1)
            const unRegisteProductURL = unregisterProductEndPoint(pageNumber)
            dispatch(Actions.unregisterProduct(unRegisteProductURL));
        }
        dispatch(Actions.generateId(formData, generateIdCallback))
    }
    const handlePopImage = (image: string) => {
        setPopup(true)
        setPopUpImage(image)
    }
    return (
        <>
            <div className="GenerationId">
                <div className="card GenerationCard">
                    <div className="card-body">
                        <div className="container">
                            <p className="Generate_Title">{MESSAGE.GENERATE_TRAINING_ID}</p>
                            <div className="GenerateForm">
                                <form id="form" onSubmit={(e) => getTrainingId(e)} encType="multipart/form-data">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm">
                                                <label className="TrainingLabel">{MESSAGE.TRAINING_ID}</label><br />{
                                                    TrainingId ? TrainingId : " Choose file to get ID"}
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input ref={ref} type="file" className="inputTag" onChange={(e) => handleImage(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {imageFile ?
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="custom-file">
                                                                <span onClick={() => handlePopImage(imageFile as string)}>
                                                                    <img src={imageFile as string} className="unRegisterImage" alt="" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null
                                            }
                                            <div className="col-sm">
                                                <Input inputType="submit" inputClassName="btn btn-primary uploadButton" disable={disable} value={MESSAGE.GET_TRAINING_ID} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}