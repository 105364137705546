import { useState } from "react";
import { useSelector } from "react-redux";
import { getCameraIdData } from "../../../redux/reducer/GetCameraIdReducre";
import { PlanogramDetailsWidget } from "./PlanogramDetailsWidget"
import { PlanogramHistoryWidget } from "./PlanogramHistoryWidget"
import { PlanogramImageWidget } from "./PlanogramImageWidget"

export const PlanogramWidget = () => {
    const cameraData = useSelector(getCameraIdData);
    const cameraId = cameraData?.[0]
    const cabinetId = cameraData?.[1]
    const [imageSize, setImageSize] = useState({
        width: 0,
        height: 0
    });
    return (
        <>
            <div className="container">
                <div className="row">
                    {/* <PlanogramImageWidget cameraId={cameraId} setImageSize={setImageSize} imageSize={imageSize} /> */}
                    <div className="col-lg-4 col-md-12 planogram_HistoryCard" style={{ width: "483px" }}>
                        {/* <PlanogramDetailsWidget cameraId={cameraId} cabinetId={cabinetId} imageSize={imageSize} /> */}
                        <PlanogramHistoryWidget cameraId={cameraId} />
                    </div>
                </div>
            </div>
        </>
    )
}