import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { INVENTORY_MANAGEMENT_RECENT, INVENTORY_MANAGEMENT_HISTORY } from '../action/InventoryManagementAction'
import END_POINTS from '../../config/Endpoints'
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
export function* inventoryManagementRecent(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.INVENTORY_MANAGEMENT, params, {})
        yield put(Actions.inventoryManagementRecentSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.inventoryManagementRecentFailure({ error }));
        callback && callback({ error })
    }
}
export function* inventoryManagementHistory(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.INVENTORY_MANAGEMENT_HISTORY, params, {})
        yield put(Actions.inventoryManagementHistorySuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.inventoryManagementHistoryFailure({ error }));
        callback && callback({ error })
    }
}
export default function* inventoryManagementWatcher() {

    yield all([
        takeLatest(INVENTORY_MANAGEMENT_RECENT, inventoryManagementRecent),
        takeLatest(INVENTORY_MANAGEMENT_HISTORY, inventoryManagementHistory),
    ]);
}
