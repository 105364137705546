import { Outlet } from "react-router-dom"
import { SideBar } from './SideBar'
export const Layout = () => {
    return (
        <section className="dashboard_main">
            <SideBar />
            <div className="dashboard_body">
                <Outlet />
            </div>
        </section>
    )
}