export const MESSAGE = {
    // CameraList Header
    CAMERA_ID: "Camera URL",
    CREATE: "Create",
    CABINET_ID: "Cabinet Id",
    PLANOGRAM: "Planogram",
    STATUS: "Status",
    CAMERA_LIST: "Camera List",
    SHELF_LIST: "Shelf List",
    //CameraList Warning Messages
    CAMERA_IS_OFFLINE: "Camera is Offline",
    MISPLACED_ITEMS_DETECTED: "Misplaced items detected",
    NO_RECORD_FOUND: "No Records Found",
    //BUTTON
    //Login
    LOGIN_HEADER: "Login",
    //PLANOGRAM LIST
    PLANOGRAM_NAME: "Planogram",
    SELECT_CABINET: "Select Cabinet",
    //dateFilter
    SELECT_FROM_DATE: "Select from date",
    SELECT_TO_DATE: "Select to date",
    REFRESH: "Refresh",
    //accordian
    SESSION_ID: "#",
    START_TIME: "Start Time",
    END_TIME: "End Time",
    DATE: "Date",
    CART: "Cart",
    VIEW_CABINET: "View Cabinet",
    //CART ITEM
    FINAL_CART: "Final Cart",
    S_NO: "S.No",
    SKU_ID: "SKU ID",
    PRODUCT: "Product",
    CAMERA: "Camera",
    MISPLACED_PRODUCT: "Misplacement Product",
    COUNT: "Count",
    NO_DATA_FOUND: "No Data Found",
    //Mismatched in SBW cart
    MISMATCHED_IN_SBW_CART: "Mismatched in SBW cart",
    //Mismatched in CV cart
    MISMATCHED_IN_CV_CART: "Mismatched in CV cart",
    EVERY_PRODUCT_IS_MATCHED: "Every Product is matched",
    //Generate Training Id
    GENERATE_TRAINING_ID: "Generate Training Id:",
    TRAINING_ID: "Training Id:",
    GET_TRAINING_ID: "Get Training Id",
    //PlanogramDetailsWidget
    DETAILS: "Details",
    UPDATE: "Update",
    AUTOFILL: "Autofill",
    CAMERA_NO: "Camera No",
    UPDATED_SUCCESSFULLY: "successfully updated",
    CO_ORDINATES: "Please give some co-ordinates",
    //PlanogramHistoryWidget
    HISTORY: "History",
    OBJECTS_IN_SHELF: "Objects In shelf",
    UPDATED_TIME: "Updated Time",
    IMAGE: "Image",
    SOMETHING_WENT_WORNG: " Something went wrong",
    //PlanogramImageWidget
    MISPLACED_ITMES: "Misplaced Items:",
    EVERYTHING_IS_WORKING_FINE: "Everything is working fine",
    CLEAR: "Clear",
    CLEAR_ALL: "Clear All",
    //PRODUCT LIST WIDGET
    PRODUCT_LIST: "Product List",
    //Product Search Table
    SKU: "SKU",
    PRODUCT_NAME: "Product Name",
    WEIGHT: "Weigth",
    HEIGHT: "Height",
    WIDTH: "Width",
    //Register New Product
    REGISTER_NEW_PRODUCT: "Register New Product",
    SEARCH_PRODUCT: "Search Product",
    //RegisterWidgets
    REGISTER: "Register",
    //SessionCabinet
    CABINET_NAME: "Cabinet Name",
    VIEW_HISTORY: "View History",
    //SessionCabinetTable
    ACTION: "Action",
    TIME_STAMP: "Time Stamp",
    VIDEO_CLIP: "Video Clip",
    //SIDE BAR
    CUSTOMER_JOURNEY: "Customer Journey",
    UNREGISTER_PRODUCT: "Unregistered Product",
    TRANSACTION: "Transaction",
    CABINET: "Cabinet",
    TESTING: "Testing",
    SIGN_OUT: "Sign Out",
    //SIGN-OUT
    OK: "Ok",
    CANCEL: "Cancel",
    //UNREGISTER PRODUCT
    REGISTER_PRODUCT: "Register Product",
    ENTER_PRODUCT_NAME: "Enter Product Name",
    ENTER_SKU_ID: "Enter SKU ID",
    ENTER_TRAINING_ID: "Enter Training ID",
    ENTER_HEIGHT_IN_MM: "Enter Height in mm",
    ENTER_WEIGHT_IN_KG: "Enter Weight in kg",
    ENTER_WIDTH_IN_MM: "Enter Width in mm",
    //Inventory Management
    INVENTORY_MANAGEMENT: "Inventory Management",
    SELCET_LOCATION: "Select Location",
    //Update Inventory
    UPDATE_INVENTORY: "Update Inventory",
    MACHINE_ID: "Machine Id",
    QUANTITY: "Quantity",
    VIEW_DETAILS: "View Details",
    CABINET_LIST: "Cabinet List",
    MISPLACEMENT: "Misplacement",
    FETCH_NEW_FRAME: "Fetch New Frame",
    BACK: "Back",
    CABINET_ONBOARDING: "Cabinet Onboarding",
    SEARCH_CABINET: "Search by cabinet id",
    SUBMIT: "Submit",
    ENTER_CABINET_ID: "Enter Cabinet Id",
    ENTER_CAMERA_ID: "Enter Camera URL",
    ENTER_CONFIGURATION_ID: "Enter Configuration Id",
    CONFIGURATION_ID: "Configuration Id",
    ADD: "Add",
    EDIT: "Edit",
    DELETE: "Delete",
    PLEASE_ANNOTATE_PRODUCT_PROPERLY: "Please annotate product properly",
    USERNAME_PASSWORD_DOES_NOT_MATCH: "User name and Password does not match",
    SHELF_ID: "Shelf Id",
    ENTER_SHELF_ID: "Enter Shelf Name",
    BRANCH_NAME: "Branch Name",
    ACTIVE: "Publish",
    SHELF_NAME: "Shelf Name",
    ACTIVATED_SUCCESSFULLY: "Published Successfully",
    AUTOFILL_DATA_IS_EMPTY: "Auto-Fill data is empty please annotate mannually",
    VENDOR: {
        VENDOR: "Vendors",
        DASHBOARD: "Dashboard",
        HEALTH_CHECK: "Health Check",
        SUBSCRIPTION: "Subscription",
        TROUBLE_SHOOT: "Trouble Shoot",
        REPORTS: "Reports",
        CATEGORY: 'Category',
        SUB_CATEGORY: 'Sub Category',


        BRANCHES: "Branches",
        PRODUCTS: "Products",
        CABINETS: "Cabinets",
        CUSTOMER_JOURNEY: "Customer Journey",
        INVENTORY_MANAGEMENT: "Inventory management",
        INVENTORY_HISTORY: "Inventory History",
        PLANOGRAM: "Planogram"
    },
    SHELF: "Shelf",
    MAC_ADDRESS: "MAC ADDRESS:",
    LOGIN: {
        PLEASE_ENTER_THE_USER_NAME: "Please enter the user name",
        PLEASE_ENTER_THE_PASSWORD: "Please enter the password",
        PLEASE_ENTER_THE_USERNAME_PASSOWRD: "Please enter the username and password"
    },
    BRANCH: {
        PLEASE_ENTER_VALID_BRANCH_NAME: "Please enter the valid branch name",
        PLEASE_ENTER_BRANCH_NAME: "Please enter the branch name",
        PLEASE_ENTER_VALID_ADDRESS: "Please enter the valid address line 1",
        PLEASE_ENTER_ADDRESS: "Please enter the address line 1",
        PLEASE_ENTER_VALID_ADDRESS_2: "Please enter the valid address line 2",
        PLEASE_ENTER_ADDRESS_2: "Please enter the address line 2",
        FIELD_CANNOT_BE_EMPTY: "Please enter the valid zipcode",
        MAXIMUM_OF_9_CHARACTERS_ACCEPTED: "Maximum of 9 characters accepted",
        ADD_BRANCH: "Add Branch",
        UPDATE_BRANCH: "Update Branch",
        ADD_NEW_BRANCH: "Add new branch",
        BRANCHES: "Branches",
        NAME: "Branch Name:",
        ADDRESS1: "Address line 1:",
        ADDRESS2: "Address line 2:",
        Zipcode: "Zipcode:",
    },
    PRODUCT_SCREEN: {
        PLEASE_ENTER_VALID_PRODUCT_NAME: "Please enter the valid product name",
        PLEASE_ENTER_VALID_PRODUCT_SKU: "Please enter the valid product SKU",
        PLEASE_SELECT_THE_CATEGORY: "Please select the category",
        PLEASE_SELECT_THE_SUB_CATEGORY: "Please select the sub category",
        PLEASE_ENTER_THE_VALID_TITLE: "Please enter the valid title",
        PLEASE_ENTER_THE_VALID_DESCRIPTION: "Please enter the valid description",
    },
    EDGE_SERVERS: "Control Unit",
    TROUBLE_SHOOT: "Trouble Shoot",
    NEXT: "Next",
    PREVIOUS: "Previous",
    OPEN: "Open",
    CAMERA_URL: "Camera URL",
    SAVE: "Save",
    CLOSE: "CLOSE",
    EDIT_PLANOGRAM: "Edit Planogram",
    CREATE_PLANOGRAM: "Create Planogram",
    DISABLE_PLANOGRAM: "Please enable cabinet"
}
export const COLORS = {
    appThemeHex: '#1e1f29',
    appThemeHover: 'rgba(30, 31, 41, .5)',
    loadingBackround: 'rgba(255, 255, 255, .4)',
    listBackground: 'rgba(30, 31, 41, .2)',

}
