import { doAction, doResponseAction } from ".";

const UPDATE_PLANOGRAM = 'UPDATE_PLANOGRAM';
const UPDATE_PLANOGRAM_SUCCESS = 'UPDATE_PLANOGRAM_SUCCESS';
const UPDATE_PLANOGRAM_FAILURE = 'UPDATE_PLANOGRAM_FAILURE';
export {
    UPDATE_PLANOGRAM,
    UPDATE_PLANOGRAM_FAILURE,
    UPDATE_PLANOGRAM_SUCCESS,
}

// CameraList
export const updateList = (params: {}, callback: any) => doAction(UPDATE_PLANOGRAM, params, callback);
export const updateListSuccess = (data: {}) => doResponseAction(UPDATE_PLANOGRAM_SUCCESS, data);
export const updateListFailure = (data: {}) => doResponseAction(UPDATE_PLANOGRAM_FAILURE, data);