import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { Actions } from "../../../redux/action";
import { getPlanogramData } from "../../../redux/reducer/PlanogramReducer";

export const Dropdowns = ({ inputLabel, onChange, onDelete, optionsprops, index, annotatedId, planogramData, setPlanogramData }: { inputLabel: string, onChange: (value: string) => void, onDelete: () => void, optionsprops: any, index?: number, annotatedId: string, planogramData: any, setPlanogramData: any }) => {
    const [_dropDownData, setDropDownData] = useState<{ value: string; options: any; }>({ value: "", options: optionsprops })
    const handleChange = (e: any, data: any) => {
        setDropDownData({ value: data?.value, options: optionsprops });
        onChange(data?.value);
    };
    const ondelete = () => {
        planogramData.splice(planogramData.findIndex((e: any) => e.id === annotatedId), 1);
        setPlanogramData(JSON.parse(JSON.stringify(planogramData)))
    };
    return (
        <>
            <div style={{ display: "flex", flex: "1", width: "200px" }}>
                <Dropdown
                    placeholder="Select"
                    fluid
                    selection
                    value={inputLabel}
                    onChange={handleChange}
                    options={optionsprops}
                />
                <div
                    className="col col-md-4"
                    style={{
                        float: "right",
                        background: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "35px",
                        color: "white",
                        fontSize: "12px",
                        backgroundColor: "#3b5bdb",
                        borderRadius: "0 5px 5px 0",
                        cursor: "pointer",
                        transition: "backgroundColor 0.5s, color 0.5s",
                        zIndex: 999,
                    }}
                    onClick={ondelete} ><i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                </div>
            </div>
        </>
    )
}