import { Input } from "../Atoms/Input"

export const RegisterMolecules = ({ label, id, placeholder, handlechange, referValue, inputValue }: { referValue?: React.MutableRefObject<null>, inputValue?: string, label: string, id?: string, placeholder?: string, handlechange: (event: React.ChangeEvent<HTMLInputElement>) => void }) => {
    return (
        <>
            <div className="row regsiterRow">
                <label className="registerLabel">{label}</label>
                {label.includes("Image") ?
                    <Input inputType="file" inputClassName="inputTag" handleChange={handlechange} /> :
                    <Input value={inputValue} inputref={referValue} inputType="text" inputClassName="form-control RegisterInput" id={id} placeholder={placeholder} handleChange={handlechange} />
                }
            </div>
        </>
    )
}