import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { ResponseGenerator } from './SagaInterface';
import { doGet } from '../../config';

export function* historySaga(param: any) {
    const { params } = param
    try {
        const response: ResponseGenerator = yield doGet(params, '', '')
        yield put(Actions.historySuccess(response));
    } catch (error) {
        yield put(Actions.historyFailure({ error }));
    }
}
export default function* historyWatcher() {
    yield all([
        takeLatest(Actions.HISTORY, historySaga),
    ]);
}