import { doAction, doResponseAction } from ".";

const SESSION_START = 'SESSION_START';
const SESSION_START_SUCCESS = 'SESSION_START_SUCCESS';
const SESSION_START_FAILURE = 'SESSION_START_FAILURE';
export {
    SESSION_START,
    SESSION_START_FAILURE,
    SESSION_START_SUCCESS
}
// SESSION_START
export const session = (params: {}, callback?: any) => doAction(SESSION_START, params, callback);
export const sessionSuccess = (data: {}) => doResponseAction(SESSION_START_SUCCESS, data);
export const sessionFailure = (data: {}) => doResponseAction(SESSION_START_FAILURE, data);