import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    id: undefined,
    is_active: false
}
export const getTrainingIdReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.TRAINING_ID: {
            return {
                ...state,
                isLoading: true,
                id: action.params
            };
        }
        case Actions.IS_ACTIVE: {
            return {
                ...state,
                is_active: action.params
            }
        }
        default:
            return state;
    }
};
export const getTrainigIdData = (state: any) => state?.GET_TRAINING_ID?.id;
export const getisActive = (state: any) => state?.GET_TRAINING_ID?.is_active;
