import { doAction, doResponseAction } from ".";

const SUBSCRIPTION_LIST = 'SUBSCRIPTION_LIST';
const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS';
const SUBSCRIPTION_LIST_FAILURE = 'SUBSCRIPTION_LIST_FAILURE';
export {
    SUBSCRIPTION_LIST,
    SUBSCRIPTION_LIST_FAILURE,
    SUBSCRIPTION_LIST_SUCCESS,
}

// SUBSCRIPTION_LIST
export const subscriptionList = (params?: {}, callback?: any) => doAction(SUBSCRIPTION_LIST, params, callback);
export const subscriptionListSuccess = (data = {}) => doResponseAction(SUBSCRIPTION_LIST_SUCCESS, data);
export const subscriptionListFailure = (data = {}) => doResponseAction(SUBSCRIPTION_LIST_FAILURE, data);