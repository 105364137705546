import { doAction, doResponseAction } from ".";

const MASTER_SUBSCRIPTION_LIST = 'MASTER_SUBSCRIPTION_LIST';
const MASTER_SUBSCRIPTION_LIST_SUCCESS = 'MASTER_SUBSCRIPTION_LIST_SUCCESS';
const MASTER_SUBSCRIPTION_LIST_FAILURE = 'MASTER_SUBSCRIPTION_LIST_FAILURE';

const MASTER_SUBSCRIPTION_CREATE = 'MASTER_SUBSCRIPTION_CREATE';
const MASTER_SUBSCRIPTION_CREATE_SUCCESS = 'MASTER_SUBSCRIPTION_CREATE_SUCCESS';
const MASTER_SUBSCRIPTION_CREATE_FAILURE = 'MASTER_SUBSCRIPTION_CREATE_FAILURE';

const MASTER_SUBSCRIPTION_UPDATE = 'MASTER_SUBSCRIPTION_UPDATE';
const MASTER_SUBSCRIPTION_UPDATE_SUCCESS = 'MASTER_SUBSCRIPTION_UPDATE_SUCCESS';
const MASTER_SUBSCRIPTION_UPDATE_FAILURE = 'MASTER_SUBSCRIPTION_UPDATE_FAILURE';

const MASTER_SUBSCRIPTION_STATUS_UPDATE = 'MASTER_SUBSCRIPTION_STATUS_UPDATE';
const MASTER_SUBSCRIPTION_STATUS_UPDATE_SUCCESS = 'MASTER_SUBSCRIPTION_STATUS_UPDATE_SUCCESS';
const MASTER_SUBSCRIPTION_STATUS_UPDATE_FAILURE = 'MASTER_SUBSCRIPTION_STATUS_UPDATE_FAILURE';
export {
    MASTER_SUBSCRIPTION_LIST,
    MASTER_SUBSCRIPTION_LIST_FAILURE,
    MASTER_SUBSCRIPTION_LIST_SUCCESS,

    MASTER_SUBSCRIPTION_CREATE,
    MASTER_SUBSCRIPTION_CREATE_FAILURE,
    MASTER_SUBSCRIPTION_CREATE_SUCCESS,

    MASTER_SUBSCRIPTION_UPDATE,
    MASTER_SUBSCRIPTION_UPDATE_FAILURE,
    MASTER_SUBSCRIPTION_UPDATE_SUCCESS,

    MASTER_SUBSCRIPTION_STATUS_UPDATE,
    MASTER_SUBSCRIPTION_STATUS_UPDATE_FAILURE,
    MASTER_SUBSCRIPTION_STATUS_UPDATE_SUCCESS,
}

// MASTER_SUBSCRIPTION_LIST
export const masterSubscriptionList = (params?: {}, callback?: any) => doAction(MASTER_SUBSCRIPTION_LIST, params, callback);
export const masterSubscriptionListSuccess = (data = {}) => doResponseAction(MASTER_SUBSCRIPTION_LIST_SUCCESS, data);
export const masterSubscriptionListFailure = (data = {}) => doResponseAction(MASTER_SUBSCRIPTION_LIST_FAILURE, data);

// MASTER_SUBSCRIPTION_CREATE
export const masterSubscriptionCreate = (params?: {}, callback?: any) => doAction(MASTER_SUBSCRIPTION_CREATE, params, callback);
export const masterSubscriptionCreateSuccess = (data = {}) => doResponseAction(MASTER_SUBSCRIPTION_CREATE_SUCCESS, data);
export const masterSubscriptionCreateFailure = (data = {}) => doResponseAction(MASTER_SUBSCRIPTION_CREATE_FAILURE, data);

// MASTER_SUBSCRIPTION_UPDATE
export const masterSubscriptionUpdate = (params?: {}, callback?: any) => doAction(MASTER_SUBSCRIPTION_UPDATE, params, callback);
export const masterSubscriptionUpdateSuccess = (data = {}) => doResponseAction(MASTER_SUBSCRIPTION_UPDATE_SUCCESS, data);
export const masterSubscriptionUpdateFailure = (data = {}) => doResponseAction(MASTER_SUBSCRIPTION_UPDATE_FAILURE, data);

// MASTER_SUBSCRIPTION_STATUS_UPDATE
export const masterSubscriptionStatusUpdate = (params?: {}, callback?: any) => doAction(MASTER_SUBSCRIPTION_STATUS_UPDATE, params, callback);
export const masterSubscriptionStatusUpdateSuccess = (data = {}) => doResponseAction(MASTER_SUBSCRIPTION_STATUS_UPDATE_SUCCESS, data);
export const masterSubscriptionStatusUpdateFailure = (data = {}) => doResponseAction(MASTER_SUBSCRIPTION_STATUS_UPDATE_FAILURE, data);