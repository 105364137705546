import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Actions } from '../../../../../redux/action'
import Select from '../../../../UI/Molecules/Select'
import { getLoginData } from '../../../../../redux/reducer/login'
import { getBranchDetails } from '../../../../../redux/reducer/BranchesReducer'
import { getCabinetSearchList } from '../../../../../redux/reducer/SearchReducer'
import { vendorListDetails } from '../../../../../redux/reducer/VendorReducer'
import { getTroubleShootCheck, getTroubleShootCheckLoader } from '../../../../../redux/reducer/TroubleShootReducer'
import PlanogramLoader from '../../../../UI/Molecules/PlanogramLoader'
import { DataNotFound } from '../../../../UI/Molecules/DataNotFound'
import { toast } from 'react-toastify'
import { MESSAGE } from '../../../../../config/String'

export const TroubleShoot = () => {
    const initialState = {
        branchId: "",
        cabinetId: "",
        vendorId: ""
    }
    const dispatch = useDispatch()
    const loginDetails = useSelector(getLoginData)
    const branchDetails = useSelector(getBranchDetails)
    const cabinetDetails = useSelector(getCabinetSearchList)
    const troubleShootDetails = useSelector(getTroubleShootCheck)
    const [filteredCabinet, setCabinetDetails] = useState<any>([])
    const [filter, setFilter] = useState<any>(initialState)
    const vendorList = useSelector(vendorListDetails)
    const isLoading = useSelector(getTroubleShootCheckLoader)

    useEffect(() => {
        const { role } = loginDetails
        if (role !== 1) {
            const { user_id } = loginDetails || {}
            const params = { vendor_id: user_id }
            dispatch(Actions.branchesList(params))
        }
        role === 1 && dispatch(Actions.vendorOnboardingList(""))
    }, [dispatch])

    useEffect(() => {
        const callback = (response: any) => {
            if (response?.status_code === 1 || response?.error) {
                toast.error(response?.message)
            }
        }
        const { role, user_id } = loginDetails
        if (filter?.branchId !== "" && filter?.cabinetId !== "") {
            if (role === 1 && filter?.vendorId) {
                dispatch(Actions.troubleShootList({ vendorId: filter?.vendorId, branchId: filter?.branchId, cabinetId: filter?.cabinetId }, callback))
            } else {
                dispatch(Actions.troubleShootList({ vendorId: user_id, branchId: filter?.branchId, cabinetId: filter?.cabinetId }, callback))
            }
        }
    }, [filter?.cabinetId])

    const getCabinetList = (branch: string, vendorId: any, storeId: any) => {
        const callback = (response: any) => {
            const filteredCabinet = response?.response?.result?.filter((o: any) => o?.branch?.id == branch)
            const convertArray = filteredCabinet?.map((k: any) => ({ value: k.id, ...k }))
            setCabinetDetails(convertArray)
        }
        let params = {
            branchId: branch,
            limit: 100,
            pagenum: 1,
            vendorId: vendorId,
            storeId: storeId
        }
        dispatch(Actions.cabinet_search(params, callback));
    }

    const handleBranchChange = (event: any) => {
        const storeObj = result?.find((o: any) => o.id == event.target.value)
        getCabinetList(event.target.value, filter?.vendorId, storeObj?.store)
        setFilter({ ...filter, 'branchId': event.target.value, 'cabinetId': '' })
    }

    const handleCabinetChange = (event: any) => {
        setFilter({ ...filter, 'cabinetId': event.target.value })
    }
    const handleVendorChange = (event: any) => {
        setFilter({ ...filter, 'vendorId': event.target.value, 'cabinetId': '' })
        const params = { vendor_id: event.target.value }
        dispatch(Actions.branchesList(params))
    }

    const fetchNew = () => {
        const callback = (response: any) => {
            if (response?.status_code === 1 || response?.error) {
                toast.error(MESSAGE?.SOMETHING_WENT_WORNG)
            }
        }
        const { role, user_id } = loginDetails
        const vendorId = role === 1 ? filter?.vendorId : user_id
        if (filter?.branchId !== "" && filter?.cabinetId !== "" && vendorId) {
            dispatch(Actions.troubleShootList({ vendorId: vendorId, branchId: filter?.branchId, cabinetId: filter?.cabinetId }, callback))
        }
    }
    const { role } = loginDetails
    const { result = [] } = branchDetails || {}
    const convertBranchArray = result?.map((obj: any) => ({ value: obj.id, ...obj }))
    const convertVendorArray = vendorList?.vendor_details?.map((obj: any) => ({ value: obj.vendor_id, name: obj.first_name, ...obj }))
    const { image } = troubleShootDetails || {}
    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-sm-6">
                    <h3 className="font-bold">TroubleShoot</h3>
                </div>
                <div className="col-sm-6 text-end">
                    <button className="btn btn-primary big-btn" onClick={() => fetchNew()}>Fetch Frames</button>
                </div>
                <div className="row mt-4">
                    {
                        role === 1 &&
                        <div className="col-4">
                            <Select
                                placeHolder={"All Vendor"}
                                isList={true}
                                array={convertVendorArray}
                                onChange={handleVendorChange}
                            />
                        </div>
                    }

                    <div className={role !== 1 ? "col-4 offset-4" : "col-4"}>
                        <Select
                            placeHolder={"All Branches"}
                            value={filter?.branchId}
                            isList={true}
                            array={convertBranchArray}
                            disabled={(convertBranchArray?.length === 0 && role === 1 || filter?.vendorId == ''&& role === 1)}
                            onChange={handleBranchChange}
                        />
                    </div>
                    <div className="col-4">
                        <Select
                            placeHolder={"All Cabinet"}
                            isList={true}
                            array={filteredCabinet}
                            disabled={!filter?.branchId || filteredCabinet?.length === 0 || filter?.vendorId == ''}
                            value={filter?.cabinetId}
                            onChange={handleCabinetChange}
                        />
                    </div>
                </div>
                {filter?.cabinetId && <div className="grid-container" style={{ marginTop: "30px" }}>
                    {
                        image?.map((obj: any) => {
                            return (
                                <div className="card pictureContainer">
                                    <div className="card-body">
                                        <img src={`data:image/png;base64,${obj?.image}`} className="card-img-top image_custom" alt="troubleshoot" />
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>}
                {(image?.length === 0 && !isLoading) && <DataNotFound
                    Error={"No data found"}
                />}
            </div>
            {isLoading && <PlanogramLoader />}
        </div>
    )
}