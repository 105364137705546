import React, { useEffect, useState } from "react"
import { PaginationSession } from "../../../UI/Molecules/Pagination"
import { useNavigate, useParams } from "react-router-dom"
import { PopupData } from "../../../UI/Molecules/PopUpData"
import { CheckBoxMolecules } from "../../../UI/Molecules/CheckboxMolecules"
import InputField from '../../../UI/Molecules/InputField'
import { AlertMolecules } from '../../../UI/Molecules/AlertMolecules'
import Select from "../../../UI/Molecules/Select"
import { Actions } from "../../../../redux/action"
import { adminproductlist, productDataList } from "../../../../redux/reducer/ProductListReducer"
import { getCategoryListData, getSubCategoryListData } from "../../../../redux/reducer/CategoryReducer"
import { useDispatch, useSelector } from "react-redux"
import { getLoginData } from "../../../../redux/reducer/login"
import { isSelectEmpty, ProductIdPop, ProductValidation, validatemaxLengthZipReg, validateMaxProduct, validateSku } from "../../../../util/Validations"
import { toast } from "react-toastify"
import { DataNotFound } from "../../../UI/Molecules/DataNotFound"
import PlanogramLoader from "../../../UI/Molecules/PlanogramLoader"
import { MESSAGE } from "../../../../config/String"
import CategotyTypeAhead from "../../Widgets/CategoryTypeAhead"
import SubCategoryTypeAhead from "../../Widgets/SubCategoryTypeAhead"

const ProductList = () => {
    const dispatch = useDispatch()
    const [popup, setPopup] = useState<boolean>(false);
    const [popupImage, setPopUpImage] = useState<string>();
    const [popupDetails, setPopUpDetails] = useState<string>();
    const [addPopup, setAddPopup] = useState<boolean>(false);
    const [updatePopup, setUpdatePopup] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const productDetails = useSelector(productDataList)
    const categoryList = useSelector(getCategoryListData)
    const subCtegoryList = useSelector(getSubCategoryListData)
    const usersDetails = useSelector(getLoginData)
    const [formDetails, setFormDetails] = useState<any>({});
    const [productIdDetails, setProductIdDetails] = useState<any>({});
    const [editProduct, setEditProduct] = useState<any>()
    const [updateProductDetails, setUpdateProductDetails] = useState<any>()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [errors, setErrors] = useState<any>();
    const [productIdError, setProductIdError] = useState<any>();
    const [pageType, setPageType] = useState<string>('publish')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [filterSubCtegoryList, setSubCtegoryList] = useState<any>([])
    const vendorId = useParams();

    //To check the onchange validation in the product onboarding
    useEffect(() => {
        if (Object.keys(formDetails)?.length !== 0) {
            let validatedError = ProductValidation(formDetails, ["name", "sku_id", "title", "description", "height", "width", "breadth", "weight", "training_id", "group_id", "price", "tax_percentage"])
            if (validatedError) {
                setErrors(validatedError)
            }
        }
    }, [formDetails])

    //To check the onchange validation in the update the status
    useEffect(() => {
        if (Object.keys(productIdDetails)?.length !== 0) {
            let validatedError = ProductIdPop(productIdDetails, ["training_id", "group_id", "status"])
            if (validatedError) {
                setProductIdError(validatedError)
            }
        }
    }, [productIdDetails])

    //Inital api call of the product list
    useEffect(() => {
        getProductList()
    }, [dispatch, currentPage])

    //Inital api call of the categoryList and subcategory list for dropdown in onboarding
    useEffect(() => {
        // dispatch(Actions.categotyList());
        dispatch(Actions.subCategotyList());
    }, [dispatch])

    //Handlechange to change the product form value.
    const handleChange = (e: any) => {
        if (e.target.name === "name") {
            setFormDetails({ ...formDetails, [e.target.name]: e.target.value.replace(/\d/g, '') })
        } else {
            setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
        }

    }

    //Handlechange to change the product id for update value.
    const ProductIdChanges = (e: any) => {
        setProductIdDetails({ ...productIdDetails, [e.target.name]: e.target.value })
    }

    //Get prodcut list function for api call.
    const getProductList = () => {
        const { user_id, role } = usersDetails || {}
        const callback = (response: any) => {
            setLoading(false)
        }
        let params: any = {
            pagenum: currentPage,
            limit: 10,
            status: pageType === "publish" ? 1 : 2,
            vendorId: role === 1 ? vendorId?.id : user_id
        }
        setLoading(true)
        role === 1 ? dispatch(Actions.admin_Product_List(params, callback)) : dispatch(Actions.publish_Product_List(params, callback))
    }

    //Common callback for show the toaster and clear the popup and loader state
    const createCallBack = (response: any) => {
        setLoading(false)
        if (response?.status_code === 0) {
            toast.success(response?.message)
            if (!editProduct) {
                changeTab('request')
            } else {
                getProductList()
            }
            setUpdatePopup(false)
            ManageProduct(false)
        } else {
            toast.error(response?.message)
        }
    }

    //Product onboarding submit check and api integration
    const productCreate = () => {
        const { role } = usersDetails || {}
        if (!formDetails?.name) {
            setErrors({ ...errors, name: "Please enter the product name" })
        } else if (validatemaxLengthZipReg(formDetails?.name)) {
            setErrors({ ...errors, name: "Please enter the valid product name" })
        } else if (!formDetails?.sku_id) {
            setErrors({ ...errors, sku_id: "Please enter the product SKU" })
        } else if (!validateSku(formDetails?.sku_id)) {
            setErrors({ ...errors, sku_id: "Please enter the valid product SKU" })
        } else if ((!formDetails?.category_id && !editProduct)) {
            setErrors({ ...errors, category_id: "Please choose the category" })
        } else if (!formDetails?.sub_category_id && !editProduct) {
            setErrors({ ...errors, sub_category_id: "Please choose the sub category" })
        } else if (!formDetails?.title) {
            setErrors({ ...errors, title: "Please enter the valid title" })
        } else if (!formDetails?.description) {
            setErrors({ ...errors, description: "Please enter the valid description" })
        } else if (!formDetails?.height) {
            setErrors({ ...errors, height: "Please enter the valid height" })
        } else if (!formDetails?.width) {
            setErrors({ ...errors, width: "Please enter the valid width" })
        } else if (!formDetails?.breadth) {
            setErrors({ ...errors, breadth: "Please enter the valid breadth" })
        } else if (!formDetails?.weight) {
            setErrors({ ...errors, weight: "Please enter the valid weight" })
        } else if (!formDetails?.price) {
            setErrors({ ...errors, price: "Please enter the valid price" })
        } else if (!formDetails?.tax_percentage) {
            setErrors({ ...errors, tax_percentage: "Please enter the tax percentage" })
        } else if (Math.ceil(formDetails?.tax_percentage) > 100) {
            setErrors({ ...errors, tax_percentage: "Please enter the valid tax percentage" })
        } else if (!productIdDetails?.group_id && editProduct && pageType === "publish") {
            setProductIdError({ ...errors, group_id: "Please enter the valid group id" })
        } else if (!productIdDetails?.training_id && editProduct && pageType === "publish") {
            setProductIdError({ ...errors, training_id: "Please enter the valid training id" })
        } else if (!productIdDetails?.status && editProduct && pageType === "publish") {
            setProductIdError({ ...errors, status: "Please enter the valid status" })
        } else {
            const { user_id } = usersDetails || {}
            let params: any = {
                vendor_id: role === 1 ? vendorId?.id : user_id,
                sku_id: formDetails?.sku_id,
                category_id: formDetails?.category?.id || formDetails?.category_id?.value,
                sub_category_id: formDetails?.sub_category?.id || formDetails?.sub_category_id,
                name: formDetails?.name,
                title: formDetails?.title,
                description: formDetails?.description,
                height: formDetails?.height,
                width: formDetails?.width,
                breadth: formDetails?.breadth,
                weight: formDetails?.weight,
                price: formDetails?.price,
                tax: formDetails?.tax_percentage,
            }
            setLoading(true)
            if (role === 1) {
                if (editProduct) {
                    params['product_id'] = formDetails?.id
                    params['group_id'] = productIdDetails?.group_id
                    params['status'] = productIdDetails?.status
                    params['training_id'] = productIdDetails?.training_id
                    dispatch(Actions.adminproductUpdate(params, createCallBack))
                } else {
                    dispatch(Actions.AdmincreateProduct(params, createCallBack))
                }
            } else {
                if (editProduct) {
                    params['product_id'] = formDetails?.id
                    dispatch(Actions.productUpdate(params, createCallBack))
                } else {
                    dispatch(Actions.createProduct(params, createCallBack))
                }
            }
        }

    }

    //ProductId submit check and api integration
    const productIdCreate = () => {
        if (!productIdDetails?.training_id) {
            setProductIdError({ ...productIdError, training_id: "Please enter the valid trainingId" })
        } else if (!productIdDetails?.group_id) {
            setProductIdError({ ...productIdError, group_id: "Please enter the valid groupId" })
        } else if (!productIdDetails?.status) {
            setProductIdError({ ...productIdError, status: "Please choose the status" })
        } else {
            let params: any = {
                vendor_id: vendorId?.id,
                product_id: updateProductDetails?.id,
                training_id: productIdDetails?.training_id,
                group_id: productIdDetails?.group_id,
                status: productIdDetails?.status,
                // is_active: updateProductDetails?.is_active
            }
            setLoading(true)
            dispatch(Actions.adminproductPublish(params, createCallBack))
        }
    }

    const updateProductId = (obj: any) => {
        setUpdateProductDetails(obj)
        setUpdatePopup(true)
    }
    const viewProductDetails = (obj: any) => {
        setPopup(true)
        setPopUpImage(obj?.image)
        setPopUpDetails(obj)
    }
    const ManageProduct = (flag: any) => {
        dispatch(Actions.subCategotyList());
        setFormDetails({})
        setAddPopup(flag)
        setEditProduct(false)
    }
    const cancel = () => {
        setAddPopup(false)
        setFormDetails('')
        setErrors('')
    }
    const productCancel = () => {
        setUpdatePopup(false)
        setProductIdError('')
        setProductIdDetails('')
    }
    const deleteVendors = (flag: boolean) => {
        setDeletePopup(flag)
    }

    const confirm = () => {
        setDeletePopup(false)
    }
    // For handle the pagination
    const onChangeHandle = (page: number) => {
        setCurrentPage(page)
    }
    const handleProductChange = (event: any, flag: boolean) => {
        if (flag) {
            setFormDetails({ ...formDetails, 'sub_category_id': event.target.value })

        } else {
            setFormDetails({ ...formDetails, 'category_id': event, 'sub_category_id': '' })
            const filteredSubCtegoryList = subCtegoryList?.Subcategory?.filter((o: any) => o?.category === event?.value)
            const convertArray = filteredSubCtegoryList?.map((k: any) => ({ value: k.id, ...k }))
            setSubCtegoryList(convertArray)
        }
    }
    //while change the tab and set the initial state
    const changeTab = (tab: string) => {
        const callback = (response: any) => {
            setLoading(false)
            setPageType(tab)
        }
        setCurrentPage(1)
        const { user_id } = usersDetails || {}
        let params: any = {
            pagenum: 1,
            limit: 10,
            status: tab === "publish" ? 1 : 2,
            vendorId: role !== 1 ? user_id : vendorId?.id
        }
        setLoading(true)
        role === 1 ? dispatch(Actions.admin_Product_List(params, callback)) : dispatch(Actions.publish_Product_List(params, callback))
    }

    const handleProductStatus = (event: any) => {
        setProductIdDetails({ ...productIdDetails, 'status': event.target.value })
    }

    const updateProduct = (obj: any) => {
        setEditProduct(true)
        setFormDetails(obj)
        setProductIdDetails({ group_id: obj?.group_id, training_id: obj?.training_id, status: obj?.status })
        const filteredSubCtegoryList = subCtegoryList?.Subcategory?.filter((o: any) => o?.category === obj?.sub_category?.category)
        const convertArray = filteredSubCtegoryList?.map((k: any) => ({ value: k.id, ...k }))
        setSubCtegoryList(convertArray)
        setAddPopup(true)
    }
    const onStatusChange = (event: any, obj: any) => {
        const callback = (res: any) => {
            setLoading(false)
            if (res?.status_code === 0) {
                toast.success("Successfully updated")
                setLoading(false)
                getProductList()
            } else {
                toast.error(res?.message)
            }

        }
        const { user_id, role } = usersDetails || {}
        let params: any = {
            vendor_id: role === 1 ? vendorId?.id : user_id,
            product_id: obj?.id,
            is_active: !obj?.is_active
        }
        setLoading(true)
        role === 1 ? dispatch(Actions.adminProductActive(params, callback)) : dispatch(Actions.productActive(params, callback))
    }
    const { user_id, role } = usersDetails || {}
    const convertArray = categoryList?.Category?.map((obj: any) => ({ value: obj.id, label: obj?.name, ...obj }))
    // const subConvertArray = subCtegoryList?.Subcategory?.map((obj: any) => ({ value: obj.id, label: obj?.name, ...obj }))
    const statusArray = [{ value: 1, name: 'Under verification' }, { value: 3, name: 'Reject' }, { value: 2, name: 'Accept' }, { value: 4, name: 'Publish' }]
    const Content = (popupDetails: any) => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="row">
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Product Name:</div>
                            <div className="col-8">{popupDetails?.name}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">SKU Id:</div>
                            <div className="col-8">{popupDetails?.sku_id}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Title:</div>
                            <div className="col-8">{popupDetails?.title}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Category:</div>
                            <div className="col-8">{popupDetails?.category?.name}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Sub Category:</div>
                            <div className="col-8">{popupDetails?.sub_category?.name}</div>
                        </div>
                        {role === 1 && <React.Fragment>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Training Id:</div>
                                <div className="col-8">{popupDetails?.training_id}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Group Id:</div>
                                <div className="col-8">{popupDetails?.group_id}</div>
                            </div>
                        </React.Fragment>}
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Description:</div>
                            <div className="col-8">{popupDetails?.description}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Height:</div>
                            <div className="col-8">{popupDetails?.height}&nbsp;cm</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Width:</div>
                            <div className="col-8">{popupDetails?.width}&nbsp;cm</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Breadth:</div>
                            <div className="col-8">{popupDetails?.breadth}&nbsp;cm</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Weight:</div>
                            <div className="col-8">{popupDetails?.weight}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Price:</div>
                            <div className="col-8">{popupDetails?.price}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Tax:</div>
                            <div className="col-8">{popupDetails?.tax_percentage}%</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const DeleteContent = () => {
        return (
            <React.Fragment>
                <p>Do you want delete the product?</p>
                <div className="row mt-4">
                    <div className="col-sm-6">
                        <button className="btn big-btn btn-primary btn-lg w-100" onClick={() => confirm()}>Yes</button>
                    </div>
                    <div className="col-sm-6 text-end">
                        <button className="btn btn-outline-danger btn-lg ml-2 w-100" onClick={() => deleteVendors(false)}>Cancel</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    const CreateProduct = () => {
        return (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="font-bold">{editProduct ? "Update Product" : "Create Product"}</h3>
                    </div>
                </div>
                <div className="row mt-3">
                    <InputField inputType={"text"} label="Product Name" placeholder="Product Name" name="name" value={formDetails?.name} columnSize={6} onChange={handleChange} error={errors?.name} />
                    <InputField inputType={"text"} label="SKU Id" placeholder="SKU Id" columnSize={6} onChange={handleChange} name="sku_id" value={formDetails?.sku_id} error={errors?.sku_id} />
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        {/* <Select
                            placeHolder={"Choose Category"}
                            array={convertArray}
                            onChange={(event: any) => handleProductChange(event, false)}
                            value={formDetails?.category_id}
                            error={errors?.category_id}
                        /> */}
                        <CategotyTypeAhead
                            value={editProduct ? { label: formDetails?.category?.name, value: formDetails?.category?.id } : formDetails?.category?.id}
                            placeholder={"Choose Category"}
                            onChange={(event: any) => handleProductChange(event, false)}
                        />
                        {errors?.category_id && <p className='errorMessage'>{errors?.category_id}</p>}
                    </div>
                    <div className="col-6">
                        <Select
                            classname={'subCategorySelect'}
                            placeHolder={"Choose Sub Category"}
                            array={filterSubCtegoryList}
                            onChange={(event: any) => handleProductChange(event, true)}
                            value={formDetails?.sub_category?.id || formDetails?.sub_category_id}
                            error={errors?.sub_category_id}
                            disabled={formDetails?.category_id?.value || formDetails?.category?.id ? false : true}

                        />
                        {/* <SubCategoryTypeAhead
                            placeholder={"Choose Sub Category"}
                            onChange={(event: any) => handleProductChange(event, true)}
                            isDisabled={formDetails?.category_id?.value ? false : true}
                        />
                        {errors?.sub_category_id && <p className='errorMessage'>{errors?.sub_category_id}</p>} */}
                    </div>
                </div>
                <div className="row mt-3">
                    <InputField inputType={"text"} label="Title" placeholder="Title" columnSize={6} onChange={handleChange} name="title" value={formDetails?.title} error={errors?.title} />
                    <InputField inputType={"text"} label="Description" placeholder="Description" columnSize={6} onChange={handleChange} name="description" value={formDetails?.description} error={errors?.description} />
                </div>
                <div className="row mt-3">
                    <InputField inputType={"number"} label="Height in cm" placeholder="Height" columnSize={6} onChange={handleChange} name="height" value={formDetails?.height} error={errors?.height} />
                    <InputField inputType={"number"} label="Width in cm" placeholder="Width" columnSize={6} onChange={handleChange} name="width" value={formDetails?.width} error={errors?.width} />
                </div>
                <div className="row mt-3">
                    <InputField inputType={"number"} label="Breadth  in cm" placeholder="Breath" columnSize={6} onChange={handleChange} name="breadth" value={formDetails?.breadth} error={errors?.breadth} />
                    <InputField inputType={"number"} label="Weight" placeholder="Weight" columnSize={6} onChange={handleChange} name="weight" value={formDetails?.weight} error={errors?.weight} />
                </div>
                <div className="row mt-3">
                    <InputField inputType={"number"} label="Price" placeholder="Price" columnSize={6} onChange={handleChange} name="price" value={formDetails?.price} error={errors?.price} />
                    <InputField inputType={"number"} label="Tax in %" placeholder="Tax percentage" columnSize={6} onChange={handleChange} name="tax_percentage" value={formDetails?.tax_percentage} error={errors?.tax_percentage} />
                </div>
                {
                    (role === 1 && pageType === "publish") &&
                    <>
                        <div className="row mt-3">
                            <InputField inputType={"text"} label="Group Id" placeholder="Group Id" columnSize={6} onChange={ProductIdChanges} name="group_id" value={productIdDetails?.group_id} error={productIdError?.group_id} />
                            <InputField inputType={"text"} label="Training Id " placeholder="Training Id" columnSize={6} onChange={ProductIdChanges} name="training_id" value={productIdDetails?.training_id} error={productIdError?.training_id} />
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-6">
                                <Select
                                    placeHolder={"Choose Action"}
                                    array={statusArray}
                                    value={productIdDetails?.status}
                                    onChange={(event: any) => handleProductStatus(event)}
                                    error={productIdError?.status}
                                />
                            </div>
                        </div>
                    </>
                }

                <div className='row mb-2 mt-4'>
                    <div className='col-sm-6'></div>
                    <div className='col-sm-6 text-end'>
                        <button className="btn btn-secondary btn-outline" onClick={() => cancel()}>Cancel</button>
                        <button className="btn btn-primary big-btn ml-2" onClick={() => productCreate()}>{editProduct ? "Update" : "Create"}</button>
                    </div>
                </div>
            </>
        )
    }

    const UpdateProductId = () => {
        return (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="font-bold">Update</h3>
                    </div>
                </div>

                <div className="row mt-3">
                    <InputField inputType={"text"} label="Training Id" placeholder="Training Id" columnSize={6} onChange={ProductIdChanges} name="training_id" error={productIdError?.training_id} />
                    <InputField inputType={"text"} label="Group Id" placeholder="Group Id" columnSize={6} onChange={ProductIdChanges} name="group_id" error={productIdError?.group_id} />
                </div>
                <div className="row mt-3">
                    <div className="col-sm-6">
                        <Select
                            placeHolder={"Choose Action"}
                            array={statusArray}
                            onChange={(event: any) => handleProductStatus(event)}
                            error={productIdError?.status}
                        />
                    </div>
                </div>
                <div className='row mb-2 mt-4'>
                    <div className='col-sm-6'></div>
                    <div className='col-sm-6 text-end'>
                        <button className="btn btn-secondary btn-outline" onClick={() => productCancel()}>Cancel</button>
                        <button className="btn btn-primary big-btn ml-2" onClick={() => productIdCreate()}>Update</button>
                    </div>
                </div>
            </>
        )
    }
    return (
        <React.Fragment>
            <div className="row mt-4">
                <div className="col-sm-6">
                    <h3 className="font-bold">{MESSAGE.PRODUCT}</h3>
                </div>
                <div className="col-sm-6 text-end">
                    <button className="btn btn-primary big-btn" onClick={() => ManageProduct(true)}>Add new product</button>
                </div>
            </div>
            <div className="mt-3">
                <nav className="nav nav-pills nav-fill inventory-nav">
                    <span className={`nav-link cursor-pointer ${pageType === 'publish' ? "active" : ""}`} aria-current="page" onClick={() => changeTab("publish")}>Publish </span>
                    <span className={`nav-link cursor-pointer ${pageType === 'request' ? "active" : ""}`} aria-current="page" onClick={() => changeTab('request')}>Request </span>
                </nav>
                <div className="container mt-4 ">
                    {productDetails?.response?.length > 0 ? <div className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0 mt-4 ">
                        <table className="table table-hover table-striped table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">SKU Id</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Sub Category</th>
                                    {
                                        (role === 1 && pageType === "request") ?
                                            <th scope="col">Process</th> : ""

                                    }
                                    <th scope="col">Status</th>
                                    <th scope="col">Details</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {productDetails?.response?.length > 0 && productDetails?.response?.map((obj: any, index: number) => {
                                    return (
                                        <tr >
                                            <th scope="row">{index + 1 + ((currentPage - 1) * 10)}</th>
                                            <td>{obj?.name}</td>
                                            <td>{obj?.sku_id}</td>
                                            <td>{obj?.category?.name}</td>
                                            <td>{obj?.sub_category?.name}</td>
                                            {
                                                (role === 1 && pageType === "request") ?
                                                    <td>
                                                        <button type="button" id="editcombooffer"
                                                            className="btn btn-sm btn-outline-dark mb-3 mb-md-0" onClick={() => updateProductId(obj)}>Publish</button>
                                                    </td> :
                                                    ""
                                            }

                                            {
                                                pageType === 'request' ?
                                                    <td className={obj?.status === "1" ? 'yellow-color' : "red-color"}>{obj?.status === "1" ? "Request" : "Rejected"}</td> :
                                                    <td><CheckBoxMolecules
                                                        checked={obj?.is_active}
                                                        onChange={(event) => onStatusChange(event, obj)}
                                                    /></td>
                                            }
                                            <td className="d-flex">
                                                <div className="ml-2">
                                                    <button type="button" id="editcombooffer"
                                                        className="btn btn-sm btn-outline-dark mb-3 mb-md-0" onClick={() => viewProductDetails(obj)}>View</button>
                                                </div>
                                            </td>

                                            <td>
                                                <button type="button" id="editcombooffer"
                                                    className="btn btn-sm btn-outline-dark mb-3 mb-md-0" onClick={() => updateProduct(obj)}>Edit</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div> :
                        <DataNotFound Error="No Product Found" />}
                </div>
                {productDetails?.length === 0 && <DataNotFound
                    Error={"No product found"}
                />}
                {popup && <PopupData image={popupImage} close={() => setPopup(false)} content={Content(popupDetails)} />}
                {
                    addPopup &&
                    <PopupData productModal={true} close={() => cancel()} content={CreateProduct()} />
                }
                {
                    deletePopup && <AlertMolecules close={() => setDeletePopup(false)} content={DeleteContent()} />
                }
                {isLoading && <PlanogramLoader />}
                {
                    productDetails?.total_count > 1 &&
                    < PaginationSession
                        onChangeHandle={onChangeHandle}
                        currentPage={currentPage}
                        sessionCount={productDetails?.total_count} />
                }
                {
                    updatePopup &&
                    <PopupData productModal={true} close={() => productCancel()} content={UpdateProductId()} />
                }
            </div>
        </React.Fragment>
    )
}
export default ProductList