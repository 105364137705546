import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { MESSAGE } from "../../../config/String"
import { Actions } from "../../../redux/action"
import { getEditCabinetData } from "../../../redux/reducer/CabinetOnboardingReducer"
import { Button } from "../../UI/Atoms/Button"
import { CabinetInput } from "../../UI/Molecules/CabinetInputs"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
export const CabinetOnboarding = () => {
    const dispatch = useDispatch();
    const [cameraCount, setCameraCount] = useState<number>(0)
    const [isDeleted, setDeleted] = useState<boolean>(false)
    const [isactive, setActive] = useState<boolean>(false)
    const [cabinetId, setCabinetId] = useState<number>()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isEdit, setEdit] = useState<boolean>(false)
    const navigate = useNavigate();
    const editData = useSelector(getEditCabinetData);
    const [cameraList, setCameraList] = useState<{ shelf_id: any, connection_string: any }[]>([{ shelf_id: '', connection_string: "" }])

    useEffect(() => {
        const url = window.location.href
        const words = url.split('/');
        setEdit(words[4] === 'edit')
        if (editData && words[4] === 'edit') {
            setCabinetId(editData?.cabinet_id)
            setActive(editData?.planogram)
            setCameraList(editData?.cameras)
        }
        else {
            setActive(false)
            setCameraList([{ shelf_id: '', connection_string: "" }])
        }
    }, [])

    //change event to get the cabinet_id arg is event
    const handleCabinetId = (e: any) => {
        if (e.target.value == -1) {
            e.target.value = 0
        }
        else {
            setCabinetId(e.target.value)
        }
    }

    //change event to get the shelf_id arg is event and index
    const handleShelfId = (e: any, i: number) => {
        if (e.target.value == -1) {
            e.target.value = 0
        }
        else {
            setCameraList((prevState: any) => {
                const customState = [...prevState]
                customState[i].shelf_id = e.target.value
                return customState
            })
        }
    }

    //change event to get the camera Id arg is event and index
    const handleCameraId = (e: any, i: number) => {
        if (e.target.value == -1) {
            e.target.value = 0
        }
        else {
            setCameraList((prevState: any) => {
                const customState = [...prevState]
                customState[i].connection_string = e.target.value
                return customState
            })
        }
    }
    // click event for change the planogram toggle input arg active(state)
    const updateStatus = (active: boolean) => {
        setActive(!active)
    }

    // click event for submit and update the cabinet based on edit(state)
    const handleSubmit = () => {
        if (isEverythingUnique(cameraList) === false) {
            toast.error("All shelf and camera id should be unique")
        }
        else if (!cabinetId) {
            toast.error("Please enter the cabinet id")
        }
        else if (isCameraInputValid(cameraList)) {
            toast.error("Please enter all shelf and camera Id")
        }
        else {
            const callback = (response: any) => {
                setLoading(false)
                if (response?.status_code === 0) {
                    toast.success("Submitted successfully")
                    navigate('/cameralist')
                }
                else {
                    toast.warn(response?.message)
                }
            }
            const updateCallback = (response: any) => {
                setLoading(false)
                if (response?.status_code === 0) {
                    toast.success("Update successfully")
                    navigate('/cameralist')
                }
                else {
                    toast.warn(response?.message)
                }
            }
            let params = {
                cabinet_id: cabinetId,
                planogram: isactive,
                cameras: cameraList
            }
            setLoading(true)
            isEdit ?
                dispatch(Actions.cabinetUpdate(params, updateCallback)) :
                dispatch(Actions.cabinetOnboarding(params, callback))

        }
    }

    //check the camera input are not empty
    const isCameraInputValid = (array: any) => {
        const isCameraInputEmpty = (currentValue: any) => (currentValue.shelf_id === '' || currentValue.connection_string === '');
        return array.some(isCameraInputEmpty)
    }

    //check the camera and connection id are unique
    const isEverythingUnique = (arr: { shelf_id: string; connection_string: string; }[]) => {
        const camera_is_unique: any = new Set(arr.map(item => item.shelf_id))
        const configureId_is_unique: any = new Set(arr.map(item => item.connection_string))
        return ([...camera_is_unique].length === arr.length && [...configureId_is_unique].length === arr.length);
    }

    //click event to add the camera input just increase the count
    const addCamera = () => {
        setCameraCount(cameraCount + 1)
        cameraCountList()
    }

    //append the object in the camera list for n number of camera input field
    const cameraCountList = () => {
        if (cameraCount >= 0) {
            cameraList.push({
                shelf_id: "",
                connection_string: ""
            })
        }
    }

    //click event to delete the camera input field
    const deleteCamera = (e: any, index: number) => {
        if (index !== 0) {
            cameraList.splice(index, 1)
            setDeleted(!isDeleted)
        }
    }

    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            {
                isEdit &&
                <div className="col offset-md-11 editBack">
                    <Button buttonClassName={"btn btn-primary"} disable={false} buttonName={MESSAGE.BACK} handleClick={() => navigate('/cameralist')} />
                </div>
            }
            <div className="card onboardingCard">
                <div className="card-body">
                    <div className="container">
                        <div className="row">
                            <p className="onboardingHeader">{MESSAGE.CABINET_ONBOARDING}</p>
                        </div>
                        <div className="row onboardingInputs">
                            <div className="col-3">
                                <CabinetInput InputType="number" disable={(isEdit && cabinetId) ? true : false} Value={cabinetId} LabelName={MESSAGE.CABINET_ID} Placeholder={MESSAGE.ENTER_CABINET_ID} handleInput={handleCabinetId} KeyDown={blockInvalidChar} />
                            </div>
                            <div className="col-2">
                                <div className="cabinetPlanogram">
                                    {MESSAGE.PLANOGRAM}
                                </div>
                                <i className={isactive ? "fa-solid fa-toggle-on fa-2xl cabinetToggle" : "fa-solid fa-toggle-off fa-2xl cabinetToggle"} onClick={() => updateStatus(isactive)} />
                            </div>
                            <div className="col-7">
                                <div className="card cameraOnboarding">
                                    <div className="card-body">
                                        <div className="row addButton">
                                            <Button buttonClassName="btn btn-primary refreshIcon" buttonName={MESSAGE.ADD} handleClick={addCamera} />
                                        </div>
                                        {
                                            cameraList && cameraList?.map((item, index) =>
                                                <div className="row mt-4" key={index}>
                                                    <div className="col">
                                                        <CabinetInput index={index} Value={item?.shelf_id} name={'cameraId'} InputType="number" InputClassName={"cameraInput"} LabelName={MESSAGE.SHELF_ID} Placeholder={MESSAGE.ENTER_SHELF_ID} handleInput={(e) => handleShelfId(e, index)} KeyDown={blockInvalidChar} />
                                                    </div>
                                                    <div className={index !== 0 ? "col" : "col-md-7"}>
                                                        <CabinetInput index={index} Value={item?.connection_string} name={'configurationId'} InputType="number" InputClassName={"cameraInput"} LabelName={MESSAGE.CAMERA_ID} Placeholder={MESSAGE.ENTER_CAMERA_ID} handleInput={(e) => handleCameraId(e, index)} KeyDown={blockInvalidChar} />
                                                    </div>
                                                    {
                                                        index !== 0 ?
                                                            <div className="col deleteCamera">
                                                                <i className="fa-solid fa-trash fa-lg" onClick={(e) => deleteCamera(e, index)}></i>
                                                            </div> : ""
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col offset-md-11 mt-4">
                                <Button buttonClassName={"btn btn-primary"} disable={false} buttonName={MESSAGE.SUBMIT} handleClick={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}