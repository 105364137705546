import { doAction, doResponseAction } from ".";

const CAMERA_LIST = 'CAMERA_LIST';
const CAMERA_LIST_SUCCESS = 'CAMERA_LIST_SUCCESS';
const CAMERA_LIST_FAILURE = 'CAMERA_LIST_FAILURE';
export {
    CAMERA_LIST,
    CAMERA_LIST_FAILURE,
    CAMERA_LIST_SUCCESS,
}

// CameraList
export const cameraList = (params?: {}, callback?: any) => doAction(CAMERA_LIST, params, callback);
export const cameraListSuccess = (data = {}) => doResponseAction(CAMERA_LIST_SUCCESS, data);
export const cameraListFailure = (data = {}) => doResponseAction(CAMERA_LIST_FAILURE, data);