

type checkboxfield = {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    classname?: any
}

export const CheckBoxMolecules = (props: checkboxfield) => {
    return (
        <>
            <div className="form-check form-switch">
                <input
                    id="disableCustomerCouponList"
                    onChange={props.onChange}
                    className={props?.classname ? props?.classname : "form-check-input cursor-pointer"}
                    type="checkbox"
                    role="switch"
                    checked={props.checked} />
            </div>
        </>
    )
}
