import { useState } from "react";
import { MESSAGE } from "../../../config/String";
import { PopupData } from "../../UI/Molecules/PopUpData";
import { GenerateId } from "./GenerateId"
import { UnregisterProductTable } from "./UnregisterProductTable"

export const UnregisterProductWidget = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [popup, setPopup] = useState(false);
    const [popupImage, setPopUpImage] = useState<string>();
    return (
        <>
            <div className="productList">
                <div className="Title">
                    {MESSAGE.UNREGISTER_PRODUCT}
                </div>
                <div>
                    <GenerateId setPageNumber={setPageNumber} pageNumber={pageNumber} setPopup={setPopup} setPopUpImage={setPopUpImage} />
                    <UnregisterProductTable pageNumber={pageNumber} setPageNumber={setPageNumber} setPopup={setPopup} setPopUpImage={setPopUpImage} />
                </div>
            </div>
            {
                popup && <PopupData image={popupImage} close={() => setPopup(false)} />
            }
        </>
    )
}