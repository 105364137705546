import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    generateTrainigId: undefined,
    error: false
}
export const generateTrainingIdReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.GENERATE_ID: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GENERATE_ID_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                generateTrainigId: action.data,
                error: false
            };
        }
        case Actions.GENERATE_ID_FAILURE: {
            return {
                ...state,
                isLoading: false,
                generateTrainigId: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const generateIdData = (state: any) => state?.GENERATE_ID?.generateTrainigId?.results;
export const generateIsLoading = (state: any) => state?.GENERATE_ID?.isLoading;