import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { sessionCabinet } from "../../../config/Endpoints"
import { Actions } from "../../../redux/action"
import { MESSAGE } from "../../../config/String"
import { Button } from "../../UI/Atoms/Button"
import { VideoClip } from "../../UI/Molecules/VideoClips"
import { SessionCabinetTable } from "./SessionCabinetTable"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { toast } from "react-toastify"
import { getEventData } from "../../../redux/reducer/SessionCabinetReducer"

export const SessionCabinet = ({ cabinetElement =[], sessionid, misplacement }: { cabinetElement: any, sessionid: string, misplacement: any }) => {    
    const [popup, setpop] = useState(false)
    const [eventId, setEventId] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const eventData = useSelector(getEventData)

    const handleClick = (event: any) => {
        setpop(true)
        setEventId(event)
        // const callback = (respones: any) => {
        //     setLoading(false)
        //     if (!respones || respones === 500) {
        //         toast.error(MESSAGE.SOMETHING_WENT_WORNG)
        //     }
        // }
        // const URL = sessionCabinet(sessionid, cabinetElement.cabinet_name)
        // if (event.target.ariaExpanded === "true") {
        //     setLoading(true)
        //     dispatch(Actions.session_cabinet(URL, callback))
        // }
    }
    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <div className="card card-body rounded-3 border-0 shadow p-2 text-center mb-3 mb-xl-0 mt-2">
                <table className="table table-hover table-striped table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Session Id</th>
                            <th scope="col">Shelf</th>
                            {/* <th scope="col">SKU Id</th> */}
                            <th scope="col">Video</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cabinetElement?.map((obj: any, index: number) => {
                            return (
                                <tr >
                                    <th scope="row">{index + 1}</th>
                                    <td>{obj?.session?.reference_id}</td>
                                    <td>{obj?.shelf?.name}</td>
                                    <td> <Button buttonName="Play"
                                        buttonClassName="btn btn-primary big-btn text-center"
                                        disable={obj?.video_url ? false : true}
                                        handleClick={() => handleClick(obj?.video_url)} /></td>
                                </tr>
                            )
                        })}
                        {cabinetElement?.length === 0 && <tr><td colSpan={4}>No data found</td></tr>}


                    </tbody>
                </table>


            </div>
            {
                popup && <VideoClip close={() => setpop(false)} eventData={eventId} />
            }
        </>
    )
}