import { doAction, doResponseAction } from ".";

const CABINET_ONBOARDING = 'CABINET_ONBOARDING';
const CABINET_ONBOARDING_SUCCESS = 'CABINET_ONBOARDING_SUCCESS';
const CABINET_ONBOARDING_FAILURE = 'CABINET_ONBOARDING_FAILURE';

const CABINET_ONBOARDING_EDIT = 'CABINET_ONBOARDING_EDIT';
const CABINET_ONBOARDING_EDIT_SUCCESS = 'CABINET_ONBOARDING_EDIT_SUCCESS';
const CABINET_ONBOARDING_EDIT_FAILURE = 'CABINET_ONBOARDING_EDIT_FAILURE';

const CABINET_DELETE = 'CABINET_DELETE';
const CABINET_DELETE_SUCCESS = 'CABINET_DELETE_SUCCESS';
const CABINET_DELETE_FAILURE = 'CABINET_DELETE_FAILURE';

const CABINET_UPDATE = 'CABINET_UPDATE';
const CABINET_UPDATE_SUCCESS = 'CABINET_UPDATE_SUCCESS';
const CABINET_UPDATE_FAILURE = 'CABINET_UPDATE_FAILURE';
export {
    CABINET_ONBOARDING,
    CABINET_ONBOARDING_FAILURE,
    CABINET_ONBOARDING_SUCCESS,

    CABINET_ONBOARDING_EDIT,
    CABINET_ONBOARDING_EDIT_FAILURE,
    CABINET_ONBOARDING_EDIT_SUCCESS,

    CABINET_DELETE,
    CABINET_DELETE_FAILURE,
    CABINET_DELETE_SUCCESS,

    CABINET_UPDATE,
    CABINET_UPDATE_FAILURE,
    CABINET_UPDATE_SUCCESS,
}

// CABINET_ONBOARDING
export const cabinetOnboarding = (params?: {}, callback?: any) => doAction(CABINET_ONBOARDING, params, callback);
export const cabinetOnboardingSuccess = (data = {}) => doResponseAction(CABINET_ONBOARDING_SUCCESS, data);
export const cabinetOnboardingFailure = (data = {}) => doResponseAction(CABINET_ONBOARDING_FAILURE, data);

// CABINET_ONBOARDING_EDIT
export const cabinetOnboardingEdit = (params?: {}, callback?: any) => doAction(CABINET_ONBOARDING_EDIT, params, callback);
export const cabinetOnboardingEditSuccess = (data = {}) => doResponseAction(CABINET_ONBOARDING_EDIT_SUCCESS, data);
export const cabinetOnboardingEditFailure = (data = {}) => doResponseAction(CABINET_ONBOARDING_EDIT_FAILURE, data);

// CABINET_DELETE
export const cabinetDelete = (params?: {}, callback?: any) => doAction(CABINET_DELETE, params, callback);
export const cabinetDeleteSuccess = (data = {}) => doResponseAction(CABINET_DELETE_SUCCESS, data);
export const cabinetDeleteFailure = (data = {}) => doResponseAction(CABINET_DELETE_FAILURE, data);

// CABINET_UPDATE
export const cabinetUpdate = (params?: {}, callback?: any) => doAction(CABINET_UPDATE, params, callback);
export const cabinetUpdateSuccess = (data = {}) => doResponseAction(CABINET_UPDATE_SUCCESS, data);
export const cabinetUpdateFailure = (data = {}) => doResponseAction(CABINET_UPDATE_FAILURE, data);