import React, { useEffect, useState } from 'react'
import { MESSAGE } from '../../../config/String';
import { validatemaxLength, validateMaxName, validateminLength, Validation } from '../../../util/Validations';
import InputField from '../../UI/Molecules/InputField'


type branchWidgetType = {
    ManageBranches?: any;
    setErrors?: any;
    errors?: any;
    CreateBranches: any
    editObj?: any
}
const BranchesWidget = (props: branchWidgetType) => {
    const fields = props.editObj?.id ? props.editObj : {}
    const [formDetails, setFormDetails] = useState<any>(fields);
    const [errors, setErrors] = useState<any>();
    const handleChange = (e: any) => {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (Object.keys(formDetails)?.length !== 0) {
            let validatedError = Validation(formDetails, ["name", "address1", "address2", "zipcode"])
            if (validatedError) {
                setErrors(validatedError)
            }
        }
    }, [formDetails])

    const OnSubmit = () => {
        if (!formDetails?.name) {
            setErrors({ ...errors, name: MESSAGE.BRANCH.PLEASE_ENTER_BRANCH_NAME })
        } else if (!validateMaxName(formDetails?.name)) {
            setErrors({ ...errors, name: MESSAGE.BRANCH.PLEASE_ENTER_VALID_BRANCH_NAME })
        } else if (!formDetails?.address1 || (!validateminLength(formDetails?.address1) || validatemaxLength(formDetails?.address1))) {
            setErrors({ ...errors, address1: MESSAGE.BRANCH.PLEASE_ENTER_VALID_ADDRESS })
        } else if (!formDetails?.address2 || (!validateminLength(formDetails?.address2) || validatemaxLength(formDetails?.address2))) {
            setErrors({ ...errors, address2: MESSAGE.BRANCH.PLEASE_ENTER_VALID_ADDRESS_2 })
        } else if (!formDetails?.zipcode || formDetails?.zipcode < 0) {
            setErrors({ ...errors, zipcode: MESSAGE.BRANCH.FIELD_CANNOT_BE_EMPTY })
        } else if (formDetails?.zipcode.length > 10) {
            setErrors({ ...errors, zipcode: MESSAGE.BRANCH.MAXIMUM_OF_9_CHARACTERS_ACCEPTED })
        } else {
            props.CreateBranches(formDetails)
        }
    }
    return (
        <React.Fragment>
            <div className="row mb-3">
                <h3>{formDetails?.id ? MESSAGE.BRANCH.UPDATE_BRANCH : MESSAGE.BRANCH.ADD_BRANCH}</h3>
            </div>
            <div className="row mt-3">
                <label>Branch name</label>
                <InputField className={"BranchField"} inputType='text' value={formDetails?.name} name='name' onChange={handleChange} placeholder="Branch name" columnSize={12} error={errors?.name} />
            </div>
            <div className="row mt-3">
                <div className="col-sm-12">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" onChange={handleChange} className="form-label">Address line 1</label>
                        <textarea className="form-control" value={formDetails?.address1} onChange={handleChange} name="address1" id="exampleFormControlTextarea1" rows={3}></textarea>
                        <p className="errorMessage">
                            {
                                errors?.address1
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" onChange={handleChange} className="form-label">Address line 2</label>
                        <textarea className="form-control" value={formDetails?.address2} onChange={handleChange} name="address2" id="exampleFormControlTextarea1" rows={3}></textarea>
                        <p className="errorMessage">
                            {
                                errors?.address2
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <label>Zip code</label>
                <InputField className={"BranchField"} inputType='number' value={formDetails?.zipcode} name="zipcode" onChange={handleChange} placeholder="Zip Code" columnSize={12} error={errors?.zipcode} />
            </div>
            <div className='row mb-2 mt-2'>
                <div className='col-sm-6'></div>
                <div className='col-sm-6 text-end mt-1'>
                    <button className="btn btn-secondary btn-outline w-30" onClick={() => props.ManageBranches(false)} >Cancel</button>
                    <button className="btn btn-primary big-btn w-30 ml-2" onClick={() => OnSubmit()} >{formDetails?.id ? 'Update' : 'Create'}</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BranchesWidget