
export const PopupData = ({ image, close, content, height, productModal, checkPlanogram }:
    { image?: string | undefined, close?: any, content?: any, height?: string, productModal?: any, checkPlanogram?: boolean }) => {
    return (
        <>
            {productModal ? <div className="modal d-block" id="loader" aria-labelledby="loader" aria-hidden="true">
                <div className={image ? "modal-dialog modal-lg modal-dialog-centered" : "modal-dialog modal-lg modal-dialog-centered"} >
                    <div className={`${checkPlanogram ? "planogramCheck" : "modal-content p-4"}`}>
                        <i className="fa fa-times fa-2x closeIcon" aria-hidden="true"
                            onClick={() => close()}></i>
                        {image && <div className="modal-body text-center imagePopup">
                            <img src={image} alt="popupImage" className="w-25 h-auto" />
                        </div>}
                        {content && <div className={`${checkPlanogram ? "" : "modal-body"}`}>
                            {content}
                        </div>}
                    </div>
                </div>
            </div> : <div className="modal d-block" id="loader" aria-labelledby="loader" aria-hidden="true">
                <div className={image ? "modal-dialog modal-dialog-centered" : !checkPlanogram ? "modal-dialog modal-dialog-centered addProductPopup" : "m-3"} >
                    <div className={`${checkPlanogram ? "planogramCheck modal-content" : "modal-content p-4"}`} style={{ width: "800px", height: "auto" }}>
                        <i className="fa fa-times fa-2x closeIcon" aria-hidden="true"
                            onClick={() => close()}></i>
                        {image && <div className="modal-body text-center imagePopup">
                            <img src={image} alt="popupImage" className="w-25 h-auto" />
                        </div>}
                        {content && <div className={`${checkPlanogram ? "" : "modal-body"}`}>
                            {content}
                        </div>}
                    </div>
                </div>
            </div>}
            <div className="modal-backdrop show loaderBackGround"></div>
        </>
    )
}
