import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Actions } from "../../../../redux/action"
import { Accordion } from "../../Widgets/Accordion"
// import { DateFilter } from "../Widgets/DateFilter"
import PlanogramLoader from "../../../UI/Molecules/PlanogramLoader";
import { toast } from "react-toastify"
import { MESSAGE } from "../../../../config/String"
import Select from "../../../UI/Molecules/Select"
import AppDatePicker from "../../../UI/Molecules/AppDatePicker"
import { getLoginData } from "../../../../redux/reducer/login"
import { getBranchDetails } from "../../../../redux/reducer/BranchesReducer"
import { getCustomerJourneyData } from "../../../../redux/reducer/CustomerJourneyReducer"
import { DataNotFound } from "../../../UI/Molecules/DataNotFound"
import { getCabinetSearchList } from "../../../../redux/reducer/SearchReducer";
import moment from "moment";
import BranchTypeAhead from "../../Widgets/BranchTypeAhead";
import { useLocation } from "react-router-dom";

export const CustomerJourney = (props?: any) => {
    const initialState = {
        startDate: "",
        endDate: "",
        branchId: "",
        cabinetId: ""
    }
    const dispatch = useDispatch()
    const customerJourneyData = useSelector(getCustomerJourneyData)
    const usersDetails = useSelector(getLoginData)
    const branchDetails = useSelector(getBranchDetails)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [filter, setFilter] = useState<any>(initialState)
    const cabinetDetails = useSelector(getCabinetSearchList)
    const [filteredCabinet, setCabinetDetails] = useState<any>([])
    const location = useLocation()
    const loginDetails = useSelector(getLoginData)
    const [options, setOptions] = useState(null || []);

    useEffect(() => {
        let startDate = null
        let endDate = null
        if (filter?.startDate) {
            startDate = moment(filter?.startDate).format('YYYY-MM-DD')
        }
        if (filter?.endDate) {
            endDate = moment(filter?.endDate).format('YYYY-MM-DD')
        }
        commonFilter(filter?.branchId, filter?.cabinetId, startDate, endDate)
    }, [currentPage])

    //customer journey dispatch and callback function 
    const GetCustomeryJourneyDetails = (params: any) => {
        const callback = (response: any) => {
            setLoading(false)
            if (!response || response === 500 || response === 404) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
        }
        setLoading(true)
        dispatch(Actions.customerJourney(params, callback))
    }

    useEffect(() => {
        let url = location?.pathname
        let word = url.split('/')
        const { user_id } = usersDetails || {}
        getCabinetList("", word[3] !== undefined ? word[3] : user_id)
        const branchparams = { vendor_id: word[3] !== undefined ? word[3] : user_id }
        dispatch(Actions.branchesList(branchparams, branchListCallback))
    }, [dispatch])


    const getCabinetList = (branch: string, vendorId: any, storeId?: any) => {
        const callback = (response: any) => {
            const filteredCabinet = response?.response?.result?.filter((o: any) => o?.branch?.id == branch)
            const convertArray = filteredCabinet?.map((k: any) => ({ value: k.id, ...k }))
            setCabinetDetails(convertArray)
        }
        let params = {
            branchId: branch,
            limit: 100,
            pagenum: 1,
            vendorId: vendorId,
            storeId: storeId
        }
        dispatch(Actions.cabinet_search(params, callback));
    }

    const { result = [] } = branchDetails || {}
    const convertBranchArray = result?.map((obj: any) => ({ value: obj.id, ...obj }))

    const handleBranchChange = (event: any) => {
        setFilter({ ...filter, 'branchId': event, 'cabinetId': '' })
        let word = url.split('/')
        const { user_id } = usersDetails || {}
        const storeObj = result?.find((o: any) => o.id == event?.value)
        if (event?.hasOwnProperty('value')) {
            getCabinetList(event?.value, word[3] !== undefined ? word[3] : user_id, storeObj?.store)
        }
        let startDate = null
        let endDate = null

        if (filter?.startDate) {
            startDate = moment(filter?.startDate).format('YYYY-MM-DD')
        }

        if (filter?.endDate) {
            endDate = moment(filter?.endDate).format('YYYY-MM-DD')
        }
        setCurrentPage(1)
        commonFilter(event, '', startDate, endDate, 1)
    }
    //dropdown function for select the cabinet
    const handleCabinetChange = (event: any) => {
        setFilter({ ...filter, 'cabinetId': event.target.value })
        let startDate = null
        let endDate = null

        if (filter?.startDate) {
            startDate = moment(filter?.startDate).format('YYYY-MM-DD')
        }

        if (filter?.endDate) {
            endDate = moment(filter?.endDate).format('YYYY-MM-DD')
        }
        setCurrentPage(1)
        commonFilter(filter?.branchId, event.target.value, startDate, endDate, 1)

    }

    const onStartDate = (date: any) => {
        let convertDate = null
        if (date) {
            convertDate = moment(date).format('YYYY-MM-DD')
        }
        let endDate = null
        if (filter?.endDate) {
            endDate = moment(filter?.endDate).format('YYYY-MM-DD')
        }
        setCurrentPage(1)
        setFilter({ ...filter, 'startDate': date })
        commonFilter(filter?.branchId, filter?.cabinetId, convertDate, endDate, 1)
    }

    const onEndDate = (date: any) => {
        let convertDate = null
        if (date) {
            convertDate = moment(date).format('YYYY-MM-DD')
        }
        setFilter({ ...filter, 'endDate': date })
        setCurrentPage(1)
        let startDate = null

        if (filter?.startDate) {
            startDate = moment(filter?.startDate).format('YYYY-MM-DD')
        }

        commonFilter(filter?.branchId, filter?.cabinetId, startDate, convertDate, 1)
    }

    const commonFilter = (branchId: any, cabinetId: any, startDate: any, endDate: any, page?: any) => {
        const { user_id, role } = usersDetails || {}
        let url = location?.pathname
        let word = url.split('/')
        const params: any = {
            vendorId: role == 1 ? word[3] : user_id,
            page: page ? page : currentPage,
            limit: 10,
        }
        if (branchId) {
            params['branchId'] = branchId?.value
        }
        if (cabinetId) {
            params['cabinetId'] = cabinetId
        }
        if (startDate) {
            params['startDate'] = startDate
        }
        if (endDate) {
            params['endDate'] = endDate
        }
        GetCustomeryJourneyDetails(params)

    }

    const branchListCallback = (res: any) => {
        let data = [];
        if (res?.response) {
            data = res?.response?.result?.map((o: any) =>
            (
                {
                    label: `${o?.name}`, value: o?.id,
                }
            )
            );
        }
        setOptions(data)
    }

    const { role, user_id } = loginDetails
    let url = location?.pathname
    let word = url.split('/')
    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <div className="row mt-4">
                <div className="col-sm-6 ">
                    <h3 className="font-bold">Customer Journey</h3>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-sm-3">
                    <AppDatePicker
                        selected={filter?.startDate}
                        placeholder="From"
                        maxDate={new Date()}
                        onChange={(date: any) => onStartDate(date)}
                        notValidTime={true}
                        id='FormDatePicker'
                    />
                </div>
                <div className=" col-sm-3">
                    <AppDatePicker
                        selected={filter?.endDate}
                        placeholder="To"
                        maxDate={new Date()}
                        onChange={(date: any) => onEndDate(date)}
                        notValidTime={true}
                        minDate={filter?.startDate}
                        id='ToDatePicker'
                    />
                </div>
                <div className="col-sm-3">
                    <BranchTypeAhead
                        options={props?.options ? props?.options : options}
                        setOptions={setOptions}
                        vendorId={role == 1 ? word[3] : user_id}
                        placeholder={"All Branches"}
                        onChange={(event: any) => handleBranchChange(event)}
                    />
                </div>
                <div className="col-sm-3">
                    <Select
                        placeHolder={"All Cabinets"}
                        array={filteredCabinet}
                        isList={true}
                        disabled={filteredCabinet?.length === 0}
                        value={filter?.cabinetId}
                        onChange={(event: any) => handleCabinetChange(event)}
                    />
                </div>
            </div>

            {(!customerJourneyData || customerJourneyData?.length === 0) && <DataNotFound Error={"No customer journey found"} />}
            <Accordion currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
    )
}