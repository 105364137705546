import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    user: undefined,
    error: false
}
export const loginReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.LOGIN_USER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.LOGIN_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                user: action.data,
                error: false
            };
        }
        case Actions.LOGIN_USER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                user: undefined,
                error: action.error,
            };
        }
        case Actions.LOGOUT: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.LOGOUT_SUCCESS: {
            localStorage.clear()
            return {
                ...initialState,
            };
        }
        case Actions.LOGOUT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getLoginData = (state: any) => state?.USER?.user;
export const getToken = (state: any) => state?.USER?.user?.token;
