import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { Actions } from "../../../redux/action"
import { Button } from "../../UI/Atoms/Button"
import { toast } from "react-toastify"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { MESSAGE } from "../../../config/String"
import InputField from "../../UI/Molecules/InputField"
import Select from "../../UI/Molecules/Select"
import { getLoginData } from "../../../redux/reducer/login"
import { getBranchDetails } from "../../../redux/reducer/BranchesReducer"
import { getCabinetSearchList } from "../../../redux/reducer/SearchReducer"
import { useSelector } from "react-redux"
import { cabinetSearchListEndPoint } from "../../../config/Endpoints"
import { isTestingForMobile } from "../../../config/Constant"
import BranchTypeAhead from "./BranchTypeAhead"
import { getEdgeDetails } from "../../../redux/reducer/EdgeServerReducer"
import { useLocation } from "react-router-dom"
import { vendorListDetails } from "../../../redux/reducer/VendorReducer"
export const TestingStartWidgets = ({ time, settime, setEnd, disable, setDisable }: { setEnd: React.Dispatch<React.SetStateAction<{ session_id: string; cabinet_id: string; }>>, time: number | null, settime: React.Dispatch<React.SetStateAction<number | null>>, disable: boolean, setDisable: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const ref = useRef<HTMLInputElement>(null);
    const [start, setStart] = useState({ session_id: '', cabinets_selected: '' })
    const [isLoading, setLoading] = useState<boolean>(false)
    const usersDetails = useSelector(getLoginData)
    const edageData = useSelector(getEdgeDetails)
    const branchDetails = useSelector(getBranchDetails)
    const cabinetDetails = useSelector(getCabinetSearchList)
    const [filteredCabinet, setCabinetDetails] = useState<any>([])
    const [branchId, setBranchId] = useState<any>()
    const [startSession, setStartSession] = useState<any>(false)
    const dispatch = useDispatch()
    const [options, setOptions] = useState(null || []);
    const loginDetails = useSelector(getLoginData)
    const location = useLocation()
    const vendorList = useSelector(vendorListDetails)
    const [vendorId, setVendorId] = useState('')
    const getdate = () => {
        const d = new Date();
        let times = d.getTime();
        setStart({ ...start, session_id: times.toString() })
    }
    const clearData = () => {
        settime(null)
        setStart({ ...start, ...{ "cabinets_selected": '' } })
        setEnd((pre) => ({ ...pre, "cabinet_id": '' }))
    }

    //Cabinet List API
    useEffect(() => {
        if(role===1){
            const callback = (response: any) => {
                setLoading(false)
                if (response?.status_code !== 0) {
                    toast.error(MESSAGE.SOMETHING_WENT_WORNG)
                }
            }
            setLoading(true)
            dispatch(Actions.vendorOnboardingList("", callback))
        }
    }, [dispatch])

    const branchListCallback = (res: any) => {
        let data = [];
        if (res?.response) {
            const fileredValue=res?.response?.result?.filter((obj:any)=>obj?.is_active === true)
            data = fileredValue?.map((o: any) =>
            (
                {
                    label: `${o?.name}`, value: o?.id,
                }
            )
            );
        }
        setOptions(data)
    }

    const getCabinetList = (branchId:any,vendorId:any,storeId:any) => {
        // const cabinetSearchURL = cabinetSearchListEndPoint("", 1000, 1)
        const callback = (response: any) => {
            const filteredCabinet = response?.response?.result?.filter((o: any) => o?.branch?.id == branchId)
            const convertArray = filteredCabinet?.map((k: any) => ({ value: k.id, ...k }))
            setCabinetDetails(convertArray)
        }
        let params = {
            branchId: branchId,
            limit: 100,
            pagenum: 1,
            vendorId: vendorId,
            storeId: storeId
        }
        dispatch(Actions.cabinet_search(params,callback));
    }

    const handleStart = () => {
        const callback = (respones: any) => {
            setLoading(false)
            if (respones === 500 || respones == 404) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
            else if (respones?.message === "success") {
                setDisable(true)
                setStartSession(true)
                toast.success("The session is started")
            }
            else {
                setDisable(false)
                toast.warn(respones?.message)
            }
        }
        const params = {
            session_id: start?.session_id,
            cabinet_id: start.cabinets_selected,
            cabinets_selected: start.cabinets_selected,
            branch_id:branchId?.value,
            vendor_id:vendorId
        }
        setLoading(true)
        dispatch(Actions.session(params, callback))
    }

    const handleBranchChange = (obj: any) => {
        setBranchId(obj)
        const storeObj = result?.find((o: any) => o.id == obj?.value)
        if (obj?.hasOwnProperty('value')) {
            getCabinetList(obj?.value, vendorId, storeObj?.store)
        }else{
            setStart({ ...start, ...{ "cabinets_selected": '' } })
        }
    }


    const handleEnd = () => {
        const macAddressss = cabinetDetails?.result?.find((obj: any) => obj.id == parseInt(start.cabinets_selected))?.mac_address
        const endCallback = (respones: any) => {
            setLoading(false)
            if (respones === 500 || respones == 404) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
            else if (respones?.message === "success") {
                setDisable(false)
                toast.success("The session is Ended")
            }
            else {
                setDisable(true)
                toast.warn(respones?.message)
            }
        }
        const endparams = {
            session_id: start?.session_id,
            cabinets_selected: start.cabinets_selected,
            locker_mac_address: macAddressss
        }
        setLoading(true)
        dispatch(Actions.sessionEnd(endparams, endCallback))
    }

    const handleComplete = () => {
        const completeCallback = (respones: any) => {
            setLoading(false)
        }
        const completeparams = {
            session_id: start?.session_id,
            cabinets_selected: start.cabinets_selected,
        }
        setLoading(true)
        dispatch(Actions.sessionComplete(completeparams, completeCallback))
    }

    const handleVendorChange = (event: any) => {
        setVendorId(event.target.value)
        const params = { vendor_id: event.target.value }
        dispatch(Actions.branchesList(params, branchListCallback))
    }

    const { result = [] } = branchDetails || {}

    const convertArray = result?.map((obj: any) => ({ value: obj.id, ...obj }))
    const { role, user_id } = loginDetails
    const convertVendorArray = vendorList?.vendor_details?.map((obj: any) => ({ value: obj.vendor_id, name: obj.first_name, ...obj }))
    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <div className="card card-body">
                <h4 className="font-bold">Session</h4>
                <div className="row">
                    {
                        role === 1 &&
                        <div className="col-sm-3 col-lg-4">
                            <Select
                                placeHolder={"All Vendor"}
                                isList={true}
                                array={convertVendorArray}
                                onChange={handleVendorChange}
                            />
                        </div>
                    }
                    <div className={role == 1 ? "col-sm-4" : "col-sm-6"}>
                        <BranchTypeAhead
                            isLoading={false}
                            placeholder={"Choose Branch"}
                            options={options}
                            setOptions={setOptions}
                            vendorId={role == 1 ? vendorId : user_id}
                            onChange={(event: any) => handleBranchChange(event)}

                        />
                    </div>
                    <div className={role == 1 ? "col-sm-4" : "col-sm-6"}>
                        <Select
                            placeHolder={"Choose Cabinet"}
                            array={filteredCabinet}
                            disabled={!branchId?.hasOwnProperty('value') || filteredCabinet?.length === 0}
                            value={start?.cabinets_selected}
                            onChange={(event: any) => setStart({ ...start, ...{ "cabinets_selected": event.target.value } })}
                        />
                    </div>

                </div>

                <div className="row mt-2">
                    <InputField
                        inputType={"text"} name="startSession"
                        label="Session Id"
                        placeholder="Start Session" columnSize={6}
                        value={start?.session_id}
                        onChange={(e) => setStart({ ...start, ...{ "session_id": e.target.value } })} />
                    <div className="col mt-3">
                        <Button buttonName="Get Session Id"
                            buttonClassName="btn btn-primary big-btn"
                            handleClick={getdate} />
                    </div>
                </div>

                <div className="mt-5 text-end">
                    <div className="col mb-4">
                        <Button buttonName="Start"
                            // disable={(start?.cabinets_selected && start.session_id) ? false : true}
                            buttonClassName="btn btn-success big-btn ml-2 startButton"
                            handleClick={handleStart} />
                    </div>
                    <div>
                        <Button buttonName="Stop"
                            // disable={!startSession}
                            buttonClassName="btn  btn-success big-btn ml-2 w-25"
                            handleClick={handleEnd} />

                        {!isTestingForMobile && <Button buttonName="Complete"
                            // disable={(start?.cabinets_selected && start.session_id) ? false : true}
                            buttonClassName="btn btn-success big-btn ml-2 w-25"
                            handleClick={handleComplete} />}
                    </div>
                </div>
            </div>
        </>
    )
}