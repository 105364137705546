import { doAction, doResponseAction } from ".";

const EDGE_SERVER_LIST = 'EDGE_SERVER_LIST';
const EDGE_SERVER_LIST_SUCCESS = 'EDGE_SERVER_LIST_SUCCESS';
const EDGE_SERVER_LIST_FAILURE = 'EDGE_SERVER_LIST_FAILURE';

const EDGE_SERVER_CREATE = 'EDGE_SERVER_CREATE';
const EDGE_SERVER_CREATE_SUCCESS = 'EDGE_SERVER_CREATE_SUCCESS';
const EDGE_SERVER_CREATE_FAILURE = 'EDGE_SERVER_CREATE_FAILURE';

const EDGE_SERVER_UPDATE = 'EDGE_SERVER_UPDATE';
const EDGE_SERVER_UPDATE_SUCCESS = 'EDGE_SERVER_UPDATE_SUCCESS';
const EDGE_SERVER_UPDATE_FAILURE = 'EDGE_SERVER_UPDATE_FAILURE';

const VENDOR_EDGE_SERVER_LIST = 'VENDOR_EDGE_SERVER_LIST';
const VENDOR_EDGE_SERVER_LIST_SUCCESS = 'VENDOR_EDGE_SERVER_LIST_SUCCESS';
const VENDOR_EDGE_SERVER_LIST_FAILURE = 'VENDOR_EDGE_SERVER_LIST_FAILURE';

const EDGE_INITIAL_STATE ="EDGE_INITIAL_STATE"

export {
    EDGE_SERVER_LIST,
    EDGE_SERVER_LIST_FAILURE,
    EDGE_SERVER_LIST_SUCCESS,

    EDGE_SERVER_CREATE,
    EDGE_SERVER_CREATE_SUCCESS,
    EDGE_SERVER_CREATE_FAILURE,

    EDGE_SERVER_UPDATE,
    EDGE_SERVER_UPDATE_SUCCESS,
    EDGE_SERVER_UPDATE_FAILURE,

    VENDOR_EDGE_SERVER_LIST,
    VENDOR_EDGE_SERVER_LIST_SUCCESS,
    VENDOR_EDGE_SERVER_LIST_FAILURE,
    EDGE_INITIAL_STATE
}

export const edgeServerList = <T>(params: {}, callback: T) => doAction(EDGE_SERVER_LIST, params, callback);
export const edgeServerListSuccess = (data: {}) => doResponseAction(EDGE_SERVER_LIST_SUCCESS, data);
export const edgeServerListFailure = (data: {}) => doResponseAction(EDGE_SERVER_LIST_FAILURE, data);

export const edgeServerCreate = <T>(params: {}, callback: T) => doAction(EDGE_SERVER_CREATE, params, callback);
export const edgeServerCreateSuccess = (data: {}) => doResponseAction(EDGE_SERVER_CREATE_SUCCESS, data);
export const edgeServerCreateFailure = (data: {}) => doResponseAction(EDGE_SERVER_CREATE_FAILURE, data);

export const edgeServerUpdate = <T>(params: {}, callback: T) => doAction(EDGE_SERVER_UPDATE, params, callback);
export const edgeServerUpdateSuccess = (data: {}) => doResponseAction(EDGE_SERVER_UPDATE_SUCCESS, data);
export const edgeServerUpdateFailure = (data: {}) => doResponseAction(EDGE_SERVER_UPDATE_FAILURE, data);

export const vendorEdgeServerList = <T>(params: {}, callback: T) => doAction(VENDOR_EDGE_SERVER_LIST, params, callback);
export const vendorEdgeServerListSuccess = (data: {}) => doResponseAction(VENDOR_EDGE_SERVER_LIST_SUCCESS, data);
export const vendorEdgeServerListFailure = (data: {}) => doResponseAction(VENDOR_EDGE_SERVER_LIST_FAILURE, data);

export const initalEdgeState = <T>(params: {}, callback: T) => doAction(EDGE_INITIAL_STATE, params, callback);
