import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MESSAGE } from "../../../config/String";
import { Actions } from "../../../redux/action";
import { getBranchDetails } from "../../../redux/reducer/BranchesReducer";
import { getHealthCheck, getHealthCheckLoader } from "../../../redux/reducer/HealthCheckReducer";
import { getLoginData } from "../../../redux/reducer/login";
import { vendorListDetails } from "../../../redux/reducer/VendorReducer";
import { DataNotFound } from "../../UI/Molecules/DataNotFound";
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader";
import { PopupData } from "../../UI/Molecules/PopUpData";
import Select from "../../UI/Molecules/Select";
import BranchTypeAhead from "../Widgets/BranchTypeAhead";

export const HealthCheck = () => {
    const dispatch = useDispatch()
    const usersDetails = useSelector(getLoginData)
    const branchDetails = useSelector(getBranchDetails)
    const healthCheckDetails = useSelector(getHealthCheck)
    const healthCheckLoader = useSelector(getHealthCheckLoader)
    const [branchId, setBranchId] = useState<any>(null)
    const [vendorId, setVendorId] = useState<any>('')
    const [offlineArr, setOfflineArray] = useState<any>([])
    const [healthpopUP, setHealthpopUp] = useState<boolean>(false)
    const [offlineCamArr, setOfflineCam] = useState<any>([])
    const [campopUp, setCampopup] = useState<boolean>(false)
    const [options, setOptions] = useState(null || []);
    const loginDetails = useSelector(getLoginData)
    const vendorList = useSelector(vendorListDetails)
    const [isLoading, setLoading] = useState(false)

    const renderCabinetStatus = (obj: any) => {
        return (
            <div>
                <h3 className="text-center">Cabinets</h3>
                <div className="grid-container2">
                    <div id='Requested'>
                        <div className="card card-body border-2">
                            <h3 className="font40 fw-bold">{healthCheckDetails?.contents?.active_cabinet_count}</h3>
                            <h4 className="text-success">Active</h4>
                        </div>
                    </div>
                    <div id='Requested' onClick={() => onShowOfflineCabinets(healthCheckDetails?.contents?.cabinet_list?.cabinet_data)}>
                        <div className="card card-body border-2 ">
                            <h3 className="font40 fw-bold">{healthCheckDetails?.contents?.inactive_cabinet_count}</h3>
                            <h4 className="text-danger">Inactive</h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderCameraStatus = (obj: any, cameraObj: any) => {

        return (
            <div>
                <h3 className="text-center">Cameras</h3>
                <div className="grid-container2">
                    <div id='Requested' >
                        <div className="card card-body border-2">
                            <h3 className="font40 fw-bold">{healthCheckDetails?.contents?.active_camera_count}</h3>
                            <h4 className="text-success">Active</h4>
                        </div>
                    </div>
                    <div id='Requested' className="cursor-pointer" onClick={() => onShowOfflineCameras(obj?.cabinet_data)}>
                        <div className="card card-body border-2">
                            <h3 className="font40 fw-bold">{healthCheckDetails?.contents?.inactive_camera_count}</h3>
                            <h4 className="text-danger">Inactive</h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const onShowOfflineCabinets = (obj: any) => {
        setOfflineArray(obj)
        setHealthpopUp(true)

    }

    const onShowOfflineCameras = (obj: any) => {
        setOfflineCam(obj)
        setCampopup(true)
    }


    useEffect(() => {
        const callback = (response: any) => {
            setLoading(false)
            if (response?.status_code !== 0) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
        }
        if (role == 1) {
            setLoading(true)
            dispatch(Actions.vendorOnboardingList("", callback))
        }

        getHealthCheckData()
    }, [dispatch])

    const callback = (response: any) => {
        if (response?.status_code === 1) {
            toast.error(response?.message)
        }
    }
    const getHealthCheckData = () => {
        let params = {}
        if (branchId?.value) {
            params = { branch_id: branchId?.value }
            dispatch(Actions.healthCheckList(params, callback))
        } else {
            dispatch(Actions.removeCheckList())
        }
    }

    const handleBranchChange = (obj: any) => {
        setBranchId(obj)
        let params = {}
        if (obj?.value) {
            params = { branch_id: obj?.value, vendor_id: vendorId }
            dispatch(Actions.healthCheckList(params, callback))
        } else {
            dispatch(Actions.removeCheckList())
        }
    }

    const renderHealthContent = (flag: boolean) => {
        return (
            <React.Fragment>
                <div className="card card-body rounded-3 border-0 shadow p-2 mb-3 mb-xl-0 mt-2">
                    <table className="table table-hover table-striped table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Cabinet</th>
                                {flag && <th scope="col">Shelf</th>}
                                {flag && <th scope="col">Camera</th>}
                                {flag && <th scope="col">Status</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {offlineCamArr?.map((obj: any, index: number) => {
                                return obj?.shelf_list?.length > 0 && obj?.shelf_list?.map((subObj: any, subindex: number) => {
                                    return (
                                        <tr >
                                            <th scope="row">{subindex + 1}</th>
                                            <td>{obj?.cabinet_name}</td>
                                            {flag && <td>{subObj?.shelf_name}</td>}
                                            {flag && <td>{subObj?.connection_string}</td>}
                                            {flag && <td className={`red-color`}>Inactive</td>}
                                        </tr>
                                    )
                                })

                            })}
                            {offlineCamArr?.length === 0 && <tr className="text-center"><td colSpan={flag ? 5 : 3}>{`No data found`}</td></tr>}
                        </tbody>
                    </table>


                </div>

            </React.Fragment>
        )
    }
    const branchListCallback = (res: any) => {
        let data = [];
        if (res?.response) {
            data = res?.response?.result?.map((o: any) =>
            (
                {
                    label: `${o?.name}`, value: o?.id,
                }
            )
            );
        }
        setOptions(data)
    }

    const handleVendorChange = (event: any) => {
        setVendorId(event.target.value)
        const params = { vendor_id: event.target.value }
        dispatch(Actions.branchesList(params, branchListCallback))
    }

    const { result = [] } = branchDetails || {}
    const { cameras = [], contents } = healthCheckDetails || {}
    const convertArray = result?.map((obj: any) => ({ value: obj.id, ...obj }))
    const { role, user_id } = loginDetails
    const convertVendorArray = vendorList?.vendor_details?.map((obj: any) => ({ value: obj.vendor_id, name: obj.first_name, ...obj }))

    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <div className="row mt-3">
                <div className="col-sm-6">
                    <h3 className="font-bold">Health Check </h3>
                </div>
                <div className="col-sm-6 text-end">
                    <span className="cursor-pointer" onClick={() => getHealthCheckData()}><i className="fa fa-refresh ml-2" ></i> Refresh</span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-4 "></div>
                <div className="col-4">
                    {
                        role === 1 &&
                        <Select
                            placeHolder={"All Vendor"}
                            isList={true}
                            array={convertVendorArray}
                            onChange={handleVendorChange}
                        />
                    }
                </div>
                <div className="col-4">
                    <BranchTypeAhead
                        options={options}
                        setOptions={setOptions}
                        placeholder={"All Branches"}
                        vendorId={role == 1 ? vendorId : user_id}
                        onChange={(event: any) => handleBranchChange(event)}
                    />
                </div>
            </div>
            {contents?.cabinet_list ? <div className="grid-container1 mt-3 healthCheckContainer">
                {renderCabinetStatus(cameras)}
                {renderCameraStatus(contents?.cabinet_list, cameras)}
            </div> : <DataNotFound Error={"Cabinet not found"} />
            }
            {healthCheckLoader && <PlanogramLoader />}
            {/* {healthpopUP && <PopupData
                close={() => setHealthpopUp(false)}
                content={renderHealthContent(false)}
            />} */}

            {campopUp && <PopupData
                close={() => setCampopup(false)}
                content={renderHealthContent(true)}
            />}
        </>
    )
}