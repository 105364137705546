import { MESSAGE } from '../../../config/String'
import { MisplacementTable } from './MisplacementTable'
const Header = [
    MESSAGE.S_NO,
    MESSAGE.SHELF_ID,
    MESSAGE.MISPLACED_ITMES,
]

export const MisplacedItem = ({ misplacementItems, close }: { misplacementItems: any, close: (a: boolean) => void }) => {
    return (
        <>
            <div className="scroll">
                <div className="modal d-block" id="loader" tabIndex={-1} aria-labelledby="loader" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content cartScrollContainer p-4" style={{ width: "900px", height: "500px" }}>
                            <i className="fa fa-times fa-2x closeIcon" aria-hidden="true" onClick={() => close(false)} ></i>
                            <div className="modal-body text-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className='row'>
                                        <div className="card">
                                            <MisplacementTable mainHeader={MESSAGE.MISPLACEMENT} tableHeader={Header} tableValue={misplacementItems} notFound={MESSAGE.NO_DATA_FOUND} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show loaderBackGround"></div>
            </div>

        </>
    )
}