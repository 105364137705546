import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    masterSubscriptionList: [],
    masterSubscriptionCreate: {},
    masterSubscriptionUpdate: {},
    masterSubscriptionStatusUpdate: {},
    count: '',
    error: false
}
export const masterSubscriptionReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.MASTER_SUBSCRIPTION_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MASTER_SUBSCRIPTION_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                masterSubscriptionList: action?.data?.response,
                count: action?.data?.total_count,
                error: false
            };
        }
        case Actions.MASTER_SUBSCRIPTION_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                masterSubscriptionList: [],
                error: action.data.error,
            };
        }

        case Actions.MASTER_SUBSCRIPTION_CREATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MASTER_SUBSCRIPTION_CREATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                masterSubscriptionCreate: action?.data,
                error: false
            };
        }
        case Actions.MASTER_SUBSCRIPTION_CREATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                masterSubscriptionCreate: {},
                error: action.data.error,
            };
        }
        case Actions.MASTER_SUBSCRIPTION_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MASTER_SUBSCRIPTION_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                masterSubscriptionUpdate: action?.data,
                error: false
            };
        }
        case Actions.MASTER_SUBSCRIPTION_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                masterSubscriptionUpdate: {},
                error: action.data.error,
            };
        }
        case Actions.MASTER_SUBSCRIPTION_STATUS_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MASTER_SUBSCRIPTION_STATUS_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                masterSubscriptionStatusUpdate: action?.data,
                error: false
            };
        }
        case Actions.MASTER_SUBSCRIPTION_STATUS_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                masterSubscriptionStatusUpdate: {},
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};
export const getMasterSubscriptionList = (state: any) => state?.MASTER_SUBSCRIPTION_LIST_REDUCER?.masterSubscriptionList;
export const getMasterSubscriptionListCount = (state: any) => state?.MASTER_SUBSCRIPTION_LIST_REDUCER?.count