import { combineReducers } from 'redux';
import { loginReducer } from './login';
import { cameraListReducer } from './CameraListReducer'
import { customerJourneyReducer } from './CustomerJourneyReducer'
import { sessionCabinetReducer } from './SessionCabinetReducer'
import { searchListReducer } from './SearchReducer'
import { productListReducer } from './ProductListReducer'
import { unregisterReducer } from './UnregisterProductReducer'
import { generateTrainingIdReducer } from './GenerateTrainingIdReducer'
import { registerReducer } from './RegisterReducer'
import { getTrainingIdReducer } from './TrainingIdReducer'
import { getPlanogramReducer } from './PlanogramReducer'
import { getCameraIdReducer } from './GetCameraIdReducre'
import { historyReducer } from './HistoryReducer'
import { updatePlanogramReducer } from './UpdatePlanogramReducer'
import { autoFillReducer } from './AutoFillReducer'
import { session_start_Reducer } from './SessionStartReducer'
import { session_end_Reducer } from './SessionEndReducer'
import { cabinetListReducer } from './CabinetListReducer'
import { planogramStatusReducer } from './PlanogramStatusReducer'
import { cabinetOnboardingReducer } from './CabinetOnboardingReducer';
import { InventoryManagementReducer } from './InventoryManagementReducer';
import { ReportReducer } from './ReportReducer';
import { VendorReducer } from './VendorReducer';
import { BranchesReducer } from './BranchesReducer';
import { DashboardReducer } from './DashboardReducer';
import { TroubleShootReducer } from './TroubleShootReducer';
import { HealthCheckReducer } from './HealthCheckReducer';
import { CategoryReducer } from './CategoryReducer';
import { EdgeServerReducer } from './EdgeServerReducer';
import{subscriptionReducer} from './SubscriptionReducer';
import{masterSubscriptionReducer} from './MasterSubscriptionReducer';
export const reducers = combineReducers({
  USER: loginReducer,
  CAMERALIST: cameraListReducer,
  CUSTOMER_JOURNEY: customerJourneyReducer,
  SESSION_CABINET: sessionCabinetReducer,
  SEARCH_LIST: searchListReducer,
  PRODUCT_LIST_DATA: productListReducer,
  UNREGISTER_PRODICT: unregisterReducer,
  GENERATE_ID: generateTrainingIdReducer,
  REGISTER_PRODUCT: registerReducer,
  GET_TRAINING_ID: getTrainingIdReducer,
  GET_PLANOGRAM_DATA: getPlanogramReducer,
  GET_CAMERA_ID: getCameraIdReducer,
  HISTORY_REDUCER: historyReducer,
  UPDATE_PLANOGRAM: updatePlanogramReducer,
  AUTO_FILL_REDUCER: autoFillReducer,
  SESSION_START: session_start_Reducer,
  SESSION_END: session_end_Reducer,
  CABINET_LIST_REDUCER: cabinetListReducer,
  PLANOGRAM_STATUS_REDUCER: planogramStatusReducer,
  CABINET_ONBOARDING_REDUCER: cabinetOnboardingReducer,
  INVENTORY_MANAGEMENT: InventoryManagementReducer,
  REPORT_LIST: ReportReducer,
  VENDOR: VendorReducer,
  BRANCHES: BranchesReducer,
  DASHBOARD: DashboardReducer,
  TROUBLE_SHOOT: TroubleShootReducer,
  HEALTH_CHECK: HealthCheckReducer,
  CATEGORY: CategoryReducer,
  EDGE_SERVER: EdgeServerReducer,
  SUBSCRIPTION_LIST_REDUCER:subscriptionReducer,
  MASTER_SUBSCRIPTION_LIST_REDUCER:masterSubscriptionReducer,
});