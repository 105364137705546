import { MESSAGE } from "../../../config/String"
import { Input } from "../../UI/Atoms/Input"
import { RegisterMolecules } from "../../UI/Molecules/RegisterMolecules"
import logo from '../../../assets/img/planogram.jpg'

export const UpdateInventory = () => {
    const handlePopImage = (img: any) => {
    }
    return (
        <>
            <div className="productList">
                <div className="Title">
                    {MESSAGE.UPDATE_INVENTORY}
                </div>
                <div>
                    <div className="inventoryUpdateContainer">
                        <form id="form" encType="multipart/form-data">
                            <div className="container">
                                <div className="row g-0">
                                    <div className="col-sm registerColumn">
                                        <RegisterMolecules label={MESSAGE.PRODUCT_NAME} id="ProductName" placeholder={MESSAGE.ENTER_PRODUCT_NAME} handlechange={(e) => ''} />
                                        <RegisterMolecules label={MESSAGE.SKU} id="TrainingId" placeholder={MESSAGE.ENTER_TRAINING_ID} handlechange={(e) => ''} />
                                    </div>
                                    <div className="col-sm registerColumn">
                                        <RegisterMolecules label={MESSAGE.MACHINE_ID} id="SKUId" placeholder={MESSAGE.ENTER_SKU_ID} handlechange={(e) => ''} />
                                        <RegisterMolecules label={MESSAGE.QUANTITY} id="Height" placeholder={MESSAGE.ENTER_HEIGHT_IN_MM} handlechange={(e) => ''} />
                                    </div>
                                    <div className="col-sm registerImageColumn">
                                        <label className="registerLabel">{MESSAGE.IMAGE}</label>
                                        <span onClick={() => handlePopImage(logo)}>
                                            <img src={logo} alt="img" style={{ width: "300px" }} className='thumbnailImage' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="Register_Button_div">
                                <Input inputType="submit" inputClassName="btn btn-primary registerButton" value={MESSAGE.UPDATE} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}