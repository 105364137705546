import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { CABINET_LIST } from '../action/CabinetList'
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
export function* cabinetListSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(params, '', '')
        yield put(Actions.cabinetListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.cabinetListFailure({ error }));
        callback && callback({ error })
    }
}

export default function* cabinetListWatcher() {

    yield all([
        takeLatest(CABINET_LIST, cabinetListSaga),
    ]);
}
