import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { LOGIN_USER } from '../action/login';
import { doPost, login } from '../../config/index';
import END_POINTS from '../../config/Endpoints';
import { ResponseGenerator } from './SagaInterface';
export function* loginUser(param: any) {
  const { params, callback } = param
  try {
    const response: ResponseGenerator = yield login(END_POINTS.LOGIN, params)
    if (callback) {
      callback(response)
      yield put(Actions.loginUserSuccess(response));
    }
  } catch (error) {

    if (callback) {
      callback({ error })
      yield put(Actions.loginUserFailure({ error }));
    }
  }
}
export function* logoutUser(param: any) {
  const { callback } = param
  try {
    let response: ResponseGenerator = yield doPost(END_POINTS.LOG_OUT, '', '')
    if (callback) {
      callback(response)
    }
    yield put(Actions.logoutSuccessAction(response));
  }
  catch (error) {
    if (callback) {
      callback(error)
    }
    yield put(Actions.logoutFailureAction({ error }));

  }
}
export default function* loginWatcher() {
  yield all([
    takeLatest(LOGIN_USER, loginUser),
    takeLatest(Actions.LOGOUT, logoutUser)
  ]);
}
