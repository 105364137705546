import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    Planogram: { planogram_details: [] },
    createPlanogram: {},
    captureShelf: {},
    trainedProduct: [],
    frames: [],
    activatePlanogram: {},
    error: false
}
export const getPlanogramReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.GET_PLANOGRAM: {
            return {
                ...state,
                isLoading: true,
                Planogram: []
            };
        }
        case Actions.GET_PLANOGRAM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                Planogram: action.data,
                error: false
            };
        }
        case Actions.GET_PLANOGRAM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                Planogram: undefined,
                error: action.error,
            };
        }
        case Actions.SET_ANNOTATION_PLANOGRAM: {
            let { Planogram } = state
            return {
                ...state,
                Planogram: {
                    ...Planogram as object | undefined,
                    planogram_details: action.data
                },
                error: false
            };
        }
        case Actions.REMOVE_ANNOTATION_PLANOGRAM: {
            let { Planogram } = state
            return {
                ...state,
                Planogram: {
                    ...Planogram as object | undefined,
                    planogram_details: []
                },
                error: false
            };
        }
        //create Planogram
        case Actions.CREARE_PLANOGRAM: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CREATE_PLANOGRAM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                createPlanogram: action.data,
                error: false
            };
        }
        case Actions.CREATE_PLANOGRAM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                createPlanogram: undefined,
                error: action.error,
            };
        }
        //activate Planogram
        case Actions.ACTIVE_PLANOGRAM: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ACTIVE_PLANOGRAM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                activatePlanogram: action.data,
                error: false
            };
        }
        case Actions.ACTIVE_PLANOGRAM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                activatePlanogram: undefined,
                error: action.error,
            };
        }
        //capture shelf
        case Actions.CAPTURE_SHELF: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CAPTURE_SHELF_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                captureShelf: action.data,
                error: false
            };
        }
        case Actions.CAPTURE_SHELF_FAILURE: {
            return {
                ...state,
                isLoading: false,
                captureShelf: {},
                error: action.error,
            };
        }
        case Actions.FETCH_FRAME: {
            return {
                ...state,
                isLoading: false,
                frames: action.data,
                error: false
            };
        }
        //trained product
        case Actions.TRAINED_PRODUCT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.TRAINED_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                trainedProduct: action.data,
                error: false
            };
        }
        case Actions.TRAINED_PRODUCT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                trainedProduct: {},
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getPlanogramData = (state: any) => state?.GET_PLANOGRAM_DATA?.Planogram;
export const getPlanogramLoader = (state: any) => state?.GET_PLANOGRAM_DATA?.isLoading;
export const getCreatePlanogram = (state: any) => state?.GET_PLANOGRAM_DATA?.createPlanogram
export const getcaptureShelf = (state: any) => state?.GET_PLANOGRAM_DATA?.captureShelf
export const getTrainedProduct = (state: any) => state?.GET_PLANOGRAM_DATA?.trainedProduct
export const getFrames = (state: any) => state?.GET_PLANOGRAM_DATA?.frames
