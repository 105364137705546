import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { MESSAGE } from "../../../config/String"
import { getCameraListData } from "../../../redux/reducer/CameraListReducer"
import { Button } from "../../UI/Atoms/Button"
import { DataNotFound } from "../../UI/Molecules/DataNotFound"
import { MisplacedItem } from "./MisplacedItem"
import { PlanogramPopup } from "./PlanogramPopup"

export const CabinetDetails = () => {
    const navigate = useNavigate();
    const [misplcementPopup, setMisplacementPopup] = useState<boolean>(false)
    const [planogramPopup, setPlanogramPopup] = useState<boolean>(false)
    const [misplacedData, setMisplacedData] = useState({})
    const [planogramData, setPlanogramData] = useState({})
    const cameraList = useSelector(getCameraListData)

    const handleMisplacement = (data: any) => {
        setMisplacementPopup(true)
        setMisplacedData(data)
    }
    const handlePlanogramPopup = (planogram: any) => {
        setPlanogramPopup(true)
        setPlanogramData(planogram)
    }
    return (
        <>
            <div className="pageHeader">
                <div className="row">
                    <div className="col-3">
                        <p className="CameraListHeader">{MESSAGE.SHELF_LIST}</p>
                    </div>
                    <div className="col-3 offset-md-6 text-right pr-4">
                        <Button buttonClassName={"btn btn-primary"} buttonName={MESSAGE.BACK} handleClick={() => navigate('/cameralist')} />
                    </div>
                </div>
            </div>
            <div className="card mainCard">
                <div className="card-body" >
                    {
                        cameraList && cameraList?.length > 0 ?
                            cameraList?.map((element: any, index: number) => {
                                return (
                                    <div className="card cameraListCard" key={index}>
                                        <div className="card-body">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className="row">
                                                            <p className="cameraTitle">{MESSAGE.CAMERA_ID}</p>
                                                        </div>
                                                        <div className="row">
                                                            <p className="cameraValues">{element?.shelf_id}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="row">
                                                            <p className="cameraTitle">{MESSAGE.STATUS}</p>
                                                        </div>
                                                        <div className="row">
                                                            {
                                                                element?.misplaced_items?.length > 0 ?
                                                                    <div className="statusRed"></div> : <div className="statusGreen"></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-3 cameraViewDetails">
                                                        <Button buttonClassName={"btn btn-primary"} disable={element?.misplaced_items?.length === 0} buttonName={MESSAGE.MISPLACEMENT} handleClick={() => handleMisplacement(element)} />
                                                    </div>
                                                    <div className="col-3 cameraViewDetails">
                                                        <Button buttonClassName={"btn btn-primary"} buttonName={MESSAGE.PLANOGRAM} handleClick={() => handlePlanogramPopup(element)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                            : <DataNotFound Error={"There is no Camera"} />
                    }
                    {
                        misplcementPopup && <MisplacedItem misplacementItems={misplacedData} close={() => setMisplacementPopup(false)} />
                    }
                    {
                        planogramPopup && <PlanogramPopup shelfDetails={planogramData} close={() => setPlanogramPopup(false)} />
                    }
                </div>
            </div>
        </>
    )
}