import { useState } from "react"
import { useSelector } from "react-redux"
import { getCustomerJourneyCount, getCustomerJourneyData } from "../../../redux/reducer/CustomerJourneyReducer"
import { Button } from "../../UI/Atoms/Button"
import { AccordionMolecules } from "../../UI/Molecules/AccordionMolecules"
import { PaginationSession } from "../../UI/Molecules/Pagination"
import { CartItem } from "./CartItem"
import { SessionCabinet } from "./SessionCabinet"
import { MESSAGE } from '../../../config/String'
import { ShowImage } from "./ShowImage"
export const Accordion = ({ currentPage, setCurrentPage }: { currentPage: number, setCurrentPage: any }) => {
    const customerJourneyData = useSelector(getCustomerJourneyData)
    const [_accordion, setAccordian] = useState<string | number>('')
    const [cartPopup, setCartPop] = useState(false)
    const [showImagePopup, setShowImagePopup] = useState(false)
    const [imageData, setImageData] = useState([])
    const pageCount = useSelector(getCustomerJourneyCount)
    const [CartDetails, setCartDetails] = useState({ finalCartData: '' })
    const handleCartPopup = (finalCart: string) => {
        setCartDetails({ finalCartData: finalCart })
        setCartPop(true)
    }
    const onChangeHandle = (page: number) => {
        setCurrentPage(page)
    }
    const checkplanogram = (element: any) => element?.planogram === true;
    const showImage=(element:any)=>{
        setImageData(element?.detection_image)
        setShowImagePopup(true)
    }
    return (
        <>
            <div className="sessionContainer">
                <div className="accordion mainaccordion" id="accordionExample" >
                    {customerJourneyData?.map((element: any, index: any) => {
                        return (
                            <div className="accordion-item sessionAccordion" key={index}>
                                <div className="card" id="flush-headingOne">
                                    <div className="card-body">
                                        <div className="maincontainer">
                                            <div className="row">
                                                <div className="col-sm text-center">
                                                    <div className="row">
                                                        <span style={{ fontWeight: 'bold' }}>Session ID</span>
                                                    </div>
                                                    <div className="row">
                                                        <span className="SessionId">{MESSAGE.SESSION_ID}{element?.session?.reference_id}</span>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="row">
                                                        <span style={{ fontWeight: 'bold' }}>Cabinet Name</span>
                                                    </div>
                                                    <div className="row">
                                                        <p className="SessionId">{element?.session?.cabinet?.name}</p>
                                                    </div>
                                                </div>
                                                <AccordionMolecules labelClassName="startingTime" label={MESSAGE.START_TIME} time={element?.session?.start_time} />
                                                <AccordionMolecules labelClassName="endTime" label={MESSAGE.END_TIME} time={element?.session?.end_time} />
                                                <AccordionMolecules labelClassName="date" label={'Date'} time={element?.session?.start_time} />
                                                <div className="col-sm">
                                                    <div className="row statusRow">
                                                        <div className="Status">
                                                            {MESSAGE.STATUS}
                                                            {
                                                                // element?.cabinets?.some(checkplanogram) ?
                                                                element?.misplacement_status === true ?
                                                                    <div className="statusRed"></div> : <div className="statusGreen"></div>
                                                                // :<p className="planogramStatus">-</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="row">
                                                        <Button buttonClassName={"btn btn-primary big-btn viewCabinet"}
                                                            disable={element?.end_time === null} buttonName={MESSAGE.CART}
                                                            handleClick={() => handleCartPopup(element?.cart)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="row">
                                                        <Button buttonClassName={"btn btn-outline btn-secondary viewCabinet"} disable={element?.end_time === null} handleClick={() => showImage(element) } buttonName={"Show Image"}  />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id={`collapse${index}`} className="accordion-collapse collapse cabinetContainer" aria-labelledby={`headingOne${index}`} data-bs-parent="#accordionExample">
                                    {
                                        <SessionCabinet cabinetElement={element?.video} sessionid={element?.session} misplacement={element?.misplacement_status} />
                                    }
                                </div>
                            </div>
                        )
                    }
                    )
                    }
                </div>
                {
                    cartPopup && <CartItem cartItems={CartDetails} close={() => setCartPop(false)} />
                }
                {
                    showImagePopup &&<ShowImage images={imageData}  close={() => setShowImagePopup(false)} />
                }
            </div>
            {
                customerJourneyData?.length > 0 &&
                <PaginationSession onChangeHandle={onChangeHandle} currentPage={currentPage} sessionCount={pageCount} />
            }
        </>
    )
}