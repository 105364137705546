import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet, doPost } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import END_POINTS from '../../config/Endpoints';
import { HEALTH_CHECK_LIST } from '../action/HealthCheckAction';
export function* HealthCheckListSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.HEALTH_CHECK_LIST, params, '')
        yield put(Actions.healthCheckListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.healthCheckListFailure({ error }));
        callback && callback({ error })
    }
}
export default function* healthCheckWatcher() {
    yield all([
        takeLatest(HEALTH_CHECK_LIST, HealthCheckListSaga),
    ]);
}
