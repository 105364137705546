import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../config';
import END_POINTS from '../../config/Endpoints';
import { Actions } from '../action';
import { ResponseGenerator } from './SagaInterface';

export function* getPlanogram(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(params, '', '')
        yield put(Actions.getPlanogramSuccess(response?.response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.getPlanogramFailure({ error }));
        callback && callback(error)

    }
}

export function* createPlanogramSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CREATE_PLANOGRAM, params, '')
        yield put(Actions.createPlanogramSuccess(response?.response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.createPlanogramFailure({ error }));
        callback && callback(error)

    }
}

export function* captureShelfSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CAPTURE_SHELF, params, '')
        yield put(Actions.captureShelfSuccess(response?.response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.captureShelfFailure({ error }));
        callback && callback(error)
    }
}

export function* trainedProductSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.TRAINED_PRODUCT, params, '')
        yield put(Actions.trainedProductSuccess(response?.response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.trainedProductFailure({ error }));
        callback && callback(error)
    }
}
export function* activatePlanogramSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.ACTIVATE_PLANOGRAM, params, '')
        yield put(Actions.activatePlanogramSuccess(response?.response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.activatePlanogramFailure({ error }));
        callback && callback(error)
    }
}
export default function* getPlanogramWatcher() {
    yield all([
        takeLatest(Actions.GET_PLANOGRAM, getPlanogram),
        takeLatest(Actions.CREATE_PLANOGRAM, createPlanogramSaga),
        takeLatest(Actions.TRAINED_PRODUCT, trainedProductSaga),
        takeLatest(Actions.CAPTURE_SHELF, captureShelfSaga),
        takeLatest(Actions.ACTIVE_PLANOGRAM, activatePlanogramSaga),
    ]);
}
