import React from "react"

export const Input = (props: InputInterface) => {
    return (
        <input
            type={props.inputType}
            className={props.inputClassName}
            value={props.value}
            id={props.id}
            name={props.inputname}
            placeholder={props.placeholder}
            disabled={props.disable}
            maxLength={props.maxLength}
            pattern={props.pattern}
            onChange={props.handleChange}
            onInput={props.handleInput}
            onClick={props.handleClick}
            onKeyDown={props.handleKeydown}
            accept={props.accept}
            ref={props.inputref}
            inputMode={props.InputMode}
            defaultValue={props.value}
            required />
    )
}
export type InputInterface = {
    InputMode?: "search" | "text" | "email" | "tel" | "url" | "none" | "numeric" | "decimal" | undefined,
    inputType: string,
    defaultValue?: any,
    inputClassName?: string,
    value?: string,
    id?: string,
    inputname?: string,
    placeholder?: string,
    disable?: boolean,
    pattern?: string,
    maxLength?: number,
    accept?: string,
    inputref?: React.RefObject<HTMLInputElement>,
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleInput?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleClick?: (e: any) => void,
    handleKeydown?: (event: React.KeyboardEvent<HTMLElement>) => void,

}