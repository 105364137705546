import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    publishProductList: [],
    adminProductList: [],
    productDetails: {},
    error: false
}
export const productListReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.PUBLISH_PRODUCT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PUBLISH_PRODUCT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                publishProductList: action.data,
                error: false
            };
        }
        case Actions.PUBLISH_PRODUCT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                publishProductList: {},
                error: action.error,
            };
        }
        case Actions.ADMIN_PRODUCT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ADMIN_PRODUCT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                publishProductList: action.data,
                error: false
            };
        }
        case Actions.ADMIN_PRODUCT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                publishProductList: [],
                error: action.error,
            };
        }
        case Actions.PRODUCT_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PRODUCT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productDetails: action.data,
                error: false
            };
        }
        case Actions.PRODUCT_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                productDetails: {},
                error: action.error,
            };
        }
        //CREATE_PRODUCT
        case Actions.CREATE_PRODUCT: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CREATE_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CREATE_PRODUCT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        //CREATE_PRODUCT
        case Actions.ADMIN_CREATE_PRODUCT: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.ADMIN_CREATE_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.ADMIN_CREATE_PRODUCT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // PRODUCT_DELETE
        case Actions.PRODUCT_DELETE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.PRODUCT_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.PRODUCT_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // PRODUCT_UPDATE
        case Actions.PRODUCT_UPDATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.PRODUCT_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.PRODUCT_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // PRODUCT_UPDATE
        case Actions.ADMIN_PRODUCT_UPDATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.ADMIN_PRODUCT_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.ADMIN_PRODUCT_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // PRODUCT_ACTIVE
        case Actions.PRODUCT_ACTIVE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.PRODUCT_ACTIVE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.PRODUCT_ACTIVE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // PRODUCT_ACTIVE
        case Actions.ADMIN_PRODUCT_ACTIVE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.ADMIN_PRODUCT_ACTIVE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.ADMIN_PRODUCT_ACTIVE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        case Actions.SEARCH_PRODUCT_LIST: {
            return {
                ...state,
                productList: action.data,
                error: false
            };
        }
        default:
            return state;
    }
};
export const productDataList = (state: any) => state?.PRODUCT_LIST_DATA?.publishProductList;
export const productDataListCount = (state: any) => state?.PRODUCT_LIST_DATA?.publishProductList;
export const adminproductlist = (state: any) => state?.PRODUCT_LIST_DATA?.adminProductList
export const productListIsLoading = (state: any) => state?.PRODUCT_LIST_DATA?.isLoading;