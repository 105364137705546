import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import END_POINTS from '../../config/Endpoints'
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import { SUBSCRIPTION_LIST } from '../action/SubscriptionActions';
export function* subscriptionListSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.SUBSCRIPTION, params, {})
        yield put(Actions.subscriptionListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.subscriptionListFailure({ error }));
        callback && callback({ error })
    }
}

export default function* subscriptionWatcher() {
    yield all([
        takeLatest(SUBSCRIPTION_LIST, subscriptionListSaga),
    ]);
}
