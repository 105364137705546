import { useState } from "react"
import { TestingEndWidget } from "../Widgets/TestingEndWidget"
import { TestingStartWidgets } from "../Widgets/TestingStartWidget"
export const TestWidgets = () => {
    const [time, settime] = useState<number | null>(null)
    const [end, setEnd] = useState({ session_id: '', cabinet_id: '' })
    const [disable, setDisable] = useState(false)
    return (
        <>
           
            <div className='card card-body rounded-3 border-0 shadow p-4 mb-3 mt-3'>
                <TestingStartWidgets time={time} settime={settime} setEnd={setEnd} disable={disable} setDisable={setDisable} />
                {/* <TestingEndWidget time={time} end={end} setEnd={setEnd} setDisable={setDisable} /> */}
            </div>
        </>
    )
}