import { doAction, doResponseAction } from ".";

const INVENTORY_MANAGEMENT_RECENT = 'INVENTORY_MANAGEMENT_RECENT';
const INVENTORY_MANAGEMENT_RECENT_SUCCESS = 'INVENTORY_MANAGEMENT_RECENT_SUCCESS';
const INVENTORY_MANAGEMENT_RECENT_FAILURE = 'INVENTORY_MANAGEMENT_RECENT_FAILURE';
const INVENTORY_MANAGEMENT_HISTORY = 'INVENTORY_MANAGEMENT_HISTORY';
const INVENTORY_MANAGEMENT_HISTORY_SUCCESS = 'INVENTORY_MANAGEMENT_HISTORY_SUCCESS';
const INVENTORY_MANAGEMENT_HISTORY_FAILURE = 'INVENTORY_MANAGEMENT_HISTORY_FAILURE';
export {
    INVENTORY_MANAGEMENT_RECENT,
    INVENTORY_MANAGEMENT_RECENT_FAILURE,
    INVENTORY_MANAGEMENT_RECENT_SUCCESS,
    INVENTORY_MANAGEMENT_HISTORY,
    INVENTORY_MANAGEMENT_HISTORY_SUCCESS,
    INVENTORY_MANAGEMENT_HISTORY_FAILURE,
}

// inventoryManagement
export const inventoryManagementRecent = (params?: {}, callback?: any) => doAction(INVENTORY_MANAGEMENT_RECENT, params, callback);
export const inventoryManagementRecentSuccess = (data: {}) => doResponseAction(INVENTORY_MANAGEMENT_RECENT_SUCCESS, data);
export const inventoryManagementRecentFailure = (data: {}) => doResponseAction(INVENTORY_MANAGEMENT_RECENT_FAILURE, data);

// inventoryManagementHistory
export const inventoryManagementHistory = (params?: {}, callback?: any) => doAction(INVENTORY_MANAGEMENT_HISTORY, params, callback);
export const inventoryManagementHistorySuccess = (data: {}) => doResponseAction(INVENTORY_MANAGEMENT_HISTORY_SUCCESS, data);
export const inventoryManagementHistoryFailure = (data: {}) => doResponseAction(INVENTORY_MANAGEMENT_HISTORY_FAILURE, data);
