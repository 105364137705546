import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    branchList: [],
    branchDetails: {},
    error: false
}
export const BranchesReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        //BRANCHES_LIST
        case Actions.BRANCHES_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.BRANCHES_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                branchList: action.data?.response,
                error: false
            };
        }
        case Actions.BRANCHES_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                branchList: [],
                error: action.error,
            };
        }
        //BRANCHES_DETAILS
        case Actions.BRANCHES_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.BRANCHES_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                branchDetails: action.data,
                error: false
            };
        }
        case Actions.BRANCHES_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                branchDetails: {},
                error: action.error,
            };
        }
        //CREATE_BRANCHES
        case Actions.CREATE_BRANCHES: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CREATE_BRANCHES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CREATE_BRANCHES_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // BRANCHES_DELETE
        case Actions.BRANCHES_DELETE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.BRANCHES_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.BRANCHES_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // BRANCHES_UPDATE
        case Actions.BRANCHES_UPDATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.BRANCHES_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.BRANCHES_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // BRANCHES_ACTIVE
        case Actions.BRANCHES_ACTIVE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.BRANCHES_ACTIVE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.BRANCHES_ACTIVE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        default:
            return state;
    }
};

export const getBranchDetails = (state: any) => state.BRANCHES.branchList

export const getBranchLoader = (state: any) => state.BRANCHES.isLoading