import empty from '../../../assets/img/Empty.png'
export const DataNotFound = ({ Error }: { Error: string }) => {
    return (
        <>
            {/* <div className="card dataNotFound">
                <img src={empty} alt="No dat Found" className="dataNotFoundImage" />
                <div className="card-body">
                    <p>{Error}</p>
                </div>
            </div> */}
               <div className="text-center">
                    <img src={empty} alt="Nol Results" className='mt-5' style={{width:"200px",height:"200px"}}/><br /><br />
                    <h4 className="font16 mb-2 fw-bold">
                        {Error}
                    </h4>
                </div>
        </>
    )
}