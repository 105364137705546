import { doAction, doResponseAction } from ".";

const BRANCHES_LIST = 'BRANCHES_LIST';
const BRANCHES_LIST_SUCCESS = 'BRANCHES_LIST_SUCCESS';
const BRANCHES_LIST_FAILURE = 'BRANCHES_LIST_FAILURE';

const BRANCHES_DETAILS = 'BRANCHES_DETAILS';
const BRANCHES_DETAILS_SUCCESS = 'BRANCHES_DETAILS_SUCCESS';
const BRANCHES_DETAILS_FAILURE = 'BRANCHES_DETAILS_FAILURE';

const CREATE_BRANCHES = 'CREATE_BRANCHES';
const CREATE_BRANCHES_SUCCESS = 'CREATE_BRANCHES_SUCCESS';
const CREATE_BRANCHES_FAILURE = 'CREATE_BRANCHES_FAILURE';

const BRANCHES_DELETE = 'BRANCHES_DELETE';
const BRANCHES_DELETE_SUCCESS = 'BRANCHES_DELETE_SUCCESS';
const BRANCHES_DELETE_FAILURE = 'BRANCHES_DELETE_FAILURE';

const BRANCHES_UPDATE = 'BRANCHES_UPDATE';
const BRANCHES_UPDATE_SUCCESS = 'BRANCHES_UPDATE_SUCCESS';
const BRANCHES_UPDATE_FAILURE = 'BRANCHES_UPDATE_FAILURE';

const BRANCHES_ACTIVE = 'BRANCHES_ACTIVE';
const BRANCHES_ACTIVE_SUCCESS = 'BRANCHES_ACTIVE_SUCCESS';
const BRANCHES_ACTIVE_FAILURE = 'BRANCHES_ACTIVE_FAILURE';
export {
    BRANCHES_LIST,
    BRANCHES_LIST_FAILURE,
    BRANCHES_LIST_SUCCESS,

    BRANCHES_DETAILS,
    BRANCHES_DETAILS_FAILURE,
    BRANCHES_DETAILS_SUCCESS,

    CREATE_BRANCHES,
    CREATE_BRANCHES_FAILURE,
    CREATE_BRANCHES_SUCCESS,

    BRANCHES_DELETE,
    BRANCHES_DELETE_FAILURE,
    BRANCHES_DELETE_SUCCESS,

    BRANCHES_UPDATE,
    BRANCHES_UPDATE_FAILURE,
    BRANCHES_UPDATE_SUCCESS,

    BRANCHES_ACTIVE,
    BRANCHES_ACTIVE_FAILURE,
    BRANCHES_ACTIVE_SUCCESS,
}

// BRANCHES_LIST
export const branchesList = (params?: {}, callback?: any) => doAction(BRANCHES_LIST, params, callback);
export const branchesListSuccess = (data = {}) => doResponseAction(BRANCHES_LIST_SUCCESS, data);
export const branchesListFailure = (data = {}) => doResponseAction(BRANCHES_LIST_FAILURE, data);

// BRANCHES_DETAILS
export const branchesDetails = (params?: {}, callback?: any) => doAction(BRANCHES_DETAILS, params, callback);
export const branchesDetailsSuccess = (data = {}) => doResponseAction(BRANCHES_DETAILS_SUCCESS, data);
export const branchesDetailsFailure = (data = {}) => doResponseAction(BRANCHES_DETAILS_FAILURE, data);

// CREATE_BRANCHES
export const createBranches = (params?: {}, callback?: any) => doAction(CREATE_BRANCHES, params, callback);
export const createBranchesSuccess = (data = {}) => doResponseAction(CREATE_BRANCHES_SUCCESS, data);
export const createBranchesFailure = (data = {}) => doResponseAction(CREATE_BRANCHES_FAILURE, data);

// BRANCHES_DELETE
export const branchesDelete = (params?: {}, callback?: any) => doAction(BRANCHES_DELETE, params, callback);
export const branchesDeleteSuccess = (data = {}) => doResponseAction(BRANCHES_DELETE_SUCCESS, data);
export const branchesDeleteFailure = (data = {}) => doResponseAction(BRANCHES_DELETE_FAILURE, data);

// BRANCHES_UPDATE
export const branchesUpdate = (params?: {}, callback?: any) => doAction(BRANCHES_UPDATE, params, callback);
export const branchesUpdateSuccess = (data = {}) => doResponseAction(BRANCHES_UPDATE_SUCCESS, data);
export const branchesUpdateFailure = (data = {}) => doResponseAction(BRANCHES_UPDATE_FAILURE, data);

// BRANCHES_UPDATE
export const branchesActive = (params?: {}, callback?: any) => doAction(BRANCHES_ACTIVE, params, callback);
export const branchesActiveSuccess = (data = {}) => doResponseAction(BRANCHES_ACTIVE_SUCCESS, data);
export const branchesActiveFailure = (data = {}) => doResponseAction(BRANCHES_ACTIVE_FAILURE, data);