import React, { useEffect, useState } from "react"
import Select from "../../UI/Molecules/Select"
import { PaginationSession } from "../../UI/Molecules/Pagination"
import { DataNotFound } from "../../UI/Molecules/DataNotFound"
import { ViewSubscription } from "./ViewSubscription"
import { useDispatch } from "react-redux"
import { Actions } from "../../../redux/action"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { getSubscriptionList, getSubscriptionListCount } from "../../../redux/reducer/SubscriptionReducer"
export const SubscriptionWidget = () => {
    const subscriptionType = [{ name: 'Quarterly', value: 1 }, { name: 'Half yearly', value: 2 }, { name: 'Annual', value: 3 }, { name: 'Unlimited', value: 4 }]
    const subscriptionStatus = [{ name: 'Subscribed', value: 1 }, { name: 'Renew', value: 2 }, { name: 'Revoked', value: 3 }]
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [popup, setPopup] = useState<boolean>(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState({})
    const [status, setStatus] = useState('')
    const [type, setType] = useState('')
    const [vendorName, setVendorName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const subscriptionListData = useSelector(getSubscriptionList)
    const pageCount = useSelector(getSubscriptionListCount)
    useEffect(() => {
        setIsLoading(true)
        let params = {
            pagenum: 1,
            limit: 10
        }
        dispatch(Actions.subscriptionList(params, subscriptionCallback))
    }, [])

    const subscriptionCallback = (response: any) => {
        setIsLoading(false)
        if (response?.status_code === 0) {
        } else {
            toast.error(response?.message)
        }
    }

    const handlePage = (page: number) => {
        setCurrentPageNumber(page)
        let params = {
            name: vendorName,
            type: type,
            status: status,
            pagenum: page,
            limit: 10
        }
        setIsLoading(true)
        dispatch(Actions.subscriptionList(params, subscriptionCallback))

    }

    //subscription view details 
    const viewDetails = (obj: any) => {
        setPopup(true)
        setSubscriptionDetails(obj)
    }

    const handleStatus = (event: any) => {
        setStatus(event.target.value)
        let params = {
            name: vendorName,
            type: type,
            status: event.target.value,
            pagenum: currentPageNumber
        }
        setIsLoading(true)
        dispatch(Actions.subscriptionList(params, subscriptionCallback))
    }

    const handleType = (event: any) => {
        setType(event.target.value)
        let params = {
            name: vendorName,
            type: event.target.value,
            status: status,
            pagenum: currentPageNumber
        }
        setIsLoading(true)
        dispatch(Actions.subscriptionList(params, subscriptionCallback))
    }
    const handleVendorName = (event: any) => {
        setVendorName(event?.target?.value)
        let params = {
            name: event?.target?.value,
            type: type,
            status: status,
            pagenum: currentPageNumber
        }
        setIsLoading(true)
        dispatch(Actions.subscriptionList(params, subscriptionCallback))
    }
    return (
        <>
            {
                isLoading && <PlanogramLoader />
            }
            <div className="container mt-4">
                <div className="row">
                    <div className="col-sm-4 col-md-12 col-lg-4 mt-2">
                        <input type="text" className="subscriptionFilter" placeholder="Subscription ID or Vendor name" onChange={(event: any) => handleVendorName(event)} />
                    </div>
                    <div className="col-sm-4 col-md-12 col-lg-4 mt-2">
                        <Select
                            placeHolder={"Select subscription type"}
                            array={subscriptionType}
                            isList={true}
                            disabled={false}
                            value={type}
                            onChange={(event: any) => handleType(event)
                            }
                        />
                    </div>
                    <div className="col-sm-4 col-md-12 col-lg-4 mt-2">
                        <Select
                            placeHolder={"Select subscription status"}
                            array={subscriptionStatus}
                            isList={true}
                            disabled={false}
                            value={status}
                            onChange={(event: any) => handleStatus(event)
                            }
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <div className="card card-body rounded-3 border-0 shadow p-2 mb-3 mb-xl-0 mt-2">
                        {
                            subscriptionListData?.length > 0 ?
                                <table className="table table-hover table-striped table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Vendor name</th>
                                            <th scope="col">Subscription name</th>
                                            <th scope="col">Subscription type</th>
                                            <th scope="col">Status of subscription</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            subscriptionListData?.length > 0 && subscriptionListData?.map((item: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item?.control_tower.store.vendor.first_name}</td>
                                                        <td>{item?.master_subscription.subscription_name}</td>
                                                        <td>{item?.master_subscription.subscription_type.subscription_type}</td>
                                                        <td><span className={`${item?.action_type === 1 ? "Subscribed" : item?.action_type === 2 ? "renewed" : "revoked"}`}>{item?.action_type === 1 ? "Subscribed" : item?.action_type === 2 ? "Renew" : "Revoked"}</span></td>
                                                        <td>
                                                            <button className="btn btn-primary big-btn" onClick={() => viewDetails(item)}>View</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table> : <DataNotFound Error="Vendors not found" />
                        }

                    </div>
                </div>
                {
                    (subscriptionListData?.length > 0 && pageCount > 1) &&
                    <PaginationSession
                        onChangeHandle={handlePage}
                        currentPage={currentPageNumber}
                        sessionCount={3} />
                }
                {
                    popup &&
                    <ViewSubscription close={() => setPopup(false)} obj={subscriptionDetails} />
                }
            </div>
        </>

    )
}