import { doAction } from ".";

const TRAINING_ID = 'TRAINING_ID';
const IS_ACTIVE = 'Is_ACTIVE';
export {
    TRAINING_ID,
    IS_ACTIVE
}

// Training Id
export const trainingIdList = (data: {}) => doAction(TRAINING_ID, data);
export const getIsActiveforTrainigId = (data?: {}) => doAction(IS_ACTIVE, data)