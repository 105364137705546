import { doAction, doResponseAction } from ".";

const LOGIN_USER = 'LOGIN_USER';
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

const LOGOUT = 'LOGOUT';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export {
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    LOGOUT,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS,
}

// LOGIN
export const loginUser = (params: {}, callback: any) => doAction(LOGIN_USER, params, callback);
export const loginUserSuccess = (data = {}) => doResponseAction(LOGIN_USER_SUCCESS, data);
export const loginUserFailure = (data = {}) => doResponseAction(LOGIN_USER_FAILURE, data);
//LOGOUT
export const logoutAction = (params: {}, callback: any) => doAction(LOGOUT, params, callback);
export const logoutSuccessAction = (data: {}) => doResponseAction(LOGOUT_SUCCESS, data);
export const logoutFailureAction = (data: {}) => doResponseAction(LOGOUT_FAILURE, data);

