import { useEffect, useState } from "react"
import { SubscriptionWidget } from "../Widgets/SubscriptionWidget"
import { MasterSubscriptionWidget } from "../Widgets/MasterSubscriptionWidget"

export const Subscription = () => {
    const [currentPage, setCurrentPage] = useState(0)

    const changeTab = (tab: number) => {
        setCurrentPage(tab)
    }
    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-sm-6">
                    <h3 className="font-bold">Subscription</h3>
                </div>
                <div className="col-sm-6 text-end">
                </div>
            </div>
            <div className="mt-3">
                <nav className="nav nav-pills nav-fill subscriptionNav">
                    <span className={`nav-link cursor-pointer col-sm-6 col-lg-6 ${currentPage === 0 ? "active" : ""}`} aria-current="page" onClick={() => changeTab(0)}>Subscribed vendor</span>
                    <span className={`nav-link cursor-pointer col-sm-6 col-lg-6 ${currentPage === 1 ? "active" : ""}`} aria-current="page" onClick={() => changeTab(1)}>Master Subscription </span>
                </nav>
                {currentPage === 0 ? <SubscriptionWidget /> : <MasterSubscriptionWidget />}
            </div>
        </div>
    )
}