import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet, doPost } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import END_POINTS from '../../config/Endpoints';
import { VENDOR_ONBOARDING_LIST, CREATE_VENDOR, VENDOT_ONBOARDING_DELETE, VENDOR_ONBOARDING_UPDATE, VENDOR_ONBOARDING_ACTIVE, VENDOR_ONBOARDING_DETAILS } from '../action/VendorAction';
export function* VendorOnboardingListSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.VENDOR_ONBOARDING_LIST, params, '')
        yield put(Actions.vendorOnboardingListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.vendorOnboardingListFailure({ error }));
        callback && callback({ error })
    }
}
export function* VendorOnboardingDetailsSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.VENDOR_ONBOARDING_DETAILS, params, '')
        yield put(Actions.vendorOnboardingDetailsSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.vendorOnboardingDetailsFailure({ error }));
        callback && callback({ error })
    }
}
export function* CreateVendorSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CREATE_VENDOR, params, '')
        yield put(Actions.createVendorSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.createVendorFailure({ error }));
        callback && callback({ error })
    }
}

export function* VendorOnboardingDeleteSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.VENDOR_ONBOARDING_DELETE, params, '')
        yield put(Actions.vendorOnboardingDeleteSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.vendorOnboardingDeleteFailure({ error }));
        callback && callback({ error })
    }
}

export function* VendorOnboardingUpdateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.VENDOR_ONBOARDING_UPDATE, params, '')
        yield put(Actions.vendorOnboardingUpdateSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.vendorOnboardingUpdateFailure({ error }));
        callback && callback({ error })
    }
}

export function* VendorOnboardingActiveSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.VENDOR_ONBOARDING_ACTIVE, params, '')
        yield put(Actions.vendorOnboardingActiveSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.vendorOnboardingActiveFailure({ error }));
        callback && callback({ error })
    }
}
export default function* vendorOnboardingWatcher() {
    yield all([
        takeLatest(VENDOR_ONBOARDING_LIST, VendorOnboardingListSaga),
        takeLatest(VENDOR_ONBOARDING_DETAILS, VendorOnboardingDetailsSaga),
        takeLatest(CREATE_VENDOR, CreateVendorSaga),
        takeLatest(VENDOT_ONBOARDING_DELETE, VendorOnboardingDeleteSaga),
        takeLatest(VENDOR_ONBOARDING_UPDATE, VendorOnboardingUpdateSaga),
        takeLatest(VENDOR_ONBOARDING_ACTIVE, VendorOnboardingActiveSaga),
    ]);
}
