import { all, fork } from 'redux-saga/effects';
import login from './login';
import CameraList from './CameraListSaga'
import CustomerSaga from './CustomerJourneySaga'
import sessionCabinetsaga from './SessionCabinetSaga'
import searchListSaga from './SearchSaga'
import productListSaga from './ProductListSaga'
import productSearchSaga from './ProductSearchSaga'
import unregisterProductSaga from './UnregisterProductSaga'
import generateTrainingSaga from './GenerateTrainingIdSaga'
import registerSaga from './RegisterSaga'
import planogramSaga from './PlanogramSaga'
import historySaga from './HistorySaga';
import updatePlanogram from './UpdatePlanogramSaga'
import autoFillSaga from './AutoFillSaga'
import sessionStartSaga from './SessionStartSaga'
import sessionEndSaga from './SessionEndSaga'
import cabinetListWatcher from './CabinetListSaga'
import planogramStatusWatcher from './PlanogramStatusSaga'
import cabinetOnboardingWatcher from './CabinetOnboardingSaga'
import inventoryManagementWatcher from './InventoryManagementSaga';
import reportWatcher from './ReportSaga';
import vendorOnboardingWatcher from './VendorSaga';
import BranchesWatcher from './BranchesSaga';
import dashboardWatcher from './DashboardSaga';
import troubleShootWatcher from './TroubleShootSaga';
import healthCheckWatcher from './HealthCheckSaga';
import { resetPassword } from '../action/ResetPasswordAction';
import CategorySaga from './CategorySaga';
import EdgeServerSaga from './EdgeServerSaga';
import SubscriptionSaga from './SubscriptionSaga';
import MasterSubscriptionSaga from './MasterSubscriptionSaga';
export default function* root() {
  yield all([
    fork(login),
    fork(CameraList),
    fork(CustomerSaga),
    fork(sessionCabinetsaga),
    fork(searchListSaga),
    fork(productListSaga),
    fork(productSearchSaga),
    fork(unregisterProductSaga),
    fork(generateTrainingSaga),
    fork(registerSaga),
    fork(planogramSaga),
    fork(historySaga),
    fork(updatePlanogram),
    fork(autoFillSaga),
    fork(sessionStartSaga),
    fork(sessionEndSaga),
    fork(cabinetListWatcher),
    fork(planogramStatusWatcher),
    fork(cabinetOnboardingWatcher),
    fork(vendorOnboardingWatcher),
    fork(inventoryManagementWatcher),
    fork(reportWatcher),
    fork(BranchesWatcher),
    fork(dashboardWatcher),
    fork(troubleShootWatcher),
    fork(healthCheckWatcher),
    fork(CategorySaga),
    fork(resetPassword),
    fork(EdgeServerSaga),
    fork(SubscriptionSaga),
    fork(MasterSubscriptionSaga)
  ]);
}