import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import END_POINTS from "../../../../config/Endpoints"
import { MESSAGE } from "../../../../config/String"
import { Actions } from "../../../../redux/action"
import PlanogramLoader from "../../../UI/Molecules/PlanogramLoader"
import { CheckBoxMolecules } from "../../../UI/Molecules/CheckboxMolecules"
import { PopupData } from "../../../UI/Molecules/PopUpData"
import InputField from "../../../UI/Molecules/InputField"
import { CabinetInput } from "../../../UI/Molecules/CabinetInputs"
import Select from "../../../UI/Molecules/Select"
import { getLoginData } from "../../../../redux/reducer/login"
import { getBranchDetails } from "../../../../redux/reducer/BranchesReducer"
import { getCabinetSearchList } from "../../../../redux/reducer/SearchReducer"
import { DataNotFound } from "../../../UI/Molecules/DataNotFound"
import { PaginationSession } from "../../../UI/Molecules/Pagination"
import { CabinetValidation, validateminLengthRegExp } from "../../../../util/Validations"
import { Button } from "../../../UI/Atoms/Button"
import { productListData } from "../../../../config/Mockup"
import { PlanogramImageWidget } from "../../Widgets/PlanogramImageWidget"
import { getFrames } from "../../../../redux/reducer/PlanogramReducer"
import { validateMaxProduct, validateminLengthReg } from "../../../../util/Validations"
import { getEdgeDetails, getEdgeServerDetails, getVendorEdgeServerDetails } from "../../../../redux/reducer/EdgeServerReducer"
import BranchTypeAhead from "../../Widgets/BranchTypeAhead"
import { Input } from "../../../UI/Atoms/Input"
import { Planogram3DWidget } from '../../Widgets/Planogram3DWidget'
import { vendorListDetails } from "../../../../redux/reducer/VendorReducer"
let branch_Id: any;

export const CabinetList = (props?: any) => {
    const initialState = [{ shelf_id: '', connection_string: "", camError: null, shelfError: null }]
    const dispatch = useDispatch();
    const branchDetails = useSelector(getBranchDetails)
    const cabinetDetails = useSelector(getCabinetSearchList)
    const [isloading, setLoading] = useState<boolean>(false)
    const [isDeleted, setDeleted] = useState<boolean>(false)
    const [cameraCount, setCameraCount] = useState<number>(0)
    const [isShowpopup, setIsShowpopup] = useState<boolean>(false)
    const [cameraList, setCameraList] = useState<{ shelf_id: any, connection_string: any, camError: any, shelfError: any }[]>(initialState)
    const [formDetails, setFormDetails] = useState<any>({ fields: {} });
    const [popupDetails, setPopupDetails] = useState<any>();
    const [planogramName, setPlanogramName] = useState<any>()
    const [planogram, setPlanogram] = useState<boolean>(false)
    const [planogramPopup, setPlanogramPopup] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const [popupError, setPopupError] = useState<any>()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [cabinetObj, setCabinetObj] = useState<any>()
    const [shelfParams, setShelfParams] = useState<any>()
    const [PlanogramPopFields, setPlanogramPopFields] = useState<any>()
    const edgeServerDetails = useSelector(getEdgeServerDetails)
    const edgeServerVendorList = useSelector(getVendorEdgeServerDetails)
    const [masterPlanogram, setMasterPlanogram] = useState<boolean>(false)
    const [imageSize, setImageSize] = useState({
        width: 0,
        height: 0
    });
    const [dropDownProduct, setDropDownProduct] = useState<any>()
    const [planogramList, setPlanogramList] = useState<any>([])
    const framesList = useSelector(getFrames)
    const [checkStatus, setCheckStatus] = useState<any>()
    const [isAccordionOpen, setIsAccordionOpen] = useState<any>({})
    const [planogramIndex, setPlanogramIndex] = useState<any>()
    const [planogramId, setPlanogramId] = useState<any>()
    const [planogramShelfId, setPlanogramShelfId] = useState<any>()
    const [showButton, setShowButton] = useState<any>()
    const [checkPlanogram, setCheckPlanogram] = useState<boolean>(true)
    const [planogramProdObj, setPlanogramProdObj] = useState<any>([])
    const [productListArray, setProductsList] = useState<any>([])
    const [limit, setLimit] = useState<any>(6)
    const [currentStoreObj, setCurrentStoreObj] = useState<any>({})
    const [showPlanogram, setShowPlanogram] = useState<boolean>(false)
    const [options, setOptions] = useState(null || []);
    const loginDetails = useSelector(getLoginData)
    const location = useLocation()

    //validation for the Create cabinet
    const [macAddress, setMacAddress] = useState<any>()

    useEffect(() => {
        if (Object.keys(formDetails)?.length !== 0) {
            let validatedError = CabinetValidation(formDetails, ["branchId", "edge_server_id", "name", "macAddress"])
            if (validatedError) {
                setError(validatedError)
            }
        }
    }, [formDetails])

    //Branch list API for the drop down
    useEffect(() => {
        let url = location?.pathname
        let word = url.split('/')
        if (role == 1) {
            const params = { vendor_id: word[3] }
            dispatch(Actions.branchesList(params, branchListCallback))
        } else {
            const { user_id } = loginDetails || {}
            const params = { vendor_id: user_id }
            dispatch(Actions.branchesList(params, branchListCallback))
        }
        dispatch(Actions.vendorEdgeServerList({ pagenum: 1, limit: 10 }))
        intiallCall()
    }, [dispatch])

    //Cabinet List API
    useEffect(() => {
        getCabinetList()
    }, [dispatch])

    //Common function for the cabinet api
    const getCabinetList = () => {
        let url = location?.pathname
        let word = url.split('/')
        const callback = (response: any) => {
            if (response?.statuscode !== 0) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
        }
        let params = {
            branchId: '',
            limit: 6,
            pagenum: currentPage,
            vendorId: word[3],
            storeId: currentStoreObj?.store ?? 1
        }
        dispatch(Actions.cabinet_search(params, callback));
    }

    //Active and Deactivate cabinet API function
    const onActive = (e: any, obj: any) => {
        const { checked } = e.target
        const params = {
            cabinet_id: obj?.id,
            branchId: obj?.branch?.id,
            is_active: checked
        }
        setLoading(true)
        dispatch(Actions.cabinetDelete(params, activeCallback))
    }

    //Enable disable callback in cabinet list
    const activeCallback = (response: any) => {
        let url = location?.pathname
        let word = url.split('/')
        setLoading(false)
        if (response?.status_code === 0) {
            let params = {
                branchId: formDetails?.branchId?.value ? formDetails?.branchId?.value ?? '' : branch_Id ?? '',
                limit: limit,
                pagenum: currentPage,
                vendorId: word[3],
                storeId: currentStoreObj?.store ?? 1
            }
            dispatch(Actions.cabinet_search(params));
            toast.success("Successfully updated")
        }
    }

    //cabinet create and edit popup
    const ManageCabinet = () => {
        setIsShowpopup(true)
        setFormDetails('')
    }

    //close the create and edit popup
    const cancel = () => {
        setIsShowpopup(false)
    }

    //check the camera input are not empty
    const isCameraInputValid = (array: any) => {
        const isCameraInputEmpty = (currentValue: any) => (currentValue.shelf_id.length > 3 && currentValue.connection_string !== '' && validateMaxProduct(currentValue?.shelf_id));
        return array.every(isCameraInputEmpty);
    }

    //Onsubmit for the cabinet 
    const manage = () => {
        if (!formDetails?.branchId?.value) {
            setError({ ...error, branchId: "Please choose the branch" })
        } else if (!formDetails?.edge_server_id) {
            setError({ ...error, edge_server_id: "Please choose any server" })
        } else if (!formDetails?.name || !validateMaxProduct(formDetails?.name)) {
            setError({ ...error, name: "Please enter the valid cabinet name" })
        } else if (!macAddress || !validateminLengthReg(macAddress)) {
            setError({ ...error, macAddress: "Please enter the valid mac address" })
        } else if (!formDetails?.cabinet_position) {
            setError({ ...error, cabinet_position: "Please select cabinet position" })
        } else if (!isCameraInputValid(cameraList) || Object.values(formDetails).every(x => x === null || x === '')) {
            setError({ ...error, shelf: "Please enter valid shelf name and camera url" })
        } else {
            const convertArray = cameraList?.map((obj: any) => ({ shelf_name: obj.shelf_id, ...obj }))
            let params: any = {
                name: formDetails?.name,
                branchId: formDetails?.branchId?.value,
                planogram: planogram,
                macAddress: macAddress,
                edge_server_id: Number(formDetails.edge_server_id),
                cabinet_position: formDetails?.cabinet_position
            }
            setLoading(true)
            if (cabinetObj?.id) {
                let convertArray = cameraList?.map((obj: any) => ({
                    shelf_name: obj.shelf_id,
                    connection_string: obj?.connection_string,
                    shelf_id: obj?.id
                }))
                params["cabinetId"] = cabinetObj?.id
                params["cameras"] = convertArray
                params["edge_server_id"] = Number(formDetails.edge_server_id)
                dispatch(Actions.cabinetUpdate(params, commonCallBack))
            } else {
                params["cameras"] = convertArray
                dispatch(Actions.cabinetOnboarding(params, commonCallBack))
            }
        }

    }

    const commonCallBack = (response: any) => {
        setLoading(false)
        if (response?.status_code === 0) {
            let params = {
                branchId: branch_Id,
                limit: limit,
                pagenum: 1,
                vendorId: word[3],
            }
            dispatch(Actions.cabinet_search(params));
            toast.success(response?.message)
            closePopUp()
            setCurrentPage(1)
        } else if (response?.status_code === 1) {
            toast.error(response?.message)
            let { contents } = response || {}
            let currentArray = cameraList
            for (var i = 0, len = currentArray?.length; i < len; i++) {
                for (var j = 0, len2 = contents?.length; j < len2; j++) {
                    if (currentArray[i]?.connection_string == contents[j]?.connection_string) {
                        currentArray[i]["camError"] = response?.message
                    }
                }
            }
            setLoading(false)
            setCameraList(currentArray)
        } else {
            toast.error(MESSAGE.SOMETHING_WENT_WORNG)
        }
    }
    //click event to add the camera input just increase the count
    const addCamera = () => {
        setCameraCount(cameraCount + 1)
        cameraCountList()
    }

    //append the object in the camera list for n number of camera input field
    const cameraCountList = () => {
        if (cameraCount >= 0) {
            cameraList.push({
                shelf_id: "",
                connection_string: "",
                camError: "",
                shelfError: ""
            })
        }
    }
    //click event to delete the camera input field
    const deleteCamera = (e: any, index: number) => {
        if (index !== 0) {
            cameraList.splice(index, 1)
            setDeleted(!isDeleted)
        }
    }

    //handle change in the Add new cabinet and edit
    const handleChange = (e: any) => {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
    }

    //handle change in the popup
    const popupHandleChange = (e: any) => {
        if (!e.target.value || !validateminLengthRegExp(e.target.value)) {
            setPopupError("Please enter the valid planogram name")
        } else {
            setPopupError("")
            setPopupDetails(e.target.value)
        }
    }

    const enablePlanogram = (event: any) => {
        setPlanogram(event?.target.checked)
    }

    //shelf validation in onchange event
    const onchangeShelfValidation = (e: any, i: any) => {
        if (e.target.value === "") {
            setCameraList((prevState: any) => {
                const customState = [...prevState]
                customState[i].shelfError = "Please enter the valid shelf name"
                return customState
            })
        }
        else {
            setCameraList((prevState: any) => {
                const customState = [...prevState]
                customState[i].shelfError = ""
                return customState
            })
        }
    }

    //handle shelf values
    const handleShelf = (e: any, i: number) => {
        setError({ ...error, shelf: "" })
        onchangeShelfValidation(e, i)
        if (e.target.value == -1) {
            e.target.value = 0
        } else {
            setCameraList((prevState: any) => {
                const customState = [...prevState]
                customState[i].shelf_id = e.target.value
                return customState
            })
        }
    }

    //camera validation in onchange event
    const onchangeCameraValidation = (e: any, i: number) => {
        if (e.target.value === "") {
            setCameraList((prevState: any) => {
                const customState = [...prevState]
                customState[i].camError = "Please enter the valid camera url"
                return customState
            })
        }
        else {
            setCameraList((prevState: any) => {
                const customState = [...prevState]
                customState[i].camError = ""
                return customState
            })
        }
    }

    //handle camera url
    const handleCamera = (e: any, i: number) => {
        onchangeCameraValidation(e, i)
        if (e.target.value == -1) {
            e.target.value = 0
        } else {
            setCameraList((prevState: any) => {
                const customState = [...prevState]
                customState[i].connection_string = e.target.value
                return customState
            })
        }
    }

    //Branch filter in on change
    const handleBranchChange = (event: any, flag: boolean) => {
        const storeObj = result?.find((o: any) => o.id == event?.value)
        setCurrentStoreObj(storeObj)
        let url = location?.pathname
        let word = url.split('/')
        const callback = (response: any) => {
            setCurrentPage(1)
            setLoading(false)
            if (response?.status_code !== 0) {
                // toast.error("Something went worng")
            }
        }
        if (flag) {
            branch_Id = event.value;
            let params = {
                branchId: branch_Id,
                limit: limit,
                pagenum: 1,
                vendorId: word[3],
                storeId: storeObj?.store
            }
            setLoading(true)
            dispatch(Actions.cabinet_search(params, callback));
        } else {
            setFormDetails({ ...formDetails, 'branchId': event })
        }

    }


    const handleServerChange = (event: any) => {
        setFormDetails({ ...formDetails, ['edge_server_id']: event.target.value })
        // if (event.target.value) {
        //     let mac = edgeServerDetails.find((obj: any) => obj?.id == event.target.value)?.mac_address
        //     setMacAddress(mac)
        // }

    }

    //For handle the pagination
    const onChangeHandle = (page: number) => {
        setCurrentPage(page)
        let params = {
            branchId: branch_Id,
            limit: limit,
            pagenum: page,
        }
        dispatch(Actions.cabinet_search(params));
    }

    const EditCabinet = (obj: any) => {
        setCabinetObj(obj)
        const branch = { label: `${obj?.branch?.name.toLowerCase()}`, value: obj?.branch?.id }
        setMacAddress(obj?.mac_address)
        let prevObj = formDetails
        prevObj["name"] = obj?.name
        prevObj["branchId"] = branch
        prevObj["edge_server_id"] = obj?.edge_server
        prevObj["cabinet_position"] = obj?.cabinet_position
        const newArray = obj?.shelves?.map((camObj: any) => ({
            shelf_id: camObj?.name,
            connection_string: camObj?.camera?.connection_string,
            id: camObj?.id
        }))
        setLoading(false)
        setCameraList(newArray)
        setFormDetails(prevObj)
        setPlanogram(obj?.planogram)
        setIsShowpopup(true)
    }

    //Closr pop event set initial state
    const closePopUp = () => {
        setCameraList(initialState)
        setError("")
        setFormDetails({})
        setPlanogram(false)
        setIsShowpopup(false)
        setCabinetObj('')
        setMacAddress('')
    }

    const closePlanogramPopup = () => {
        setShowPlanogram(false)
        setCheckStatus({ status: false, shelf: [] })
        setIsAccordionOpen({ status: false })
        getCabinetList()
        dispatch(Actions.fetchFrame([]))
        setPlanogramPopup(false)
        setPlanogramList([])
        setPopupError('')
        setPopupDetails('')
        dispatch(Actions.removeAnnotationPlanogram())
        setCheckPlanogram(true)
        setPlanogramName('')

    }
    const ConfigurePlanogram = (branObj: any, index: any) => {
        setPlanogramProdObj([])
        const callback = (response: any) => {
            setLoading(false)
            if (response?.status_code !== 0) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            }
            else {
                let productList = response?.response?.map((product: any, index: number) => {
                    let a = {
                        key: index,
                        value: product?.id,
                        text: product?.name,
                    }
                    return a
                })
                setDropDownProduct(productList)
                setProductsList(response?.response)
                setPlanogramIndex(index)
            }
        }
        if (branObj?.planogram_detail) {
            setPlanogramList(branObj?.shelves)
            setPlanogramId(branObj?.planogram_detail?.id)
        }
        setPlanogramPopup(true)
        setPlanogramPopFields(branObj)
        setMasterPlanogram(branObj?.planogram_detail?.is_master)
        setPopupDetails(branObj?.planogram_detail?.name)
        setPlanogramName(branObj?.planogram_detail?.name)
        setShowButton(branObj?.planogram_detail === null)
        dispatch(Actions.trainedProduct('', callback))
        if (branObj?.planogram_detail?.hasOwnProperty('planogram_shelfs')) {
            dispatch(Actions.fetchFrame(branObj?.planogram_detail?.planogram_shelfs))
            let shelf = branObj?.planogram_detail?.planogram_shelfs.map((obj: any) => obj?.shelf)
            setCheckStatus({ status: branObj?.planogram_detail?.hasOwnProperty('planogram_shelfs'), shelf: shelf })
        }
    }

    const CreatePlanogram = () => {
        const callback = (response: any) => {
            setLoading(false)
            if (response?.status_code !== 0) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            } else {
                setPlanogramList(PlanogramPopFields?.shelves)
                if (!checkPlanogram) {
                    setCheckPlanogram(true)
                }
                const callback = (response: any) => {
                    if (response?.statuscode !== 0) {
                        toast.error(MESSAGE.SOMETHING_WENT_WORNG)
                    } else {
                        setPlanogramId(response?.response?.result[planogramIndex]?.planogram_detail?.id)
                    }
                }
                let params = {
                    branchId: '',
                    limit: 6,
                    pagenum: currentPage,
                }
                dispatch(Actions.cabinet_search(params, callback));
            }
        }
        if (!popupDetails || !validateminLengthRegExp(popupDetails)) {
            setPopupError("Please enter the valid planogram name")
        } else {
            let param: any = {
                name: popupDetails,
                cabinet_id: PlanogramPopFields?.id,
                is_master: masterPlanogram
            }
            setLoading(true)
            dispatch(Actions.createPlanogram(param, callback))
        }
    }
    const openShelf = (obj: any, index: any, flag?: any) => {
        setShowPlanogram(true)
        let params = {
            cabinet_id: PlanogramPopFields?.id,
            planogram_id: PlanogramPopFields?.planogram_detail?.id || planogramId,
            shelf_id: obj?.id,
            shelf_name: obj?.name
        }
        setShelfParams(params)
        getCabinetList()
        if (!flag) {
            const myGreeting = () => {
                let classData = document.querySelector(`#collapse${index}`)?.classList
                setIsAccordionOpen({ status: classData?.contains("show"), index: index })
            }
            setTimeout(myGreeting, 2500);
        }
    }

    const appendImageURL = (obj: any) => {
        Object.keys(obj).forEach((item) => {
            if (item === "image_url") {
                let a = obj
                a[item] = obj?.image_url + '?$' + Math.random()
            }
        })
    }

    const handleFetchNewFrame = (obj: any) => {
        let params = {
            cabinet_id: obj?.cabinet,
            planogram_id: planogramId || obj?.planogram_shelf?.planogram,
            shelf_id: obj?.id,
        }
        const callback = (response: any) => {
            setLoading(false)
            if (response?.status_code !== 0) {
                toast.error(response?.message)
            } else {
                setPlanogramShelfId(response?.response?.id)
                let frame = framesList
                if (framesList?.length === 0) {
                    appendImageURL(response?.response)
                    frame.push(response?.response)

                }
                else {
                    appendImageURL(response?.response)
                    frame.splice(frame.findIndex((e: any) => e.id === response?.response?.id) !== -1 ? frame.findIndex((e: any) => e.id === response?.response?.id) : frame?.length, 1, response?.response);
                }
                // dispatch(Actions.fetchFrame(frame))
            }
        }
        setLoading(true)
        dispatch(Actions.captureShelf(params, callback))
    }
    const getImage = (url: any) => {
        let image = END_POINTS.API_BASE_URL + url
        return (image)
    }

    const activatePlanogram = () => {
        const callback = (response: any) => {
            setLoading(false)
            if (response === 500) {
                toast.error(MESSAGE.SOMETHING_WENT_WORNG)
            } else if (response?.status_code !== 0) {
                toast.error(response?.message)
            } else {
                toast.success(MESSAGE.ACTIVATED_SUCCESSFULLY)
                closePlanogramPopup()

            }
        }
        let params: any = {
            cabinet_id: PlanogramPopFields?.id,
            planogram_id: PlanogramPopFields?.planogram_detail?.id || planogramId,
            is_published: true
        }
        setLoading(true)
        dispatch(Actions.activatePlanogram(params, callback))
    }

    const { result = [] } = branchDetails || {}
    const { totalPages } = cabinetDetails || {}

    const cabinetPosition = (e: any, value: any) => {
        setFormDetails({ ...formDetails, [e.target.name]: value })
    }
    const getMacAddressData = (e: any) => {
        if (e.target.value === '') {
            setError({ ...error, macAddress: "Please enter the valid mac address" })
        } else {
            setError({ ...error, macAddress: "" })

        }
        setMacAddress(e.target.value)
    }

    const intiallCall = () => {
        const { role } = loginDetails

        let params: any = {
            pagenum: currentPage,
            limit: 10
        }
        if (role === 1) {
            dispatch(Actions.edgeServerList(params, ''))
        } else {
            dispatch(Actions.vendorEdgeServerList(params, ''))
        }
    }
    const CreateCabinet = () => {
        const { role, user_id } = loginDetails
        const convertEdgeArray = (role == 1 ? edgeServerDetails?.edgeServer?.result : edgeServerVendorList)?.map((obj: any) => ({ value: obj.id, ...obj }))
        return (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="font-bold">Add Cabinet</h3>
                    </div>
                </div>
                <div className='card card-body rounded-3 border-0 shadow p-4 mb-3  mt-4'>
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            <BranchTypeAhead
                                isLoading={false}
                                placeholder={"Choose Branch"}
                                options={props?.options ? props?.options : options}
                                setOptions={setOptions}
                                vendorId={role == 1 ? word[3] : user_id}
                                onChange={(event: any) => handleBranchChange(event, false)}
                                value={formDetails?.branchId}
                            />
                            {error?.branchId && <p className='errorMessage'>{error?.branchId}</p>}
                        </div>
                        <div className="col-sm-6">
                            <Select
                                classname={'serverSelect'}
                                placeHolder={"Choose Server"}
                                array={convertEdgeArray}
                                value={formDetails?.edge_server_id}
                                onChange={(event: any) => handleServerChange(event)}
                                error={error?.edge_server_id}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <InputField
                            inputType={"text"}
                            label="Cabinet Name"
                            placeholder="Cabinet Name"
                            columnSize={6}
                            name="name"
                            value={formDetails?.name}
                            error={error?.name}
                            onChange={handleChange} />
                        <InputField
                            inputType={"text"}
                            label="Locker Mac Address"
                            placeholder="Locker Mac Address"
                            columnSize={6}
                            name="macAddress"
                            // disabled={true}
                            value={macAddress}
                            error={error?.macAddress}
                            onChange={getMacAddressData} />
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            Planogram
                            <CheckBoxMolecules
                                checked={planogram}
                                onChange={(event) => enablePlanogram(event)}
                            />
                        </div>
                        <div className="col-sm-6">
                            Cabinet Position
                            <div className="d-flex">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="cabinet_position" id="flexRadioDefault1" onChange={(e) => cabinetPosition(e, 1)} checked={formDetails?.cabinet_position == 1} />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Left
                                    </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input" type="radio" name="cabinet_position" id="flexRadioDefault2" onChange={(e) => cabinetPosition(e, 2)} checked={formDetails?.cabinet_position == 2} />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Right
                                    </label>
                                </div>
                            </div>
                            <p className="errorMessage">{error?.cabinet_position}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card cameraOnboarding">
                                <div className="card-body">
                                    <div className="row addButton">
                                        <p className="errorMessage">{error?.shelf}</p>
                                        <button className="btn btn-primary big-btn ml-2 refreshIcon"
                                            onClick={() => addCamera()}>Add shelf</button>
                                    </div>
                                    {
                                        !isloading && cameraList && cameraList?.map((item, index) =>
                                            <div className="row mt-4 cabinetContainer" >
                                                <div className="col ">
                                                    <CabinetInput index={index}
                                                        Value={item?.shelf_id}
                                                        name={'cameraId'}
                                                        InputType="text"
                                                        InputClassName={"cameraInput"}
                                                        LabelName={MESSAGE.SHELF_NAME}
                                                        Placeholder={MESSAGE.ENTER_SHELF_ID}
                                                        handleInput={(e) => handleShelf(e, index)}
                                                        shelfError={item?.shelfError}

                                                    />
                                                </div>
                                                <div className={index !== 0 ? "col ml-1" : "col-md-7"}>
                                                    <CabinetInput index={index}
                                                        Value={item?.connection_string}
                                                        name={'configurationId'}
                                                        InputType="text"
                                                        InputClassName={"cameraInput"}
                                                        LabelName={MESSAGE.CAMERA_ID}
                                                        Placeholder={MESSAGE.ENTER_CAMERA_ID}
                                                        handleInput={(e) => handleCamera(e, index)}
                                                        cameraError={item?.camError}
                                                    />
                                                </div>
                                                {
                                                    index !== 0 ?
                                                        <i className="fa-solid fa-trash mt-2 fa-lg flexChild cursor-pointer"
                                                            onClick={(e) => deleteCamera(e, index)}></i>
                                                        : ""
                                                }
                                            </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2 mt-4'>
                        <div className='col-sm-6'></div>
                        <div className='col-sm-6 text-end'>
                            <button className="btn btn-secondary btn-outline" onClick={() => closePopUp()}>Cancel</button>
                            <button
                                className="btn btn-primary big-btn ml-2"
                                disabled={result?.length === 0}
                                onClick={() => manage()}>{cabinetObj?.id ? "Update" : "Create"}</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const onMasterActive = (event: any) => {
        setMasterPlanogram(event.target.checked)
    }

    const CancelPlanogram = () => {
        setPopupDetails(planogramName)
        setCheckPlanogram(true)
    }
    const onPlanogramChange = (obj?: any) => {
        setPlanogramProdObj([...obj])
    }

    const branchListCallback = (res: any) => {
        let data = [];
        if (res?.response) {
            data = res?.response?.result?.map((o: any) =>
            (
                {
                    label: `${o?.name}`, value: o?.id,
                }
            )
            );
        }
        setOptions(data)
    }
    const getCabinetUnit = (array?: any) => {
        const sumWithInitial = array?.reduce((accumulator: any, currentValue: any) => accumulator + currentValue?.count, 0);
        return <>
            <p className="font-bold mt-3"><b>Current Stock : Total Units ({sumWithInitial}) </b></p>
            {array?.length > 0 && <div className="current_product">
                {
                    array?.map((proObj: any) => {
                        return (
                            <div className="card">
                                <div className="current_product_circle m-0">{proObj?.count}</div>
                                <img src={END_POINTS.IMG_URL + proObj?.product?.product_img} className="current_product_img" />
                            </div>
                        )
                    })
                }

            </div>}

        </>
    }
    const Planogram = () => {
        return (
            <>
                <div className="row m-4">
                    <div className="row mt-1">
                        <div className="col-4 mt-1 d-flex">
                            <p className="mt-1 pr-2">
                                Cabinet Name:
                            </p>
                            <Input inputType={"text"} inputClassName="inputCabinet" id="floatingInput"
                                placeholder={"Cabinet Name"}
                                inputname={"cabinet_name"}
                                handleChange={popupHandleChange}
                                value={PlanogramPopFields?.name} />
                        </div>
                        <div className="col-4  mt-1">
                            <div className="d-flex">
                                <p className="mt-1 pr-2">
                                    Planogram Name:
                                </p>
                                <Input inputType={"text"} inputClassName="inputPlanogram" id="floatingInput"
                                    placeholder={"Planogram Name"}
                                    inputname={"planogram_name"}
                                    handleChange={popupHandleChange}
                                    disable={checkPlanogram && planogramList?.length !== 0}
                                    value={popupDetails} />
                            </div>
                            {popupError && <p className='errorMessage'>{popupError}</p>}
                        </div>

                        <div className="col-4 d-flex">
                            <p className="pr-2 mt-2">
                                Master Planogram:
                            </p>
                            <CheckBoxMolecules onChange={(e) => onMasterActive(e)} checked={masterPlanogram} classname={"planogramToggle form-check-input cursor-pointer"} />
                            {
                                (planogramList?.length === 0) ? <button className="btn btn-primary big-btn createPlanogram ml-3" onClick={CreatePlanogram}>{MESSAGE.CREATE}</button> :
                                    checkPlanogram ? <button className="btn btn-primary big-btn createPlanogram ml-3" onClick={() => setCheckPlanogram(false)}>{MESSAGE.EDIT}</button> :
                                        <div>
                                            <button className="btn btn-primary big-btn createPlanogram ml-3" onClick={CreatePlanogram}>{MESSAGE?.UPDATE}</button>
                                            <button className="btn btn-primary big-btn createPlanogram ml-3" onClick={() => CancelPlanogram()}>{MESSAGE?.CANCEL}</button>
                                        </div>
                            }
                        </div>

                    </div>
                </div>
                {
                    planogramList?.length > 0 &&
                    <div className="card planogramPopCard">
                        <div className="planogram_container">
                            <div className="sessionContainer planogram_width">
                                <div className="accordion mainaccordion" id="accordionExample" >
                                    {
                                        planogramList?.length > 0 && planogramList?.map((obj: any, index: number) => {
                                            return (
                                                <div className="accordion-item sessionAccordion  planogramCardBody" key={index}>
                                                    <div className="card planogramAccordian" id="flush-headingOne">
                                                        <div className="card-body mt-3">
                                                            <div className="maincontainer">
                                                                <div className="row">
                                                                    <div className="col-sm-6 fieldContainer">
                                                                        <div>
                                                                            <span className=" fw-bold mr-2"># {obj?.name}</span>

                                                                        </div>
                                                                        <div className="ml-3">
                                                                            <span className="fw-bold ml-2"><i className="fas fa-camera"></i> {obj?.camera?.connection_string}</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        {/* {
                                                                            checkStatus?.status === true && checkStatus?.shelf[index] === obj?.id ?
                                                                               <span onClick={() => openShelf(obj, index, true)} className="planogramComplete">
                                                                                 <i className="fa-solid fa-2x  fa fa-arrow-down"
                                                                                    aria-controls={`collapse${index}`} aria-expanded={true}
                                                                                    data-bs-toggle={"collapse"}
                                                                                    data-bs-target={`#collapse${index}`}
                                                                                    ></i>
                                                                               </span> : */}
                                                                        <Button buttonClassName={"btn btn-outline btn-secondary viewCabinet"}
                                                                            disable={false} handleClick={() => openShelf(obj, index, false)}
                                                                            controls={`collapse${index}`} expanded={true}
                                                                            buttonName={(isAccordionOpen?.status && isAccordionOpen?.index == index) ? MESSAGE.CLOSE : MESSAGE.OPEN}
                                                                            toggle={"collapse"} target={`#collapse${index}`} />
                                                                        {/* } */}
                                                                    </div>
                                                                    {obj?.inventory?.length > 0 && <div className="mt-1">
                                                                        <span className="fw-bold ml-2">Current Stock : </span><span>{obj?.inventory?.map((obj: any) => `${obj?.product?.name} (${obj?.count})`)?.join(', ')}</span>
                                                                    </div>}

                                                                    {obj?.inventory?.length > 0 && <div className="card-body alert-danger mt-1">
                                                                        <span className="fw-bold ml-2" style={{ color: 'red' }}><i className="fas fa-exclamation-triangle"></i> Misplaced Products: </span><span>{obj?.misplacement?.map((obj: any) => `${obj?.product?.name} (${obj?.count})`)?.join(', ')}</span>
                                                                    </div>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id={`collapse${index}`} className="accordion-collapse collapse  cabinetContainer" aria-labelledby={`headingOne${index}`} data-bs-parent="#accordionExample">
                                                        <div className="container">
                                                            <PlanogramImageWidget
                                                                productListArray={productListArray}
                                                                index={index}
                                                                planogramObject={obj}
                                                                closePlanogramPopup={closePlanogramPopup}
                                                                planogramId={obj?.planogram_shelf !== null ? obj?.planogram_shelf?.planogram : ''}
                                                                planogramShelfId={planogramShelfId}
                                                                setIsAccordionOpen={setIsAccordionOpen}
                                                                PlanogramPopFields={PlanogramPopFields}
                                                                checkStatus={checkStatus}
                                                                setCheckStatus={setCheckStatus}
                                                                imageURL={getImage(framesList[index]?.image_url)}
                                                                handleFetchNewFrame={handleFetchNewFrame}
                                                                dropDownProduct={dropDownProduct}
                                                                shelfparams={shelfParams}
                                                                length={planogramList?.length}
                                                                planogramList={planogramList}
                                                                next={index + 1}
                                                                previous={index - 1}
                                                                cameraId={'1'}
                                                                cabinetData={''}
                                                                setImageSize={setImageSize}
                                                                imageSize={imageSize}
                                                                onPlanogramChange={(planoObj: any) => onPlanogramChange(planoObj)}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {
                                showPlanogram &&
                                <div className="planogram_3d_container">
                                    <Planogram3DWidget
                                        // cabinet={planogramList}
                                        annotateArray={planogramProdObj}
                                    // products={productListArray}
                                    />
                                    <div className="publishButton mt-auto">
                                        <button disabled={checkStatus?.shelf.length !== planogramList?.length} className={"btn btn-primary big-btn clearButton m-3"} onClick={activatePlanogram}>{MESSAGE.ACTIVE}</button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                }
            </>
        )
    }
    const { role, user_id } = loginDetails
    let url = location?.pathname
    let word = url.split('/')
    return (
        <>
            {
                isloading && <PlanogramLoader />
            }
            <div className="container mt-4">
                <div className="row">
                    <div className={"col-sm-6"}>
                        <h3 className="font-bold">Cabinets</h3>
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm-6 text-start">
                                <BranchTypeAhead
                                    isLoading={false}
                                    placeholder={"Choose Branch"}
                                    options={props?.options ? props?.options : options}
                                    setOptions={setOptions}
                                    vendorId={role == 1 ? word[3] : user_id}
                                    onChange={(event: any) => handleBranchChange(event, true)}
                                />
                            </div>
                            <div className="col-sm-6">
                                <button className="btn btn-primary big-btn" style={{ width: '100%', height: '42px' }} onClick={() => ManageCabinet()}>Add New Cabinet</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    {
                        cabinetDetails?.result?.map((branObj: any, index: number) => {
                            let currentStock = branObj?.current_stock ? Object.values(branObj?.current_stock) : []
                            return (
                                <div className="col-sm-6">
                                    <div className="card card-body rounded-3 p-4 mb-3  mt-4 shadow">
                                        <div className="row">
                                            <div className="col-sm-9">
                                                <p className="font-bold "># {branObj.name}</p>
                                            </div>
                                            <div className="col-sm-3 text-end cursor-pointer d-flex" >
                                                <CheckBoxMolecules onChange={(e) => onActive(e, branObj)} checked={branObj?.is_active} />
                                                <span className="fa fa-edit mt-1" onClick={() => EditCabinet(branObj)}></span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <p className="mt-2"><b>Branch Name:</b> {branObj?.branch?.name}</p>
                                                <p className="mt-2"><b>No of Shelf:</b> {branObj?.shelves?.length}</p>
                                                <p><b>Locker Mac Address:</b> {branObj?.mac_address}</p>
                                                <p><b>Edge Server:</b> {branObj?.edge_server}</p>
                                            </div>
                                            <div className="col-sm-6 mt-2">
                                                {
                                                    branObj?.misplaced?.length > 0 && <div className="card mt-2">
                                                        <div className="card-body alert-danger ">
                                                            <p className="text-center" style={{ color: 'red' }}><i className="fas fa-exclamation-triangle"></i> Misplaced Products</p>

                                                            <span>{branObj?.misplaced?.map((obj: any) => `${obj?.product?.name} (${obj?.count})`)?.join(', ')}</span>

                                                            {/* {branObj?.misplaced?.map((misObj: any) => <p>{misObj?.product?.name} ({misObj?.count})</p>)} */}
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        {getCabinetUnit(currentStock)}
                                        {!branObj?.planogram && <p style={{ color: 'red' }}><b>Planogram:</b> Not available</p>}
                                        {
                                            branObj?.planogram && branObj?.is_active &&
                                            <button className="btn btn-primary big-btn ml-2" disabled={!branObj?.is_active}
                                                onClick={() => ConfigurePlanogram(branObj, index)}>
                                                {(branObj?.planogram_detail?.hasOwnProperty('planogram_shelfs') && branObj?.planogram_detail?.planogram_shelfs.length > 0) ? branObj?.is_active ? MESSAGE.EDIT_PLANOGRAM : MESSAGE.DISABLE_PLANOGRAM
                                                    : !branObj?.is_active ? MESSAGE.DISABLE_PLANOGRAM : MESSAGE.CREATE_PLANOGRAM}
                                            </button>
                                        }

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    (cabinetDetails?.result && cabinetDetails?.result?.length === 0) && <DataNotFound Error={"No cabinet found"} />
                }
            </div>

            {isShowpopup && <PopupData
                checkPlanogram={false}
                close={() => closePopUp()}
                content={CreateCabinet()}
            />}
            {
                cabinetDetails?.totalPages > 1 &&
                <PaginationSession
                    onChangeHandle={onChangeHandle}
                    currentPage={currentPage}
                    sessionCount={totalPages} />
            }
            {planogramPopup &&
                <PopupData
                    checkPlanogram={true}
                    close={() => closePlanogramPopup()}
                    content={Planogram()}
                />}

        </>
    )
}
