import { useState } from 'react';
import { PlanogramImageWidget } from './PlanogramImageWidget';
export const PlanogramPopup = ({ shelfDetails, close }: { shelfDetails: any, close: (a: boolean) => void }) => {
    const [imageSize, setImageSize] = useState({
        width: 0,
        height: 0
    });
    return (
        <>
            <div>
                <div className="modal d-block" id="loader" aria-labelledby="loader" aria-hidden="true">
                    <div className=" modal-dialog-centered" style={{ width: "900px", height: "auto" }}>
                        <div className="modal-content p-4" style={{ left: "25%" }}>
                            <i className="fa fa-times fa-2x close" aria-hidden="true" onClick={() => close(false)} ></i>
                            <div className="text-centers">
                                {/* <PlanogramImageWidget cameraId={shelfDetails?.shelf_id} cabinetData={shelfDetails?.cabinet} setImageSize={setImageSize} imageSize={imageSize} /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show loaderBackGround"></div>
            </div>
        </>
    )
}