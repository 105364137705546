import { doAction, doResponseAction } from ".";

const UNREGISTER_PRODUCT = 'UNREGISTER_PRODUCT';
const UNREGISTER_PRODUCT_SUCCESS = 'UNREGISTER_PRODUCT_SUCCESS';
const UNREGISTER_PRODUCT_FAILURE = 'UNREGISTER_PRODUCT_FAILURE';
export {
    UNREGISTER_PRODUCT,
    UNREGISTER_PRODUCT_FAILURE,
    UNREGISTER_PRODUCT_SUCCESS,
}
// Unregister Product
export const unregisterProduct = (params: {}, callback?: any) => doAction(UNREGISTER_PRODUCT, params, callback);
export const unregisterProductSuccess = (data: {}) => doResponseAction(UNREGISTER_PRODUCT_SUCCESS, data);
export const unregisterProductFailure = (data: {}) => doResponseAction(UNREGISTER_PRODUCT_FAILURE, data);