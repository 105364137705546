import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet, doPost } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import END_POINTS from '../../config/Endpoints';
import { CABINET_DELETE, CABINET_ONBOARDING, CABINET_ONBOARDING_EDIT, CABINET_UPDATE } from '../action/CabinetOnboarding';
export function* CabinetOnboardingSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CABINET_ONBOARDING, params, '')
        yield put(Actions.cabinetOnboardingSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.cabinetOnboardingFailure({ error }));
        callback && callback({ error })
    }
}

export function* CabinetOnboardingEditSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(params, '', '')
        yield put(Actions.cabinetOnboardingEditSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.cabinetOnboardingEditFailure({ error }));
        callback && callback({ error })
    }
}

export function* CabinetDeleteSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.DELETE_CABINET, params, '')
        yield put(Actions.cabinetDeleteSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.cabinetDeleteFailure({ error }));
        callback && callback({ error })
    }
}

export function* CabinetUpdateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.UPDATE_CABINET, params, '')
        yield put(Actions.cabinetUpdateSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.cabinetUpdateFailure({ error }));
        callback && callback({ error })
    }
}
export default function* cabinetOnboardingWatcher() {
    yield all([
        takeLatest(CABINET_ONBOARDING, CabinetOnboardingSaga),
        takeLatest(CABINET_ONBOARDING_EDIT, CabinetOnboardingEditSaga),
        takeLatest(CABINET_DELETE, CabinetDeleteSaga),
        takeLatest(CABINET_UPDATE, CabinetUpdateSaga),
    ]);
}
