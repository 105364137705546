import { takeLatest, all, put } from 'redux-saga/effects';
import { doGetMultiform } from '../../config/index';
import { Actions } from '../action';
import { ResponseGenerator } from './SagaInterface';

export function* unregisterProductSaga(param: any) {
    const { callback, params } = param
    try {
        const response: ResponseGenerator = yield doGetMultiform(params, '', '')
        callback && callback(response?.response)
        yield put(Actions.unregisterProductSuccess(response));

    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.unregisterProductFailure({ error }));
        }
    }
}
export default function* productListWatcher() {
    yield all([
        takeLatest(Actions.UNREGISTER_PRODUCT, unregisterProductSaga),
    ]);
}