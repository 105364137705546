import { Navigate } from "react-router-dom";
import { Layout } from "../../src/components/HOC/Widgets/Layout"
import { store } from "../../src/createStore";

const PrivateRoutes = () => {
    const userDetails = store?.getState()?.USER
    const { user } = userDetails || {}
    const { token } = user || {}

    return (
        token ? <Layout /> : <Navigate to='/' />
    )
}

export default PrivateRoutes