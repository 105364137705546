import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Actions } from "../../../redux/action"
import { MESSAGE } from "../../../config/String"
import { Button } from "../../UI/Atoms/Button"

export const SignOutPopup = ({ close }: { close: (a: boolean) => void }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const LogOutCallback = () => {
    //     // localStorage.clear();
    //     dispatch(Actions.initalEdgeState('', ''))
    //     dispatch(Actions.loginUserSuccess(''))
    //     close(false)
    //     navigate('/')
    // }
    const signOut = () => {
        localStorage.clear();
        close(false)
        setTimeout(() => {
            window.location.reload()
        }, 0);
        // dispatch(Actions.logoutAction('', LogOutCallback))
    }
    return (
        <>
            <div>
                <div className="modal d-block" id="loader" aria-labelledby="loader" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-4" style={{ width: "500px", height: "250px" }}>
                            <i className="fa fa-times fa-2x close" aria-hidden="true" onClick={() => close(false)} ></i>
                            <div className="modal-body text-center">
                                <p className="signOutTitle">{MESSAGE.SIGN_OUT}</p>
                                <Button buttonClassName="btn big-btn signoutSuccess" buttonName={MESSAGE.OK} handleClick={signOut} />
                                <Button buttonClassName="btn btn-secondary signoutFailure" buttonName={MESSAGE.CANCEL} handleClick={() => close(false)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show loaderBackGround"></div>
            </div>
        </>
    )
}