import { doAction, doResponseAction } from ".";

const REGISTER = 'REGISTER';
const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const REGISTER_FAILURE = 'REGISTER_FAILURE';
export {
    REGISTER,
    REGISTER_FAILURE,
    REGISTER_SUCCESS,
}
// Register the Product
export const register = <T>(params: {}, callback: T) => doAction(REGISTER, params, callback);
export const registerSuccess = (data: {}) => doResponseAction(REGISTER_SUCCESS, data);
export const registerFailure = (data: {}) => doResponseAction(REGISTER_FAILURE, data);