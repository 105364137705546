import { doAction, doResponseAction } from ".";

const CATEGORY_LIST = 'CATEGORY_LIST';
const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
const CATEGORY_LIST_FAILURE = 'CATEGORY_LIST_FAILURE';

const CATEGORY_CREATE = 'CATEGORY_CREATE';
const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS';
const CATEGORY_CREATE_FAILURE = 'CATEGORY_CREATE_FAILURE';

const CATEGORY_UPDATE = 'CATEGORY_UPDATE';
const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
const CATEGORY_UPDATE_FAILURE = 'CATEGORY_UPDATE_FAILURE';

const SUB_CATEGORY_LIST = 'SUB_CATEGORY_LIST';
const SUB_CATEGORY_LIST_SUCCESS = 'SUB_CATEGORY_LIST_SUCCESS';
const SUB_CATEGORY_LIST_FAILURE = 'SUB_CATEGORY_LIST_FAILURE';

const CREATE_SUB_CATEGORY = 'CREATE_SUB_CATEGORY';
const CREATE_SUB_CATEGORY_SUCCESS = 'CREATE_SUB_CATEGORY_SUCCESS';
const CREATE_SUB_CATEGORY_FAILURE = 'CREATE_SUB_CATEGORY_FAILURE';

const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
const UPDATE_SUB_CATEGORY_SUCCESS = 'UPDATE_SUB_CATEGORY_SUCCESS';
const UPDATE_SUB_CATEGORY_FAILURE = 'UPDATE_SUB_CATEGORY_FAILURE';
export {
    CATEGORY_LIST,
    CATEGORY_LIST_FAILURE,
    CATEGORY_LIST_SUCCESS,

    CATEGORY_CREATE,
    CATEGORY_CREATE_FAILURE,
    CATEGORY_CREATE_SUCCESS,

    CATEGORY_UPDATE,
    CATEGORY_UPDATE_FAILURE,
    CATEGORY_UPDATE_SUCCESS,
    

    SUB_CATEGORY_LIST,
    SUB_CATEGORY_LIST_FAILURE,
    SUB_CATEGORY_LIST_SUCCESS,

    CREATE_SUB_CATEGORY,
    CREATE_SUB_CATEGORY_SUCCESS,
    CREATE_SUB_CATEGORY_FAILURE,

    UPDATE_SUB_CATEGORY,
    UPDATE_SUB_CATEGORY_SUCCESS,
    UPDATE_SUB_CATEGORY_FAILURE
}
//categotyList
export const categotyList = (params?: {}, callback?: any) => doAction(CATEGORY_LIST, params, callback);
export const categotyListSuccess = (data = {}) => doResponseAction(CATEGORY_LIST_SUCCESS, data);
export const categotyListFailure = (data = {}) => doResponseAction(CATEGORY_LIST_FAILURE, data);
//createCategoryList
export const createCategory = (params?: {}, callback?: any) => doAction(CATEGORY_CREATE, params, callback);
export const createCategorySuccess = (data = {}) => doResponseAction(CATEGORY_CREATE_SUCCESS, data);
export const createCategoryFailure = (data = {}) => doResponseAction(CATEGORY_CREATE_FAILURE, data);
//subCategory
export const subCategotyList = (params?: {}, callback?: any) => doAction(SUB_CATEGORY_LIST, params, callback);
export const subCategotyListSuccess = (data = {}) => doResponseAction(SUB_CATEGORY_LIST_SUCCESS, data);
export const subCategotyListFailure = (data = {}) => doResponseAction(SUB_CATEGORY_LIST_FAILURE, data);

//createSubCategory
export const createSubCategory = (params?: {}, callback?: any) => doAction(CREATE_SUB_CATEGORY, params, callback);
export const createSubCategorySuccess = (data = {}) => doResponseAction(CREATE_SUB_CATEGORY_SUCCESS, data);
export const createSubCategoryFailure = (data = {}) => doResponseAction(CREATE_SUB_CATEGORY_FAILURE, data);

//updateSubCategory
export const updateSubCategory = (params?: {}, callback?: any) => doAction(UPDATE_SUB_CATEGORY, params, callback);
export const updateSubCategorySuccess = (data = {}) => doResponseAction(UPDATE_SUB_CATEGORY_SUCCESS, data);
export const updateSubCategoryFailure = (data = {}) => doResponseAction(UPDATE_SUB_CATEGORY_FAILURE, data);

//updateCAtegory
export const updateCategory = (params?: {}, callback?: any) => doAction(CATEGORY_UPDATE, params, callback);
export const updateCategorySuccess = (data = {}) => doResponseAction(CATEGORY_UPDATE_SUCCESS, data);
export const updateCategoryFailure = (data = {}) => doResponseAction(CATEGORY_UPDATE_FAILURE, data);