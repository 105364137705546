import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    vendorList: [],
    vendorDetails: {},
    createVendor: [],
    deleteVendor: [],
    updateVendor: [],
    activeVendor: [],
    error: false,
    currentVendorDetails: {}
}
export const VendorReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        //VENDOR_ONBOARDING_LIST
        case Actions.VENDOR_ONBOARDING_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_ONBOARDING_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorList: action.data,
                error: false
            };
        }
        case Actions.VENDOR_ONBOARDING_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorList: [],
                error: action.error,
            };
        }
        //VENDOR_ONBOARDING_DETAILS
        case Actions.VENDOR_ONBOARDING_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_ONBOARDING_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorDetails: action.data,
                error: false
            };
        }
        case Actions.VENDOR_ONBOARDING_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorDetails: {},
                error: action.error,
            };
        }
        //CREATE_VENDOT
        case Actions.CREATE_VENDOR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case Actions.CREATE_VENDOR_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                createVendor: action.data,
                error: false
            }
        }
        case Actions.CREATE_VENDOR_FAILURE: {
            return {
                ...state,
                isLoading: false,
                createVendor: [],
                error: action.error
            }
        }
        // VENDOT_ONBOARDING_DELETE
        case Actions.VENDOT_ONBOARDING_DELETE: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case Actions.VENDOT_ONBOARDING_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                deleteVendor: action.data,
                error: false
            }
        }
        case Actions.VENDOT_ONBOARDING_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteVendor: [],
                error: action.error
            }
        }
        // VENDOR_ONBOARDING_UPDATE
        case Actions.VENDOR_ONBOARDING_UPDATE: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case Actions.VENDOR_ONBOARDING_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                updateVendor: action.data,
                error: false
            }
        }
        case Actions.VENDOR_ONBOARDING_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                updateVendor: [],
                error: action.error
            }
        }
        // VENDOR_ONBOARDING_ACTIVE
        case Actions.VENDOR_ONBOARDING_ACTIVE: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case Actions.VENDOR_ONBOARDING_ACTIVE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                activeVendor: action.data,
                error: false
            }
        }
        case Actions.VENDOR_ONBOARDING_ACTIVE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                activeVendor: [],
                error: action.error
            }
        }

        case Actions.SET_CURRENT_VENDOR_DETAILS: {
            return {
                ...state,
                currentVendorDetails: action.data,
            }
        }
        default:
            return state;
    }
};
export const vendorListDetails = (state: any) => state?.VENDOR?.vendorList

export const getVendorDetails = (state: any) => state?.VENDOR?.currentVendorDetails