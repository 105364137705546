import { doAction, doResponseAction } from ".";

const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export {
    RESET_PASSWORD,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
}

// RESET_PASSWORD
export const resetPassword = (params?: {}, callback?: any) => doAction(RESET_PASSWORD, params, callback);
export const resetPasswordSuccess = (data = {}) => doResponseAction(RESET_PASSWORD_SUCCESS, data);
export const resetPasswordFailure = (data = {}) => doResponseAction(RESET_PASSWORD_FAILURE, data);
