import { MESSAGE } from "../config/String";

type ValidationType = {

}


export const isValidEmail = (email: any) => {
    const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
    return validEmailRegex.test(email);
}

export const isFieldEmpty = (field: any) => {
    return (!field || field?.trim().length === 0)
}
export const isSelectEmpty = (field: any) => {
    return (Object.keys(field)?.length === 0)
}

export const skuRegex = /^[A-Z]{3}-[0-9]{5}$/;
export const uppercaseRegExp = /(?=.*?[A-Z])/;
export const lowercaseRegExp = /(?=.*?[a-z])/;
export const digitsRegExp = /(?=.*?\d)/;
export const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
export const minLengthRegExp = /.{8,}/;
export const whiteSpaceValidation = (str: any) => {
    const validEmailRegex = RegExp('^[a-zA-Z0-9_]*$')
    return validEmailRegex.test(str);
}

export const validateName = (name: any) => {
    let re = /^[a-zA-Z ]{3,30}$/;
    return re.test(String(name).toLowerCase());
}
export const validateMaxName = (name: any) => {
    let re = /^[a-zA-Z ]{4,150}$/;
    return re.test(String(name).toLowerCase());
}
export const validateMaxProduct = (name: any) => {
    let re = /^[a-zA-Z ]{4,50}$/;
    return re.test(String(name).toLowerCase());
}
export const validateSku = (name: any) => {
    let re = /^[a-zA-Z0-9\s,.'-]{4,50}$/;
    return re.test(name);
}
export const validateMaxAddress = (name: any) => {
    let re = /^[a-zA-Z0-9\s,.'-]{4,200}$/;
    return re.test(name);
}
export const validateMobileNumnber = (number: any) => {
    let re = /^(\+91[\-\s]?)?[0]?(91)?[789654321]\d{9}$/;
    return re.test(number);
}
export const validateuppercaseRegExp = (name: any) => {
    let re = /(?=.*?[A-Z])/;
    return re.test(String(name));
}
export const validatelowercaseRegExp = (name: any) => {
    let re = /(?=.*?[a-z])/;
    return re.test(String(name));
}
export const validatedigitsRegExp = (name: any) => {
    let re = /(?=.*?\d)/;
    return re.test(name);
}
export const validatespecialCharRegExp = (name: any) => {
    let re = /(?=.*?[#?!@$%^&*-])/;
    return re.test(name);
}
export const validateminLengthRegExp = (name: any) => {
    let re = /.{5,}/;
    return re.test(name);
}
export const validateminLength = (name: any) => {
    let re = /.{5,}/;
    return re.test(name);
}
export const validateminserverLengthRegExp = (name: any) => {
    let re = /.{3,}/;
    return re.test(name);
}
export const validateminLengthReg = (name: any) => {
    let re = /.{4,}/;
    return re.test(name);
}
export const validatemaxLengthZipReg = (name: any) => {
    let re = /^.{50,}$/;
    return re.test(name);
}
export const validatemaxLength = (name: any) => {
    let re = /^.{150,}$/;
    return re.test(name);
}
export const validatemax = (name: any) => {
    let re = /^.{50,}$/;
    return re.test(name);
}
export const validatemaxLengthZipRegExp = (name: any) => {
    let re = /^.{10,}$/;
    return re.test(name);
}
export const validatemaxLengthNumeberRegExp = (name: any) => {
    let re = /^.{10,}$/;
    return re.test(name);
}
export const validateNumbers = (name: any) => {
    let re = /^([^0-9]*)$/;
    return re.test(name);
}
export const checkPercentage = (field: any) => {
    return (Math.ceil(field) > 100)
}
export const Validation = (validationObj: any, fieldArray: any) => {
    let validationError: any = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = MESSAGE.BRANCH.PLEASE_ENTER_BRANCH_NAME
                    break;
                } else if (!validateMaxName(value)) {
                    validationError[key] = MESSAGE.BRANCH.PLEASE_ENTER_VALID_BRANCH_NAME
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value)) {
                    validationError[key] = MESSAGE.BRANCH.PLEASE_ENTER_ADDRESS
                    break;
                } else if (!validateminLength(value)) {
                    validationError[key] = MESSAGE.BRANCH.PLEASE_ENTER_VALID_ADDRESS
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value)) {
                    validationError[key] = MESSAGE.BRANCH.PLEASE_ENTER_ADDRESS_2
                    break;
                } else if (!validateminLength(value)) {
                    validationError[key] = MESSAGE.BRANCH.PLEASE_ENTER_VALID_ADDRESS_2
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[3]:
                if (isFieldEmpty(value)) {
                    validationError[key] = MESSAGE.BRANCH.FIELD_CANNOT_BE_EMPTY
                    break;
                } else if (validatemaxLengthZipRegExp(value)) {
                    validationError[key] = MESSAGE.BRANCH.MAXIMUM_OF_9_CHARACTERS_ACCEPTED
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            default:
                break;
        }
    }
    return validationError;
}

export const VendorValidation = (validationObj: any, fieldArray: any) => {
    let validationError: any = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the first name"
                    break;
                } else if (!validateName(value)) {
                    validationError[key] = "Please enter the valid first name"
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the last name"
                    break;
                } else if (!validateName(value)) {
                    validationError[key] = "Please enter the valid last name"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the email id"
                    break;
                } else if (!isValidEmail(value)) {
                    validationError[key] = "Please enter the valid email id"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[3]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the mobile number"
                    break;
                } else if (!validateMobileNumnber(value)) {
                    validationError[key] = "Please enter the valid mobile number"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[4]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the address"
                    break;
                } else if (validatemaxLength(value)) {
                    validationError[key] = "Please enter the valid address"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[5]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the password"
                    break;
                } else if (!validateminLengthRegExp(value)) {
                    validationError[key] = "Please enter the valid password"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            default:
                break;
        }
    }
    return validationError;
}

export const CabinetValidation = (validationObj: any, fieldArray: any) => {
    let validationError: any = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isSelectEmpty(value)) {
                    validationError[key] = "Please choose the branch"
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (!value) {
                    validationError[key] = "Please choose any server"
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value) || !validateMaxProduct(value)) {
                    validationError[key] = "Please enter the valid cabinet name"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[3]:
                if (isFieldEmpty(value) || !validateminLengthReg(value)) {
                    validationError[key] = "Please enter the valid mac address"
                    break;
                } else if (validatemaxLengthZipReg(value)) {
                    validationError[key] = "Please enter the valid mac address"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            default:
                break;
        }
    }
    return validationError;
}

export const ProductValidation = (validationObj: any, fieldArray: any) => {
    let validationError: any = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the product name"
                    break;
                }
                else if (validatemax(value)) {
                    validationError[key] = "Please enter the valid product name"
                    break;
                }
                else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the product SKU"
                    break;
                } else if (!validateSku(value)) {
                    validationError[key] = "Please enter the valid product SKU"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[2]:
                if (!value) {
                    validationError[key] = "Please enter the title"
                    break;
                } else if (validatemax(value)) {
                    validationError[key] = "Please enter the valid title"
                    break;
                } else if (validatespecialCharRegExp(value)) {
                    validationError[key] = "Please enter the valid title"
                    break;
                }
                else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[3]:
                if (!value) {
                    validationError[key] = "Please enter the description"
                    break;
                } else if (validatemax(value)) {
                    validationError[key] = "Please enter the valid description"
                    break;
                } else if (validatespecialCharRegExp(value)) {
                    validationError[key] = "Please enter the valid description"
                    break;
                }
                else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[4]:
                if (!value) {
                    validationError[key] = "Please enter the height"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[5]:
                if (!value) {
                    validationError[key] = "Please enter the width"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[6]:
                if (!value) {
                    validationError[key] = "Please enter the breadth"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[7]:
                if (!value) {
                    validationError[key] = "Please enter the weight"
                    break
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[8]:
                if (!value) {
                    validationError[key] = "Please enter the training Id"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[9]:
                if (!value) {
                    validationError[key] = "Please enter the group Id"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[10]:
                if (!value) {
                    validationError[key] = "Please enter the price"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[11]:
                if (!value) {
                    validationError[key] = "Please enter the tax percentage"
                    break;
                } else if (checkPercentage(value)) {
                    validationError[key] = "Please enter the valid tax percentage"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            default:
                break;
        }
    }

    return validationError;

}

export const EdgeValidation = (validationObj: any, fieldArray: any) => {
    let validationError: any = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value) || !validateminserverLengthRegExp(value)) {
                    validationError[key] = "Please enter the valid tower name"
                    break;
                } else if (validatemax(value)) {
                    validationError[key] = "Please enter the tower name"
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (!value) {
                    validationError[key] = "Please choose the vendor"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the valid mac address"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[3]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the valid ip address"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            default:
                break;
        }
    }
    return validationError;
}

export const ProductIdPop = (validationObj: any, fieldArray: any) => {
    let validationError: any = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the training Id"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the valid group Id"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please select the status"
                    break;
                } else {
                    validationError[key] = ''
                    break;
                }
            default:
                break;
        }
    }
    return validationError;
}