import { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import END_POINTS from "../../../config/Endpoints";

export const ShowImage = ({ images, close }: { images: any, close: (a: boolean) => void }) => {
    const [imageDetails, setImageDetails] = useState<any>(images[0])

    let imagesvalue = images?.map((obj: any) => {
        return { url: END_POINTS.IMG_URL + obj?.url }
    })
    const image = imagesvalue
    const clicknext = (index: any) => {
        setImageDetails(images[index])
    }
    const clickSideNav=(index:any,lenght:any)=>{
        setImageDetails(images[index-1])

    }
    return (
        <>
            <div className="modal  d-block" id="loader" tabIndex={-1} aria-labelledby="loader" aria-hidden="true">
                <div className="imageSlideShow">
                    <div className="modal-content p-4">
                        <i className="fa fa-times fa-2x closeIcon" aria-hidden="true" onClick={() => close(false)} ></i>
                        <div className="modal-body imageSlideShow">
                            <div className="row ">
                                <div className="row mb-2">
                                    <div className="row">
                                        <div className="col-4 d-flex">
                                            <label className="imageLabels">Cabinet Name:<span className="imageValue">{imageDetails?.cabinet_name}</span></label>
                                        </div>
                                        <div className="col-4">
                                            <div className="d-flex">
                                                <label className="imageLabels">Shelf Name:<span className="imageValue">{imageDetails?.shelf_name}</span></label>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex">
                                            <label className="imageLabels">Image Name:<span className="imageValue">{imageDetails?.name}</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <SimpleImageSlider
                                    width={1100}
                                    height={504}
                                    images={image}
                                    showBullets={true}
                                    showNavs={true}
                                    onStartSlide={(id: any,lenght: any)=>clickSideNav(id,lenght)}
                                    onClickBullets={(idx: any) => clicknext(idx)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show loaderBackGround"></div>

        </>
    )
}