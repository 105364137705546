import { doAction, doResponseAction } from ".";

const PLANOGRAM_STATUS = 'PLANOGRAM_STATUS';
const PLANOGRAM_STATUS_SUCCESS = 'PLANOGRAM_STATUS_SUCCESS';
const PLANOGRAM_STATUS_FAILURE = 'PLANOGRAM_STATUS_FAILURE';
export {
    PLANOGRAM_STATUS,
    PLANOGRAM_STATUS_FAILURE,
    PLANOGRAM_STATUS_SUCCESS,
}

// PLANOGRAM_STATUS
export const planogramStatus = (params?: {}, callback?: any) => doAction(PLANOGRAM_STATUS, params, callback);
export const planogramStatusSuccess = (data = {}) => doResponseAction(PLANOGRAM_STATUS_SUCCESS, data);
export const planogramStatusFailure = (data = {}) => doResponseAction(PLANOGRAM_STATUS_FAILURE, data);