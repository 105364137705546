import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    subscriptionList: [],
    count: '',
    error: false
}
export const subscriptionReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.SUBSCRIPTION_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SUBSCRIPTION_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                subscriptionList: action?.data?.response,
                count: action?.data?.total_count,
                error: false
            };
        }
        case Actions.SUBSCRIPTION_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                subscriptionList: [],
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};
export const getSubscriptionList = (state: any) => state?.SUBSCRIPTION_LIST_REDUCER?.subscriptionList;
export const getSubscriptionListCount = (state: any) => state?.SUBSCRIPTION_LIST_REDUCER?.count