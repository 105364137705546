import { doAction, doResponseAction } from ".";

const CABINET_LIST = 'CABINET_LIST';
const CABINET_LIST_SUCCESS = 'CABINET_LIST_SUCCESS';
const CABINET_LIST_FAILURE = 'CABINET_LIST_FAILURE';
const SEARCH_CABINET_LIST_SUCCESS = "SEARCH_CABINET_LIST_SUCCESS";
export {
    CABINET_LIST,
    CABINET_LIST_FAILURE,
    CABINET_LIST_SUCCESS,
    SEARCH_CABINET_LIST_SUCCESS
}

// CABINET_LIST
export const cabinetList = (params?: {}, callback?: any) => doAction(CABINET_LIST, params, callback);
export const cabinetListSuccess = (data = {}) => doResponseAction(CABINET_LIST_SUCCESS, data);
export const searchCabinetListSuccess = (data = {}) => doResponseAction(SEARCH_CABINET_LIST_SUCCESS, data);
export const cabinetListFailure = (data = {}) => doResponseAction(CABINET_LIST_FAILURE, data);