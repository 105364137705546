export const CartItemTable = ({ cardHeader, tableHeader, tableValue, notFound }: { cardHeader: string, tableHeader: string[], tableValue: any, notFound: string }) => {
    return (
        <>
            <div className="card-header bg-color-yellow">
                {cardHeader}
            </div>
            <div className="card-body">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            {tableHeader &&
                                tableHeader?.map((elements, index) => (
                                    <th key={index} scope="col">
                                        {elements}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableValue?.length > 0 ? tableValue?.map((element: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{element?.product?.sku_id}</td>
                                        <td>{element?.shelf?.name}</td>
                                        <td>{element?.product?.name}</td>
                                        <td>{element?.count}</td>
                                    </tr>
                                )
                            }

                            ) : (<tr>
                                <td className="text-center" colSpan={5}>
                                    <div>
                                        {notFound}<label onClick={() => window.location.reload()}><i className="fa fa-refresh cartRefreshIcons" aria-hidden="true"></i></label>
                                    </div>
                                </td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}