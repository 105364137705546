import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { unregisterProductEndPoint } from "../../../config/Endpoints";
import { Actions } from "../../../redux/action";
import { unregisterProductCount, unregisterProductData } from "../../../redux/reducer/UnregisterProductReducer";
import { MESSAGE } from "../../../config/String";
import { Button } from "../../UI/Atoms/Button";
import { PaginationSession } from "../../UI/Molecules/Pagination";
import { unregisterProductInterface } from "./UnregisterProductInterface";
const header = [
    MESSAGE.S_NO,
    MESSAGE.IMAGE,
    MESSAGE.TRAINING_ID,
    MESSAGE.DATE,
    MESSAGE.ACTION
]
export const UnregisterProductTable = ({ pageNumber, setPopup, setPageNumber, setPopUpImage }: unregisterProductInterface) => {
    const dispatch = useDispatch()
    const unregisterProductValue = useSelector(unregisterProductData)
    const sessioncount = useSelector(unregisterProductCount)
    const navigate = useNavigate();
    useEffect(() => {
        const unRegisteProductURL = unregisterProductEndPoint(pageNumber)
        dispatch(Actions.unregisterProduct(unRegisteProductURL));
    }, [pageNumber, dispatch]);
    const handlePopImage = (image: string) => {
        setPopup(true)
        setPopUpImage(image)
    }
    const ProductRegister = (data: string) => {
        dispatch(Actions.trainingIdList(data))
        dispatch(Actions.getIsActiveforTrainigId(true))
        navigate('/register');

    }
    const onChangeHandle = (pageNumbers: number) => {
        setPageNumber(pageNumbers)
    }
    return (
        <>
            <div className="listContainer">
                <div className="unregisterTable">
                    <div className="unregisterScrollbar">
                        <table className="table table-bordered unregisterProductTable">
                            <thead>
                                <tr>
                                    {header &&
                                        header?.map((elements, index) => (
                                            <th key={index} scope="col" >
                                                {elements}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    unregisterProductValue?.map((element: any, index: number) => {
                                        const Image = `${'data:image/png;base64,' + element?.img}`
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{((5 * pageNumber) - (5 - (index + 1)))}</th>
                                                <td>
                                                    <span onClick={() => handlePopImage(Image)}>
                                                        <img src={Image} alt="img" style={{ width: "70px" }} className='thumbnailImage' />
                                                    </span>
                                                </td>
                                                <td>{element?.training_id}</td>
                                                <td>{new Date(element?.date).toLocaleDateString()}</td>
                                                <td>
                                                    <Button buttonClassName="btn btn-primary" handleClick={() => ProductRegister(element?.training_id)} buttonName={MESSAGE.REGISTER_PRODUCT} />
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="productPagination">
                    <PaginationSession onChangeHandle={onChangeHandle} currentPage={pageNumber} sessionCount={sessioncount} />
                </div>
            </div>
        </>
    )
}