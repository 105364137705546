import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    cabinet_onboarding: {},
    cabinet_onboarding_edit: {},
    deleteResponse: {},
    updataResponse: {},
    error: false
}
export const cabinetOnboardingReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.CABINET_ONBOARDING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CABINET_ONBOARDING_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cabinet_onboarding: action?.data?.results,
                cabinet_onboarding_edit: {},
                deleteResponse: {},
                error: false
            };
        }
        case Actions.CABINET_ONBOARDING_FAILURE: {
            return {
                ...state,
                isLoading: false,
                cabinet_onboarding: {},
                cabinet_onboarding_edit: {},
                deleteResponse: {},
                error: action.data.error,
            };
        }

        case Actions.CABINET_ONBOARDING_EDIT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CABINET_ONBOARDING_EDIT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cabinet_onboarding_edit: action?.data?.response,
                error: false
            };
        }
        case Actions.CABINET_ONBOARDING_EDIT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                cabinet_onboarding_edit: {},
                error: action.data.error,
            };
        }
        case Actions.CABINET_DELETE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CABINET_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                deleteResponse: action?.data?.response,
                error: false
            };
        }
        case Actions.CABINET_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteResponse: {},
                error: action.data.error,
            };
        }
        case Actions.CABINET_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CABINET_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                updataResponse: action?.data?.response,
                error: false
            };
        }
        case Actions.CABINET_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                updataResponse: {},
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};

export const getEditCabinetData = (state: any) => state?.CABINET_ONBOARDING_REDUCER;
