import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    searchList: undefined,
    cabinetSearchList: {},
    error: false
}
export const searchListReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.SEARCH: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SEARCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                searchList: action.data,
                error: false
            };
        }
        case Actions.SEARCH_FAILURE: {
            return {
                ...state,
                isLoading: false,
                searchList: undefined,
                error: action.error,
            };
        }

        case Actions.CABINET_SEARCH: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CABINET_SEARCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cabinetSearchList: action.data?.response,
                error: false
            };
        }
        case Actions.CABINET_SEARCH_FAILURE: {
            return {
                ...state,
                isLoading: false,
                cabinetSearchList: {},
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const searchList = (state: any) => state?.SEARCH_LIST?.searchList?.results;
export const getCabinetSearchList = (state: any) => state?.SEARCH_LIST?.cabinetSearchList
export const searchListLoading = (state: any) => state?.SEARCH_LIST?.isLoading;