import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../redux/action";
import { getPlanogramData } from "../../../redux/reducer/PlanogramReducer";
import { toast } from "react-toastify"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader";
import { MESSAGE } from '../../../config/String'
import { useState } from "react";
import { autoFill } from "../../../config/Endpoints";
let annotationArray = [];
export const PlanogramDetailsWidget = ({ planogramObject, planogramShelfId, imageSize, checkAnnotation, handleClear, shelfparams, index, setCheckStatus, checkStatus, PlanogramPopFields, planogramData, setPlanogramData }: { planogramObject: any, cameraId: string, cabinetId: string, imageSize: { width: number, height: number }, planogramShelfId: any, checkAnnotation: boolean, handleClear: any, shelfparams: any, index: number, setCheckStatus: any, checkStatus: any, PlanogramPopFields: any, planogramData: any, setPlanogramData: any }) => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [closeValue, setCloseValue] = useState<any>()
    const saveAnnotation = () => {
        const saveCallback = (response: any) => {
            setLoading(false)
            if (response?.status_code === 0) {
                toast.success(MESSAGE.UPDATED_SUCCESSFULLY)
                getPlanogram(response?.response)
                setCloseValue(index)
                planogramObject?.id !== checkStatus?.shelf[index] && setCheckStatus({ status: true, shelf: [...checkStatus?.shelf, shelfparams?.shelf_id] })
                //open the next accordion after success
                //    let accordion= document.getElementById(`collapse${index}`)
                //    let nextaccordion =document.getElementById(`collapse${index+1}`)
                //    if(accordion?.classList?.contains('show'))
                //    {
                //     accordion?.classList?.remove('show')
                //     nextaccordion?.classList?.add('show')
                //    }            
            }
            else {
                toast.error(response?.message)
            }
        }
        if (planogramData?.length > 0) {
            annotationArray = [...planogramData]
            let parmas = {};
            let planogram: { product_id: any; xtl: string; ytl: string; xbr: string; ybr: string; }[] = [];
            let planogramObjectdata;
            annotationArray.forEach((o: any) => {
                planogramObjectdata = {
                    product_id: o?.comment,
                    xtl: `${parseFloat(((o?.mark?.x || 0) / imageSize.width).toString()).toFixed(5)}`,
                    ytl: `${parseFloat(((o?.mark?.y || 0) / imageSize.height).toString()).toFixed(5)}`,
                    xbr: `${(parseFloat(o?.mark?.x + o?.mark?.width) / imageSize.width).toFixed(5)}`,
                    ybr: `${(parseFloat(o?.mark?.y + o?.mark?.height) / imageSize.height).toFixed(5)}`,
                }
                planogram.push(planogramObjectdata)
                parmas = {
                    shelf_id: shelfparams?.shelf_id,
                    planogram_shelf_id: PlanogramPopFields?.planogram_detail?.planogram_shelfs[index]?.id || planogramShelfId,
                    planogram_coordinates: planogram
                }
            })
            setLoading(true)
            dispatch(Actions.updateList(parmas, saveCallback))
        }
        else {
            toast.warn(MESSAGE.CO_ORDINATES)
        }
    }

    const getPlanogram = (response: any) => {
        let planogram: { comment: any; id: any; mark: { x: number; y: number; width: number; height: number; }; }[] = [];
        response?.map((o: any, index: number) => {
            return (
                planogram.push({
                    comment: o?.product?.id,
                    id: index,
                    mark: {
                        x: o?.xtl * imageSize.width,
                        y: o?.ytl * imageSize.height,
                        width: (o?.xbr * imageSize.width) - (o?.xtl * imageSize.width),
                        height: (o?.ybr * imageSize.height) - (o?.ytl * imageSize.height)
                    }
                }))
        })
        setPlanogramData(planogram)
    }

    const autoFillHandler = () => {
        const autoFillCallback = (response: any) => {
            setLoading(false)
            if (response?.status_code === 0) {
                let autofilldata: { comment: any; id: any; mark: { x: number; y: number; width: number; height: number; }; }[] = [];
                response.result?.map((o: any, index: number) => {
                    return (
                        autofilldata.push({
                            comment: o?.product_id,
                            id: index,
                            mark: {
                                x: o?.xtl * imageSize.width,
                                y: o?.ytl * imageSize.height,
                                width: (o?.xbr * imageSize.width) - (o?.xtl * imageSize.width),
                                height: (o?.ybr * imageSize.height) - (o?.ytl * imageSize.height)
                            }
                        }))
                })
                if (autofilldata?.length > 0) {
                    setPlanogramData(autofilldata)
                } else {
                    toast.warning(MESSAGE?.AUTOFILL_DATA_IS_EMPTY)
                }
            }
        }
        const URL = autoFill(shelfparams)
        setLoading(true)
        dispatch(Actions.autoFill(URL, autoFillCallback))
    }
    return (
        <>

            {
                isLoading && <PlanogramLoader />
            }
            <div className="row justify-content-end">
                <div className="col-6 pt-3">
                    <button className="btn btn-primary big-btn mt-2 clearButton" onClick={handleClear}>{MESSAGE.CLEAR}</button>
                    <button className="btn btn-primary big-btn mt-2 clearButton" aria-controls={`collapse${index + 1}`} aria-expanded={true} data-bs-toggle={"collapse"} data-bs-target={`#collapse${index + 1}`} disabled={checkAnnotation} onClick={saveAnnotation}>{MESSAGE.SAVE}</button>
                    {/* <button className="btn btn-primary big-btn mt-2 clearButton" onClick={autoFillHandler}>{MESSAGE.AUTOFILL}</button> */}
                </div>
            </div>
        </>
    )
}