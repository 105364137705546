import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { MESSAGE } from '../../../../../config/String'
import { Actions } from '../../../../../redux/action'
import { getEdgeServerDetails } from '../../../../../redux/reducer/EdgeServerReducer'
import { getLoginData } from '../../../../../redux/reducer/login'
import { vendorListDetails } from '../../../../../redux/reducer/VendorReducer'
import { EdgeValidation, validateminserverLengthRegExp } from '../../../../../util/Validations'
import InputField from '../../../../UI/Molecules/InputField'
import { PaginationSession } from '../../../../UI/Molecules/Pagination'
import PlanogramLoader from '../../../../UI/Molecules/PlanogramLoader'
import { PopupData } from '../../../../UI/Molecules/PopUpData'
import Select from '../../../../UI/Molecules/Select'
import { DataNotFound } from '../../../../UI/Molecules/DataNotFound'
import AppDatePicker from '../../../../UI/Molecules/AppDatePicker'
import moment from 'moment'
import { getMasterSubscriptionList } from '../../../../../redux/reducer/MasterSubscriptionReducer'

const EdgeServerList = () => {
    const dispatch = useDispatch()
    const usersDetails = useSelector(getLoginData)
    const edgeServerDetails = useSelector(getEdgeServerDetails)
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const vendorList = useSelector(vendorListDetails)
    const [formDetails, setFormDetails] = useState<any>({ fields: {} });
    const [error, setError] = useState<any>()
    const [currentPage, setCurrentPage] = useState(1)
    const [fromDate, setFromDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [subscriptionName, setSubscriptionName] = useState('')
    const [subscriptionType, setSubscriptionType] = useState('')
    const [subscriptionTypeArray, setSubscriptionTypeArray] = useState([])
    const masterSubscriptionList = useSelector(getMasterSubscriptionList)
    const [serachTowerData, setSearchTowerData] = useState('')

    useEffect(() => {
        intiallCall()
        if (usersDetails?.role === 1) {
            dispatch(Actions.vendorOnboardingList("", ""))
        }
    }, [currentPage])

    // useEffect(() => {
    //     dispatch(Actions.masterSubscriptionList(''))
    // }, [dispatch])

    useEffect(() => {
        if (Object.keys(formDetails)?.length !== 0) {
            let validatedError = EdgeValidation(formDetails, ["name", "store_id", "mac_address", "ip_address"])
            if (validatedError) {
                setError(validatedError)
            }
        }
    }, [formDetails])

    const intiallCall = () => {
        let params: any = {
            pagenum: currentPage,
            limit: 10
        }
        if (usersDetails?.role === 1) {
            dispatch(Actions.edgeServerList(params, ''))
        } else {
            dispatch(Actions.vendorEdgeServerList(params, ''))
        }
    }

    const openEdgeServerPopUP = () => {
        setIsOpen(true)
    }

    const handleVendorChange = (event: any) => {
        setFormDetails({ ...formDetails, ['store_id']: event.target.value })
    }

    const handleChange = (e: any) => {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
    }


    const callback = (resp: any) => {
        if (resp?.status_code === 0) {
            intiallCall()
            closeModal()
        } else {
            toast.error(resp?.message)
        }
    }

    const manage = () => {
        let params: any = {
            store_id: Number(formDetails?.store_id),
            mac_address: formDetails?.mac_address,
            name: formDetails?.name,
            ip_address: formDetails?.ip_address,
            master_subscription_id: subscriptionType,
            start_date: moment(fromDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD')
        }
        if (!formDetails?.name || !validateminserverLengthRegExp(formDetails?.name)) {
            setError({ name: "Please enter the valid server name" })
        } else if (!formDetails?.store_id) {
            setError({ store_id: "Please choose the vendor" })
        } else if (!subscriptionName) {
            setError({ subscriptionName: "Please select the subscription name" })
        } else if (!subscriptionType) {
            setError({ subscriptionType: "Please select the subscription type" })
        } else if (!fromDate) {
            setError({ fromDate: "Please select the start date" })
        } else if (!endDate) {
            setError({ endDate: "Please select the end date" })
        } else if (!formDetails?.mac_address) {
            setError({ mac_address: "Please enter the valid mac address" })
        } else if (!formDetails?.ip_address) {
            setError({ ip_address: "Please enter the valid ip address" })
        } else {
            if (formDetails?.id) {
                let params = {
                    control_tower_id: formDetails?.id,
                    mac_address: formDetails?.mac_address,
                    ip_address: formDetails?.ip_address,
                    master_subscription_id: subscriptionType,
                    start_date: moment(fromDate).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD')
                }
                dispatch(Actions.edgeServerUpdate(params, callback))
            } else {
                dispatch(Actions.edgeServerCreate(params, callback))
            }
        }

    }

    //handle the subscription name and provide the subscription type array
    const handleSubscriptionName = (event: any) => {
        setEndDate('')
        setFromDate('')
        if (event?.target.value === '') {
            setError({ ...error, "subscriptionName": "Please select the subscription name" })
            setSubscriptionName(event?.target.value)
            setSubscriptionTypeArray([])
            setSubscriptionType('')
        } else {
            setSubscriptionName(event?.target.value)
            setError({ ...error, "subscriptionName": "" })
            let subscriptionFilteredData = masterSubscriptionList?.filter((item: any) => item?.subscription_name === event?.target.value)
            const subscriptionType = subscriptionFilteredData?.map((k: any) => ({ value: k.id, name: k.subscriptions_type }))
            setSubscriptionTypeArray(subscriptionType)
        }
    }

    //handle the subscription type
    const handleSubscriptionType = (event: any) => {
        if (event?.target.value === '') {
            setError({ ...error, "subscriptionType": "Please select the subscription type" })
            setSubscriptionType(event?.target.value)
            setEndDate('')
            setFromDate('')
        } else {
            setError({ ...error, "subscriptionType": "" })
            setSubscriptionType(event?.target.value)
        }
    }

    const onStartDate = (date: any) => {
        if (subscriptionType) {
            setFromDate(date)
            if (date) {
                const month: any = subscriptionTypeArray?.find((item: any) => item?.value == subscriptionType)
                if (month) {
                    const monthcount: any = month?.name === "quarterly" ? 3 : month?.name === "half_yearly" ? 6 : 12
                    const newDate = new Date(date)
                    const endDate = new Date(newDate.setMonth(newDate.getMonth() + parseInt(monthcount)))
                    setEndDate(endDate)
                    setError({ fromDate: "" })
                }
            } else {
                setError({ fromDate: "Please select the start date" })
            }
        }
    }
    const onEndDate = (date: any) => {
        date.preventDefault()
    }

    //Get the unique value from the master subscription list for subscription name dropdown
    var result = masterSubscriptionList?.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.subscription_name === o.subscription_name)) {
            unique.push(o);
        }
        return unique;
    }, []);
    const subscriptionNameArray = result?.map((k: any) => ({ value: k.subscription_name, name: k.subscription_name }))

    const manageEdgeServerContent = () => {
        const convertArray = vendorList?.vendor_details?.map((obj: any) => ({ value: obj?.store_id, name: obj?.first_name }))
        return (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="font-bold">{isEdit ? "Manage Control Tower" : "Add Control Tower"}</h3>
                    </div>
                </div>
                <div className='card card-body rounded-3 border-0 shadow p-4 mb-3  mt-4'>
                    <div className="row mt-3">
                        <InputField
                            inputType={"text"}
                            label="Tower Name"
                            placeholder="Tower name"
                            columnSize={6}
                            name="name"
                            value={formDetails?.name}
                            disabled={formDetails?.id}
                            error={error?.name}
                            onChange={handleChange}
                        />
                        <div className="col-sm-6">
                            <Select
                                placeHolder={"Choose Vendor"}
                                array={convertArray}
                                value={formDetails?.store_id}
                                disabled={formDetails?.id}
                                onChange={(event: any) => handleVendorChange(event)}
                                error={error?.store_id}
                            />
                        </div>
                        {/* <div className="col-sm-4">
                            <Select
                                placeHolder={"Choose subscription name"}
                                array={subscriptionNameArray}
                                value={subscriptionName}
                                onChange={(event: any) => handleSubscriptionName(event)}
                                error={error?.subscriptionName}
                            />
                        </div> */}
                    </div>
                    {/* <div className="row mt-3">
                        <div className="col-sm-4">
                            <Select
                                placeHolder={"Choose subscription type"}
                                array={subscriptionTypeArray}
                                value={subscriptionType}
                                disabled={!subscriptionName}
                                onChange={(event: any) => handleSubscriptionType(event)}
                                error={error?.subscriptionType}
                            />
                        </div>
                        <div className="col-sm-4">
                            <AppDatePicker
                                selected={fromDate}
                                placeholder="Start Date"
                                onChange={(date: any) => onStartDate(date)}
                                notValidTime={true}
                                id='FormDatePicker'
                            />
                            {
                                error?.fromDate && <p className='errorMessage'>{error?.fromDate}</p>
                            }
                        </div>
                        <div className=" col-sm-4">
                            <AppDatePicker
                                selected={endDate}
                                placeholder="End Date"
                                onChange={(date: any) => onEndDate(date)}
                                notValidTime={true}
                                minDate={fromDate}
                                id='ToDatePicker'
                            />
                            {
                                error?.endDate && <p className='errorMessage'>{error?.endDate}</p>
                            }
                        </div>
                    </div> */}
                    <div className="row mt-3">
                        <InputField
                            inputType={"text"}
                            label="Mac address"
                            placeholder="Mac address"
                            columnSize={6}
                            name="mac_address"
                            value={formDetails?.mac_address}
                            error={error?.mac_address}
                            onChange={handleChange}
                        />
                        <InputField
                            inputType={"text"}
                            label="Ip address"
                            placeholder="Ip ddress"
                            columnSize={6}
                            name="ip_address"
                            value={formDetails?.ip_address}
                            error={error?.ip_address}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='row mb-2 mt-4'>
                        <div className='col-sm-6'></div>
                        <div className='col-sm-6 text-end'>
                            <button className="btn btn-secondary btn-outline" onClick={() => closeModal()}>Cancel</button>
                            <button
                                className="btn btn-primary big-btn ml-2"
                                onClick={() => manage()}>{formDetails?.id ? "Update" : "Create"}</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const closeModal = () => {
        setFormDetails({ fields: {} })
        setIsOpen(false)
        setSubscriptionName('')
        setSubscriptionType('')
        setSubscriptionTypeArray([])
        setEndDate('')
        setFromDate('')
        setIsEdit(false)
    }

    const editEdgeServer = (obj: any) => {
        // console.log(obj,'obj')
        // let subscriptionFilteredData = masterSubscriptionList?.filter((item: any) => item?.subscription_name === obj?.subscription_details[0]?.master_subscription?.subscription_name)
        // const subscriptionType = subscriptionFilteredData?.map((k: any) => ({ value: k.id, name: k.subscriptions_type }))
        // setSubscriptionTypeArray(subscriptionType)
        setIsOpen(true)
        setIsEdit(true)
        setFormDetails({
            name: obj?.name,
            mac_address: obj?.mac_address,
            ip_address: obj?.ip_address,
            // id: obj?.subscription_details[0]?.control_tower?.id,
            store_id: (obj?.store.id)?.toString()
        })
        // setSubscriptionName(obj?.subscription_details[0]?.master_subscription?.subscription_name)
        // setSubscriptionType(obj?.subscription_details[0].master_subscription.subscription_type.number_of_months)
        // setEndDate(new Date(obj?.subscription_details[0].end_date))
        // setFromDate(new Date(obj?.subscription_details[0].start_date))
    }

    const { isLoading, edgeServer } = edgeServerDetails || {}
    const edgeArray = usersDetails?.role === 1 ? edgeServer?.result : edgeServer
    const handlePage = (page: number) => {
        setCurrentPage(page)
        let params: any = {
            pagenum: page,
            limit: 10,
            keyword: serachTowerData
        }
        if (usersDetails?.role === 1) {
            dispatch(Actions.edgeServerList(params))
        } else {
            dispatch(Actions.vendorEdgeServerList(params))
        }
    }
    const searchTower = (event: any) => {
        setSearchTowerData(event.target.value)
        let params: any = {
            pagenum: currentPage,
            limit: 10,
            keyword: event.target.value
        }
        if (usersDetails?.role === 1) {
            dispatch(Actions.edgeServerList(params, ''))
        } else {
            dispatch(Actions.vendorEdgeServerList(params, ''))
        }
    }
    return (
        <React.Fragment>
            <div className="row mt-4">
                <div className="col-sm-6">
                    <h3 className="font-bold">{MESSAGE.EDGE_SERVERS}</h3>
                </div>
                {/* <div className='col-sm-3'>
                    <input type="text" className='searchControlTower' placeholder="Enter the control tower name" onChange={(event) => searchTower(event)} />
                </div> */}
                {
                    usersDetails?.role === 1 && <div className="col-sm-6 text-end">
                        <input type="text" className='searchControlTower' placeholder="Search by control tower name" onChange={(event) => searchTower(event)} />

                        <button className="btn btn-primary big-btn ml-4" onClick={() =>
                            openEdgeServerPopUP()}>Add New Tower</button>
                    </div>
                }

            </div>
            <div className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0 mt-4 ">
                {
                    edgeArray?.length > 0 ?
                        <table className="table table-hover table-striped table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Tower name</th>
                                    <th scope="col">Vendor Name</th>
                                    {/* <th scope="col">Subscription name</th>
                                    <th scope="col">Subscription type</th>
                                    <th scope="col">Start date</th>
                                    <th scope="col">End date</th> */}
                                    <th scope="col">IP Address</th>
                                    {usersDetails?.role === 1 && <th scope="col">Action</th>}
                                </tr>
                            </thead>

                            <tbody>
                                {edgeArray?.map((obj: any, index: number) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{obj?.name}</td>
                                            <td>{obj?.vendor_name}</td>
                                            {/* <td>{obj?.subscription_details[0]?.master_subscription?.subscription_name}</td>
                                            <td>{obj?.subscription_details[0]?.master_subscription?.subscription_type?.subscription_type}</td>
                                            <td>{moment(obj?.subscription_details[0]?.start_date).format('DD-MM-YYYY')}</td>
                                            <td>{moment(obj?.subscription_details[0]?.start_date).format('DD-MM-YYYY')}</td> */}
                                            <td>{obj?.ip_address}</td>
                                            {usersDetails?.role === 1 && <td className="d-flex">
                                                <div className="ml-2">
                                                    <button type="button"
                                                        id="editcombo"
                                                        className="btn btn-sm btn-outline-dark"
                                                        onClick={() => editEdgeServer(obj)}>Edit</button>
                                                </div>
                                            </td>}
                                        </tr>
                                    )
                                })}

                                {(!isLoading && edgeArray?.length == 0) && <tr className='text-center'>
                                    <td colSpan={6}>No Data Found</td>
                                </tr>}
                            </tbody>
                        </table> : <DataNotFound Error='No data found' />
                }

                {isLoading && <PlanogramLoader />}
            </div>
            {
                edgeArray?.length > 6 &&
                <PaginationSession
                    onChangeHandle={handlePage}
                    currentPage={currentPage}
                    sessionCount={edgeServer?.totalPages} />
            }

            {
                isOpen && <PopupData
                    close={() => closeModal()}
                    content={manageEdgeServerContent()}
                />
            }
        </React.Fragment>
    )
}

export default EdgeServerList