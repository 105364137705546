import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
export function* productSearchSaga(param: any) {
    const { callback, params } = param
    try {
        const response: ResponseGenerator = yield doGet(params, '', '')
        callback && callback(response)
        yield put(Actions.product_List_Search_Success(response));

    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.product_List_Search_Failure({ error }));
        }
    }
}
export default function* productSearchWatcher() {
    yield all([
        takeLatest(Actions.PRODUCT_LIST_SEARCH, productSearchSaga),
    ]);
}