import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet, doPost } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import END_POINTS from '../../config/Endpoints';

export function* publishProductListSaga(param: any) {
    const { callback, params } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.PUBLISH_PRODUCT_LIST, params, '')
        callback && callback(response?.response)
        yield put(Actions.publish_Product_List_Success(response));

    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.publish_Product_List_Failure({ error }));
        }
    }
}
export function* adminProductListSaga(param: any) {
    const { callback, params } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.ADMIN_PRODUCT_LIST, params, '')
        callback && callback(response?.response)
        yield put(Actions.admin_Product_List_Success(response));

    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.admin_Product_List_Failure({ error }));
        }
    }
}
export function* CreateProductSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CREATE_PRODUCT, params, '')
        yield put(Actions.createProductSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.createProductFailure({ error }));
        callback && callback({ error })
    }
}

export function* AdminCreateProductSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.ADMIN_CREATE_PRODUCT, params, '')
        yield put(Actions.AdmincreateProductSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.AdmincreateProductFailure({ error }));
        callback && callback({ error })
    }
}

export function* ProductDeleteSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.BRANCHES_DELETE, params, '')
        yield put(Actions.productDeleteSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.productDeleteFailure({ error }));
        callback && callback({ error })
    }
}

export function* ProductUpdateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.VENDOR_PRODUCT_UPDATE, params, '')
        yield put(Actions.productUpdateSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.productUpdateFailure({ error }));
        callback && callback({ error })
    }
}

export function* AdminProductUpdateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.ADMIN_PRODUCT_UPDATE, params, '')
        yield put(Actions.adminproductUpdateSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.adminproductUpdateFailure({ error }));
        callback && callback({ error })
    }
}

export function* AdminProductPublishSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.ADMIN_PRODUCT_PUBLISH, params, '')
        yield put(Actions.adminproductPublishSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.adminproductPublishFailure({ error }));
        callback && callback({ error })
    }
}

export function* ProductActiveSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.VENDOR_PRODUCT_ACTIVE, params, '')
        callback && callback(response)
        yield put(Actions.productActiveSuccess(response));
    } catch (error) {
        yield put(Actions.productActiveFailure({ error }));
        callback && callback({ error })
    }
}
export function* AdminProductActiveSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.ADMIN_PRODUCT_ACTIVE, params, '')
        callback && callback(response)
        yield put(Actions.adminProductActiveSuccess(response));
    } catch (error) {
        yield put(Actions.adminProductActiveFailure({ error }));
        callback && callback({ error })
    }
}

export default function* productListWatcher() {
    yield all([
        takeLatest(Actions.PUBLISH_PRODUCT_LIST, publishProductListSaga),
        takeLatest(Actions.ADMIN_PRODUCT_LIST, adminProductListSaga),
        takeLatest(Actions.CREATE_PRODUCT, CreateProductSaga),
        takeLatest(Actions.PRODUCT_DELETE, ProductDeleteSaga),
        takeLatest(Actions.PRODUCT_UPDATE, ProductUpdateSaga),
        takeLatest(Actions.ADMIN_PRODUCT_UPDATE, AdminProductUpdateSaga),
        takeLatest(Actions.ADMIN_PRODUCT_PUBLISH, AdminProductPublishSaga),
        takeLatest(Actions.PRODUCT_ACTIVE, ProductActiveSaga),
        takeLatest(Actions.ADMIN_PRODUCT_ACTIVE, AdminProductActiveSaga),
        takeLatest(Actions.ADMIN_CREATE_PRODUCT, AdminCreateProductSaga),
    ]);
}