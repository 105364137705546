import moment from "moment"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { cabinetSearchListEndPoint } from "../../../config/Endpoints"
import { Branches, Cabinents, InventoryManagement, InventoryRecent, productDetails } from "../../../config/Mockup"
import { MESSAGE } from "../../../config/String"
import { Actions } from "../../../redux/action"
import { getBranchDetails } from "../../../redux/reducer/BranchesReducer"
import { getInventoryManagementHistoryData, getInventoryManagementRecentData } from "../../../redux/reducer/InventoryManagementReducer"
import { getLoginData } from "../../../redux/reducer/login"
import { getCabinetSearchList } from "../../../redux/reducer/SearchReducer"
import { vendorListDetails } from "../../../redux/reducer/VendorReducer"
import AppDatePicker from "../../UI/Molecules/AppDatePicker"
import { DataNotFound } from "../../UI/Molecules/DataNotFound"
import { PaginationSession } from "../../UI/Molecules/Pagination"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { PopupData } from "../../UI/Molecules/PopUpData"
import Select from "../../UI/Molecules/Select"
import BranchTypeAhead from "./BranchTypeAhead"

export const InventoryWidget = () => {
    const initialState = {
        branchId: "",
        cabinetId: "",
        fromDate: "",
        toDate: ""
    }
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [currentPage, setCurrentPage] = useState<string>('recent')
    const usersDetails = useSelector(getLoginData)
    const branchDetails = useSelector(getBranchDetails)
    const [filter, setFilter] = useState<any>(initialState)
    const cabinetDetails = useSelector(getCabinetSearchList)
    const [filteredCabinet, setCabinetDetails] = useState<any>([])
    const currentStock = useSelector(getInventoryManagementRecentData)
    const stockHistory = useSelector(getInventoryManagementHistoryData)
    const [isPopUp, setpopUp] = useState<any>(false)
    const [recentInventory, setRecentInventory] = useState<any>([])
    const [isloading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const loginDetails = useSelector(getLoginData)
    const vendorList = useSelector(vendorListDetails)
    const [options, setOptions] = useState(null || []);
    const [vendorId, setVendorId] = useState<any>();
    const location = useLocation()

    useEffect(() => {
        let url = location?.pathname
        let word = url.split('/')
        if (role == 1) {
            setVendorId(word[3])
            const params = { vendor_id: word[3] }
            dispatch(Actions.branchesList(params, branchListCallback))
        } else {
            const { user_id } = loginDetails || {}
            const params = { vendor_id: user_id }
            dispatch(Actions.branchesList(params, branchListCallback))
        }
        getCabinetList("", word[3] !== undefined ? word[3] : "")
        let startDate = null
        let endDate = null
        let branchId = null
        let cabinetId = null
        if (filter?.fromDate) {
            startDate = moment(filter?.fromDate).format('YYYY-MM-DD')
        }
        if (filter?.toDate) {
            endDate = moment(filter?.toDate).format('YYYY-MM-DD')
        }
        if (filter?.branchId?.value) {
            branchId = filter.branchId?.value
        }
        if (filter?.cabinetId) {
            cabinetId = filter.cabinetId
        }
        currentPage === "recent" ? getInventoryCurrentList(startDate, endDate, branchId, cabinetId) : getInventoryHistory(startDate, endDate, branchId, cabinetId)
    }, [dispatch, currentPageNumber])

    //Cabinet list API for the filter
    const getCabinetList = (branchId: any, vendorId: any, storeId?: any) => {
        const callback = (response: any) => {
            const filteredCabinet = response?.response?.result?.filter((o: any) => o?.branch?.id == branchId)
            const convertArray = filteredCabinet?.map((k: any) => ({ value: k.id, ...k }))
            setCabinetDetails(convertArray)
        }
        let params = {
            branchId: branchId,
            limit: 100,
            pagenum: 1,
            vendorId: vendorId,
            storeId: storeId
        }
        dispatch(Actions.cabinet_search(params, callback));
    }

    //Inventorry current list API and set loader
    const getInventoryCurrentList = (startDate?: any, endDate?: any, branchId?: any, cabinetId?: any) => {
        setLoading(true)
        let params: any = {
            pagenum: currentPageNumber,
            limit: 10,
            startDate: startDate,
            endDate: endDate,
            branchId,
            cabinetId
        }
        dispatch(Actions.inventoryManagementRecent(params, callback))
    }

    //Inventorry history list API and set loader
    const getInventoryHistory = (startDate?: any, endDate?: any, branchId?: any, cabinetId?: any) => {
        setLoading(true)
        let params: any = {
            pagenum: currentPageNumber,
            limit: 6,
            startDate: startDate,
            endDate: endDate,
            branchId,
            cabinetId
        }
        dispatch(Actions.inventoryManagementHistory(params, callback))
    }

    //Common callback to check the response status and set loader false
    const callback = (response: any) => {
        setLoading(false)
        if (response?.status_code !== 0) {
            toast.error(MESSAGE.SOMETHING_WENT_WORNG)
        }
    }

    //handle change for the branch, pass the event as the argument,filter the cabinet based on branch
    const handleBranchChange = (event: any) => {
        const { user_id } = loginDetails || {}
        let params: any = {
            pagenum: currentPageNumber,
            limit: currentPage === 'recent' ? 10 : 6
        }
        const storeObj = result?.find((o: any) => o.id == event?.value)
        setFilter({ ...filter, 'branchId': event, 'cabinetId': '' })
        let url = location?.pathname
        let word = url.split('/')
        getCabinetList(event?.value, word[3] !== undefined ? word[3] : "", storeObj?.store)
        if (event?.value) {
            params['branchId'] = event?.value
        }
        if (filter?.startDate) {
            params['startDate'] = moment(filter?.startDate).format('YYYY-MM-DD')
        }
        if (filter?.endDate) {
            params['endDate'] = moment(filter?.endDate).format('YYYY-MM-DD')
        }

        params['vendorId'] = role == 1 ? vendorId : user_id

        setLoading(true)
        currentPage === 'recent' ? dispatch(Actions.inventoryManagementRecent(params, callback)) : dispatch(Actions.inventoryManagementHistory(params, callback))
    }

    //handle change for the cabinet and pass the event as the argument
    const handleCabinetChange = (event: any) => {
        const { user_id } = loginDetails || {}
        setFilter({ ...filter, 'cabinetId': event.target.value })
        let params: any = {
            pagenum: currentPageNumber,
            limit: currentPage === 'recent' ? 10 : 6
        }
        if (filter?.branchId?.value) {
            params['branchId'] = filter.branchId?.value
        }
        if (event.target.value) {
            params['cabinetId'] = event.target.value
        }
        if (filter?.fromDate) {
            params['startDate'] = moment(filter?.fromDate).format('YYYY-MM-DD')
        }
        if (filter?.toDate) {
            params['endDate'] = moment(filter?.toDate).format('YYYY-MM-DD')
        }
        params['vendorId'] = role == 1 ? vendorId : user_id

        setLoading(true)
        currentPage === 'recent' ? dispatch(Actions.inventoryManagementRecent(params, callback)) : dispatch(Actions.inventoryManagementHistory(params, callback))
    }

    //switch the tab and set the initial state
    const changeTab = (tab: string) => {
        setCurrentPage(tab)
        setFilter(initialState)
        setCabinetDetails([])
        setCurrentPageNumber(1)
        if (tab === "history") {
            getInventoryHistory()
        }
        else {
            getInventoryCurrentList()
        }

    }

    //startDate function to set the date
    const onStartDate = (date: any) => {
        let params: any = {
            pagenum: currentPageNumber,
            limit: currentPage === 'recent' ? 10 : 6
        }
        setFilter({ ...filter, 'fromDate': date })
        if (filter?.branchId?.value) {
            params['branchId'] = filter.branchId?.value
        }
        if (filter?.cabinetId) {
            params['cabinetId'] = filter?.cabinetId
        }
        if (date) {
            params['startDate'] = moment(date).format('YYYY-MM-DD')
        }
        if (filter?.endDate) {
            params['endDate'] = moment(filter?.endDate).format('YYYY-MM-DD')
        }
        dispatch(Actions?.inventoryManagementHistory(params, callback))

    }


    //endDate function to set the end date
    const onEndDate = (date: any) => {
        let params: any = {
            pagenum: currentPageNumber,
            limit: currentPage === 'recent' ? 10 : 6
        }
        setFilter({ ...filter, 'toDate': date })
        if (filter?.branchId?.value) {
            params['branchId'] = filter.branchId?.value
        }
        if (filter?.cabinetId) {
            params['cabinetId'] = filter?.cabinetId
        }
        if (filter?.fromDate) {
            params['startDate'] = moment(filter?.fromDate).format('YYYY-MM-DD')
        }
        if (date) {
            params['endDate'] = moment(date).format('YYYY-MM-DD')
        }
        dispatch(Actions?.inventoryManagementHistory(params, callback))
    }

    //current inventory
    const showInventoryList = (obj: any) => {
        setRecentInventory(obj?.inventory)
        setpopUp(true)

    }

    //For handle the pagination
    const handlePage = (page: number) => {
        setCurrentPageNumber(page)
    }
    const branchListCallback = (res: any) => {
        let data = [];
        if (res?.response) {
            data = res?.response?.result?.map((o: any) =>
            (
                {
                    label: `${o?.name}`, value: o?.id,
                }
            )
            );
        }
        setOptions(data)
    }

    const renderRecentInventory = (convertArray: any, handleBranchChange?: any, filteredCabinet?: any,
        filter?: any, handleCabinetChange?: any, currentStock?: any) => {
        const { result = [] } = currentStock || {}
        const { role, user_id } = loginDetails
        return (
            <React.Fragment>
                <div className="row mt-2">
                    <div className={"col-sm-6 offset-6 text-start"}>
                        <div className="row">
                            <div className="col-sm-6">
                                <BranchTypeAhead
                                    options={options}
                                    setOptions={setOptions}
                                    vendorId={role == 1 ? vendorId : user_id}
                                    isLoading={false}
                                    placeholder={"Choose Branch"}
                                    onChange={(event: any) => handleBranchChange(event)}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Select
                                    placeHolder={"All Cabinets"}
                                    array={filteredCabinet}
                                    isList={true}
                                    disabled={filteredCabinet?.length === 0}
                                    value={filter?.cabinetId}
                                    onChange={(event: any) => handleCabinetChange(event)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {result.length > 0 ? <div>
                    <div className="card card-body rounded-3 border-0 shadow p-2 mb-3 mb-xl-0 mt-2">
                        <table className="table table-hover table-striped table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">SKU Id</th>
                                    <th scope="col">Category Name</th>
                                    <th scope="col">Sub Category Name</th>
                                    <th scope="col">Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentStock?.result?.map((obj: any, index: number) => {
                                    return (
                                        <tr className="cursor-pointer" onClick={() => showInventoryList(obj)}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{obj?.name}</td>
                                            <td>{obj?.sku_id}</td>
                                            <td>{obj?.category?.name}</td>
                                            <td>{obj?.sub_category?.name}</td>
                                            <td>{obj?.count}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div> : <DataNotFound Error="No Product Found" />}


            </React.Fragment>
        )
    }

    const renderInventoryHistory = (convertArray: any, handleBranchChange?: any, filteredCabinet?: any, filter?: any, handleCabinetChange?: any, onStartDate?: any, onEndDate?: any, stockHistory?: any) => {
        const { result = [] } = stockHistory || {}
        return (
            <React.Fragment>
                <div className="row mt-2">
                    <div className={"col-sm-3"}>
                        <AppDatePicker
                            selected={filter?.fromDate}
                            placeholder="From"
                            maxDate={new Date()}
                            onChange={(date: any) => onStartDate(date)}
                            notValidTime={true}
                            id='FormDatePicker'
                        />
                    </div>
                    <div className={"col-sm-3"}>
                        <AppDatePicker
                            selected={filter?.toDate}
                            placeholder="To"
                            maxDate={new Date()}
                            onChange={(date: any) => onEndDate(date)}
                            notValidTime={true}
                            minDate={filter?.fromDate}
                            id='ToDatePicker'
                        />
                    </div>
                    <div className={"col-sm-3"}>
                        <BranchTypeAhead
                            options={options}
                            setOptions={setOptions}
                            vendorId={role == 1 ? vendorId : user_id}
                            isLoading={false}
                            placeholder={"Choose Branch"}
                            onChange={(event: any) => handleBranchChange(event)}
                        />
                    </div>
                    <div className={"col-sm-3"}>
                        <Select
                            placeHolder={"All Cabinets"}
                            array={filteredCabinet}
                            isList={true}
                            disabled={filteredCabinet?.length === 0}
                            value={filter?.cabinetId}
                            onChange={(event: any) => handleCabinetChange(event)}
                        />
                    </div>
                </div>
                {
                    result?.length > 0 ? result?.map((obj: any, index: number) => {
                        let filledArray: any = []
                        let purchasedArray: any = []
                        obj?.inventory_history.forEach((element: any) => {
                            if (element?.update_type === 1) {
                                filledArray.push({ ...element })
                            } else {
                                purchasedArray.push({ ...element })
                            }
                        });
                        return (
                            <div className="card card-body rounded-3 shadow border-1 p-3 mb-xl-0 mt-4" key={index}>
                                <div className="row">
                                    <div className="col-sm text-center">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold' }}>Reference ID</span>
                                        </div>
                                        <div className="row">
                                            <span className="mt-2">#{obj?.reference_id}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm text-center">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold' }}>Branch Name</span>
                                        </div>
                                        <div className="row">
                                            <span className="mt-2"> <i className="fas fa-store-alt"></i>{obj?.branch?.name}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm text-center">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold' }}>Cabinet Name</span>
                                        </div>
                                        <div className="row">
                                            <span className="mt-2"><i className="fa fa-boxes"></i> {obj?.cabinet?.name}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm text-center">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold' }}>Date - Time</span>
                                        </div>
                                        <div className="row">
                                            <span className="mt-2"><i className="fa fa-clock-o"></i> {moment(obj?.end_time).format('MM/DD/YYYY - hh:mm A')}</span>
                                        </div>
                                    </div>
                                </div>
                                {obj?.inventory_history?.length > 0 &&
                                    <div className="p-2 mt-1">
                                        {filledArray?.length > 0 && <div>
                                            <span className="green-color font-bold">Refilled:</span>
                                            <ul>
                                                {filledArray?.map((subObj: any) => {
                                                    return (
                                                        <li
                                                            style={{ textTransform: 'lowercase' }}>
                                                            {subObj?.count} x {subObj?.product?.name} refilled in shelf {subObj?.shelf?.name}
                                                        </li>
                                                    )
                                                })}
                                            </ul>

                                        </div>}

                                        {purchasedArray?.length > 0 && <div className="mt-1">
                                            <span className="red-color font-bold">Purchased:</span>
                                            <ul>
                                                {purchasedArray?.map((subObj: any) => {
                                                    return (
                                                        <li
                                                            style={{ textTransform: 'lowercase' }}>
                                                            {subObj?.count} x {subObj?.product?.name} purchased in shelf {subObj?.shelf?.name}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>}

                                        {/* <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Count</th>
                                                <th scope="col">Shelf</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Time</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {obj?.inventory_history?.map((subObj: any, subIndex: number) => {
                                                return (
                                                    <tr key={subIndex}>
                                                        <td>{subObj?.product?.name}</td>
                                                        <td>{subObj?.count}</td>
                                                        <td>{subObj?.shelf?.name}</td>
                                                        <td className={`${subObj?.update_type === 1 ? 'green-color' : 'red-color'}`}>
                                                            {subObj?.update_type === 1 ? 'Refilled' : 'Purchased'}
                                                        </td>
                                                        <td>{moment(subObj?.updated_date).format('MM/DD/YYYY - hh:mm A')}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table> */}
                                    </div>}
                                {/* <div className="row">
                                    {purchasedArray?.length > 0 && <div className="col-sm-6">
                                        <p className="font-bold red-color">Purchased</p>
                                        {purchasedArray?.map((subObj: any, index: number) => {
                                            return (
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p>Products :{subObj?.product?.name}</p>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <p>Shelf :{subObj?.shelf?.name}</p>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <p>Last Updated :{moment(subObj?.updated_date).format('MM/DD/YYYY - hh:mm A')}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>}

                                    {filledArray?.length > 0 && <div className="col-sm-6">
                                        <p className="font-bold red-color">Refilled</p>

                                      
                                    </div>}
                                </div> */}
                            </div>
                        )
                    }

                    )
                        : <DataNotFound Error="No History Found" />
                }


            </React.Fragment>
        )
    }

    const showContent = () => {
        return (
            <div className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0 mt-4 ">
                <table className="table table-hover table-striped table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Cabinet Name</th>
                            <th scope="col">Shelf</th>
                            <th scope="col">planogram</th>
                        </tr>
                    </thead>

                    <tbody>
                        {recentInventory?.map((obj: any, index: number) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{obj?.cabinet?.name}</td>
                                    <td>{obj?.shelf?.name}</td>
                                    <td className={`${obj?.cabinet?.planogram ? 'green-color' : 'red-color'} ml-1`}>{obj?.cabinet?.planogram ? "Available" : "Not available"}</td>
                                </tr>
                            )
                        })}
                        {
                            recentInventory?.length === 0 &&
                            <tr>
                                <td className="text-center" colSpan={4}>{MESSAGE.NO_RECORD_FOUND} </td>
                            </tr>
                        }

                    </tbody>
                </table>
            </div>
        )
    }

    const { result = [] } = branchDetails || {}
    const convertArray = result?.map((obj: any) => ({ value: obj.id, ...obj }))
    const { role, user_id } = loginDetails
    const convertVendorArray = vendorList?.vendor_details?.map((obj: any) => ({ value: obj.vendor_id, name: obj.first_name, ...obj }))
    return (
        <>
            {
                isloading && <PlanogramLoader />
            }
            <div className="row mt-4">
                <div className="col-sm-6">
                    <h3 className="font-bold">{MESSAGE.INVENTORY_MANAGEMENT}</h3>
                </div>
            </div>
            <div className="mt-3">
                <nav className="nav nav-pills nav-fill inventory-nav">
                    <span className={`nav-link cursor-pointer ${currentPage === 'recent' ? "active" : ""}`} aria-current="page" onClick={() => changeTab("recent")}>Current Stock </span>
                    <span className={`nav-link cursor-pointer ${currentPage === 'history' ? "active" : ""}`} aria-current="page" onClick={() => changeTab('history')}>History </span>
                </nav>
                {currentPage === 'recent' ? renderRecentInventory(convertArray, handleBranchChange,
                    filteredCabinet, filter, handleCabinetChange, currentStock) :
                    renderInventoryHistory(convertArray, handleBranchChange,
                        filteredCabinet, filter, handleCabinetChange, onStartDate, onEndDate, stockHistory)}
                {
                    ((currentPage === "recent" && currentStock?.total_count > 1) || (currentPage === "history" && stockHistory?.total_count > 1)) &&
                    <PaginationSession
                        onChangeHandle={handlePage}
                        currentPage={currentPageNumber}
                        sessionCount={currentPage === 'recent' ? currentStock?.total_count : stockHistory?.total_count} />
                }

            </div>
            {isPopUp && <PopupData
                close={() => setpopUp(false)}
                content={showContent()}
            />}
        </>
    )


}




