import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
// import logo from '../../../assets/img/ustLogo.png'
import logo from '../../../assets/img/cv-logo.png'
// import logo from '../../../assets/img/Cv-Logo.png'

import { MESSAGE } from '../../../config/String'
import { getLoginData } from '../../../redux/reducer/login'
import { SignOutPopup } from '../Widgets/SignOutPopup'
export const SideBar = () => {
    const [signoutPopup, setSignoutPopup] = useState(false)
    const usersDetails = useSelector(getLoginData)
    const SignOut = () => {
        setSignoutPopup(true)
    }
    const location = useLocation()


    const renderAdminRoutes = () => {
        return (
            <React.Fragment>
                <Link to={'/dashboard'} className={location?.pathname === '/dashboard' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-tachometer fa-lg planogramIcon" aria-hidden="true"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.DASHBOARD}</span>
                </Link>

                {/* <Link to={'/dashboard'} className={location?.pathname === '/dashboard' ? "nav_link nav_active" : "nav_link"}>
                                    <i className="fa fa-home fa-lg planogramIcon" aria-hidden="true"></i>
                                    <span className="nav_name">{MESSAGE.TRANSACTION}</span>
                                </Link> */}
                {/* <Link to={'/cameralist'} className={location?.pathname === '/cameralist' || location?.pathname === '/viewdetails' ? "nav_link nav_active" : "nav_link"}>
                                    <i className="fa-solid fa-camera fa-lg planogramIcon"></i>
                                    <span className="nav_name">{MESSAGE.CABINET}</span>
                                </Link>
                                <Link to={'/cabinetOnboarding'} className={(location?.pathname === '/cabinetOnboarding' || location?.pathname === '/cabinetOnboarding/edit') ? "nav_link nav_active" : "nav_link"}>
                                    <i className="fa fa-plus-circle fa-lg planogramIcon" aria-hidden="true"></i>
                                    <span className="nav_name">{MESSAGE.CABINET_ONBOARDING}</span>
                                </Link> */}

                <Link to={'/vendors'} className={location?.pathname.includes('/vendor') ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-users fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.VENDOR}</span>
                </Link>
                <Link to={'/category'} className={location?.pathname.includes('/category') ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-list-alt fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.CATEGORY}</span>
                </Link>
                <Link to={'/subCategory'} className={location?.pathname.includes('/subCategory') ? "nav_link nav_active" : "nav_link"}>
                    <i className="fas fa-newspaper fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.SUB_CATEGORY}</span>
                </Link>
                <Link to={'/reports'} className={location?.pathname === '/reports' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-file fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.REPORTS}</span>
                </Link>
                <Link to={'/troubleshoot'} className={location?.pathname === '/troubleshoot' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-exclamation-triangle planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.TROUBLE_SHOOT}</span>
                </Link>

                <Link to={'/healthcheck'} className={location?.pathname === '/healthcheck' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-check fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.HEALTH_CHECK}</span>
                </Link>
                {/* <Link to={'/subscription'} className={location?.pathname === '/subscription' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-money-bill fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.SUBSCRIPTION}</span>
                </Link> */}
                <Link to={'/testing'} className={location?.pathname === '/testing' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-cog fa-lg  planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.TESTING}</span>
                </Link>
                <Link to={'/edgeserver'} className={location?.pathname === '/edgeserver' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-server fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.EDGE_SERVERS}</span>
                </Link>
            </React.Fragment>
        )
    }

    const renderVendorRoutes = () => {
        return (
            <React.Fragment>
                <Link to={'/dashboard'} className={location?.pathname === '/dashboard' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-tachometer fa-lg planogramIcon" aria-hidden="true"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.DASHBOARD}</span>
                </Link>

                <Link to={'/branches'} className={location?.pathname.includes('/branch') ? "nav_link nav_active" : "nav_link"}>
                    <i className="fas fa-store-alt fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.BRANCHES}</span>
                </Link>

                <Link to={'/products'} className={location?.pathname === '/products' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-product-hunt fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.PRODUCTS}</span>
                </Link>
                <Link to={'/cabinets'} className={location?.pathname === '/cabinets' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-boxes fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.CABINETS}</span>
                </Link>

                <Link to={'/customer/journey'} className={location?.pathname === '/customer/journey' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-user fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.CUSTOMER_JOURNEY}</span>
                </Link>

                <Link to={'/inventory/management'} className={location?.pathname.includes('/inventory') ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-file-alt fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.INVENTORY_MANAGEMENT}</span>
                </Link>


                <Link to={'/reports'} className={location?.pathname === '/reports' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-file fa-lg  planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.REPORTS}</span>
                </Link>

                <Link to={'/healthcheck'} className={location?.pathname === '/healthcheck' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-check fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.HEALTH_CHECK}</span>
                </Link>

                <Link to={'/testing'} className={location?.pathname === '/testing' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-cog fa-lg  planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.TESTING}</span>
                </Link>

                <Link to={'/edgeserver'} className={location?.pathname === '/edgeserver' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-server fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.EDGE_SERVERS}</span>
                </Link>

                <Link to={'/troubleshoot'} className={location?.pathname === '/troubleshoot' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-exclamation-triangle fa-lg planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.TROUBLE_SHOOT}</span>
                </Link>
                {/* <Link to={'/planogram'} className={location?.pathname === '/planogram' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-wrench fa-lg  planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.PLANOGRAM}</span>
                </Link>

                <Link to={'/subscription'} className={location?.pathname === '/subscription' ? "nav_link nav_active" : "nav_link"}>
                    <i className="fa fa-money-bill fa-lg  planogramIcon"></i>
                    <span className="nav_name">{MESSAGE.VENDOR.SUBSCRIPTION}</span>
                </Link> */}
            </React.Fragment>
        )
    }
    const { role } = usersDetails || {}
    return (
        <>
            <div className="sideBarContainer" style={{ width: "350px" }}>
                <div className="l-navbar" id="nav-bar">
                    <nav className="nav-sidebar">
                        <React.Fragment>
                            <Link to={'/dashboard'} className="nav_logo">
                                <i className='bx bx-layer nav_logo-icon'></i>
                                <div className="nav_logo-name">
                                    <div className="header_img"> <img src={logo} alt="Logo" /> </div>
                                </div>
                            </Link>
                            <hr />
                            <div className="nav_list sidebar-overflow">
                                {role === 2 ? renderVendorRoutes() : renderAdminRoutes()}
                                <div className="nav_link signOut" onClick={() => SignOut()}>
                                    <i className='fa fa-sign-out fa-lg signOutIcon'></i>
                                    <span className="nav_name">{MESSAGE.SIGN_OUT}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    </nav>
                </div>
            </div>
            {
                signoutPopup && <SignOutPopup close={() => setSignoutPopup(false)} />
            }
        </>
    )
}