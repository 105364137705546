import { doAction, doResponseAction } from ".";

const DASHBOARD_LIST = 'DASHBOARD_LIST';
const DASHBOARD_LIST_SUCCESS = 'DASHBOARD_LIST_SUCCESS';
const DASHBOARD_LIST_FAILURE = 'DASHBOARD_LIST_FAILURE';

export {
    DASHBOARD_LIST,
    DASHBOARD_LIST_FAILURE,
    DASHBOARD_LIST_SUCCESS,
}

// DASHBOARD_LIST
export const dashboardList = (params?: {}, callback?: any) => doAction(DASHBOARD_LIST, params, callback);
export const dashboardListSuccess = (data = {}) => doResponseAction(DASHBOARD_LIST_SUCCESS, data);
export const dashboardListFailure = (data = {}) => doResponseAction(DASHBOARD_LIST_FAILURE, data);
