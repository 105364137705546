import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { ResponseGenerator } from './SagaInterface';
import { doGet, doPost } from '../../config';
import END_POINTS from '../../config/Endpoints';
export function* autoFillSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost( params, '',"")
        callback && callback(response?.response)
        yield put(Actions.autoFillSuccess(response));
    } catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.autoFillFailure({ error }));
    }
}
export default function* autoFillWatcher() {
    yield all([
        takeLatest(Actions.AUTO_FILL, autoFillSaga),
    ]);
}