import { doAction, doResponseAction } from ".";

const GET_PLANOGRAM = 'GET_PLANOGRAM';
const GET_PLANOGRAM_SUCCESS = 'GET_PLANOGRAM_SUCCESS';
const GET_PLANOGRAM_FAILURE = 'GET_PLANOGRAM_FAILURE';
const SET_ANNOTATION_PLANOGRAM = 'SET_ANNOTATION_PLANOGRAM';

const CREATE_PLANOGRAM = "CREATE_PLANOGRAM";
const CREATE_PLANOGRAM_SUCCESS = "CREATE_PLANOGRAM_SUCCESS";
const CREATE_PLANOGRAM_FAILURE = "CREATE_PLANOGRAM_FAILURE";

const CAPTURE_SHELF = "CAPTURE_SHELF";
const CAPTURE_SHELF_SUCCESS = "CAPTURE_SHELF_SUCCESS";
const CAPTURE_SHELF_FAILURE = "CAPTURE_SHELF_FAILURE";
const FETCH_FRAME = "FETCH_FRAME";

const TRAINED_PRODUCT = "TRAINED_PRODUCT";
const TRAINED_PRODUCT_SUCCESS = "TRAINED_PRODUCT_SUCCESS";
const TRAINED_PRODUCT_FAILURE = "TRAINED_PRODUCT_FAILURE";

const ACTIVE_PLANOGRAM = "ACTIVE_PLANOGRAM";
const ACTIVE_PLANOGRAM_SUCCESS = "ACTIVE_PLANOGRAM_SUCCESS";
const ACTIVE_PLANOGRAM_FAILURE = "ACTIVE_PLANOGRAM_FAILURE";
const REMOVE_ANNOTATION_PLANOGRAM = "REMOVE_ANNOTATION_PLANOGRAM"
export {
    GET_PLANOGRAM,
    GET_PLANOGRAM_FAILURE,
    GET_PLANOGRAM_SUCCESS,
    SET_ANNOTATION_PLANOGRAM,
    REMOVE_ANNOTATION_PLANOGRAM,

    CREATE_PLANOGRAM,
    CREATE_PLANOGRAM_SUCCESS,
    CREATE_PLANOGRAM_FAILURE,

    CAPTURE_SHELF,
    CAPTURE_SHELF_SUCCESS,
    CAPTURE_SHELF_FAILURE,
    FETCH_FRAME,

    TRAINED_PRODUCT,
    TRAINED_PRODUCT_SUCCESS,
    TRAINED_PRODUCT_FAILURE,

    ACTIVE_PLANOGRAM,
    ACTIVE_PLANOGRAM_SUCCESS,
    ACTIVE_PLANOGRAM_FAILURE
}
// Get_Planogram
export const getPlanogram = (params?: {}, callback?: any) => doAction(GET_PLANOGRAM, params, callback);
export const getPlanogramSuccess = (data: {}) => doResponseAction(GET_PLANOGRAM_SUCCESS, data);
export const getPlanogramFailure = (data: {}) => doResponseAction(GET_PLANOGRAM_FAILURE, data);
export const setAnnotaionPlanogram = (data: {}) => doResponseAction(SET_ANNOTATION_PLANOGRAM, data);
export const removeAnnotationPlanogram = (data: {}) => doResponseAction(REMOVE_ANNOTATION_PLANOGRAM, data);

//CREARE_PLANOGRAM
export const createPlanogram = (params?: {}, callback?: any) => doAction(CREATE_PLANOGRAM, params, callback);
export const createPlanogramSuccess = (data: {}) => doResponseAction(CREATE_PLANOGRAM_SUCCESS, data);
export const createPlanogramFailure = (data: {}) => doResponseAction(CREATE_PLANOGRAM_FAILURE, data);

//CAPTURE_SHELF
export const captureShelf = (params?: {}, callback?: any) => doAction(CAPTURE_SHELF, params, callback);
export const captureShelfSuccess = (data: {}) => doResponseAction(CAPTURE_SHELF_SUCCESS, data);
export const captureShelfFailure = (data: {}) => doResponseAction(CAPTURE_SHELF_FAILURE, data);
export const fetchFrame = (data: {}) => doResponseAction(FETCH_FRAME, data);


//TRAINED PRODUCT
export const trainedProduct = (params?: {}, callback?: any) => doAction(TRAINED_PRODUCT, params, callback);
export const trainedProductSuccess = (data: {}) => doResponseAction(TRAINED_PRODUCT_SUCCESS, data);
export const trainedProductFailure = (data: {}) => doResponseAction(TRAINED_PRODUCT_FAILURE, data);

//ACTIVATE_PLANOGRA,
export const activatePlanogram = (params?: {}, callback?: any) => doAction(ACTIVE_PLANOGRAM, params, callback);
export const activatePlanogramSuccess = (data: {}) => doResponseAction(ACTIVE_PLANOGRAM_SUCCESS, data);
export const activatePlanogramFailure = (data: {}) => doResponseAction(ACTIVE_PLANOGRAM_FAILURE, data);