const END_POINTS = {
    API_BASE_URL: "https://rfcontrol-poc-api.proglint.com/",
    LOGIN: "api/login",
    CAMERA: "api/cameras",
    SUBSCRIPTION: "/api/subscription/list",
    MASTER_SUBSCRIPTION: "/api/master/subscription/list",
    MASTER_SUBSCRIPTION_CREATE: "/api/master/subscription/create",
    MASTER_SUBSCRIPTION_UPDATE: "/api/master/subscription/details/update",
    LOG_OUT: "api/logout",
    MASTER_SUBSCRIPTION_STATUS_UPDATE: "/api/master/subscription/status/update",
    GET_PLANOGRAM: "api/get_planogram",
    UPDATE_PLANOGRAM: "/api/planogram/annotation/save",
    GENERATE_ID: "product/get_training_id",
    REGISTER_PRODUCT: "product/register_product",
    SESSION_START: "avm/session/start",
    SESSION_END: "avm/session/stop",
    SESSION_START_MOBILE: "session/start",
    SESSION_END_MOBILE: "session/stop",
    SESSION_COMPLETED: "avm/session/complete",
    PLANOGRAM_STATUS: "/api/toggle_planogram_status",
    CABINET_ONBOARDING: "/cabinet_onboarding/create",
    DELETE_CABINET: "cabinet_onboarding/delete",
    UPDATE_CABINET: "/cabinet_onboarding/update",
    // Vendors API
    VENDOR_ONBOARDING_LIST: "api/vendor/list",
    VENDOR_ONBOARDING_DETAILS: "",
    CREATE_VENDOR: "/api/vendor/create",
    VENDOR_ONBOARDING_DELETE: "",
    VENDOR_ONBOARDING_UPDATE: "",
    VENDOR_ONBOARDING_ACTIVE: "/api/vendor/status/update",

    BRANCHES_LIST: "/api/branch_list",
    CREATE_BRANCHES: "/api/branch_onboarding",
    BRANCHES_DELETE: "/api/branch_delete",
    BRANCHES_UPDATE: "/api/branch_update",
    BRANCHES_ACTIVE: "/api/branch_disable",

    SEARCH_CABINET:"/api/searchCabinet",
    DASHBOARD_LIST: "/api/dashboard",
    TROUBLE_SHOOT_LIST: "/api/troubleshoot",
    HEALTH_CHECK_LIST: "api/camera_check",
    RESET_PASSWORD: "",
    BRANCHES_DETAILS: "",

    PUBLISH_PRODUCT_LIST: '/api/productlist',
    REQUEST_PRODUCT_LIST: "",
    CREATE_PRODUCT: "/api/vendor/productonboarding",
    PRODUCT_DELETE: "/api/branch_delete",
    VENDOR_PRODUCT_UPDATE: "/api/vendor/product/update/detail",

    ADMIN_PRODUCT_UPDATE: "/api/admin/product/update/detail",
    ADMIN_PRODUCT_PUBLISH:"/api/admin/product/publish/update",
    ADMIN_CREATE_PRODUCT: "/api/productonboarding",
    ADMIN_PRODUCT_LIST: "/api/admin_product_list",

    VENDOR_PRODUCT_ACTIVE: "/api/vendor_product_update",
    ADMIN_PRODUCT_ACTIVE: "api/admin_product_update",

    CATEGORY_LIST: '/api/categorylist',
    CREATE_CATEGORY: '/api/category',
    UPDATE_CATEGORY: 'api/category/update',
    SUB_CATEGORY_LIST: '/api/subcategorylist',
    CREATE_SUB_CATEGORY: '/api/subcategory',
    UPDATE_SUB_CATEGORY: 'api/subcategory/update',
    CUSTOMER_JOURNEY: "/api/customerjourney",

    INVENTORY_MANAGEMENT: "api/vendor/inventory/products",
    INVENTORY_MANAGEMENT_HISTORY: "api/vendor/inventory/history",

    VENDOR_EDGE_SERVER_LIST: "/api/vendor/edge/list",
    CREATE_EDGE_SERVER: "/api/edge/create",
    UPDATE_EDGE_SERVER: "/api/edge/update",
    EDGE_SERVER_LIST: "/api/edge/list",

    REPORT: "/api/report",
    REPORT_LIST: "/api/report_list",

    CREATE_PLANOGRAM: "/api/planogram/create",
    CAPTURE_SHELF:"/api/planogram/shelf/capture",
    TRAINED_PRODUCT:"/api/product/trained",

    ACTIVATE_PLANOGRAM:"api/planogram/publish",
    IMG_URL: "http://192.168.1.52:9100",


}
export const autoFill=(params:any)=>{
    return `/planogram/autofill/${params?.planogram_id}/${params?.cabinet_id}/${params?.shelf_id}`

}
export const GetPlanogramList = (id: string, cabinetId: string) => {
    return `/api/get_planogram?cabinet_id=${cabinetId}&shelf_id=${id}`
}
export const sessionCabinet = (sessionId: string, cabinet_name: string) => {
    return `/api/session_cabinet_status?cabinet_id=${cabinet_name}&session_id=${sessionId}`
}
export const searchListEndPoint = (searchValue: string) => {
    return `product/product_search?searching_value=${searchValue}`
}
export const cabinetSearchListEndPoint = (searchValue: string, limit?: any, currentPage?: any,vendorId?:any,store?:any) => {
    return `api/searchCabinet?branchId=${searchValue}&limit=${limit}&pagenum=${currentPage}&vendorId=${vendorId}&storeId=${store}`
}
export const ProductListEndPoint = (pageNumber: number) => {
    return `product/product_list?page=${pageNumber}`
}
export const ProductListSearch = (sku: string) => {
    return `product/product_list?sku=${sku}`
}
export const unregisterProductEndPoint = (pageNumber: number) => {
    return `product/unregistered_product?page=${pageNumber}`
}
export const historyEndPoint = (id: string, pageNo: number) => {
    return `api/planogram_history?camera_id=${id}&page=${pageNo}`
}
export const cabinetList = (pageNumber: number) => {
    return `/api/list_cabinets?page=${pageNumber}`
}
export const editCabinet = (cabinetId: number) => {
    return `/cabinet_onboarding/cabinet_detail?cabinet_id=${cabinetId}`
}
export default END_POINTS;