import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
export function* sessionCabinetsaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(params, '', '')
        yield put(Actions.session_cabinetSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.session_cabinetFailure({ error }));
        callback && callback(error)
    }
}

export default function* sessionCabinetWatcher() {
    yield all([
        takeLatest(Actions.SESSION_CABINET, sessionCabinetsaga),
    ]);
}