import { doAction, doResponseAction } from ".";

const HEALTH_CHECK_LIST = 'HEALTH_CHECK_LIST';
const HEALTH_CHECK_LIST_SUCCESS = 'HEALTH_CHECK_LIST_SUCCESS';
const HEALTH_CHECK_LIST_FAILURE = 'HEALTH_CHECK_LIST_FAILURE';
const REMOVE_CHECK_LIST = 'REMOVE_CHECK_LIST';

export {
    HEALTH_CHECK_LIST,
    HEALTH_CHECK_LIST_FAILURE,
    HEALTH_CHECK_LIST_SUCCESS,
    REMOVE_CHECK_LIST
}

// HEALTH_CHECK_LIST
export const healthCheckList = (params?: {}, callback?: any) => doAction(HEALTH_CHECK_LIST, params, callback);
export const healthCheckListSuccess = (data = {}) => doResponseAction(HEALTH_CHECK_LIST_SUCCESS, data);
export const healthCheckListFailure = (data = {}) => doResponseAction(HEALTH_CHECK_LIST_FAILURE, data);
export const removeCheckList = (data = {}) => doResponseAction(REMOVE_CHECK_LIST, data);
