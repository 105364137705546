import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    edgeServer: {},
    error: false,
    vendorEdgeServer: []
}
export const EdgeServerReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.EDGE_SERVER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.EDGE_SERVER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                edgeServer: action.data.response,
                error: false
            };
        }
        case Actions.EDGE_SERVER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.data.error,
            };
        }

        case Actions.VENDOR_EDGE_SERVER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_EDGE_SERVER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                edgeServer: action.data.response,
                vendorEdgeServer: action.data.response,
                error: false
            };
        }
        case Actions.VENDOR_EDGE_SERVER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.data.error,
            };
        }


        case Actions.EDGE_SERVER_CREATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.EDGE_SERVER_CREATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.EDGE_SERVER_CREATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.data.error,
            };
        }

        case Actions.EDGE_SERVER_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.EDGE_SERVER_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.EDGE_SERVER_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.data.error,
            };
        }

        case Actions.EDGE_INITIAL_STATE: {
            return {
                ...state,
                ...initialState
            };
        }

        default:
            return state;
    }
};

export const getEdgeServerDetails = (state: any) => state?.EDGE_SERVER;
export const getEdgeDetails = (state: any) => state?.EDGE_SERVER?.edgeServer;

export const getVendorEdgeServerDetails = (state: any) => state?.EDGE_SERVER?.vendorEdgeServer;
