import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    customerJourneyData: {},
    error: false
}
export const customerJourneyReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.CUSTOMER_JOURNEY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CUSTOMER_JOURNEY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerJourneyData: action.data,
                error: false
            };
        }
        case Actions.CUSTOMER_JOURNEY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                customerJourneyData: {},
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getCustomerJourneyData = (state: any) => state?.CUSTOMER_JOURNEY?.customerJourneyData?.response;
export const getCustomerJourneyCount = (state: any) => state?.CUSTOMER_JOURNEY?.customerJourneyData?.total_count;

