import moment from "moment"

export const ViewSubscription = ({ close, obj }: any) => {
    return (
        <>
            <div className="modal d-block" id="loader" aria-labelledby="loader" aria-hidden="true">
                <div className={"modal-dialog modal-dialog-centered addProductPopup"} >
                    <div className={"modal-content p-4"} style={{ width: "700px", height: "auto" }}>
                        <i className="fa fa-times fa-2x closeIcon" aria-hidden="true"
                            onClick={() => close()}></i>
                        <div className={"modal-body"}>
                            <div className="row">
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col-4 fw-bold">Vendor Name:</div>
                                        <div className="col-8">{obj?.control_tower.store.vendor.first_name}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 fw-bold">Subscription Name:</div>
                                        <div className="col-8">{obj?.master_subscription.subscription_name}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 fw-bold">Subscription Type:</div>
                                        <div className="col-8">{obj?.master_subscription.subscription_type.subscription_type}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 fw-bold">Status:</div>
                                        <div className="col-8">{obj?.action_type}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 fw-bold">Control tower name:</div>
                                        <div className="col-8">{obj?.control_tower?.name}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 fw-bold">Start Date:</div>
                                        <div className="col-8">{moment(obj?.start_date).format("DD-MM-YYYY")}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 fw-bold">End Date:</div>
                                        <div className="col-8">{moment(obj?.end_date).format("DD-MM-YYYY")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show loaderBackGround"></div>
        </>
    )
}