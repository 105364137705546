import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    reportList: [],
    reportListApi: [],
    error: false
}
export const ReportReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.REPORT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.REPORT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reportList: action.data,
                error: false
            };
        }
        case Actions.REPORT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reportList: [],
                error: action.data.error,
            };
        }
        case Actions.REPORT_LIST_API: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.REPORT_LIST_API_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reportListApi: action.data?.filter || [],
                error: false
            };
        }
        case Actions.REPORT_LIST_API_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reportListApi: [],
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};
export const getReportListData = (state: any) => state?.REPORT_LIST?.reportList;
export const getReportLoadingData = (state: any) => state?.REPORT_LIST?.isLoading;
export const getReportApiListData = (state: any) => state?.REPORT_LIST?.reportListApi;
