import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import END_POINTS from '../../config/Endpoints';
import { doPostMultiform } from '../../config/index'
import { ResponseGenerator } from './SagaInterface';
export function* generateTrainingSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPostMultiform(END_POINTS.GENERATE_ID, params, '')
        callback && callback(response?.response)
        yield put(Actions.generateId_Success(response));
    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.generateId_Failure({ error }));
        }
    }
}
export default function* genrate_Id_Watcher() {

    yield all([
        takeLatest(Actions.GENERATE_ID, generateTrainingSaga),
    ]);
}