
export const AlertMolecules = ({ close, content }: { close: (setPopup: boolean) => void, content?: any }) => {
    return (
        <>
            <div>
                <div className="modal d-block" id="loader" aria-labelledby="loader" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-4">
                            <i className="fa fa-times fa-2x closeIcon" aria-hidden="true" onClick={() => close(false)}></i>

                            {content && <div className="modal-body">
                            {content}
                        </div>}

                        </div>
                       
                    </div>
                </div>
                <div className="modal-backdrop show loaderBackGround"></div>
            </div>
        </>
    )
}
