import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    categoryList: [],
    subCategoryList: [],
    error: false
}
export const CategoryReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.CATEGORY_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CATEGORY_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                categoryList: action.data?.response,
                error: false
            };
        }
        case Actions.CATEGORY_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                categoryList: [],
                error: action.data.error,
            };
        }
        //CREATE_CATEGORY
        case Actions.CATEGORY_CREATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CATEGORY_CREATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CATEGORY_CREATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        //UPDATE_CATEGORY
        case Actions.CATEGORY_UPDATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CATEGORY_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CATEGORY_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        case Actions.SUB_CATEGORY_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SUB_CATEGORY_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                subCategoryList: action.data?.response,
                error: false
            };
        }
        case Actions.SUB_CATEGORY_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                subCategoryList: [],
                error: action.data.error,
            };
        }
         //CREATE_SUB_CATEGORY
         case Actions.CREATE_SUB_CATEGORY: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CREATE_SUB_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CREATE_SUB_CATEGORY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        //UPDATE_SUB_CATEGORY
        case Actions.UPDATE_SUB_CATEGORY: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.UPDATE_SUB_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.UPDATE_SUB_CATEGORY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        default:
            return state;
    }
};
export const getCategoryListData = (state: any) => state?.CATEGORY?.categoryList;
export const getSubCategoryListData = (state: any) => state?.CATEGORY?.subCategoryList;
export const getCategoryLoader = (state: any) => state.CATEGORY.isLoading
