import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import AppDatePicker from "../../UI/Molecules/AppDatePicker"
import BarGraph from "../Widgets/Charts/BarChart"
import { Actions } from "../../../redux/action"
import { getDashboardData, getDashboardLoader } from "../../../redux/reducer/DashboardReducer"
import { getLoginData } from "../../../redux/reducer/login";
import { useSelector } from "react-redux"
import BranchTypeAhead from '../../../components/HOC/Widgets/BranchTypeAhead'
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { toast } from "react-toastify"


export const DashBoard = () => {
    const dispatch = useDispatch()
    const dashboardDetails = useSelector(getDashboardData)
    const usersDetails = useSelector(getLoginData)
    const isLoading = useSelector(getDashboardLoader)
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [branchObject, setBranchObject] = useState();
    const [filter, setFilter] = useState("date")

    // useEffect(() => {
    //     getDashboardList()
    // }, [dispatch])

    // const getDashboardList = () => {
    //     dispatch(Actions.dashboardList())
    // }
    const dashboardCallBack = (response: any) => {
        // if (response?.status_code !== 0) {
        //     toast.error(response?.message)
        // }
    }
    useEffect(() => {
        const { user_id } = usersDetails || {}
        let params = {
            vendor_id: user_id,
            filter: filter
        }
        dispatch(Actions.dashboardList(params, dashboardCallBack))
    }, [dispatch, filter])

    const data = React.useMemo(() => dashboardDetails, [dashboardDetails]);
    const addBranch = (value: any) => {
        setBranchObject(value?.value)
    }
    //Clear Input Fields
    const clear = () => {
        setFromDate(null);
        setToDate(null);
        if (fromDate || toDate) {
            // getDashboardList()
        }
    }
    const handleDashboardChange = (event: any, flag: boolean) => {
        setFilter(event?.target?.value)
    }
    return (
        <div className="container mt-3 mb-4">
            <div className="row">
                {/* <div className="row mb-3">
                    <div className="col-12 pb-3">
                        <div className='row'>
                            <div className='col-8 col-sm-4'>Select the Filter for Customer Journey</div>
                            <div className='col-4 col-sm-8 text-end pl-2'>
                                <span className='cursor-pointer fw-bold' id='clear' onClick={clear}>Clear</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-4">
                        <AppDatePicker
                            selected={fromDate}
                            placeholder="From"
                            maxDate={new Date()}
                            onChange={(date: any) => setFromDate(date)}
                            notValidTime={true}
                            id='FormDatePicker'
                        />
                    </div>
                    <div className="col-6 col-sm-4 col-lg-4">
                        <AppDatePicker
                            selected={toDate}
                            placeholder="To"
                            maxDate={new Date()}
                            onChange={(date: any) => setToDate(date)}
                            notValidTime={true}
                            minDate={fromDate}
                            id='ToDatePicker'
                        />
                    </div>
                    <div className="col-4" >
                        <div className="test" >
                            <BranchTypeAhead
                                placeholder="Select Branch"
                                onChange={addBranch}
                            />
                        </div>

                        <select name="" className="form-select form_custom"
                            id="reportType"
                            defaultValue={'date'}
                            onChange={(event: any) => handleDashboardChange(event, false)}
                        >
                            <option value="date">Day wise</option>
                            <option value="week">Week wise</option>
                            <option value="month">Month wise</option>
                            <option value="year">Year wise</option>
                        </select>
                    </div>
                </div> */}


                <div className="row mb-4 mt-3">
                    <div className="col-12 col-md-6 col-xl-4">
                        <div id="store-info" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0 ">
                            <h5 className="font15 fw-bold text-uppercase mb-3">Branch Information</h5>
                            <div className="row">
                                <div className="col font13 ps-0 lh-lg">
                                    Number of active branches: {data?.response?.active_branch_count ?? 0}<br />
                                    Number of inactive branches: {data?.response?.inactive_branch_count ?? 0} <br />
                                    Total number of branches: {data?.response?.branch_total_count ?? 0} <br />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4">
                        <div id="store-info" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                            <h5 className="font15 fw-bold text-uppercase mb-3">Cabinets Information</h5>
                            <div className="row">
                                <div className="col font13 ps-0 lh-lg">
                                    Number of active cabinets: {data?.response?.cabinet_active_count ?? 0}<br />
                                    Number of inactive cabinets: {data?.response?.cabinet_inactive_count ?? 0}<br />
                                    Total number of cabinets: {data?.response?.cabinet_total_count ?? 0}<br />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <div id="store-info" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                            <h5 className="font15 fw-bold text-uppercase mb-3">Camera Information</h5>
                            <div className="row">
                                <div className="col font13 ps-0 lh-lg">
                                    Number of active cameras: {data?.response?.camera_active_count ?? 0}<br />
                                    Number of inactive cameras: {data?.response?.camera_inactive_count ?? 0}<br />
                                    Total number of cameras: {data?.response?.camera_total_count ?? 0}<br />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-8 col-sm-4'></div>
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                        <select name="" className="form-select form_custom"
                            id="reportType"
                            defaultValue={'date'}
                            onChange={(event: any) => handleDashboardChange(event, false)}
                        >
                            <option value="date">Day wise</option>
                            <option value="week">Week wise</option>
                            <option value="month">Month wise</option>
                            <option value="year">Year wise</option>
                        </select>
                    </div>
                </div>

                <BarGraph
                    data={data?.session || []}
                    filter={filter}
                />
            </div>
            {isLoading && <PlanogramLoader />}
        </div>
    )
}