import { useState, useEffect } from "react"
import Select from "../../UI/Molecules/Select"
import { validatemaxLengthZipReg, validateminLength } from "../../../util/Validations"
import { useDispatch } from "react-redux"
import { Actions } from "../../../redux/action"
import { toast } from "react-toastify"

export const AddMasterSubscription = ({ close, editObject, setEditObject }: any) => {
    const initialState = [{ type: '', price: "", typeError: null, priceError: null }]
    const [subscriptionDetailsCount, setSubscriptionDetailsCount] = useState<number>(0)
    const [subscriptionDetails, setSubscriptionDetails] = useState<{ type: any, price: any, typeError: any, priceError: any }[]>(initialState)
    const [isDeleted, setDeleted] = useState<boolean>(false)
    const [subscriptionName, setSubscriptionName] = useState('')
    const [checkboxValue, setCheckboxValue] = useState(false)
    const [error, setError] = useState({ name: "", limit: "" })
    const subscriptionType = [{ name: 'Quarterly', value: 1 }, { name: 'Half-Yearly', value: 2 }, { name: 'Annual', value: 3 }]

    const dispatch = useDispatch()
    useEffect(() => {
        if (editObject.hasOwnProperty('id')) {
            setCheckboxValue(editObject.is_active)
            setSubscriptionName(editObject.subscription_name)
        }
    }, [])

    //click event to add the subscription input just increase the count
    const addSubscriptionDetails = () => {
        setSubscriptionDetailsCount(subscriptionDetailsCount + 1)
        cameraCountList()
    }

    //append the object in the subscription list for n number of camera input field
    const cameraCountList = () => {
        if (subscriptionDetailsCount >= 0) {
            subscriptionDetails.push({
                type: "",
                price: "",
                typeError: "",
                priceError: ""
            })
        }
    }

    //click event to delete the subscription input field
    const deleteCamera = (e: any, index: number) => {
        if (index !== 0) {
            subscriptionDetails.splice(index, 1)
            setDeleted(!isDeleted)
        }
    }

    const handleType = (event: any, i: any) => {
        const isPresent = subscriptionDetails.some((item2: any) => item2.type == event.target.value);
        if (event.target.value === '') {
            setSubscriptionDetails((prevState: any) => {
                const customState = [...prevState]
                customState[i].type = event.target.value
                customState[i].typeError = "Please select the type"
                return customState
            })
        } else if (isPresent) {
            setSubscriptionDetails((prevState: any) => {
                const customState = [...prevState]
                customState[i].type = event.target.value
                customState[i].typeError = "Type already selected, please select another type"
                return customState
            })
        } else {
            setSubscriptionDetails((prevState: any) => {
                const customState = [...prevState]
                customState[i].type = event.target.value
                customState[i].typeError = ""
                return customState
            })
        }

    }

    const handlePrice = (event: any, i: any) => {
        if (event.target.value === '') {
            setSubscriptionDetails((prevState: any) => {
                const customState = [...prevState]
                customState[i].price = event.target.value
                customState[i].priceError = "Please enter the subscription price"
                return customState
            })
        } else {
            setSubscriptionDetails((prevState: any) => {
                const customState = [...prevState]
                customState[i].price = event.target.value
                customState[i].priceError = ""
                return customState
            })
        }
    }

    const handlePriceKeydown = (event: any) => {
        if (event.key === "e") {
            event.preventDefault();
        }
    }
    const closePopUp = () => {
        close()
        setEditObject({})
    }
    // check the array of subscription type and price
    const checksubscriptionDetails = (list: any) => {
        list?.filter((item: any, index: any) => {
            if (item?.price === '' && item?.type === '') {
                setSubscriptionDetails((prevState: any) => {
                    const customState = [...prevState]
                    customState[index].typeError = "Please select the type"
                    customState[index].priceError = "Please enter the price"
                    return customState
                })
            } else if (item?.type === '') {
                setSubscriptionDetails((prevState: any) => {
                    const customState = [...prevState]
                    customState[index].typeError = "Please select the type"
                    customState[index].priceError = ""
                    return customState
                })
            } else if (item?.price === '') {
                setSubscriptionDetails((prevState: any) => {
                    const customState = [...prevState]
                    customState[index].priceError = "Please enter the price"
                    customState[index].typeError = ""
                    return customState
                })
            } else {
                setSubscriptionDetails((prevState: any) => {
                    const customState = [...prevState]
                    customState[index].typeError = ""
                    customState[index].priceError = ""
                    return customState
                })
            }
        })
    }
    const createCallback = (res: any) => {
        if (res?.status_code === 0) {
            close()
            dispatch(Actions.masterSubscriptionList(''))
            toast.success("Successfully created")
        } else {
            toast.error(res?.message)
        }
    }
    const manage = () => {
        checksubscriptionDetails(subscriptionDetails)
        if (subscriptionName === '') {
            setError({ ...error, name: "Please enter the subscription name" })
        } else if (validatemaxLengthZipReg(subscriptionName)) {
            setError({ ...error, name: "Please enter the valid subscription name" })
        } else {
            let convertArray = subscriptionDetails?.map((obj: any) => ({
                type: obj.type,
                price: obj?.price,
            }))
            let params = {
                subscription_name: subscriptionName,
                subscription_details: convertArray
            }
            dispatch(Actions.masterSubscriptionCreate(params, createCallback))
        }

    }

    const handleCheckbox = (event: any) => {
        setCheckboxValue(event?.target?.checked)
        if (event?.target?.checked) {
            setSubscriptionDetails((prevState: any) => {
                const customState = [...prevState]
                customState[0].type = 4
                customState[0].typeError = ""
                return customState
            })
        } else {
            setSubscriptionDetails((prevState: any) => {
                const customState = [...prevState]
                customState[0].type = ''
                customState[0].typeError = ""
                return customState
            })
        }
        setSubscriptionDetails((prevState: any) => {
            const customState = [...prevState]
            customState[0].price = ''
            customState[0].priceError = ""
            return customState
        })
    }

    const handleSubscriptionName = (event: any) => {
        setSubscriptionName(event.target.value)
        if (event.target.value === '') {
            setError({ ...error, name: "Please the enter the subscription name" })
        } else if (validatemaxLengthZipReg(event.target.value)) {
            setError({ ...error, name: "Subscription name must be 50 characters or less " })
        } else if (!validateminLength(event.target.value)) {
            setError({ ...error, name: "Subscription name must be 5 characters or more " })
        } else {
            setError({ ...error, name: "" })
        }
    }
    const checkCreate = subscriptionDetails?.filter((item: any, index: any) => item?.typeError !== '' || item?.priceError !== '')

    return (
        <>
            <div className="modal d-block" id="loader" aria-labelledby="loader" aria-hidden="true">
                <div className={"modal-dialog modal-dialog-centered addProductPopup"} >
                    <div className={"modal-content p-4"} style={{ width: "700px", height: "auto" }}>
                        <i className="fa fa-times fa-2x closeIcon" aria-hidden="true"
                            onClick={() => closePopUp()}></i>
                        <div className={"modal-body"}></div>
                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="font-bold">Add Subscription</h3>
                            </div>
                        </div>
                        <div className='card card-body rounded-3 border-0 shadow p-4 mb-3  mt-4'>
                            <div className="row">
                                <div className="col-sm-5 mt-2">
                                    <input type="text" className="subscriptionName" placeholder="Enter subscription name" value={subscriptionName} onChange={(event) => handleSubscriptionName(event)} />
                                    {error?.name && <p className="errorMessage">{error?.name}</p>}
                                </div>
                                {checkboxValue && <div className="col-sm-5 mt-2">
                                    <input type="number" className="subscriptionName" placeholder="Enter subscription price" onKeyDown={(event) => handlePriceKeydown(event)} onChange={(event) => handlePrice(event, 0)} />
                                    {
                                        subscriptionDetails[0]?.priceError && <p className="errorMessage">{subscriptionDetails[0]?.priceError}</p>
                                    }
                                </div>}
                                <div className="col-sm-3 mt-3 d-flex">
                                    <input type="checkbox" className="subscriptionCheckbox" onClick={(event) => handleCheckbox(event)} checked={checkboxValue} />
                                    <label className="mr-2 ml-2">Unlimited</label>

                                </div>
                            </div>
                            {
                                !checkboxValue &&
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="card cameraOnboarding">
                                            <div className="card-body">
                                                <div className="row addButton">
                                                    <button className="btn btn-primary big-btn ml-auto addsubscription"
                                                        disabled={(subscriptionType?.length === subscriptionDetails?.length) || checkCreate?.length !== 0}
                                                        onClick={() => addSubscriptionDetails()}>+ Add</button>
                                                </div>
                                                {
                                                    subscriptionDetails && subscriptionDetails?.map((item, index) =>
                                                        <div className="row mt-4 cabinetContainer" >
                                                            <div className="col">
                                                                <Select
                                                                    placeHolder={"Select subscription type"}
                                                                    array={subscriptionType}
                                                                    isList={false}
                                                                    classname={"subscriptionType"}
                                                                    disabled={false}
                                                                    value={item?.type}
                                                                    onChange={(event: any) => handleType(event, index)
                                                                    }
                                                                />
                                                                {
                                                                    item?.typeError && <p className="errorMessage">{item?.typeError}</p>
                                                                }
                                                            </div>
                                                            <div className={index !== 0 ? "col ml-1" : "col-md-7"}>
                                                                <input type="number" className={`${index == 0 ? "subscriptionPrice firstPrice" : "subscriptionPrice"}`} placeholder="Enter subscription price" onKeyDown={(event) => handlePriceKeydown(event)} onChange={(event) => handlePrice(event, index)} />
                                                                {
                                                                    item?.priceError && <p className="errorMessage mt-3">{item?.priceError}</p>
                                                                }
                                                            </div>
                                                            {
                                                                index !== 0 ?
                                                                    <i className="fa-solid fa-trash mt-3 fa-lg flexChild cursor-pointer"
                                                                        onClick={(e) => deleteCamera(e, index)}></i>
                                                                    : ""
                                                            }
                                                        </div>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='row mb-2 mt-4'>
                                <div className='col-sm-6'></div>
                                <div className='col-sm-6 text-end'>
                                    <button className="btn btn-secondary btn-outline" onClick={() => closePopUp()}>Cancel</button>
                                    <button
                                        className="btn btn-primary big-btn ml-2"
                                        disabled={checkCreate?.length !== 0}
                                        onClick={() => manage()}>{"Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show loaderBackGround"></div>
        </>
    )
}