import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    camera: [],
    error: false
}
export const cameraListReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.CAMERA_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CAMERA_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                camera: action.data?.results,
                error: false
            };
        }
        case Actions.CAMERA_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                camera: [],
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};
export const getCameraListData = (state: any) => state?.CAMERALIST?.camera;
