import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { ResponseGenerator } from './SagaInterface';
import { doGet, doPost } from '../../config';
import END_POINTS from '../../config/Endpoints';
export function* edgeServerSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.EDGE_SERVER_LIST, params, '')
        callback && callback(response?.response)
        yield put(Actions.edgeServerListSuccess(response));
    } catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.edgeServerListFailure({ error }));
    }
}


export function* vendorEdgeServerSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.VENDOR_EDGE_SERVER_LIST, params, '',)
        callback && callback(response?.response)
        yield put(Actions.vendorEdgeServerListSuccess(response));
    } catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.vendorEdgeServerListFailure({ error }));
    }
}

export function* vendorEdgeCreateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.CREATE_EDGE_SERVER, params, '',)
        callback && callback(response)
        yield put(Actions.edgeServerCreateSuccess(response));
    } catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.edgeServerCreateFailure({ error }));
    }
}


export function* vendorEdgeUpdateSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.UPDATE_EDGE_SERVER, params, '',)
        callback && callback(response)
        yield put(Actions.edgeServerUpdateSuccess(response));
    } catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.edgeServerUpdateFailure({ error }));
    }
}

export default function* edgeServerWatcher() {
    yield all([
        takeLatest(Actions.EDGE_SERVER_LIST, edgeServerSaga),
        takeLatest(Actions.VENDOR_EDGE_SERVER_LIST, vendorEdgeServerSaga),
        takeLatest(Actions.EDGE_SERVER_CREATE, vendorEdgeCreateSaga),
        takeLatest(Actions.EDGE_SERVER_UPDATE, vendorEdgeUpdateSaga),
    ]);
}