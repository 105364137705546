import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    historyData: undefined,
    error: false
}
export const historyReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.HISTORY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HISTORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                historyData: action.data,
                error: false
            };
        }
        case Actions.HISTORY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                historyData: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const historyListData = (state: any) => state?.HISTORY_REDUCER?.historyData?.results;
export const historyCount = (state: any) => state?.HISTORY_REDUCER?.historyData?.count
export const historyIsLoading = (state: any) => state?.HISTORY_REDUCER?.isLoading;