import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Actions } from '../../../redux/action'
import { getBranchDetails } from '../../../redux/reducer/BranchesReducer'
import { getLoginData } from '../../../redux/reducer/login'
import { getCabinetSearchList } from '../../../redux/reducer/SearchReducer'
import AppDatePicker from '../../UI/Molecules/AppDatePicker'
import Select from '../../UI/Molecules/Select'
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { toast } from 'react-toastify'
import { getReportApiListData, getReportLoadingData } from '../../../redux/reducer/ReportReducer'
import PlanogramLoader from '../../UI/Molecules/PlanogramLoader'
import BranchTypeAhead from '../Widgets/BranchTypeAhead'
import { vendorListDetails } from '../../../redux/reducer/VendorReducer'
import { useLocation } from 'react-router-dom'
const Reports = () => {
    const EXCEL_EXTENSION = '.xlsx'
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const initialState = {
        startDate: "",
        endDate: "",
        list: "",
        cabinetId: "",
        branchId: "",
        vendorId: ""
    }
    const usersDetails = useSelector(getLoginData)
    const cabinetDetails = useSelector(getCabinetSearchList)
    const isLoading = useSelector(getReportLoadingData)
    const loginDetails = useSelector(getLoginData)
    const [filters, setFilter] = useState<any>(initialState)
    // const [currentPage, setCurrentPage] = useState<number>(1)
    const branchDetails = useSelector(getBranchDetails)
    const [filteredCabinet, setCabinetDetails] = useState<any>([])
    const resportList = useSelector(getReportApiListData)
    const dispatch = useDispatch()
    const vendorList = useSelector(vendorListDetails)
    const [options, setOptions] = useState(null || []);
    const location = useLocation()

    useEffect(() => {
        let url = location?.pathname
        let word = url.split('/')
        dispatch(Actions.report_Api_List())
        getCabinetList("", word[3] !== undefined ? word[3] : "")
    }, [dispatch])

    useEffect(() => {
        const { role } = loginDetails
        if (role !== 1) {
            const { user_id } = loginDetails || {}
            const params = { vendor_id: user_id }
            dispatch(Actions.branchesList(params, branchListCallback))
        }
        role === 1 && dispatch(Actions.vendorOnboardingList(""))
    }, [dispatch])

    const getCabinetList: any = (branch: string, vendorId: any, storeId: any) => {
        const callback = (response: any) => {
            const filteredCabinet = response?.response?.result?.filter((o: any) => o?.branch?.id == branch)
            const convertArray = filteredCabinet?.map((k: any) => ({ value: k.id, ...k }))
            setCabinetDetails(convertArray)
        }
        let params = {
            branchId: branch,
            limit: 100,
            pagenum: 1,
            vendorId: vendorId,
            storeId: storeId
        }
        dispatch(Actions.cabinet_search(params, callback));
    }

    const onStartDate = (date: any) => {
        setFilter({ ...filters, 'startDate': date })
    }

    const onEndDate = (date: any) => {
        setFilter({ ...filters, 'endDate': date })
    }

    const branchListCallback = (res: any) => {
        let data = [];
        if (res?.response) {
            data = res?.response?.result?.map((o: any) =>
            (
                {
                    label: `${o?.name}`, value: o?.id,
                }
            )
            );
        }
        setOptions(data)
    }

    const handleBranchChange = (event: any) => {
        setFilter({ ...filters, 'branchId': event, 'cabinetId': '' })
        const storeObj = result?.find((o: any) => o.id == event?.value)
        if (event?.hasOwnProperty('value')) {
            getCabinetList(event?.value, filters?.vendorId, storeObj?.store)
        }
    }

    const handleCabinetChange = (event: any) => {
        setFilter({ ...filters, 'cabinetId': event.target.value })
    }

    const handleVendorChange = (event: any) => {
        setFilter({ ...filters, 'vendorId': event.target.value, 'cabinetId': '', 'branchId': '' })
        const params = { vendor_id: event.target.value }
        dispatch(Actions.branchesList(params, branchListCallback))
    }

    const handleListChange = (event: any) => {
        setFilter({ ...filters, 'list': event.target.value })

    }

    const checkReportValue = (arr: any, type: any) => {
        if (type === "cabinet_operational_report") {
            //    let data =JSON.parse(JSON.stringify(arr));
            let newArr: any[] = []
            arr.forEach((o: any) => {
                if (o?.variance === null) {
                    o.variance = 'Yet to resolve'
                    newArr.push(o)
                } else if (o?.variance) {
                    let seconds = o?.variance;
                    let hours = seconds / 3600;
                    o.variance = hours + 'hrs'
                    newArr.push(o)
                } else {
                    newArr.push(o)
                }
            })
            return newArr;
        } else if (type === "customer_report") {
            const countDict = arr?.reduce((acc: any, curr: any) => {
                const { customer_email } = curr;
                if (acc[customer_email]) ++acc[customer_email];
                else acc[customer_email] = 1;
                return acc;
            }, {});

            const result = arr?.map((obj: any) => {
                obj["count"] = countDict[obj.customer_email];
                return obj;
            })
            let results = result?.filter((value: any, index: any, self: any) =>
                index === self?.findIndex((t: any) => (
                    t.customer_email === value.customer_email
                ))
            )
            return results
        } else {
            return arr
        }

    }
    const clear = () => {
        setFilter(initialState)
    }
    const onSubmitReport = () => {
        const { user_id } = usersDetails || {}

        const saveAsExcelFile = (buffer: any, fileName: any) => {
            const data = new Blob([buffer], { type: EXCEL_TYPE }) || [];
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
        }
        const callback = (response: any) => {
            if (response?.status_code === 0) {
                if (response?.content?.length > 0) {
                    const worksheet = XLSX.utils.json_to_sheet(checkReportValue(response?.content, filters?.list));
                    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    saveAsExcelFile(excelBuffer, filters?.list)
                    toast.success("Downloaded Successfully")
                } else {
                    toast.error("No Report Found")
                }
            }
            else {
                toast.error("Something went worng")
            }
        }
        let params: any = {
            vendor_id: filters?.vendorId,
        }
        if (filters?.list) {
            params['filter'] = filters?.list
        }
        if (filters?.branchId?.value) {
            params['branch_id'] = filters?.branchId?.value
        }
        if (filters?.cabinetId) {
            params['cabinet_id'] = filters?.cabinetId
        }
        if (filters?.startDate) {
            params['start_date'] = moment(filters?.startDate).format('YYYY-MM-DD')
        }
        if (filters?.endDate) {
            params['end_date'] = moment(filters?.endDate).format('YYYY-MM-DD')
        }
        if (filters?.startDate !== null && filters?.endDate !== null && filters?.list !== '' && filters?.branchId?.value !== "" && filters?.cabinetId !== "") {
            dispatch(Actions.reportList(params, callback))
        }
    }

    const { result = [] } = branchDetails || {}
    const { role, user_id } = loginDetails
    const convertBranchArray = result?.map((obj: any) => ({ value: obj.id, ...obj }))
    const convertreportArray = resportList?.map((obj: any) => ({ value: obj, name: obj.toUpperCase(), ...obj }))
    const convertVendorArray = vendorList?.vendor_details?.map((obj: any) => ({ value: obj.vendor_id, name: obj.first_name, ...obj }))
        return (
        <div className="col-12 ps-lg-4">
            <div className="row mt-4">
                <div className="col-sm-6">
                    <h3 className="font-bold">Reports</h3>
                </div>
            </div>
            <div id="report-options-bar"
                className="card card-body rounded-3 border-0 shadow p-4 mt-2" style={{ height: "500px" }}>
                <div className="row mb-3 align-items-center">
                    <div className="col-12 pb-3">
                        <div className='row'>
                            <div className='col-8 col-sm-4'>Select the dates for report</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-4 col-sm-4 col-lg-4">
                            <AppDatePicker
                                selected={filters?.startDate}
                                placeholder="From"
                                maxDate={new Date()}
                                onChange={(date: any) => onStartDate(date)}
                                notValidTime={true}
                                id='FormDatePicker'
                            />
                        </div>
                        <div className="col-4 col-sm-4 col-lg-4">
                            <AppDatePicker
                                selected={filters?.endDate}
                                placeholder="To"
                                maxDate={new Date()}
                                onChange={(date: any) => onEndDate(date)}
                                notValidTime={true}
                                minDate={filters?.startDate}
                                id='ToDatePicker'
                            />
                        </div>
                        {
                            role === 1 &&
                            <div className="col-4 col-sm-4 col-lg-4">
                                <Select
                                    placeHolder={"All Vendor"}
                                    isList={true}
                                    array={convertVendorArray}
                                    onChange={handleVendorChange}
                                />
                            </div>
                        }
                    </div>
                    <div className='row mt-3'>
                        <div className="col-4 col-sm-4 col-lg-4 mt-sm-0">
                            <BranchTypeAhead
                                value={filters?.branchId}
                                placeholder={"All Branches"}
                                options={options}
                                setOptions={setOptions}
                                vendorId={role == 1 ? filters?.vendorId : user_id}
                                onChange={(event: any) => handleBranchChange(event)}
                            />
                        </div>
                        <div className="col-4 col-sm-4 col-lg-4 mt-sm-0">
                            <Select
                                placeHolder={"All Cabinets"}
                                array={filteredCabinet}
                                isList={true}
                                disabled={!filters?.branchId?.value || filteredCabinet?.length == 0}
                                value={filters?.cabinetId}
                                onChange={(event: any) => handleCabinetChange(event)}
                            />
                        </div>
                        <div className="col-4 col-sm-4 col-lg-4 mt-sm-0">
                            <Select
                                placeHolder={"Choose Report Type"}
                                array={convertreportArray}
                                isList={true}
                                onChange={(event: any) => handleListChange(event)}
                                value={filters?.list}
                            />
                        </div>
                    </div>

                    <div className="row justify-content-end mt-sm-5 mt-3">
                        <div className="col-4 col-lg-2">
                            <button className="btn btn-dark w-100 big-btn" type="button"
                                disabled={(filters?.startDate !== null && filters?.endDate !== null && filters?.list !== '' && filters?.branchId?.value !== "" && filters?.cabinetId !== "") ? false : true}
                                onClick={() => onSubmitReport()}
                                id="onSendEmail">Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <PlanogramLoader />}
        </div >
    )
}

export default Reports