import { Routes, Route } from 'react-router-dom'
import { Login } from './components/HOC/Pages/Login';
import { PlanogramCameraList } from './components/HOC/Pages/PlanogramList';
import { DashBoard } from './components/HOC/Pages/DashBoard'
import PrivateRoutes  from '../src/util/PrivateRoutes';
// import { ProductList } from './components/HOC/Pages/ProductList';
import { UnregisterProduct } from './components/HOC/Pages/UnregisterProduct';
import { Register } from './components/HOC/Pages/Register';
import 'react-toastify/dist/ReactToastify.css';
import { Planogram } from './components/HOC/Pages/Planogam';
import { Testing } from './components/HOC/Pages/Testing';
import { InventoryManagement } from './components/HOC/Pages/vendors/InventoryManagement';
import { UpdateInventory } from './components/HOC/Widgets/UpdateInventory';
import { CabinetDetails } from './components/HOC/Widgets/CabinetDetails';
import { CabinetOnboarding } from './components/HOC/Pages/CabinetOnboarding';
import { ToastContainer } from 'react-toastify';
import Vendors from './components/HOC/Pages/Vendors';
import VendorDetails from './components/HOC/Pages/VendorDetails';
import { ManageVendors } from './components/HOC/Pages/ManageVendors';
import Branches from './components/HOC/Pages/vendors/Branches';
import { CustomerJourney } from './components/HOC/Pages/vendors/CustomerJourney';
import Reports from './components/HOC/Pages/Reports';
import { CabinetList } from './components/HOC/Pages/vendors/CabinetList';
import { Subscription } from './components/HOC/Pages/Subscription';
import ProductList from './components/HOC/Pages/vendors/ProductList';
import { HealthCheck } from './components/HOC/Pages/HealthCheck';
import Category from './components/HOC/Pages/Category';
import SubCategory from './components/HOC/Pages/SubCategory';
import EdgeServerList from './components/HOC/Pages/vendors/edgeServers/EdgeServerList';
import { TroubleShoot } from './components/HOC/Pages/vendors/trobleshoot/TroubleShoot';

function App() {
  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path={'/'} element={<Login />} />
        <Route path='/' element={<PrivateRoutes />}>
          <Route path={'/cameralist'} element={<PlanogramCameraList />} />
          <Route path={'/dashboard'} element={<DashBoard />} />
          <Route path={'/productlist'} element={<ProductList />} />
          <Route path={'/register'} element={<Register />} />
          <Route path={'/planogram'} element={<Planogram />} />
          <Route path={'/testing'} element={<Testing />} />
          <Route path={'/unregisterproduct'} element={<UnregisterProduct />} />
          <Route path={'/inventory'} element={<InventoryManagement />} />
          <Route path={'/updateInventory'} element={<UpdateInventory />} />
          <Route path={'/viewdetails'} element={<CabinetDetails />} />
          <Route path={'/cabinetOnboarding'} element={<CabinetOnboarding />} />
          <Route path={'/cabinetOnboarding/edit'} element={<CabinetOnboarding />} />
          <Route path={'/vendors'} element={<Vendors />} />
          <Route path={'/vendor/details/:id'} element={<VendorDetails />} />
          <Route path={'/vendor/manage'} element={<ManageVendors />} />
          <Route path={'/reports'} element={<Reports />} />
          <Route path={'/troubleshoot'} element={<TroubleShoot />} />
          <Route path={'/healthcheck'} element={<HealthCheck />} />
          <Route path={'/category'} element={<Category />} />
          <Route path={'/subCategory'} element={<SubCategory />} />

          {/* VENDOR ROUTES */}
          <Route path={'/branches'} element={<Branches />} />
          <Route path={'/customer/journey'} element={<CustomerJourney />} />
          <Route path={'/cabinets'} element={<CabinetList />} />
          <Route path={'/inventory/management'} element={<InventoryManagement />} />
          <Route path={'/subscription'} element={<Subscription />} />
          <Route path={'/products'} element={<ProductList />} />
          <Route path={'/edgeserver'} element={<EdgeServerList />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
