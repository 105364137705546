export const MisplacementTable = ({ mainHeader, tableHeader, tableValue, notFound }: { mainHeader: string, tableHeader: string[], tableValue: { misplaced_items: any, shelf_id: number }, notFound: string }) => {
    const misplacementData = (element: any) => {
        let outStr = '';
        element.forEach((ele: any, index: any) =>
            outStr += `${ele.product}: ${ele.count}${index < element.length - 1 ? ',' : ' '}`
        );
        return outStr
    }
    return (
        <>
            <div className="card-header bg-color-yellow">
                {mainHeader}
            </div>
            <div className="card-body">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            {tableHeader &&
                                tableHeader?.map((elements, index) => (
                                    <th key={index} scope="col">
                                        {elements}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableValue ?
                                <tr>
                                    <td>{1}</td>
                                    <td>{tableValue?.shelf_id}</td>
                                    <td>{misplacementData(tableValue?.misplaced_items)}</td>

                                </tr>
                                : (
                                    <tr>
                                        <td className="text-center" colSpan={4}>
                                            <div>
                                                {notFound}<label onClick={() => window.location.reload()}><i className="fa fa-refresh cartRefreshIcons" aria-hidden="true"></i></label>
                                            </div>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}