import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { MESSAGE } from "../../../../config/String"
import { Actions } from "../../../../redux/action"
import { getBranchDetails, getBranchLoader } from "../../../../redux/reducer/BranchesReducer"
import { getLoginData } from "../../../../redux/reducer/login"
import { getVendorDetails } from "../../../../redux/reducer/VendorReducer"
import { CheckBoxMolecules } from "../../../UI/Molecules/CheckboxMolecules"
import { DataNotFound } from "../../../UI/Molecules/DataNotFound"
import { PaginationSession } from "../../../UI/Molecules/Pagination"
import PlanogramLoader from "../../../UI/Molecules/PlanogramLoader"
import { PopupData } from "../../../UI/Molecules/PopUpData"
import BranchesWidget from "../../Widgets/BranchWidget"

const Branches = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const usersDetails = useSelector(getLoginData)
    const branchDetails = useSelector(getBranchDetails)
    const isLoading = useSelector(getBranchLoader)
    const [isShowpopup, setpopup] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>()
    const [editObj, setBranchObj] = useState<any>()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [vendorId, setVendorId] = useState<any>('')
    const location = useLocation()

    const ManageBranches = (flag: boolean) => {
        setpopup(flag)
        setErrors({})
    }

    useEffect(() => {
        getBranchList()
    }, [])
    const getBranchList = (currentPage?:any) => {
        let url = location?.pathname
        let word = url.split('/')
        if (role == 1) {
            setVendorId(word[3])
            const params = { vendor_id: word[3],limit: 10,page:currentPage }
            dispatch(Actions.branchesList(params))
        }else{
            const { user_id } = usersDetails || {}
            const params = { vendor_id: user_id,limit: 10,page:currentPage }
            dispatch(Actions.branchesList(params))
        }
    }

    const disableCallback = (response?: any) => {
        if (response?.status_code === 0) {
            toast.success(response?.message)
            const { user_id } = usersDetails || {}
            const params = { vendor_id: vendorId ? vendorId : user_id, limit: 10, page: currentPage }
            dispatch(Actions.branchesList(params))
        } else {
            toast.error(response?.message)
        }
    }

    //For handle the pagination
    const onChangeHandle = (page: number) => {
        setCurrentPage(page)
        const { user_id } = usersDetails || {}
        const params = { vendor_id: vendorId ? vendorId : user_id, limit: 10, page: page }
        dispatch(Actions.branchesList(params))
    }

    const disableORenableBranch = (event: any, branObj: any) => {
        const params = {
            branch_id: branObj?.id,
            is_active: event.target.checked,
        }
        dispatch(Actions.branchesActive(params, disableCallback))
    }

    const editBraches = (obj: any) => {
        setBranchObj(obj)
        ManageBranches(true)
    }

    const createCallBack = (response: any) => {
        if (response?.status_code === 0) {
            getBranchList()
            ManageBranches(false)
            setCurrentPage(1)
            setBranchObj({})
            toast.success("successfully created")
        } else {
            toast.error(response?.message)
        }
    }
    const updateCallBack = (response: any) => {
        if (response?.status_code === 0) {
            getBranchList(currentPage)
            ManageBranches(false)
            // setCurrentPage(currentPage)
            setBranchObj({})
            toast.success(MESSAGE.UPDATED_SUCCESSFULLY)
        } else {
            toast.error(response?.message)
        }
    }

    const closePopUp = () => {
        setBranchObj({})
        ManageBranches(false)
    }

    const createBranches = (formDetails: any) => {
        const { user_id } = usersDetails || {}
        let params: any = {
            vendor_id: vendorId ? vendorId : user_id,
            branch_name: formDetails?.name,
            address_1: formDetails?.address1,
            address_2: formDetails?.address2,
            zipcode: formDetails?.zipcode
        }
        if (formDetails?.id) {
            params.branch_id = formDetails.id
            dispatch(Actions.branchesUpdate(params, updateCallBack))
        } else {
            dispatch(Actions.createBranches(params, createCallBack))
        }
    }

    const { result = [], total_pages } = branchDetails || {}
    const { role, user_id } = usersDetails

    return (
        <React.Fragment>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-sm-6">
                        {usersDetails?.role === 2 &&
                            <h3 className="font-bold">{MESSAGE.BRANCH.BRANCHES}</h3>}
                    </div>
                    <div className="col-sm-6 text-end">
                        <button className="btn btn-primary big-btn" onClick={() => ManageBranches(true)}>{MESSAGE.BRANCH.ADD_NEW_BRANCH}</button>
                    </div>

                </div>
                <div className="row">
                    {
                        result?.map((branObj: any, index: number) => {
                            return (
                                <div className="col-sm-6" key={index}>
                                    <div className="card card-body rounded-3 p-4 mb-3  mt-4 shadow">
                                        <div className="row">
                                            <div className="col-sm-9">
                                                <p className="font-bold "><b>{MESSAGE.BRANCH.NAME}</b> {branObj?.name}</p>
                                            </div>
                                            <div className="col-sm-1 text-end cursor-pointer">
                                                <CheckBoxMolecules
                                                    checked={branObj?.is_active}
                                                    onChange={(event) => disableORenableBranch(event, branObj)}
                                                />
                                            </div>
                                            <div className="col-sm-1 ml-2 text-end cursor-pointer" onClick={() => editBraches(branObj)}>
                                                <span className="fa fa-edit"></span>
                                            </div>
                                        </div>
                                        <p className="mt-2"><b>{MESSAGE.BRANCH.ADDRESS1}</b> {branObj?.address1}</p>
                                        <p ><b>{MESSAGE.BRANCH.ADDRESS2}</b> {branObj?.address2}</p>
                                        <p ><b>{MESSAGE.BRANCH.Zipcode}</b> {branObj?.zipcode}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {!isLoading && result?.length === 0 && <DataNotFound
                    Error={"No branches found"}
                />}
            </div>

            {isShowpopup && <PopupData
                content={<BranchesWidget ManageBranches={closePopUp} setErrors={setErrors} editObj={editObj} errors={errors} CreateBranches={(formDetails: any) => createBranches(formDetails)} />}
                height={"450px"}
                close={() => closePopUp()}
            />}

            {isLoading && <PlanogramLoader />}

            {(result && result?.length > 0 && total_pages > 1) && <PaginationSession
                onChangeHandle={onChangeHandle}
                currentPage={currentPage}
                sessionCount={total_pages} />}


        </React.Fragment>
    )
}

export default Branches