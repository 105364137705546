import { doAction, doResponseAction } from ".";

const SESSION_END = 'SESSION_END';
const SESSION_END_SUCCESS = 'SESSION_END_SUCCESS';
const SESSION_END_FAILURE = 'SESSION_END_FAILURE';

const SESSION_COMPLETE = 'SESSION_COMPLETE';
const SESSION_COMPLETE_SUCCESS = 'SESSION_COMPLETE_SUCCESS';
const SESSION_COMPLETE_FAILURE = 'SESSION_COMPLETE_FAILURE';
export {
    SESSION_END,
    SESSION_END_FAILURE,
    SESSION_END_SUCCESS,

    SESSION_COMPLETE,
    SESSION_COMPLETE_FAILURE,
    SESSION_COMPLETE_SUCCESS
}
// SESSION_END
export const sessionEnd = (params: {}, callback?: any) => doAction(SESSION_END, params, callback);
export const sessionEndSuccess = (data: {}) => doResponseAction(SESSION_END_SUCCESS, data);
export const sessionEndFailure = (data: {}) => doResponseAction(SESSION_END_FAILURE, data);

// SESSION_COMPLETED
export const sessionComplete = (params: {}, callback?: any) => doAction(SESSION_COMPLETE, params, callback);
export const sessionCompleteSuccess = (data: {}) => doResponseAction(SESSION_COMPLETE_SUCCESS, data);
export const sessionCompleteFailure = (data: {}) => doResponseAction(SESSION_COMPLETE_FAILURE, data);