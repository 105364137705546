import { doAction, doResponseAction } from ".";

const VENDOR_ONBOARDING_LIST = 'VENDOR_ONBOARDING_LIST';
const VENDOR_ONBOARDING_LIST_SUCCESS = 'VENDOR_ONBOARDING_LIST_SUCCESS';
const VENDOR_ONBOARDING_LIST_FAILURE = 'VENDOR_ONBOARDING_LIST_FAILURE';

const VENDOR_ONBOARDING_DETAILS = 'VENDOR_ONBOARDING_DETAILS';
const VENDOR_ONBOARDING_DETAILS_SUCCESS = 'VENDOR_ONBOARDING_DETAILS_SUCCESS';
const VENDOR_ONBOARDING_DETAILS_FAILURE = 'VENDOR_ONBOARDING_DETAILS_FAILURE';

const CREATE_VENDOR = 'CREATE_VENDOR';
const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS';
const CREATE_VENDOR_FAILURE = 'CREATE_VENDOR_FAILURE';

const VENDOT_ONBOARDING_DELETE = 'VENDOT_ONBOARDING_DELETE';
const VENDOT_ONBOARDING_DELETE_SUCCESS = 'VENDOT_ONBOARDING_DELETE_SUCCESS';
const VENDOT_ONBOARDING_DELETE_FAILURE = 'VENDOT_ONBOARDING_DELETE_FAILURE';

const VENDOR_ONBOARDING_UPDATE = 'VENDOR_ONBOARDING_UPDATE';
const VENDOR_ONBOARDING_UPDATE_SUCCESS = 'VENDOR_ONBOARDING_UPDATE_SUCCESS';
const VENDOR_ONBOARDING_UPDATE_FAILURE = 'VENDOR_ONBOARDING_UPDATE_FAILURE';

const VENDOR_ONBOARDING_ACTIVE = 'VENDOR_ONBOARDING_ACTIVE';
const VENDOR_ONBOARDING_ACTIVE_SUCCESS = 'VENDOR_ONBOARDING_ACTIVE_SUCCESS';
const VENDOR_ONBOARDING_ACTIVE_FAILURE = 'VENDOR_ONBOARDING_ACTIVE_FAILURE';

const SET_CURRENT_VENDOR_DETAILS = 'SET_CURRENT_VENDOR_DETAILS';

export {
    VENDOR_ONBOARDING_LIST,
    VENDOR_ONBOARDING_LIST_FAILURE,
    VENDOR_ONBOARDING_LIST_SUCCESS,

    VENDOR_ONBOARDING_DETAILS,
    VENDOR_ONBOARDING_DETAILS_FAILURE,
    VENDOR_ONBOARDING_DETAILS_SUCCESS,

    CREATE_VENDOR,
    CREATE_VENDOR_FAILURE,
    CREATE_VENDOR_SUCCESS,

    VENDOT_ONBOARDING_DELETE,
    VENDOT_ONBOARDING_DELETE_FAILURE,
    VENDOT_ONBOARDING_DELETE_SUCCESS,

    VENDOR_ONBOARDING_UPDATE,
    VENDOR_ONBOARDING_UPDATE_FAILURE,
    VENDOR_ONBOARDING_UPDATE_SUCCESS,

    VENDOR_ONBOARDING_ACTIVE,
    VENDOR_ONBOARDING_ACTIVE_FAILURE,
    VENDOR_ONBOARDING_ACTIVE_SUCCESS,

    SET_CURRENT_VENDOR_DETAILS
}

// VENDOR_ONBOARDING_LIST
export const vendorOnboardingList = (params?: {}, callback?: any) => doAction(VENDOR_ONBOARDING_LIST, params, callback);
export const vendorOnboardingListSuccess = (data = {}) => doResponseAction(VENDOR_ONBOARDING_LIST_SUCCESS, data);
export const vendorOnboardingListFailure = (data = {}) => doResponseAction(VENDOR_ONBOARDING_LIST_FAILURE, data);

// VENDOR_ONBOARDING_DETAILS
export const vendorOnboardingDetails = (params?: {}, callback?: any) => doAction(VENDOR_ONBOARDING_DETAILS, params, callback);
export const vendorOnboardingDetailsSuccess = (data = {}) => doResponseAction(VENDOR_ONBOARDING_DETAILS_SUCCESS, data);
export const vendorOnboardingDetailsFailure = (data = {}) => doResponseAction(VENDOR_ONBOARDING_DETAILS_FAILURE, data);

// CREATE_VENDOR
export const createVendor = (params?: {}, callback?: any) => doAction(CREATE_VENDOR, params, callback);
export const createVendorSuccess = (data = {}) => doResponseAction(CREATE_VENDOR_SUCCESS, data);
export const createVendorFailure = (data = {}) => doResponseAction(CREATE_VENDOR_FAILURE, data);

// VENDOT_ONBOARDING_DELETE
export const vendorOnboardingDelete = (params?: {}, callback?: any) => doAction(VENDOT_ONBOARDING_DELETE, params, callback);
export const vendorOnboardingDeleteSuccess = (data = {}) => doResponseAction(VENDOT_ONBOARDING_DELETE_SUCCESS, data);
export const vendorOnboardingDeleteFailure = (data = {}) => doResponseAction(VENDOT_ONBOARDING_DELETE_FAILURE, data);

// VENDOR_ONBOARDING_UPDATE
export const vendorOnboardingUpdate = (params?: {}, callback?: any) => doAction(VENDOR_ONBOARDING_UPDATE, params, callback);
export const vendorOnboardingUpdateSuccess = (data = {}) => doResponseAction(VENDOR_ONBOARDING_UPDATE_SUCCESS, data);
export const vendorOnboardingUpdateFailure = (data = {}) => doResponseAction(VENDOR_ONBOARDING_UPDATE_FAILURE, data);

// VENDOR_ONBOARDING_UPDATE
export const vendorOnboardingActive = (params?: {}, callback?: any) => doAction(VENDOR_ONBOARDING_ACTIVE, params, callback);
export const vendorOnboardingActiveSuccess = (data = {}) => doResponseAction(VENDOR_ONBOARDING_ACTIVE_SUCCESS, data);
export const vendorOnboardingActiveFailure = (data = {}) => doResponseAction(VENDOR_ONBOARDING_ACTIVE_FAILURE, data);

export const updatedCurrentVendorDetails = (data = {}) => doResponseAction(SET_CURRENT_VENDOR_DETAILS, data);
