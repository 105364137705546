import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { REPORT_LIST, REPORT_LIST_API } from '../action/ReportAction'
import END_POINTS from '../../config/Endpoints'
import { doGet } from '../../config';
import { ResponseGenerator } from './SagaInterface';
export function* report(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.REPORT, params, {})
        yield put(Actions.reportListSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.reportListFailure({ error }));
        callback && callback({ error })
    }
}

export function* reportApiList(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doGet(END_POINTS.REPORT_LIST, params, {})
        yield put(Actions.report_Api_List_Success(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.report_Api_List_Failure({ error }));
        callback && callback({ error })
    }
}

export default function* reportWatcher() {

    yield all([
        takeLatest(REPORT_LIST, report),
        takeLatest(REPORT_LIST_API, reportApiList),
    ]);
}
