import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import END_POINTS from '../../config/Endpoints'
import { ResponseGenerator } from './SagaInterface';
import { doTestingPost } from '../../config';
import { isTestingForMobile } from '../../config/Constant';

export function* sessionEndSaga(param: any) {
    const { params, callback } = param
    try {
        let url = END_POINTS.SESSION_END
        if(isTestingForMobile) {
            url = END_POINTS.SESSION_END_MOBILE
        }
        const response: ResponseGenerator = yield doTestingPost(url, params, '')
        yield put(Actions.sessionEndSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.sessionEndFailure({ error }));
        callback && callback(error)
    }
}

export function* sessionCompleteSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doTestingPost(END_POINTS.SESSION_COMPLETED, params, '')
        yield put(Actions.sessionCompleteSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.sessionCompleteFailure({ error }));
        callback && callback(error)
    }
}
export default function* sessionStartWatcher() {
    yield all([
        takeLatest(Actions.SESSION_END, sessionEndSaga),
        takeLatest(Actions.SESSION_COMPLETE, sessionCompleteSaga),
    ]);
}