import { useSelector } from "react-redux"
import { unregisterIsLoading } from "../../../redux/reducer/UnregisterProductReducer"
import PlanogramLoader from "../../UI/Molecules/PlanogramLoader"
import { UnregisterProductWidget } from "../Widgets/UnregisterProductWidgets"

export const UnregisterProduct = () => {
    const loader = useSelector(unregisterIsLoading)
    return (
        <>
            {
                loader && <PlanogramLoader />
            }
            <UnregisterProductWidget />
        </>
    )
}