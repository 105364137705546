import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker'
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  // scales: {
  //   yAxis: {
  //     min: 0,
  //     max: 100,
  //   }},
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Customer Journey',
    },
  },
};


function BarGraph(props: any) {
  let labels ;
  const { filter, data } = props
  const count = data?.map((o: any) => o?.sessions)

  switch (filter) {
    case 'date':
      labels = data?.map((o: any) => moment(o?.start_time__date).format('DD/MM/YYYY'))
      break;
    case 'week':
      labels = data?.map((o: any) => o?.start_time__week + "week")
      break;
    case 'month':
      labels = data?.map((o: any) => moment(`${o?.start_time__month}` + `/01/${o?.start_time__year}`).format('MMMM-YYYY'))
      break;
    case 'year':
      labels = data?.map((o: any) => (o?.start_time__year))
      break;
    default:
      labels = []
      break;
  }
  const Bardata = {
    labels,
    count,
    datasets: [
      {
        label: '',
        // data: labels.map(() => faker.datatype.number({ min: 10, max:100  })),
        backgroundColor: '#006e74',
        data: count,
        scale: { min: 0, max: 10 }

      },
    ],
  };
  return <Bar options={options} data={Bardata} />;
}

export default BarGraph
