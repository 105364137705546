import { doAction, doResponseAction } from ".";

const TROUBLE_SHOOT_LIST = 'TROUBLE_SHOOT_LIST';
const TROUBLE_SHOOT_LIST_SUCCESS = 'TROUBLE_SHOOT_LIST_SUCCESS';
const TROUBLE_SHOOT_LIST_FAILURE = 'TROUBLE_SHOOT_LIST_FAILURE';

export {
    TROUBLE_SHOOT_LIST,
    TROUBLE_SHOOT_LIST_FAILURE,
    TROUBLE_SHOOT_LIST_SUCCESS,
}

// TROUBLE_SHOOT_LIST
export const troubleShootList = (params?: {}, callback?: any) => doAction(TROUBLE_SHOOT_LIST, params, callback);
export const troubleShootListSuccess = (data = {}) => doResponseAction(TROUBLE_SHOOT_LIST_SUCCESS, data);
export const troubleShootListFailure = (data = {}) => doResponseAction(TROUBLE_SHOOT_LIST_FAILURE, data);
