import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import END_POINTS from '../../config/Endpoints';
import { doPostMultiform } from '../../config';
import { ResponseGenerator } from './SagaInterface';

export function* registerSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPostMultiform(END_POINTS.REGISTER_PRODUCT, params, '')
        callback && callback(response)
        yield put(Actions.registerSuccess(response));

    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.registerFailure({ error }));
        }
    }
}

export default function* registerWatcher() {
    yield all([
        takeLatest(Actions.REGISTER, registerSaga),
    ]);
}