import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import { PLANOGRAM_STATUS } from '../action/PlanogramStatus'
import { doPost } from '../../config';
import { ResponseGenerator } from './SagaInterface';
import END_POINTS from '../../config/Endpoints';
export function* planogramStatusSaga(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.PLANOGRAM_STATUS, params, '')
        yield put(Actions.planogramStatusSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.planogramStatusFailure({ error }));
        callback && callback({ error })
    }
}

export default function* planogramStatusWatcher() {

    yield all([
        takeLatest(PLANOGRAM_STATUS, planogramStatusSaga),
    ]);
}
