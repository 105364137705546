import * as React from 'react';
import { useEffect } from "react"
import { MESSAGE } from "../../../config/String"
import END_POINTS from '../../../config/Endpoints'
import Rack from '../../../config/cooler.json'
const planogram_header = [
    MESSAGE.S_NO,
    MESSAGE.OBJECTS_IN_SHELF,
    MESSAGE.UPDATED_TIME,
    MESSAGE.IMAGE
]
export const Planogram3DWidget = (props: any) => {
    const { annotateArray } = props
    let shelvesProducts: any = annotateArray?.map((elem: any) => ({
        id: elem?.id,
        shelveName: elem?.name,
        shelveHeight:'30',
        // shelveHeight: Math.max(...elem?.annotation && elem?.annotation?.length > 0 && elem?.annotation?.map((obj:any) => obj?.product?.height)) + 10,
        products: elem?.annotation ? elem?.annotation?.sort((a: any, b: any) => a?.mark?.x - b?.mark?.x)?.map((sortObj: any) => ({
            "id": sortObj?.product?.id,
            "name": sortObj?.product?.name,
            "imageUrl": `http://localhost:9100${sortObj?.product?.img}`,
            "upc": sortObj?.product?.sku_id,
            "heightInCm": Number(sortObj?.product?.height),
            "widthInCm": Number(sortObj?.product?.width),
            "depthInCm": Number(sortObj?.product?.breadth),
            "productView": "Front_View"
        })) : []
    }))



    let cooler = {
        "id": 6,
        "rackName": "A",
        "width": "67",
        "height": shelvesProducts?.length * 40,
        "depth": "21.5",
        "type": 2,
        "shelves": shelvesProducts,
        "deleted": false
    }
    useEffect(() => {
        setTimeout(() => {
        let iframe: any = document.getElementById('iframe_rack')
        var iWindow = iframe?.contentWindow?.postMessage({ message: cooler }, "*")
        }, 2000)
    }, [cooler])

    return (<>
        <div className='rack-canvas'>
            <iframe id="iframe_rack" src="http://shop-planogram.proglint.com/frame" style={{ width: '100%', height: '100%', boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px' }}></iframe>
        </div>
    </>)
}