import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    healthCheckList: [],
    error: false
}
export const HealthCheckReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        //HEALTH_CHECK_LIST
        case Actions.HEALTH_CHECK_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HEALTH_CHECK_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                healthCheckList: action.data,
                error: false
            };
        }
        case Actions.HEALTH_CHECK_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                healthCheckList: [],
                error: action.error,
            };
        }
        case Actions.REMOVE_CHECK_LIST: {
            return {
                ...state,
                isLoading: false,
                healthCheckList: [],
                error: false,
            };
        }
        default:
            return state;
    }
};

export const getHealthCheck = (state: any) => state?.HEALTH_CHECK?.healthCheckList;
export const getHealthCheckLoader = (state: any) => state?.HEALTH_CHECK?.isLoading;
