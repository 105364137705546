import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    user: undefined,
    error: false
}
export const updatePlanogramReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.UPDATE_PLANOGRAM: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_PLANOGRAM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                user: action.data,
                error: false
            };
        }
        case Actions.UPDATE_PLANOGRAM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                user: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getUpdateData = (state: any) => state?.UPDATE_PLANOGRAM?.user?.response;
export const updateIsLoading = (state: any) => state?.UPDATE_PLANOGRAM?.isLoading;