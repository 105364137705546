import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    troubleShootList: [],
    error: false
}
export const TroubleShootReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        //TROUBLE_SHOOT_LIST
        case Actions.TROUBLE_SHOOT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.TROUBLE_SHOOT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                troubleShootList: action.data,
                error: false
            };
        }
        case Actions.TROUBLE_SHOOT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                troubleShootList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export const getTroubleShootCheck = (state: any) => state?.TROUBLE_SHOOT?.troubleShootList;
export const getTroubleShootCheckLoader = (state: any) => state?.TROUBLE_SHOOT?.isLoading;