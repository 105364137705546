import React from 'react'

type select = {
    placeHolder?: string;
    value?: string;
    onChange?: any;
    array?: any,
    isList?: any,
    error?: any,
    disabled?: any,
    classname?: any
}

const Select = (props: select) => {
    return (<>
        <select className={`form-select ${!props.isList ? `select-box-height ${props.classname}` : ''}`} aria-label="Default select example"
            onChange={(event) => props.onChange(event)}
            disabled={props.disabled}
            style={{ height: '44px' }}
            value={props.value}>
            <option value={""}>{props.placeHolder}</option>
            {props.array?.map((obj: any,index:any) => {
                return (
                    <option value={obj?.value}>{obj?.name}</option>
                )
            })}

        </select>
        <p className='errorMessage'>{props.error}</p>
    </>
    )
}

export default Select