import { doAction, doResponseAction } from ".";

const HISTORY = 'HISTORY';
const HISTORY_SUCCESS = 'HISTORY_SUCCESS';
const HISTORY_FAILURE = 'HISTORY_FAILURE';
export {
    HISTORY,
    HISTORY_FAILURE,
    HISTORY_SUCCESS,
}

// History
export const history = <T>(params: {}, callback?: T) => doAction(HISTORY, params, callback);
export const historySuccess = (data: {}) => doResponseAction(HISTORY_SUCCESS, data);
export const historyFailure = (data: {}) => doResponseAction(HISTORY_FAILURE, data);