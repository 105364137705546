import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    isLoading: false,
    autoFill: {},
    error: false
}
export const autoFillReducer = (state = initialState, action: ActionInterface) => {
    switch (action.type) {
        case Actions.AUTO_FILL: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.AUTO_FILL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                autoFill: action.data,
                error: false
            };
        }
        case Actions.AUTO_FILL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                autoFill: {},
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};

export const autoFillLoader = (state: any) => state?.AUTO_FILL_REDUCER.isLoading;
