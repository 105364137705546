import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action';
import END_POINTS from '../../config/Endpoints'
import { ResponseGenerator } from './SagaInterface';
import { doPost } from '../../config';

export function* updatePlanogram(param: any) {
    const { params, callback } = param
    try {
        const response: ResponseGenerator = yield doPost(END_POINTS.UPDATE_PLANOGRAM, params, '')
        if (callback) {
            callback(response)
        }
        yield put(Actions.updateListSuccess(response));

    } catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.updateListFailure({ error }));
    }
}
export default function* updatePlanogramWatcher() {

    yield all([
        takeLatest(Actions.UPDATE_PLANOGRAM, updatePlanogram),
    ]);
}