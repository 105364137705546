import { doAction, doResponseAction } from ".";

const AUTO_FILL = 'AUTO_FILL';
const AUTO_FILL_SUCCESS = 'AUTO_FILL_SUCCESS';
const AUTO_FILL_FAILURE = 'AUTO_FILL_FAILURE';
export {
    AUTO_FILL,
    AUTO_FILL_FAILURE,
    AUTO_FILL_SUCCESS,
}

// AutoFill
export const autoFill = <T>(params: {}, callback: T) => doAction(AUTO_FILL, params, callback);
export const autoFillSuccess = (data: {}) => doResponseAction(AUTO_FILL_SUCCESS, data);
export const autoFillFailure = (data: {}) => doResponseAction(AUTO_FILL_FAILURE, data);