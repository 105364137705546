import React from 'react'
import { Input } from '../Atoms/Input'

type inputField = {
    label?: string;
    placeholder?: string;
    inputType: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    columnSize?: number;
    name?: string;
    value?: any;
    error?: any;
    disabled?: boolean;
    className?:string;
}

const InputField = (props: inputField) => {
    return (
        <div className={`col-sm-${props.columnSize}`}>
            {
                props.inputType === "file" ?
                    <Input inputType={props.inputType} inputClassName="form-control" id="floatingInput"
                        placeholder={props.placeholder}
                        inputname={props.name}
                        handleChange={props.onChange} disable={props.disabled} /> :
                    <div className="form-floating">
                        <Input inputType={props.inputType} inputClassName={props.className?props.className:"form-control"} id="floatingInput"
                            placeholder={props.placeholder}
                            inputname={props.name}
                            handleChange={props.onChange}
                            value={props.value} disable={props.disabled} />
                        <label htmlFor="floatingInput">{props.label}</label>
                        <p className="errorMessage">
                            {
                                props.error
                            }
                        </p>
                    </div>
            }
        </div>
    )
}

export default InputField