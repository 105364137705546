import { takeLatest, all, put } from 'redux-saga/effects';
import END_POINTS from '../../config/Endpoints'
import { ResponseGenerator } from './SagaInterface';
import { Actions } from '../action';
import { doTestingPost } from '../../config';
import { isTestingForMobile } from '../../config/Constant';

export function* sessionStartSaga(param: any) {
    const { callback, params } = param
    try {
        let url = END_POINTS.SESSION_START
        if(isTestingForMobile)
            url = END_POINTS.SESSION_START_MOBILE

        const response: ResponseGenerator = yield doTestingPost(url, params, '')
        yield put(Actions.sessionSuccess(response));
        callback && callback(response)
    } catch (error) {
        yield put(Actions.sessionFailure({ error }));
        callback && callback(error)
    }
}
export default function* sessionStartWatcher() {
    yield all([
        takeLatest(Actions.SESSION_START, sessionStartSaga),
    ]);
}