import { Actions } from '../action/index'
import { ActionInterface } from './interface';
const initialState = {
    id: undefined
}
export const getCameraIdReducer = (state = initialState, action: ActionInterface) => {
    if (action.type === Actions.CAMERA_ID) {
        return {
            ...state,
            isLoading: true,
            id: action.params
        };
    }
    else {
        return state;
    }
};
export const getCameraIdData = (state: any) => state?.GET_CAMERA_ID?.id;
